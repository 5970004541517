import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";

import SearchBar from "../../../components/SearchBar";
import { CustomButton, CustomTable, Text } from "../../../shared/uiComponents";
import { useInitializeURLParams } from "../../../shared/Helpers/hooks";
import { Container, Header } from "../StyledComponents";
import MobileView from "./mobileView";
import { Headers, TableBody } from "./tableInfo";
import { useCheckPermission } from "../Permissions/helpers";

import { getPatients } from "../../../redux/State/clientSlice/clientSlice";
import { DispatchProperties, useSelector } from "../../../redux/store";
import { PERMISSIONS } from "../../../App/constants";

const Patients = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<DispatchProperties>();
  const [params] = useSearchParams();
  useInitializeURLParams();
  const { permissionGranted } = useCheckPermission(PERMISSIONS.CLIENT.CREATE);

  const patientsData = useSelector((state) => state.client.patients);
  const loading = useSelector((state) => state.client.loading);

  const addPatientHandler = () => navigate("/admin/patients/new");

  useEffect(() => {
    const page = params.get("page");
    const pageSize = params.get("pageSize");
    const searchString = params.get("search") || "";
    const orderBy = params.get("orderBy") || "";
    const direction = params.get("direction") || "";
    const status = params.get("status");

    if (!page || !pageSize) return;

    dispatch(
      getPatients({ page, pageSize, searchString, orderBy, direction, status })
    );
  }, [params, dispatch]);

  return (
    <Container>
      <Header>
        <Text title={"Clients"} size="mediumBold" />
        <Header>
          {permissionGranted && (
            <CustomButton
              title={"Add client"}
              className={"marginRight16"}
              onClick={addPatientHandler}
            />
          )}
          <SearchBar />
        </Header>
      </Header>
      <CustomTable
        headers={Headers}
        data={patientsData}
        TableBody={TableBody}
        loading={loading}
      />
      <MobileView {...patientsData} />
    </Container>
  );
};

export default Patients;
