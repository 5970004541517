import { ChangeEventHandler, FocusEventHandler, FC } from "react";
import {
  InputAdornment,
  InputBase,
  InputProps,
  styled,
  IconButton,
} from "@mui/material";
import PhoneInput, {
  isPossiblePhoneNumber,
  Value,
} from "react-phone-number-input";

import "react-phone-number-input/style.css";
import "./input.scss";

import {
  black500,
  black900,
  red500,
  blue400,
  white100,
} from "../../Helpers/colors";
import Text from "../Text";

type inputTypes = "text" | "email" | "hidden" | "number" | "password";

export interface InputProperties {
  value: string | number;
  setValue: ChangeEventHandler<HTMLInputElement>;
  onBlur?: FocusEventHandler<HTMLInputElement>;
  disabled?: boolean;
  label?: string;
  placeholder?: string;
  description?: string;
  error?: boolean;
  errorMessage?: string;
  multiline?: MultilineInput;
  className?: string;
  type?: inputTypes;
  EndAdornment?: EndAdornment;
  maxLength?: number;
}

interface MultilineInput {
  multiline: boolean;
  rowCount?: number;
}

interface EndAdornment {
  Icon: FC;
  onClickEndAdornment?: () => void;
}

export const CustomizedInput = styled(InputBase)<InputProps>((props) => ({
  border: "1px solid",
  borderColor: black500,
  borderRadius: "10px",
  background: white100,
  color: black900,
  padding: 0,
  width: "100%",
  height: "40px",
  borderBottom: props.error ? "2px solid" : "1px solid",
  borderBottomColor: props.error ? red500 : black500,
  "& .MuiInputBase-input": {
    padding: "8px 10px",
    "&:focus": {
      borderRadius: "10px",
      boxShadow: `0 0 0 0.2rem ${blue400}`,
    },
  },
}));

const CustomInput: FC<InputProperties> = ({
  value,
  setValue,
  onBlur,
  error = false,
  errorMessage = "",
  label = "",
  description = "",
  multiline = {
    multiline: false,
    rowCount: 2,
  },
  className = "",
  type = "text",
  placeholder,
  disabled = false,
  EndAdornment,
  maxLength = 255,
}) => {
  return (
    <div className={className}>
      <Text
        className={`inputLabel ${!!label ? "active" : ""}`}
        title={label}
        size={"tinyBold"}
      />
      <CustomizedInput
        error={error}
        multiline={multiline?.multiline}
        minRows={multiline?.rowCount}
        type={type}
        value={value}
        onBlur={onBlur}
        onChange={setValue}
        placeholder={placeholder}
        disabled={disabled}
        endAdornment={
          !!EndAdornment ? (
            <InputAdornment position={"end"}>
              <IconButton onClick={EndAdornment.onClickEndAdornment}>
                <EndAdornment.Icon />
              </IconButton>
            </InputAdornment>
          ) : null
        }
        inputProps={{ maxLength: multiline.multiline ? 10000 : maxLength }}
        style={{ height: "max-content" }}
      />
      <Text
        className={`error ${errorMessage ? "active" : ""}`}
        title={errorMessage}
        size={"tiny"}
        textColor={red500}
      />
      <Text
        className={`inputDescription ${
          !error && !!description ? "active paddingTop8" : ""
        }`}
        title={description}
        size={"tiny"}
      />
    </div>
  );
};

export const CustomPhoneInput = ({
  className,
  label,
  setValue,
  value,
  error = false,
  errorMessage = "",
  onBlur,
}: {
  className?: string;
  label: string;
  setValue: (value: Value) => void;
  value: Value;
  error?: boolean;
  errorMessage?: string;
  onBlur?: FocusEventHandler<HTMLInputElement>;
}) => {
  return (
    <div className={className}>
      <Text
        className={`inputLabel ${!!label ? "active" : ""}`}
        title={label}
        size={"tinyBold"}
      />
      <PhoneInput
        defaultCountry="US"
        className="customPhoneInput"
        onChange={setValue}
        value={value}
        maxLength={!!value && isPossiblePhoneNumber(value) ? value.length : 20}
        onBlur={onBlur}
      />
      <Text
        className={`error ${error ? "active" : ""}`}
        title={errorMessage}
        size={"tiny"}
        textColor={red500}
      />
    </div>
  );
};

export default CustomInput;
