import { EXPORT } from "./constants";
import { ExportClientGraphsProperties, ExportClientReportsProperties, SoapNotesPDFProperties } from "./exportProperties";
import { FetchAPI } from "./Main/fetchAPI";

export const ExportAPI = {
    Pdf: {
        getClientGraph: (data: ExportClientGraphsProperties) => {
            const {
                clientId,
                startDate,
                endDate,
                includeTargets,
                includeBehaviors
            } = data;
            const query = new URLSearchParams();

            if (!!startDate) query.set("startDate", startDate);
            if (!!endDate) query.set("endDate", endDate);
            query.set("includeTargets", `${!!includeTargets}`);
            query.set("includeBehaviors", `${!!includeBehaviors}`);

            return FetchAPI.getJson({
                urlStr: `${EXPORT}/clients/${clientId}/graphs?${query.toString()}`,
            });
        },
        getClientReports: (data: ExportClientReportsProperties) => {
            const {
                clientId,
                startDate,
                endDate,
                includeTrials,
            } = data;
            const query = new URLSearchParams();

            if (!!startDate) query.set("startDate", startDate);
            if (!!endDate) query.set("endDate", endDate);
            query.set("includeTrials", `${!!includeTrials}`);

            return FetchAPI.getJson({
                urlStr: `${EXPORT}/clients/${clientId}/reports?${query.toString()}`,
            });
        },
        getSoapNotePDF: (data: SoapNotesPDFProperties) => {

            const {
                reportId,
                includeTrials,
            } = data;
            const query = new URLSearchParams();
            query.set("includeTrials", `${!!includeTrials}`);

            return FetchAPI.getJson({
                urlStr: `${EXPORT}/soap-notes/${reportId}/pdf?${query.toString()}`,
            });
        },
        downloadSoapNotePDF: (data: SoapNotesPDFProperties) => {

            const {
                reportId,
                includeTrials,
            } = data;
            const query = new URLSearchParams();
            query.set("includeTrials", `${!!includeTrials}`);

            return FetchAPI.getJson({
                urlStr: `${EXPORT}/soap-notes/${reportId}/pdf/download?${query.toString()}`,
            });
        },
    }
}