import { useState } from "react";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import { CircularProgress, SelectChangeEvent } from "@mui/material";
import { toast } from "react-toastify";
import { DeleteOutlineOutlined as Delete } from "@mui/icons-material";

import {
  CustomAlert,
  CustomButton,
  CustomSelect,
  Text,
} from "../../../shared/uiComponents";

import { DispatchProperties, StateProperties } from "../../../redux/store";
import {
  deletePrompts,
  updateDTTPrompts,
  updateTAPrompts,
} from "../../../redux/State/clientSlice/dataCollectionSlice";
import { errorNormalizer } from "../../../shared/Helpers/functions";
import { Loader } from "../../AdminPanel/StyledComponents";
import {
  DTTPromptsResponseProperties,
  TAPromptsResponseProperties,
} from "../../../redux/API/ClientAPIHelpers/calendarProperties";
import { UpdatePromptsProperties } from "../../../redux/API/ClientAPIHelpers/dataCollectionProperties";

export const TAPromptInfoContent = ({
  targetId,
  item,
  fetchHistory,
}: {
  targetId: string;
  item: TAPromptsResponseProperties;
  fetchHistory: () => void;
}) => {
  const dispatch = useDispatch<DispatchProperties>();

  const [areYouSure, setAreYouSure] = useState<boolean>(false);
  const [promptCodeId, setPromptCodeId] = useState<string>(item.promptCode.id);

  const promptsData = useSelector(
    (state: StateProperties) => state.dataCollection.promptCodes
  );
  const loading = useSelector(
    (state: StateProperties) => state.dataCollection.loading
  );

  const onPromptUpdate = (event: SelectChangeEvent<string>) => {
    const { value } = event.target;

    if (!value) return;
    setPromptCodeId(value);
    const data: UpdatePromptsProperties = {
      targetId,
      data: [{ promptCodeId: value, responseId: item.id }],
    };
    dispatch(updateTAPrompts(data))
      .then(unwrapResult)
      .then(() => {
        fetchHistory();
        toast("Success");
      })
      .catch(errorNormalizer);
  };

  const onDeleteHandler = () => {
    dispatch(deletePrompts([item.id]))
      .then(unwrapResult)
      .then(() => {
        fetchHistory();
        toast("Deleted");
      })
      .catch(errorNormalizer)
      .finally(() => setAreYouSure(false));
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        borderBottom: "1px solid rgba(0, 0, 0, 0.08)",
        paddingBottom: "8px",
        paddingTop: "8px",
      }}
    >
      <div>
        <div className="marginBottom8">
          <Text title="Specialist" size="tinyBold" />
          <Text title={item.user.fullName} size="tiny" />
        </div>
        <div className="marginBottom8">
          <Text title="Step" size="tinyBold" />
          <Text title={item.step.name} size="tiny" />
        </div>
        <div className="marginBottom8">
          <Text title="Date" size="tinyBold" />
          <Text
            title={dayjs(item.date).format("MM/DD/YYYY : hh:mm A")}
            size="tiny"
          />
        </div>
      </div>
      {!loading ? (
        <div>
          <Delete
            style={{ width: "40px", cursor: "pointer", float: "right" }}
            onClick={() => setAreYouSure(true)}
            className="marginBottom8"
          />
          <CustomSelect
            label="Prompt Code"
            data={promptsData}
            value={promptCodeId}
            setValue={onPromptUpdate}
          />
        </div>
      ) : (
        <Loader />
      )}
      <CustomAlert
        open={areYouSure}
        onClose={() => setAreYouSure(false)}
        title="Are you sure?"
        Content={() => (
          <div style={{ display: "flex", justifyContent: "space-around" }}>
            <CustomButton
              title="Delete"
              onClick={onDeleteHandler}
              loading={loading}
            />
            <CustomButton
              title="Cancel"
              onClick={() => setAreYouSure(false)}
              secondaryButton
            />
          </div>
        )}
      />
    </div>
  );
};

export const DTTPromptInfoContent = ({
  targetId,
  item,
  fetchHistory,
}: {
  targetId: string;
  item: DTTPromptsResponseProperties;
  fetchHistory: () => void;
}) => {
  const dispatch = useDispatch<DispatchProperties>();

  const [areYouSure, setAreYouSure] = useState<boolean>(false);
  const [promptCodeId, setPromptCodeId] = useState<string>(item.promptCode.id);

  const loading = useSelector(
    (state: StateProperties) => state.dataCollection.loading
  );
  const promptsData = useSelector(
    (state: StateProperties) => state.dataCollection.promptCodes
  );

  const onPromptUpdate = (event: SelectChangeEvent<string>) => {
    const { value } = event.target;

    if (!value) return;
    setPromptCodeId(value);
    const data: UpdatePromptsProperties = {
      targetId,
      data: [{ promptCodeId: value, responseId: item.id }],
    };
    dispatch(updateDTTPrompts(data))
      .then(unwrapResult)
      .then(() => {
        fetchHistory();
        toast("Success");
      })
      .catch(errorNormalizer);
  };

  const onDeleteHandler = () => {
    dispatch(deletePrompts([item.id]))
      .then(unwrapResult)
      .then(() => {
        fetchHistory();
        toast("Deleted");
      })
      .catch(errorNormalizer)
      .finally(() => setAreYouSure(false));
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        borderBottom: "1px solid rgba(0, 0, 0, 0.08)",
        paddingBottom: "8px",
        paddingTop: "8px",
      }}
    >
      <div>
        <div className="marginBottom8">
          <Text title="Specialist" size="tinyBold" />
          <Text title={item.user.fullName} size="tiny" />
        </div>
        <div className="marginBottom8">
          <Text title="Date" size="tinyBold" />
          <Text
            title={dayjs(item.date).format("MM/DD/YYYY : hh:mm A")}
            size="tiny"
          />
        </div>
      </div>
      <div>
        {!loading ? (
          <div>
            <Delete
              style={{ width: "40px", cursor: "pointer", float: "right" }}
              onClick={() => setAreYouSure(true)}
              className="marginBottom8"
            />
            <CustomSelect
              label="Prompt Code"
              data={promptsData}
              value={promptCodeId}
              setValue={onPromptUpdate}
            />
          </div>
        ) : (
          <CircularProgress size={64} color="inherit" />
        )}
        <CustomAlert
          open={areYouSure}
          onClose={() => setAreYouSure(false)}
          title="Are you sure?"
          Content={() => (
            <div style={{ display: "flex", justifyContent: "space-around" }}>
              <CustomButton
                title="Delete"
                onClick={onDeleteHandler}
                loading={loading}
              />
              <CustomButton
                title="Cancel"
                onClick={() => setAreYouSure(false)}
                secondaryButton
              />
            </div>
          )}
        />
      </div>
    </div>
  );
};
