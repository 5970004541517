import { useRef, MutableRefObject } from "react";
import jsPDF from "jspdf";
import { CustomButton } from "../../../../../../shared/uiComponents";
import "./downloadPdfAnnotations.scss";
import BodyPdfAnnotations from "./bodyPdfAnnotations";
import dayjs from "dayjs";

const PdfAnnotations = ({
  annotationsList,
  patientName,
  name,
  title1,
  title2,
}: {
  annotationsList: any;
  patientName: string;
  name: string;
  title1: string;
  title2: string;
}) => {
  const reportTemplateRef = useRef() as MutableRefObject<HTMLDivElement>;

  const today = new Date();
  const todayDayjs = dayjs(today);

  const handleGeneratePdf = () => {
    const doc = new jsPDF({
      format: "a4",
      unit: "px",
    });

    doc.html(reportTemplateRef.current, {
      callback: (doc) =>
        doc.save(
          `${todayDayjs.format("hh_mm_MM/DD/YYYY")}-${patientName}-${name}`
        ),
    });
  };

  return (
    <>
      <CustomButton title={"Generate PDF"} onClick={handleGeneratePdf} />
      <BodyPdfAnnotations
        reportTemplateRef={reportTemplateRef}
        title1={title1}
        patientName={patientName}
        title2={title2}
        name={name}
        annotationsList={annotationsList}
      />
      {/* <div className="display-none">
        <div ref={reportTemplateRef}>
          <div className="content-annotation">
            <h2>Annotations description</h2>
            <h3>
              <span>{title1}:</span> {patientName}
            </h3>
            <h3>
              <span>{title2}:</span> {name}
            </h3>
            <div className="wrapper-list">
              <div className="title-block">
                <h4>Description</h4>
                <h4>Date</h4>
              </div>
              <div className="body-block">
                {annotationsList &&
                  annotationsList.map((item) => (
                    <div key={item.id} className="item-block">
                      <p>{item.note}</p>
                      <p>{dayjs(new Date(item.date)).format("MM-DD-YYYY")}</p>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
      </div> */}
    </>
  );
};

export default PdfAnnotations;
