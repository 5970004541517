import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";

import { Container, Header } from "../../AdminPanel/StyledComponents";
import SearchBar from "../../../components/SearchBar";
import { CustomTable, Text } from "../../../shared/uiComponents";
import { useInitializeURLParams } from "../../../shared/Helpers/hooks";

import { Headers, TableBody } from "./tableInfo";
import MobileView from "./mobileView";

import { DispatchProperties, useSelector } from "../../../redux/store";
import { getUserPatients } from "../../../redux/State/clientSlice/userClientSlice";

const Patients = () => {
  const dispatch = useDispatch<DispatchProperties>();

  const [params] = useSearchParams();
  useInitializeURLParams();

  const userId = useSelector((state) => state.account.user.id);
  const userPatients = useSelector((state) => state.userClient.userPatients);

  const loading = useSelector((state) => state.userClient.loadingGetUserPatients);

  useEffect(() => {
    const page = params.get("page");
    const pageSize = params.get("pageSize");
    const searchString = params.get("search") || "";
    const orderBy = params.get("orderBy") || "";
    const direction = params.get("direction") || "";
    const status = params.get("status");

    if (!page || !pageSize || !userId) return;

    dispatch(
      getUserPatients({
        userId,
        page,
        pageSize,
        searchString,
        orderBy,
        direction,
        status,
      })
    );
  }, [params, userId, dispatch]);

  return (
    <Container>
      <Header>
        <Text title={"Clients"} size="mediumBold" />
        <Header>
          <SearchBar />
        </Header>
      </Header>
      <CustomTable
        headers={Headers}
        data={userPatients}
        TableBody={TableBody}
        loading={loading}
      />
      <MobileView {...userPatients} />
    </Container>
  );
};

export default Patients;
