import { useEffect, useReducer, useState } from "react";
import { useDispatch } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import { useNavigate, useSearchParams } from "react-router-dom";
import dayjs from "dayjs";
import { toast } from "react-toastify";

import BehaviorTreatmentData from "./behaviorTreatmentData";
import { AdminTypes } from "../../../../../components/Action";
import { errorNormalizer } from "../../../../../shared/Helpers/functions";
import {
  SOAP_NOTES,
  SaveSoapNote,
  SoapNoteButtons,
  hasAnyData,
  isActionAllowed,
  removeSavedSoapNoteData,
  ForcedOperation,
} from "../helpers";
import {
  ACTIONS,
  behaviorTreatmentReducer,
  initialBehaviorTreatmentState,
} from "./behaviorTreatmentReducer";
import {
  SoapNoteBehaviorTreatmentType,
  UpdateBehaviorTreatmentProperties,
} from "../../SoapNoteTypesProperties/behaviorTreatmentTypeProperties";

import { DispatchProperties, useSelector } from "../../../../../redux/store";
import {
  getSoapNote,
  updateBehaviorTreatment,
} from "../../../../../redux/State/clientSlice/soapNoteSlice";
import {
  setDate,
  setEndTime,
  setStartTime,
} from "../../../../../redux/State/clientSlice/sessionSlice";
import { SoapNotesStatuses } from "../../../../../redux/API/ClientAPIHelpers/dataCollectionProperties";
import { useResetCalendarData } from "../../../Calendar/Popups/hooks";

const UpdateSoapNote = () => {
  const dispatch = useDispatch<DispatchProperties>();
  const [params] = useSearchParams();
  const navigate = useNavigate();
  const { resetData } = useResetCalendarData();

  const sessionInfo = useSelector((state) => state.session.sessionInfo);
  const role = useSelector((state) => state.account.role);

  const isRBT = !!role && role.section.id === AdminTypes.rbt;

  const [soapNoteStatus, setSoapNoteStatus] = useState<SoapNotesStatuses>(0);
  const [data, setData] = useReducer(
    behaviorTreatmentReducer,
    initialBehaviorTreatmentState
  );

  useEffect(() => {
    if (!hasAnyData(data)) return;
    const allData = {
      ...data,
      submit: false,
    };
    SaveSoapNote(allData);
  }, [data]);

  useEffect(() => {
    const reportId = params.get("reportId");
    const clientId = params.get("clientId");
    const sessionId = params.get("sessionId");

    if (!reportId || !clientId || !sessionId) return;
    const notes = window.localStorage.getItem(SOAP_NOTES);
    const soapNotes: Array<any> = notes ? JSON.parse(notes) : [];
    const savedData = soapNotes.find((x) => x.sessionId === sessionId);
    if (!!savedData) {
      setData({
        type: ACTIONS.setState,
        payload: savedData,
      });
      return;
    }

    dispatch(getSoapNote({ reportId, clientId }))
      .then(unwrapResult)
      .then((response) => {
        const {
          barrierToTreatmentResponses,
          behaviorTreatmentTechniquesResponses,
          promptLevelsResponses,
          reinforcementResponses,
          skillDomainAddressedResponses,
          maladaptiveBehaviorTechniquesResponses,
          clientMoodResponses,
          behaviorTreatmentFuturePlanResponses,
          location,
          date,
          startTime,
          endTime,
          status,
        } = response as SoapNoteBehaviorTreatmentType;
        const bariers = barrierToTreatmentResponses?.map((x) => x.id);
        const techniques = behaviorTreatmentTechniquesResponses?.map(
          (x) => x.id
        );
        const levels = promptLevelsResponses?.map((x) => x.id);
        const reinforcements = reinforcementResponses?.map((x) => x.id);
        const skillDomains = skillDomainAddressedResponses?.map((x) => x.id);
        const maladaptiveBehaviorTechniques =
          maladaptiveBehaviorTechniquesResponses?.map((x) => x.id);
        const clientMoods = clientMoodResponses?.map((x) => x.id);
        const behaviorTreatmentFuturePlans =
          behaviorTreatmentFuturePlanResponses?.map((x) => x.id);

        dispatch(setDate(dayjs(date).format()));
        dispatch(setStartTime(dayjs(startTime).format()));
        dispatch(setEndTime(dayjs(endTime).format()));

        setSoapNoteStatus(status.status);

        setData({
          type: ACTIONS.setState,
          payload: response,
        });

        setData({
          type: ACTIONS.setBarrierToTreatmentResponses,
          payload: bariers,
        });

        setData({
          type: ACTIONS.setBehaviorTreatmentTechniquesResponses,
          payload: techniques,
        });

        setData({
          type: ACTIONS.setClientMoodResponses,
          payload: clientMoods,
        });

        setData({
          type: ACTIONS.setFuturePlanResponses,
          payload: behaviorTreatmentFuturePlans,
        });

        setData({
          type: ACTIONS.setPromptLevelsResponses,
          payload: levels,
        });

        setData({
          type: ACTIONS.setReinforcementResponses,
          payload: reinforcements,
        });

        setData({
          type: ACTIONS.setSkillDomainAddressed,
          payload: skillDomains,
        });

        setData({
          type: ACTIONS.setMaladaptiveTechniquesResponses,
          payload: maladaptiveBehaviorTechniques,
        });

        if (!location) return;
        setData({
          type: ACTIONS.setLocationId,
          payload: location.id,
        });
      })
      .catch(errorNormalizer);
  }, [dispatch, params]);

  const updateSoapNoteHandler = (submit: boolean) => {
    const reportId = params.get("reportId");
    const clientId = params.get("clientId");
    const forcedOperation =
      params.get("forcedOperation") === "true" ? true : false;
    if (!reportId || !clientId || !data) return;
    const { date, startTime, endTime } = sessionInfo;
    const allData: UpdateBehaviorTreatmentProperties = {
      ...data,
      date,
      startTime,
      endTime,
      reportId,
      submit,
      forcedOperation,
    };
    dispatch(updateBehaviorTreatment({ clientId, reportId, data: allData }))
      .then(unwrapResult)
      .then(() => {
        resetData();
        toast("Success");
        removeSavedSoapNoteData(data.sessionId);
        if (isRBT) {
          navigate("/rbt/calendar");
          return;
        }
        navigate("/bcba/calendar");
      })
      .catch(errorNormalizer);
  };

  return (
    <>
      <BehaviorTreatmentData
        setData={setData}
        data={data}
        disabled={!isActionAllowed(soapNoteStatus)}
      />
      <SoapNoteButtons
        status={soapNoteStatus}
        save={() => updateSoapNoteHandler(false)}
        submit={() => updateSoapNoteHandler(true)}
      />
      <ForcedOperation submit={() => updateSoapNoteHandler(true)} />
    </>
  );
};

export default UpdateSoapNote;
