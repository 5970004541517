import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { CustomButton, CustomTable } from "../../../shared/uiComponents";
import { Headers, TableBody } from "./tableInfo";
import MobileView from "./mobileView";
import { Container } from "../StyledComponents";

import { DispatchProperties, StateProperties } from "../../../redux/store";
import { getRoles } from "../../../redux/State/identitySlice/roleSlice";

const Permissions = () => {
  const dispatch = useDispatch<DispatchProperties>();
  const navigate = useNavigate();

  const roles = useSelector((state: StateProperties) => state.role.roles);
  const loading = useSelector((state: StateProperties) => state.role.loading);
  const addCustomRoleHandler = () => {
    navigate("/admin/permissions/new");
  };

  useEffect(() => {
    dispatch(getRoles());
  }, [dispatch]);

  return (
    <Container>
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <CustomButton
          title={"Add custom role"}
          onClick={addCustomRoleHandler}
        />
      </div>
      <CustomTable
        headers={Headers}
        data={{ query: roles }}
        TableBody={TableBody}
        loading={loading}
        hasPagination={false}
      />
      <MobileView data={roles} />
    </Container>
  );
};

export default Permissions;
