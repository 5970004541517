import { FetchAPI } from "../Main/fetchAPI";
import { AddClientProperties, ClientPaginationProperties, UpdateClientInfoProperties } from "./clientProperties";
import { CLIENTS } from "../constants";

export const Client =
{
    getClients: (data: ClientPaginationProperties) => {
        const { page, pageSize, direction, orderBy, searchString, status } = data;
        const query = new URLSearchParams();

        if (!!searchString) query.set("searchString", searchString);
        if (!!direction) query.set("direction", direction);
        if (!!orderBy) query.set("orderBy", orderBy);
        if (!!status) query.set("status", status);
        if (!!page) query.set("page", page);
        if (!!pageSize) query.set("pageSize", pageSize);

        return FetchAPI.getJson({
            urlStr: `${CLIENTS}?${query.toString()}`,
        });
    },
    addClient: (data: AddClientProperties) =>
        FetchAPI.postJson({
            urlStr: `${CLIENTS}`,
            data,
        }),
    getClientStatuses: () => FetchAPI.getJson({
        urlStr: `${CLIENTS}/statuses`,
    }),
    getClient: (id: string) =>
        FetchAPI.getJson({
            urlStr: `${CLIENTS}/${id}`,
        }),
    updateClient: (data: UpdateClientInfoProperties) =>
        FetchAPI.putJson({
            urlStr: `${CLIENTS}/${data.id}`,
            data,
        }),
    deleteClient: (id: string) =>
        FetchAPI.deleteJson({
            urlStr: `${CLIENTS}/${id}`,
        }),
}
