import accountSlice from "./accountSlice";
import roleSlice from "./roleSlice";
import userSlice from "./userSlice";

const Identity = {
    account: accountSlice,
    user: userSlice,
    role: roleSlice,
}

export default Identity;