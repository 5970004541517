import { useState } from "react";
import { DeleteOutlineOutlined as Delete } from "@mui/icons-material";
import { useDispatch } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import { Tooltip } from "@mui/material";

import { errorNormalizer } from "../../../../../../shared/Helpers/functions";
import {
  CustomAlert,
  CustomButton,
} from "../../../../../../shared/uiComponents";

import { DispatchProperties } from "../../../../../../redux/store";
import { ProgramProperties } from "../../../../../../redux/API/ClientAPIHelpers/clientProgramProperties";
import {
  deleteProgram,
  getPrograms,
} from "../../../../../../redux/State/clientSlice/clientProgramSlice";
import { ButtonWrapper } from "../../../../../AdminPanel/StyledComponents";

const DeleteProgram = ({ data }: { data: ProgramProperties }) => {
  const [open, setOpen] = useState<boolean>(false);
  const closeHandler = () => setOpen(false);

  return (
    <>
      <Tooltip title="Delete">
        <Delete onClick={() => setOpen(true)} />
      </Tooltip>
      <CustomAlert
        title="Are you sure you want to delete?"
        open={open}
        onClose={closeHandler}
        Content={({ onClose }) => (
          <DeleteContent data={data} onClose={onClose} />
        )}
      />
    </>
  );
};

const DeleteContent = ({
  data,
  onClose,
}: {
  data: ProgramProperties;
  onClose: () => void;
}) => {
  const dispatch = useDispatch<DispatchProperties>();

  const deleteHandler = () => {
    dispatch(deleteProgram(data))
      .then(unwrapResult)
      .then(() => {
        dispatch(getPrograms({skillAreaId: data.skillAreaId}));
        onClose();
      })
      .catch(errorNormalizer);
  };
  return (
    <ButtonWrapper>
      <CustomButton title="Yes" onClick={deleteHandler} />
      <CustomButton title="Cancel" secondaryButton />
    </ButtonWrapper>
  );
};
export default DeleteProgram;
