import { styled } from "@mui/material";
import { black900 } from "../../Helpers/colors";
import { CSSProperties, FC } from "react";

interface TextProperties {
  title: string | number;
  textColor?: string;
  size?: sizes;
  className?: string;
  onClick?: () => void;
  center?: boolean;
  style?: CSSProperties;
}

enum SizeList {
  tiny,
  tinyBold,
  small,
  smallBold,
  medium,
  mediumBold,
  large,
  largeBold,
}

export type sizes = keyof typeof SizeList;

const CustomTextTiny = styled("div")((props) => ({
  maxWidth: "100%",
  fontSize: "14px",
  fontWeight: 400,
  lineHeight: "20px",
  color: props.color,
  cursor: !!props.onClick ? "pointer" : "default",
}));

const CustomTextTinyBold = styled("div")((props) => ({
  maxWidth: "100%",
  fontSize: "14px",
  fontWeight: 600,
  lineHeight: "20px",
  color: props.color,
  cursor: !!props.onClick ? "pointer" : "default",
}));

const CustomTextSmall = styled("div")((props) => ({
  maxWidth: "100%",
  fontSize: "16px",
  fontWeight: 400,
  lineHeight: "24px",
  color: props.color,
  cursor: !!props.onClick ? "pointer" : "default",
  "@media (max-width: 425px)": {
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "20px",
  },
}));

const CustomTextSmallBold = styled("div")((props) => ({
  maxWidth: "100%",
  fontSize: "16px",
  fontWeight: 600,
  lineHeight: "24px",
  color: props.color,
  cursor: !!props.onClick ? "pointer" : "default",
  "@media (max-width: 425px)": {
    fontSize: "14px",
    fontWeight: 600,
    lineHeight: "20px",
  },
}));

const CustomTextMedium = styled("div")((props) => ({
  maxWidth: "100%",
  fontSize: "24px",
  fontWeight: 400,
  lineHeight: "32px",
  color: props.color,
  cursor: !!props.onClick ? "pointer" : "default",
  "@media (max-width: 425px)": {
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "24px",
  },
}));

const CustomTextMediumBold = styled("div")((props) => ({
  maxWidth: "100%",
  fontSize: "24px",
  fontWeight: 600,
  lineHeight: "32px",
  color: props.color,
  cursor: !!props.onClick ? "pointer" : "default",
  "@media (max-width: 425px)": {
    fontSize: "16px",
    fontWeight: 600,
    lineHeight: "24px",
  },
}));

const CustomTextLarge = styled("div")((props) => ({
  maxWidth: "100%",
  fontSize: "32px",
  fontWeight: 400,
  lineHeight: "40px",
  color: props.color,
  cursor: !!props.onClick ? "pointer" : "default",
  "@media (max-width: 425px)": {
    fontSize: "24px",
    fontWeight: 400,
    lineHeight: "32px",
  },
}));

const CustomTextLargeBold = styled("div")((props) => ({
  maxWidth: "100%",
  fontSize: "32px",
  fontWeight: 600,
  lineHeight: "40px",
  color: props.color,
  cursor: !!props.onClick ? "pointer" : "default",
  "@media (max-width: 425px)": {
    fontSize: "24px",
    fontWeight: 600,
    lineHeight: "32px",
  },
}));

const Texts = [
  CustomTextTiny,
  CustomTextTinyBold,
  CustomTextSmall,
  CustomTextSmallBold,
  CustomTextMedium,
  CustomTextMediumBold,
  CustomTextLarge,
  CustomTextLargeBold,
];

const Text: FC<TextProperties> = ({
  title,
  textColor = black900,
  size = "small",
  className = "",
  onClick,
  center = false,
  style = { wordBreak: "break-word", whiteSpace: "normal" },
}) => {
  const StyledComponent = Texts[SizeList[size]];

  return (
    <StyledComponent
      className={`${className}${center ? " textAlignCenter" : ""}`}
      color={textColor}
      onClick={onClick}
      style={style}
    >
      {title}
    </StyledComponent>
  );
};

export default Text;
