import { ChangeEvent, FC, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";
import { unwrapResult } from "@reduxjs/toolkit";
import { useSearchParams } from "react-router-dom";
import { Add } from "@mui/icons-material";

import {
  CustomInput,
  CustomTable,
  CustomCheckBox,
} from "../../../../../shared/uiComponents";
import { blue400 } from "../../../../../shared/Helpers/colors";
import SkillAreaMenu from "./skillMenu";
import {
  ProgramTableBody,
  programHeaders,
  programHeadersRbt,
} from "./tableInfo";
import { errorNormalizer } from "../../../../../shared/Helpers/functions";
import { AdminTypes } from "../../../../../components/Action";
import AddProgram from "./Actions/addProgram";
import UploadPrograms from "./Actions/uploadExcelFile";

import { DispatchProperties, useSelector } from "../../../../../redux/store";
import {
  addSkillAreas,
  getSkillAreas,
} from "../../../../../redux/State/clientSlice/skillAreaSlice";
import { getPrograms } from "../../../../../redux/State/clientSlice/clientProgramSlice";

const SkillAcquisition: FC = () => {
  const dispatch = useDispatch<DispatchProperties>();
  const { clientId } = useParams();
  const [params, setParams] = useSearchParams();

  const skillAreas = useSelector((state) => state.skillArea.skillAreas);
  const programs = useSelector((state) => state.clientProgram.programs);
  const loading = useSelector((state) => state.clientProgram.loading);
  const role = useSelector((state) => state.account.role);

  const [name, setName] = useState<string>("");
  const [includeArchived, setIncludeArchived] = useState<boolean>(false);
  const [includeArchivedPrograms, setIncludeArchivedPrograms] =
    useState<boolean>(false);

  useEffect(() => {
    if (!clientId) return;
    dispatch(getSkillAreas({ clientId, includeArchived }));
  }, [clientId, includeArchived, dispatch]);

  useEffect(() => {
    const skillAreaId = params.get("skillAreaId");
    if (!skillAreaId) return;
    dispatch(
      getPrograms({ skillAreaId, includeArchived: includeArchivedPrograms })
    );
  }, [params, includeArchivedPrograms, dispatch]);

  useEffect(() => {
    let skillAreaId = params.get("skillAreaId");
    if (!skillAreas || !skillAreas.length || !!skillAreaId) return;
    skillAreaId = skillAreas[0].id;
    params.set("skillAreaId", skillAreaId);
    setParams(params);
  }, [skillAreas, params, setParams]);

  const addSkillAreaHandler = async () => {
    if (!clientId || !name) return;

    dispatch(addSkillAreas({ clientId, name }))
      .then(unwrapResult)
      .then((response) => {
        dispatch(getSkillAreas({ clientId, includeArchived }));
        const { id } = response;
        params.set("skillAreaId", id);
        setParams(params);
        setName("");
      })
      .catch(errorNormalizer);
  };

  const nameHandler = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setName(value);
  };

  const showArchivedSkillAreasHandler = () => {
    setIncludeArchived((prev) => !prev);
    params.set("includeArchived", `${!includeArchived}`);
    setParams(params);
  };

  const showArchivedProgramsHandler = () => {
    setIncludeArchivedPrograms((prev) => !prev);
    params.set("includeArchivedPrograms", `${!includeArchivedPrograms}`);
    setParams(params);
  };

  return (
    <div
      style={{
        border: `2px solid ${blue400}`,
        borderRadius: "12px",
        padding: "10px",
        marginTop: "10px",
      }}
    >
      <div>
        {!!role && role.section.id !== AdminTypes.rbt && (
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "space-between",
              alignItems: 'center',
            }}
          >
            <div style={{ display: "flex", flexWrap: "wrap", gap: "16px" }}>
              <CustomInput
                placeholder="Add Skill Area"
                value={name}
                setValue={nameHandler}
                className={"marginTop8"}
                EndAdornment={{
                  Icon: Add,
                  onClickEndAdornment: addSkillAreaHandler,
                }}
              />
              <CustomCheckBox
                onChange={showArchivedSkillAreasHandler}
                item={{
                  id: "0",
                  checked: includeArchived,
                  label: `Show Archived Skill Areas`,
                }}
              />
            </div>
            <UploadPrograms />
          </div>
        )}
        <div style={{ display: "flex", flexWrap: "wrap" }}>
          {!!skillAreas &&
            skillAreas.map((item, index) => (
              <SkillAreaMenu item={item} key={index} />
            ))}
        </div>
      </div>
      <CustomTable
        headers={
          !!role && role.section.id !== AdminTypes.rbt
            ? programHeaders
            : programHeadersRbt
        }
        TableBody={ProgramTableBody}
        data={{ query: programs }}
        loading={loading}
        hasPagination={false}
      />
      {!!role && role.section.id !== AdminTypes.rbt && (
        <div style={{ display: "flex", gap: "16px", alignItems: "center" }}>
          <AddProgram />
          <CustomCheckBox
            onChange={showArchivedProgramsHandler}
            item={{
              id: "0",
              checked: includeArchivedPrograms,
              label: `Show Archived Programs`,
              disabled: loading,
            }}
          />
        </div>
      )}
    </div>
  );
};

export default SkillAcquisition;
