import dayjs, { Dayjs } from "dayjs";
import { toast } from "react-toastify";

export const validateEmail = (email: string): boolean => {
    const mailformat = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return mailformat.test(email);
}

export const validateName = (str: string): boolean => {
    const regex = /^([A-Za-z \\.'`-]{2,})$/;
    return regex.test(str);
}

export const errorNormalizer = ({ errors, title }) => {
    if (!!errors) {
        const errorList = Object.values(errors) as Array<string>;
        toast(errorList.join(" "));
        return
    }
    if (!!title) toast(title);
}

export const capitalizer = (input: string): string => {
    return input.replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase())))
}

export const disableFuture = (date: Dayjs) => {
    const today = new Date();
    const todayDayjs = dayjs(today);
    return date > todayDayjs
};
export const disablePast = (date: Dayjs) => {
    const today = new Date();
    const todayDayjs = dayjs(today);
    return date < todayDayjs
};

export const validateNumber = (value: string) => {
    const regex = /^([0-9]*)$/;
    return regex.test(value);
}

export const validateDecimalNumber = (value: string) => {
    const regex = /^\d*\.?\d{0,2}$/;
    return regex.test(value);
}

export const dateNormalizer = (date: Dayjs) => (date.format("YYYY-MM-DD").split("T")[0]);

//this helper mostly used for backend generated dates
export const dateLocalizer = (date: string) => {
    const timeZone = -new Date().getTimezoneOffset() / 60;

    return dayjs(date)
        .add(timeZone, "hour")
}
