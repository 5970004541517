import { FC, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";

import {
  CustomCheckBox,
  CustomTable,
} from "../../../../../shared/uiComponents";
import { blue400 } from "../../../../../shared/Helpers/colors";
import {
  BehaviorTableBody,
  behaviorHeaders,
  behaviorHeadersRbt,
} from "./tableInfo";
import AddBehavior from "./Actions/addBehavior";

import { DispatchProperties, useSelector } from "../../../../../redux/store";
import { getBehaviors } from "../../../../../redux/State/clientSlice/behaviorReductionSlice";
import { BehaviorReductionProperties } from "../../../../../redux/API/ClientAPIHelpers/behaviorReductionProperties";
import { AdminTypes } from "../../../../../components/Action";

const BehaviorReduction: FC = () => {
  const { clientId } = useParams();
  const dispatch = useDispatch<DispatchProperties>();

  const [clientBehaviors, setClientBehaviors] = useState<
    Array<BehaviorReductionProperties>
  >([]);
  const [includeArchived, setIncludeArchived] = useState<boolean>(false);

  const behaviors = useSelector((state) => state.behaviorReduction.behaviors);
  const role = useSelector((state) => state.account.role);
  const loading = useSelector((state) => state.clientProgram.loading);

  const showArchivedBehaviorReductionsHandler = () => {
    setIncludeArchived((prev) => !prev);
  };

  useEffect(() => {
    if (!clientId) return;
    dispatch(getBehaviors(clientId));
  }, [clientId, dispatch]);

  useEffect(() => {
    if (!behaviors) return;
    if (includeArchived) {
      setClientBehaviors(behaviors);
      return;
    }

    const data = behaviors.filter((item) => !item.isArchived);
    setClientBehaviors(data);
  }, [behaviors, includeArchived]);

  return (
    <div
      style={{
        border: `2px solid ${blue400}`,
        borderRadius: "12px",
        padding: "10px",
        marginTop: "10px",
      }}
    >
      <CustomTable
        headers={
          !!role && role.section.id !== AdminTypes.rbt
            ? behaviorHeaders
            : behaviorHeadersRbt
        }
        TableBody={BehaviorTableBody}
        data={{ query: clientBehaviors }}
        loading={loading}
        hasPagination={false}
      />
      <div className="margin16">
        <AddBehavior />
        <CustomCheckBox
          item={{
            id: "0",
            checked: includeArchived,
            label: `Show archived`,
            disabled:
              !!behaviors && !behaviors.filter((x) => x.isArchived).length,
          }}
          onChange={showArchivedBehaviorReductionsHandler}
        />
      </div>
    </div>
  );
};

export default BehaviorReduction;
