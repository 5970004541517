import { FC, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Tooltip } from "@mui/material";
import {
  PersonOutlined as Person,
  EmailOutlined as Email,
  PhoneIphoneOutlined as Phone,
  PsychologyOutlined as Therapist,
  InfoOutlined as Info,
} from "@mui/icons-material";

import { initialState, PatientsDataProperties } from "./tableInfo";
import { PatientDetailedInfo } from "../../AdminPanel/Patients/tableInfo";
import {
  Text,
  MobilePagination,
  CustomAlert,
  PatientStatusBadge,
} from "../../../shared/uiComponents";
import { Loader, Row, Wrapper } from "../../AdminPanel/StyledComponents";
import MobileSort from "../../../components/MobileSort";
import { patientSortData } from "../../AdminPanel/Patients/mobileView";

import { DispatchProperties, useSelector } from "../../../redux/store";
import { InfoClientProperties } from "../../../redux/API/ClientAPIHelpers/clientProperties";
import { clearSkillAreas } from "../../../redux/State/clientSlice/skillAreaSlice";

const MobileView: FC<PatientsDataProperties> = (data) => {
  const { query, totalNumberOfItems } = data;

  const dispatch = useDispatch<DispatchProperties>();
  const { pathname } = useLocation();

  const loading = useSelector(
    (state) => state.userClient.loadingGetUserPatients
  );

  const [infoOpen, setInfoOpen] = useState<boolean>(false);
  const [patientInfo, setPatientInfo] =
    useState<InfoClientProperties>(initialState);

  const showPatientInfo = (info: InfoClientProperties) => {
    setPatientInfo(info);
    setInfoOpen(true);
  };

  const navigatePatientHandler = () => {
    dispatch(clearSkillAreas());
  };

  return (
    <div className={"mobileView"}>
      <MobileSort data={patientSortData} />
      {loading && <Loader />}
      {!loading && !totalNumberOfItems && (
        <div className={"mobileView"}>
          <Text
            title={"No results"}
            size={"smallBold"}
            className={"marginAuto"}
          />
        </div>
      )}
      {!loading &&
        !!query &&
        query.map((item, index) => (
          <Wrapper key={index}>
            <Row style={{ justifyContent: "space-between" }}>
              <div style={{ marginLeft: "16px" }}>
                <PatientStatusBadge statusCode={item.status.id} />
              </div>
              <div
                onClick={() => showPatientInfo(item)}
                className={"marginRight16"}
                style={{ cursor: "pointer" }}
              >
                <Tooltip title="More Information">
                  <Info />
                </Tooltip>
              </div>
            </Row>
            <Link to={`${pathname}/patient/${item.id}`}>
              <Row>
                <Person />
                <Text
                  title={item.fullName}
                  className={"marginLeft8"}
                  onClick={navigatePatientHandler}
                />
              </Row>
            </Link>
            <Row>
              <Email /> <Text title={item.email} className={"marginLeft8"} />
            </Row>
            <Row>
              <Therapist />
              <Text
                title={`${item.numberOfTherapist}`}
                className={"marginLeft8"}
              />
            </Row>
            <Row>
              <Phone />
              <Text
                title={!!item.parentPhoneNumber ? item.parentPhoneNumber : "-"}
                className={"marginLeft8"}
              />
            </Row>
          </Wrapper>
        ))}
      <MobilePagination data={data} />
      <CustomAlert
        open={infoOpen}
        onClose={() => setInfoOpen(false)}
        title={"Detailed Information"}
        Content={() => <PatientDetailedInfo {...patientInfo} />}
      />
    </div>
  );
};

export default MobileView;
