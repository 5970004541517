import { useEffect, useReducer, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import dayjs from "dayjs";

import { errorNormalizer } from "../../../../../shared/Helpers/functions";
import AssessmentData from "./assessmentData";
import {
  ACTIONS,
  assessmentReducer,
  initialAssessmentState,
} from "./assessmentReducer";
import {
  SOAP_NOTES,
  SaveSoapNote,
  SoapNoteButtons,
  hasAnyData,
  isActionAllowed,
  removeSavedSoapNoteData,
  ForcedOperation,
} from "../helpers";
import {
  SoapNoteAssessmentType,
  UpdateAssessmentTypeProperties,
} from "../../SoapNoteTypesProperties/assessmentTypeProperties";
import { useResetCalendarData } from "../../../Calendar/Popups/hooks";

import { DispatchProperties, useSelector } from "../../../../../redux/store";
import {
  getSoapNote,
  updateAssessment,
} from "../../../../../redux/State/clientSlice/soapNoteSlice";
import {
  setDate,
  setEndTime,
  setStartTime,
} from "../../../../../redux/State/clientSlice/sessionSlice";
import { SoapNotesStatuses } from "../../../../../redux/API/ClientAPIHelpers/dataCollectionProperties";

const UpdateSoapNote = () => {
  const dispatch = useDispatch<DispatchProperties>();
  const [params] = useSearchParams();
  const navigate = useNavigate();
  const { resetData } = useResetCalendarData();
  
  const sessionInfo = useSelector((state) => state.session.sessionInfo);

  const [data, setData] = useReducer(assessmentReducer, initialAssessmentState);
  const [soapNoteStatus, setSoapNoteStatus] = useState<SoapNotesStatuses>(0);

  useEffect(() => {
    if (!hasAnyData(data)) return;
    const allData = {
      ...data,
      submit: false,
    };
    SaveSoapNote(allData);
  }, [data]);

  useEffect(() => {
    const reportId = params.get("reportId");
    const clientId = params.get("clientId");
    const sessionId = params.get("sessionId");
    if (!reportId || !clientId || !sessionId) return;

    const notes = window.localStorage.getItem(SOAP_NOTES);
    const soapNotes: Array<any> = notes ? JSON.parse(notes) : [];
    const savedData = soapNotes.find((x) => x.sessionId === sessionId);
    if (!!savedData) {
      setData({
        type: ACTIONS.setState,
        payload: savedData,
      });
      return;
    }

    dispatch(getSoapNote({ reportId, clientId }))
      .then(unwrapResult)
      .then((response) => {
        const {
          assessmentToolResponses,
          participantResponses,
          treatmentPlanResponses,
          location,
          date,
          startTime,
          endTime,
          status,
        } = response as SoapNoteAssessmentType;
        const assessmentTools = assessmentToolResponses?.map((x) => x.id);
        const participants = participantResponses?.map((x) => x.id);
        const treatmentPlans = treatmentPlanResponses?.map((x) => x.id);

        dispatch(setDate(dayjs(date).format()));
        dispatch(setStartTime(dayjs(startTime).format()));
        dispatch(setEndTime(dayjs(endTime).format()));

        setSoapNoteStatus(status.status);
        setData({
          type: ACTIONS.setState,
          payload: response,
        });

        setData({
          type: ACTIONS.setAssessmentTools,
          payload: assessmentTools,
        });

        setData({
          type: ACTIONS.setParticipants,
          payload: participants,
        });

        setData({
          type: ACTIONS.setTreatmentPlans,
          payload: treatmentPlans,
        });

        if (!location) return;
        setData({
          type: ACTIONS.setLocationId,
          payload: location.id,
        });
      })
      .catch(errorNormalizer);
  }, [dispatch, params]);

  const updateSoapNoteHandler = (submit: boolean) => {
    const forcedOperation =
      params.get("forcedOperation") === "true" ? true : false;
    const reportId = params.get("reportId");
    const clientId = params.get("clientId");
    if (!reportId || !clientId || !data) return;
    const { date, startTime, endTime } = sessionInfo;
    const allData: UpdateAssessmentTypeProperties = {
      ...data,
      date,
      startTime,
      endTime,
      reportId,
      submit,
      forcedOperation,
    };
    dispatch(updateAssessment({ clientId, reportId, data: allData }))
      .then(unwrapResult)
      .then(() => {
        resetData();
        removeSavedSoapNoteData(data.sessionId);
        toast("Success");
        navigate("/bcba/calendar");
      })
      .catch(errorNormalizer);
  };

  return (
    <>
      <AssessmentData
        setData={setData}
        data={data}
        disabled={!isActionAllowed(soapNoteStatus)}
      />
      <SoapNoteButtons
        status={soapNoteStatus}
        save={() => updateSoapNoteHandler(false)}
        submit={() => updateSoapNoteHandler(true)}
      />
      <ForcedOperation submit={() => updateSoapNoteHandler(true)} />
    </>
  );
};

export default UpdateSoapNote;
