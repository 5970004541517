import dayjs from "dayjs";

import { Text } from "../../../../shared/uiComponents";
import { InfoRow as Row, Wrapper } from "../../../AdminPanel/StyledComponents";
import { TargetProperties } from "../../../../redux/API/ClientAPIHelpers/programTargetsProperties";

const TargetDetails = ({ target }: { target: TargetProperties }) => {
  const {
    sd,
    name,
    status,
    responsePercentage,
    dateMastered,
    dateOpened,
    type,
  } = target;

  return (
    <Wrapper className="targetsMobileContainer">
      <Row>
        <Text title="Target name:" size="mediumBold" />
        <Text title={name} size="mediumBold" />
      </Row>
      <Row>
        <Text title="SD:" size="smallBold" />
        <Text title={`${!!sd ? sd : "No SD"}`} />
      </Row>
      <Row>
        <Text title="Status" size="smallBold" />
        <Text title={status.name} />
      </Row>
      <Row>
        <Text title="Response Rate" size="smallBold" />
        <Text title={`${responsePercentage}%`} />
      </Row>
      <Row>
        <Text title="Date Opened" size="smallBold" />
        <Text
          title={!!dateOpened ? dayjs(dateOpened).format("MM/DD/YYYY") : "-"}
        />
      </Row>
      <Row>
        <Text title="Date Mastered" size="smallBold" />
        <Text
          title={
            !!dateMastered ? dayjs(dateMastered).format("MM/DD/YYYY") : "-"
          }
        />
      </Row>
      <Row>
        <Text title="Target Type" size="smallBold" />
        <Text title={type.name} />
      </Row>
    </Wrapper>
  );
};

export default TargetDetails;
