import { FC, forwardRef, ReactElement, Ref } from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  styled,
  Slide,
} from "@mui/material";
import IconButton from "@mui/material/IconButton/IconButton";
import { Breakpoint } from "@mui/material";
import { Close as Icon } from "@mui/icons-material";
import { TransitionProps } from "@mui/material/transitions";

import "./alert.scss";

interface DialogProperties {
  open: boolean;
  onClose: () => void;
  title?: string;
  Content: FC<{ onClose: () => void }>;
  maxWidth?: Breakpoint;
  popupHeight?: string;
}

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: ReactElement<any, any>;
  },
  ref: Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const CustomizedDialog = styled(Dialog)(() => ({
  ".MuiPaper-root": {
    borderRadius: "10px",
  },
}));

const CustomAlert: FC<DialogProperties> = ({
  open,
  onClose,
  title,
  Content,
  maxWidth = "sm",
  popupHeight = "100%",
}) => {
  return (
    <CustomizedDialog
      TransitionComponent={Transition}
      open={open}
      fullWidth={true}
      onClose={(_, reason) => {
        if (reason === "backdropClick") return;
        if (reason === "escapeKeyDown") return;
        onClose();
      }}
      maxWidth={maxWidth}
    >
      <DialogTitle textAlign={"center"} fontWeight={600} fontSize={32}>
        {title}
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
          }}
        >
          <Icon />
        </IconButton>
      </DialogTitle>
      {open && (
        <DialogContent style={{ overflowY: "visible", height: popupHeight }}>
          <Content onClose={onClose} />
        </DialogContent>
      )}
    </CustomizedDialog>
  );
};

export default CustomAlert;
