import { useState } from "react";
import { useDispatch } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import { UnarchiveTwoTone } from "@mui/icons-material";
import { Tooltip } from "@mui/material";

import {
  CustomAlert,
  CustomButton,
} from "../../../../../../shared/uiComponents";
import { errorNormalizer } from "../../../../../../shared/Helpers/functions";
import { ButtonWrapper } from "../../../../../AdminPanel/StyledComponents";

import { DispatchProperties } from "../../../../../../redux/store";
import {
  getBehaviors,
  unArchiveBehavior,
} from "../../../../../../redux/State/clientSlice/behaviorReductionSlice";
import { ArchiveClientBehaviorsProperties } from "../../../../../../redux/API/ClientAPIHelpers/behaviorReductionProperties";

const Unarchive = ({ data }: { data: ArchiveClientBehaviorsProperties }) => {
  const [open, setOpen] = useState<boolean>(false);
  const closeHandler = () => setOpen(false);
  return (
    <>
      <Tooltip title="Unarchive">
        <UnarchiveTwoTone onClick={() => setOpen(true)} />
      </Tooltip>
      <CustomAlert
        title="Do you want to unarchive?"
        open={open}
        onClose={closeHandler}
        Content={() => <UnarchiveContent data={data} onClose={closeHandler} />}
      />
    </>
  );
};

const UnarchiveContent = ({
  data,
  onClose,
}: {
  data: ArchiveClientBehaviorsProperties;
  onClose: () => void;
}) => {
  const dispatch = useDispatch<DispatchProperties>();

  const unarchiveHandler = () => {
    dispatch(unArchiveBehavior(data))
      .then(unwrapResult)
      .then(() => {
        dispatch(getBehaviors(data.clientId));
        onClose();
      })
      .catch(errorNormalizer);
  };

  return (
    <ButtonWrapper>
      <CustomButton title="Yes" onClick={unarchiveHandler} />
      <CustomButton title="Cancel" secondaryButton />
    </ButtonWrapper>
  );
};

export default Unarchive;
