import { useEffect, FC, useState, ChangeEvent } from "react";
import { TableRow, TableBody as Body, TableCell } from "@mui/material";
import { useDispatch } from "react-redux";
import { SelectChangeEvent } from "@mui/material/Select";
import { CheckOutlined as Check } from "@mui/icons-material";

import {
  TableNoData,
  CustomCheckBox,
  CustomSelect,
} from "../../../../shared/uiComponents";
import {
  HeaderProperties,
  TableDataProperties,
} from "../../../../shared/uiComponents/Table/tableProperties";
import { AdminTypes } from "../../../../components/Action";

import { DispatchProperties, useSelector } from "../../../../redux/store";
import {
  addTherapist,
  updateTherapist,
} from "../../../../redux/State/clientSlice/clientSlice";
import { AssignIdsProperties } from "../../../../redux/API/ClientAPIHelpers/userClientProperties";
import { AuthorizedUsersProperties } from "../../../../redux/API/ClientAPIHelpers/clientProperties";

export interface AvailableUsersDataProperties extends TableDataProperties {
  query: Array<AuthorizedUsersProperties> | null;
}

export const Headers: HeaderProperties[] = [
  { id: "0", name: "Select", width: "80px" },
  { id: "1", name: "Full Name", orderBy: "fullName" },
  { id: "2", name: "Email" },
  { id: "3", name: "Licensed or Limited" },
  { id: "4", name: "Role" },
  { id: "5", name: "Default" },
];

export const HeadersRBT: HeaderProperties[] = [
  { id: "0", name: "Select", width: "80px" },
  { id: "1", name: "Full Name", orderBy: "fullName" },
  { id: "2", name: "Email" },
];

interface RowRendererProperties {
  data: Array<AuthorizedUsersProperties>;
}

export const TableBody: FC<RowRendererProperties> = ({ data }) => {
  const [assignedUsersDefault, setAssignedUsersDefault] = useState<
    Array<AssignIdsProperties>
  >([]);

  const selectedUsers = useSelector(
    (state) => state.client.therapistsListForAssign
  );

  useEffect(() => {
    const assignedUsers = data
      .filter((x) => x.isAssigned && !selectedUsers.find((y) => y.id === x.id))
      .map((i) => {
        const user: AssignIdsProperties = {
          id: i.id,
          isAssigned: i.isAssigned,
          supervisorId: !!i.supervisor ? i.supervisor.id : null,
          authorizationTypeId: !!i.authorizationType
            ? i.authorizationType.id
            : null,
          fullName: i.fullName,
        };
        return user;
      });

    setAssignedUsersDefault([...selectedUsers, ...assignedUsers]);
  }, [data, selectedUsers]);

  if (!data || !data.length) {
    return <TableNoData spanScope={Headers.length} />;
  }

  return (
    <Body>
      {data.map((row, index) => (
        <Row
          row={row}
          key={index}
          selected={
            !!assignedUsersDefault?.find((x) => x.id === row.id)?.isAssigned
          }
        />
      ))}
    </Body>
  );
};

const Row = ({
  row,
  selected,
}: {
  row: AuthorizedUsersProperties;
  selected: boolean;
}) => {
  const dispatch = useDispatch<DispatchProperties>();

  const [supervisorId, setSupervisorId] = useState<string | null>(null);

  const supervisors = useSelector((state) => state.userClient.supervisors);
  const selectedUsers = useSelector(
    (state) => state.client.therapistsListForAssign
  );

  const setNewData = (newId: AssignIdsProperties) => {
    if (!selectedUsers.find((x) => x.id === row.id)) {
      dispatch(addTherapist(newId));
      return;
    }
    dispatch(updateTherapist(newId));
  };

  const onSupervisorChange = (event: SelectChangeEvent<string>) => {
    const { value } = event.target;
    if (!value) return;
    setSupervisorId(value);
    const newId: AssignIdsProperties = {
      id: row.id,
      isAssigned: row.isAssigned,
      supervisorId: value,
      authorizationTypeId: !!row.authorizationType
        ? row.authorizationType.id
        : null,
      fullName: row.fullName,
    };
    setNewData(newId);
  };

  const onChoose = (event: ChangeEvent<HTMLInputElement>) => {
    const { checked, name } = event.target;
    const newId: AssignIdsProperties = {
      id: name,
      isAssigned: checked,
      supervisorId,
      authorizationTypeId: !!row.authorizationType
        ? row.authorizationType.id
        : null,
      fullName: row.fullName,
    };
    setNewData(newId);
  };

  useEffect(() => {
    const user = selectedUsers.find((x) => x.id === row.id);
    if (!user) return;
    setSupervisorId(user.supervisorId);
  }, [selectedUsers, row]);

  useEffect(() => {
    if (!row.supervisor) return;
    setSupervisorId(row.supervisor.id);
  }, [row]);

  return (
    <TableRow selected={selected}>
      <TableCell>
        <CustomCheckBox
          item={{
            checked: selected,
            disabled: row.authorizationType?.id === 2 && !supervisorId,
            id: row.id,
          }}
          onChange={onChoose}
        />
      </TableCell>
      <TableCell>
        {row.fullName}
        {row.authorizationType?.id === 2 &&
          !!supervisors &&
          !!supervisors.length && (
            <div style={{ width: "250px" }}>
              <CustomSelect
                label="Supervising BCBA: "
                data={supervisors.map((x) => ({ id: x.id, name: x.fullName }))}
                value={supervisorId as string}
                setValue={onSupervisorChange}
                className="marginTop8 marginLeft8"
              />
            </div>
          )}
      </TableCell>
      <TableCell>{row.email}</TableCell>
      {row.role.section.id !== AdminTypes.rbt && (
        <>
          <TableCell>{row.authorizationType?.name}</TableCell>
          <TableCell>{row.role.section.name}</TableCell>
          <TableCell>
            {row.isDefaultBcba && <Check style={{ marginLeft: "16px" }} />}
          </TableCell>
        </>
      )}
    </TableRow>
  );
};
