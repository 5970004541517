import { ChangeEvent, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import dayjs, { Dayjs } from "dayjs";
import { unwrapResult } from "@reduxjs/toolkit";
import { SelectChangeEvent } from "@mui/material";

import {
  CustomButton,
  CustomCheckBox,
  CustomSelect,
  Picker,
} from "../../../shared/uiComponents";
import { DispatchProperties, useSelector } from "../../../redux/store";
import { SelectDataProperties } from "../../../shared/uiComponents/Dropdown";
import {
  dateNormalizer,
  errorNormalizer,
} from "../../../shared/Helpers/functions";
import { ButtonWrapper, Container } from "../../AdminPanel/StyledComponents";
import { useCompareDates } from "../../../shared/Helpers/hooks";
import { TimeInputsWrapper } from "../Calendar/Popups/addEvent";

import { getUserPatients } from "../../../redux/State/clientSlice/userClientSlice";
import { getClientGraphs } from "../../../redux/State/exportSlice";

const Reports = () => {
  const dispatch = useDispatch<DispatchProperties>();
  const today = new Date();
  const todayDayjs = dayjs(today);
  const pastDateMonth = dayjs(today).subtract(1, "month");

  const patients = useSelector((state) => state.userClient.userPatients);
  const userId = useSelector((state) => state.account.user.id);
  const loading = useSelector((state) => state.export.loading);

  const [clientId, setClientId] = useState<string>("");
  const [pageSize, setPageSize] = useState<number>(8);
  const [startDateValue, setStartDateValue] = useState<Dayjs>(pastDateMonth);
  const [endDateValue, setEndDateValue] = useState<Dayjs>(todayDayjs);
  const [includeBehaviors, setIncludeBehaviors] = useState<boolean>(true);
  const [includeTargets, setIncludeTargets] = useState<boolean>(true);
  const [patientsData, setPatientsData] = useState<Array<SelectDataProperties>>(
    []
  );
  const { hasError, message } = useCompareDates(startDateValue, endDateValue);

  useEffect(() => {
    if (!userId) return;
    dispatch(
      getUserPatients({ userId, page: "1", pageSize: pageSize.toString() })
    );
  }, [pageSize, userId, dispatch]);

  useEffect(() => {
    if (!patients || !patients.query) return;
    const data: Array<SelectDataProperties> = patients.query.map((patient) => ({
      id: patient.id,
      label: patient.fullName,
    }));
    setPatientsData(data);
  }, [patients]);

  const onClientChange = (event: SelectChangeEvent<string>) => {
    const { value } = event.target;
    setClientId(value);
  };

  const onStartDateChange = (value: Dayjs | null) => {
    if (!value) return;
    setStartDateValue(value);
  };
  const onEndDateChange = (value: Dayjs | null) => {
    if (!value) return;
    setEndDateValue(value);
  };

  const onTargetsChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { checked } = event.target;
    setIncludeTargets(checked);
  };
  const onBehaviorsChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { checked } = event.target;
    setIncludeBehaviors(checked);
  };

  const downloadGraphReport = () => {
    if (!clientId || !startDateValue || !endDateValue) return;
    const startDate = dateNormalizer(startDateValue);
    const endDate = dateNormalizer(endDateValue);

    dispatch(
      getClientGraphs({
        clientId,
        includeBehaviors,
        includeTargets,
        startDate,
        endDate,
      })
    )
      .then(unwrapResult)
      .then((response) => {
        const objectURL = URL.createObjectURL(response);
        window.open(objectURL);
      })
      .catch(errorNormalizer);
  };

  return (
    <Container style={{ maxWidth: "720px" }}>
      <CustomSelect
        label="Clients"
        data={patientsData}
        value={clientId}
        setValue={onClientChange}
        loadMore={{
          activate: true,
          setSize: setPageSize,
        }}
        className="marginBottom16"
        disabled={loading}
      />
      <TimeInputsWrapper className="marginBottom16">
        <Picker.CustomDate
          label="Start Date"
          value={startDateValue}
          onChange={onStartDateChange}
          disabled={loading}
        />
        <Picker.CustomDate
          label="End Date"
          value={endDateValue}
          onChange={onEndDateChange}
          disabled={loading}
          error={hasError}
          errorMessage={message}
        />
      </TimeInputsWrapper>
      <ButtonWrapper className="marginBottom16">
        <CustomCheckBox
          item={{
            id: "1",
            label: "Include behaviors",
            checked: includeBehaviors,
            disabled: loading,
          }}
          onChange={onBehaviorsChange}
        />
        <CustomCheckBox
          item={{
            id: "1",
            label: "Include targets",
            checked: includeTargets,
            disabled: loading,
          }}
          onChange={onTargetsChange}
        />
      </ButtonWrapper>
      <CustomButton
        title="Download"
        onClick={downloadGraphReport}
        disabled={
          loading || !clientId || !startDateValue || !endDateValue || hasError
        }
        loading={loading}
      />
    </Container>
  );
};

export default Reports;
