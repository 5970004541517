import { FetchAPI } from "../Main/fetchAPI";
import { BEHAVIORS, TARGETS } from "../constants";
import { BehaviorsAnnotationsProperties, TargetsAnnotationsProperties } from "./graphAnnotationProperties";
import { BehaviorGraphProperties, TargetGraphProperties } from "./graphProperties";

export const Graph = {
    targets: {
        getDTTGraph: (data: TargetGraphProperties) => {
            const { targetId, endDate, periodicity, startDate } = data;
            const query = new URLSearchParams();

            if (!!startDate) query.set("startDate", startDate);
            if (!!endDate) query.set("endDate", endDate);
            if (!!periodicity) query.set("periodicity", periodicity);
            return FetchAPI.getJson({
                urlStr: `${TARGETS}/dtt/${targetId}/graphs/percentage?${query.toString()}`,
            });
        },
        getTAGraph: (data: TargetGraphProperties) => {
            const { targetId, endDate, periodicity, startDate } = data;
            const query = new URLSearchParams();

            if (!!startDate) query.set("startDate", startDate);
            if (!!endDate) query.set("endDate", endDate);
            if (!!periodicity) query.set("periodicity", periodicity);
            return FetchAPI.getJson({
                urlStr: `${TARGETS}/ta/${targetId}/graphs/percentage?${query.toString()}`,
            });
        },
        getTargetAnnotations: (data: TargetsAnnotationsProperties) => {
            const { targetId, endDate, startDate } = data;
            const query = new URLSearchParams();
            if (!!startDate) query.set("startDate", startDate);
            if (!!endDate) query.set("endDate", endDate);
            return FetchAPI.getJson({
                urlStr: `${TARGETS}/${targetId}/graphs/annotations?${query.toString()}`,
            });
        },
        getTargetNotes: (targetId: string) => FetchAPI.getJson({
            urlStr: `${TARGETS}/${targetId}/graphs/notes`
        }),
        updateTargetNotes: (targetId: string, data: { notes: string }) => FetchAPI.putJson({
            urlStr: `${TARGETS}/${targetId}/graphs/notes`,
            data
        }),
    },
    behaviors: {
        getBehaviorFrequencyGraph: (data: BehaviorGraphProperties) => {
            const { behaviorId, endDate, periodicity, startDate } = data;
            const query = new URLSearchParams();

            if (!!startDate) query.set("startDate", startDate);
            if (!!endDate) query.set("endDate", endDate);
            if (!!periodicity) query.set("periodicity", periodicity);
            return FetchAPI.getJson({
                urlStr: `${BEHAVIORS}/frequency/${behaviorId}/graphs/standard?${query.toString()}`,
            });
        },
        getBehaviorDurationGraph: (data: BehaviorGraphProperties) => {
            const { behaviorId, endDate, periodicity, startDate } = data;
            const query = new URLSearchParams();

            if (!!startDate) query.set("startDate", startDate);
            if (!!endDate) query.set("endDate", endDate);
            if (!!periodicity) query.set("periodicity", periodicity);
            return FetchAPI.getJson({
                urlStr: `${BEHAVIORS}/duration/${behaviorId}/graphs/total?${query.toString()}`,
            });
        },
        getBehaviorsAnnotations: (data: BehaviorsAnnotationsProperties) => {
            const { behaviorId, endDate, startDate } = data;
            const query = new URLSearchParams();
            if (!!startDate) query.set("startDate", startDate);
            if (!!endDate) query.set("endDate", endDate);
            return FetchAPI.getJson({
                urlStr: `${BEHAVIORS}/${behaviorId}/graphs/annotations?${query.toString()}`,
            });
        },
        getBehaviorNotes: (behaviorId: string) => FetchAPI.getJson({
            urlStr: `${BEHAVIORS}/${behaviorId}/graphs/notes`
        }),
        updateBehaviorNotes: (behaviorId: string, data: { notes: string }) => FetchAPI.putJson({
            urlStr: `${BEHAVIORS}/${behaviorId}/graphs/notes`,
            data
        }),
    }
}