import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import API from "../../API/Main/API";
import {
    AddRoleProperties,
    ClaimsProperties,
    ErrorProperties,
    ExtendedRoleProperties,
    RoleSectionProperties,
} from "../../API/identityAPIProperties";

import { resetState } from "../../resetState";

interface IdentityRoleStateProperties {
    loading: boolean;
    error: ErrorProperties;
    roles: Array<ExtendedRoleProperties> | null;
    role: ExtendedRoleProperties;
    claims: Array<ClaimsProperties> | null;
    sections: Array<RoleSectionProperties> | null;
}

const initialRoleState: IdentityRoleStateProperties = {
    loading: false,
    error: {
        status: 0,
        title: '',
    },
    roles: null,
    role: {
        id: "",
        name: "",
        claims: [],
        description: "",
        createdAt: "",
        modifiedAt: "",
        isReadonly: false,
        createdBy: {
            id: "",
            firstName: "",
            lastName: "",
            email: "",
        },
        numberOfUsage: 0,
        section: { id: 0, name: '' },
    },
    claims: null,
    sections: null,
}

export const getClaims = createAsyncThunk(
    '/identity/claims',
    async (_, thunkAPI) => {
        const response = await API.IdentityAPI.Claim.getClaims();
        if (!!response.error) {
            return thunkAPI.rejectWithValue(response.error);
        }
        return response as Array<ClaimsProperties>;
    }
)

export const getSections = createAsyncThunk(
    '/identity/sections',
    async (_, thunkAPI) => {
        const response = await API.IdentityAPI.Role.getSections();
        if (!!response.error) {
            return thunkAPI.rejectWithValue(response.error);
        }
        return response;
    }
)

export const getRoles = createAsyncThunk(
    '/identity/roles',
    async (_, thunkAPI) => {
        const response = await API.IdentityAPI.Role.getRoles();
        if (!!response.error) {
            return thunkAPI.rejectWithValue(response.error);
        }
        return response as Array<ExtendedRoleProperties>;
    }
)

export const getRole = createAsyncThunk(
    '/identity/role',
    async (id: string, thunkAPI) => {
        const response = await API.IdentityAPI.Role.getRole(id);
        if (!!response.error) {
            return thunkAPI.rejectWithValue(response.error);
        }
        return response as ExtendedRoleProperties;
    }
)

export const addRole = createAsyncThunk(
    '/identity/roles/add',
    async (data: AddRoleProperties, thunkAPI) => {
        const response = await API.IdentityAPI.Role.addRole(data);
        if (!!response.error) {
            return thunkAPI.rejectWithValue(response.error);
        }
        return response as ExtendedRoleProperties;
    }
)

export const updateRole = createAsyncThunk(
    '/identity/roles/update',
    async (data: AddRoleProperties, thunkAPI) => {
        const response = await API.IdentityAPI.Role.updateRole(data);
        if (!!response.error) {
            return thunkAPI.rejectWithValue(response.error);
        }
        return response as ExtendedRoleProperties;
    }
)

export const deleteRole = createAsyncThunk(
    '/identity/roles/delete',
    async (id: string, thunkAPI) => {
        const response = await API.IdentityAPI.Role.deleteRole(id);
        if (!!response.error) {
            return thunkAPI.rejectWithValue(response.error);
        }
        return response;
    }
)

const roleSlice = createSlice({
    name: 'role',
    initialState: initialRoleState,
    reducers: {},
    extraReducers: (builder) => {
        //get claims
        builder.addCase(getClaims.pending, (state) => {
            state.loading = true;
            state.error = initialRoleState.error;
        })
        builder.addCase(getClaims.fulfilled, (state, action) => {
            state.loading = false;
            state.claims = action.payload;
        })
        builder.addCase(getClaims.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload as ErrorProperties;
        })
        //get sections
        builder.addCase(getSections.pending, (state) => {
            state.loading = true;
            state.error = initialRoleState.error;
        })
        builder.addCase(getSections.fulfilled, (state, action) => {
            state.loading = false;
            state.sections = action.payload;
        })
        builder.addCase(getSections.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload as ErrorProperties;
        })
        //get roles
        builder.addCase(getRoles.pending, (state) => {
            state.loading = true;
            state.error = initialRoleState.error;
        })
        builder.addCase(getRoles.fulfilled, (state, action) => {
            state.loading = false;
            state.roles = action.payload;
        })
        builder.addCase(getRoles.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload as ErrorProperties;
        })
        //get role
        builder.addCase(getRole.pending, (state) => {
            state.loading = true;
            state.error = initialRoleState.error;
        })
        builder.addCase(getRole.fulfilled, (state, action) => {
            state.loading = false;
            state.role = action.payload;
        })
        builder.addCase(getRole.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload as ErrorProperties;
        })
        //add role
        builder.addCase(addRole.pending, (state) => {
            state.loading = true;
            state.error = initialRoleState.error;
        })
        builder.addCase(addRole.fulfilled, (state) => {
            state.loading = false;
        })
        builder.addCase(addRole.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload as ErrorProperties;
        })
        //update role
        builder.addCase(updateRole.pending, (state) => {
            state.loading = true;
            state.error = initialRoleState.error;
        })
        builder.addCase(updateRole.fulfilled, (state) => {
            state.loading = false;
        })
        builder.addCase(updateRole.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload as ErrorProperties;
        })
        //delete role
        builder.addCase(deleteRole.pending, (state) => {
            state.loading = true;
            state.error = initialRoleState.error;
        })
        builder.addCase(deleteRole.fulfilled, (state) => {
            state.loading = false;
        })
        builder.addCase(deleteRole.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload as ErrorProperties;
        })
       
        //reset state
        builder.addCase(resetState, () => initialRoleState);
    }
})

export default roleSlice.reducer;