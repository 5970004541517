import { CircularProgress, styled } from "@mui/material";
import { white100 } from "../../../shared/Helpers/colors";

export const Container = styled("div")(() => ({
  backgroundColor: white100,
  width: "100%",
  padding: "24px",
  margin: "auto",
  borderRadius: "12px",
  minWidth: "720px",
  maxWidth: "1440px",
  ".mobileView": {
    display: "none",
  },
  "@media (max-width: 768px)": {
    minWidth: "220px",
    backgroundColor: "transparent",
    padding: 0,
    ".tableContainer": {
      display: "none",
    },
    ".mobileView": {
      display: "flex",
      flexDirection: "column",
    },
  },
}));

export const AdminContainer = styled("div")(() => ({
  width: "max-content",
  minWidth: "420px",
  margin: "auto",
  "@media (max-width: 468px)": {
    minWidth: "100%",
  },
}));

export const Header = styled("div")(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  marginBottom: "24px",
  "@media (max-width: 768px)": {
    flexDirection: "column",
    marginBottom: 0,
    ">div": {
      marginBottom: "8px",
      width: "100%",
    },
    ".marginRight16": {
      marginRight: 0,
    },
  },
}));

export const Wrapper = styled("div")(() => ({
  padding: "12px",
  borderRadius: "12px",
  backgroundColor: white100,
  marginBottom: "12px",
  boxShadow: "0px 4px 10px rgba(224, 238, 242, 0.8)",
}));

export const Row = styled("div")(() => ({
  display: "flex",
  width: "100%",
  padding: "8px 0",
  margin: "auto",
  alignItems: "center",
  lineHeight: "24px",
}));

export const InfoRow = styled("div")(() => ({
  display: "flex",
  width: "100%",
  padding: "8px 0",
  margin: "auto",
  alignItems: "center",
  lineHeight: "24px",
  ">div": {
    wordWrap: "break-word",
    width: "calc(100% - 32px)",
  },
}));

export const InputWrapper = styled("div")(() => ({
  display: "flex",
  justifyContent: "space-between",
  marginBottom: "16px",
  ">div": {
    width: "100%",
    ":nth-of-type(odd)": {
      marginRight: "16px",
    },
  },
  "@media (max-width: 768px)": {
    flexDirection: "column",
    ">div": {
      marginBottom: "16px",
      ":nth-of-type(odd)": {
        marginRight: "0",
      },
    },
  },
}));

export const ButtonWrapper = styled("div")(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: 'center',
  gap: '16px',
  marginBottom: "24px",
  "@media (max-width: 500px)": {
    flexDirection: "column",
    ">div": {
      width: "100%",
    },
  },
}));

export const CheckboxWrapper = styled("div")(() => ({
  display: "flex",
  flexDirection: "column",
  marginBottom: "16px",
  backgroundColor: white100,
  padding: "24px",
  borderRadius: "12px",
  boxShadow: "0px 4px 10px rgba(224, 238, 242, 0.8)",
}));

export const LoadingWrapper = styled("div")(() => ({
  width: "max-content",
  height: "80px",
  margin: "auto",
  display: "flex",
  alignItems: "center",
}));

export const Loader = ({ color }: { color?: string }) => (
  <LoadingWrapper color={color}>
    <CircularProgress color={"inherit"} />
  </LoadingWrapper>
);
