import { ChangeEvent, useEffect, useState, Dispatch } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useParams, useSearchParams } from "react-router-dom";
import { SelectChangeEvent } from "@mui/material/Select";
import { Value } from "react-phone-number-input";
import { isPossiblePhoneNumber } from "react-phone-number-input";

import { DispatchProperties, useSelector } from "../../../redux/store";
import { getRoles } from "../../../redux/State/identitySlice/roleSlice";
import { SaveUserDataProperties } from "../../../redux/API/userAPIProperties";
import { RoleProperties } from "../../../redux/API/identityAPIProperties";
import { getAuthorizationTypes } from "../../../redux/State/userSlice";
import { getInsurances } from "../../../redux/State/clientSlice/clientSlice";

import {
  capitalizer,
  validateEmail,
  validateName,
} from "../../../shared/Helpers/functions";
import { ACTIONS, ActionProperties } from "./adminReducer";
import {
  CustomSelect,
  Text,
  CustomInput,
  CustomCheckBox,
} from "../../../shared/uiComponents";
import { AdminContainer as Container, Loader } from "../StyledComponents";
import { SelectDataProperties } from "../../../shared/uiComponents/Dropdown";
import { CustomPhoneInput } from "../../../shared/uiComponents/Input";
import { AdminTypes } from "../../../components/Action";
import CustomSearchMultipleSelect from "../../../shared/uiComponents/Dropdown/saerchMultipleSelect";

interface ErrorProperties {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  address: string;
  role: string;
}

const AdminForm = ({
  adminInfo,
  setAdminInfo,
}: {
  adminInfo: SaveUserDataProperties;
  setAdminInfo: Dispatch<ActionProperties>;
}) => {
  const dispatch = useDispatch<DispatchProperties>();
  const { userId } = useParams();
  const { pathname } = useLocation();
  const [params, setParams] = useSearchParams();

  const [disabled, setDisabledSave] = useState<boolean>(true);
  const [authorizationTypeAllowed, setAuthorizationTypeAllowed] =
    useState<boolean>(false);
  const [adminType, setAdminType] = useState<AdminTypes>(0);
  const [adminRoles, setAdminRoles] = useState<Array<RoleProperties>>([]);
  const [error, setError] = useState<ErrorProperties>({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    address: "",
    role: "",
  });

  const loading = useSelector((state) => state.user.loading);
  const loadingRoles = useSelector((state) => state.role.loading);

  const roles = useSelector((state) => state.role.roles);
  const authorizationTypes = useSelector(
    (state) => state.user.authorizationTypes
  );
  const insurances = useSelector((state) => state.client.insurances);

  useEffect(() => {
    if (!!authorizationTypes.length) return;
    dispatch(getAuthorizationTypes());
  }, [authorizationTypes, dispatch]);

  useEffect(() => {
    dispatch(getInsurances());
  }, [dispatch]);

  useEffect(() => {
    params.set("saveDisabled", `${disabled}`);
    setParams(params);
  }, [params, setParams, disabled]);

  useEffect(() => {
    if (!pathname) return;
    const adminURLType = pathname.split("/")[2] as keyof typeof AdminTypes;
    setAdminType(AdminTypes[adminURLType]);
  }, [pathname]);

  useEffect(() => {
    if (!roles || !roles.length) return;
    if (pathname.includes("user")) {
      setAdminRoles(roles);
      return;
    }
    const currentRoles = roles.filter((role) => role.section.id === adminType);
    const defaultId = currentRoles.find((x) => x.isReadonly)?.id;
    setAdminInfo({ type: ACTIONS.setRoleId, payload: defaultId });
    setAdminRoles(currentRoles);
  }, [roles, adminType, pathname, setAdminInfo]);

  useEffect(() => {
    if (!!roles) return;
    dispatch(getRoles());
  }, [roles, dispatch]);

  useEffect(() => {
    const {
      firstName,
      lastName,
      email,
      phoneNumber,
      address,
      roleId,
      authorizationType,
    } = adminInfo;
    const values = [firstName, lastName, email, phoneNumber, address, roleId];
    const hasInitialData =
      Object.values(error).some((x) => !!x) || values.some((x) => !x);
    if (!authorizationTypeAllowed) {
      setDisabledSave(hasInitialData);
      setAdminInfo({ type: ACTIONS.setAuthorizationType, payload: null });
      return;
    }

    if (!authorizationType) {
      setDisabledSave(true);
      return;
    }
    setDisabledSave(hasInitialData);
  }, [error, adminInfo, adminType, authorizationTypeAllowed, setAdminInfo]);

  useEffect(() => {
    setAuthorizationTypeAllowed(
      AdminTypes.rbt !== adminType &&
        AdminTypes.rbt !==
          roles?.find((x) => x.id === adminInfo.roleId)?.section.id
    );
  }, [adminInfo, adminType, roles]);

  const onFirstNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setAdminInfo({ type: ACTIONS.setFirstName, payload: capitalizer(value) });
    setError((prev) => ({ ...prev, firstName: "" }));
  };

  const onLastNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setAdminInfo({ type: ACTIONS.setLastName, payload: capitalizer(value) });
    setError((prev) => ({ ...prev, lastName: "" }));
  };

  const onEmailChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setAdminInfo({ type: ACTIONS.setEmail, payload: value });
    setError((prev) => ({ ...prev, email: "" }));
  };

  const onPhoneNumberChange = (value: Value) => {
    setAdminInfo({ type: ACTIONS.setPhoneNumber, payload: value });
    setError((prev) => ({ ...prev, phoneNumber: "" }));
  };

  const onAddressChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setAdminInfo({ type: ACTIONS.setAddress, payload: value });
    setError((prev) => ({ ...prev, address: "" }));
  };

  const onRoleChange = (event: SelectChangeEvent<string>) => {
    const value = event.target.value;
    if (!value) return;
    setAdminInfo({ type: ACTIONS.setRoleId, payload: value });
    setError((prev) => ({ ...prev, role: "" }));
  };

  const onAuthorizationTypeChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name } = event.target;
    if (!name) return;
    const payload = parseInt(name);
    setAdminInfo({ type: ACTIONS.setAuthorizationType, payload });
    if (payload === 2) {
      //limited permit
      setAdminInfo({ type: ACTIONS.setInsurances, payload: [] });
    }
  };

  const onInsurancesChange = (value: Array<string>) => {
    if (!value || typeof value === "string") return;
    setAdminInfo({ type: ACTIONS.setInsurances, payload: value });
  };

  const onFirstNameBlurHandler = () => {
    if (!!adminInfo.firstName && validateName(adminInfo.firstName)) return;
    if (!adminInfo.firstName) {
      setError((prev) => ({ ...prev, firstName: "Required" }));
      return;
    }
    setError((prev) => ({ ...prev, firstName: "Invalid name" }));
  };

  const onLastNameBlurHandler = () => {
    if (!!adminInfo.lastName && validateName(adminInfo.lastName)) return;
    if (!adminInfo.lastName) {
      setError((prev) => ({ ...prev, lastName: "Required" }));
      return;
    }
    setError((prev) => ({ ...prev, lastName: "Invalid name" }));
  };

  const onEmailBlurHandler = () => {
    const { email } = adminInfo;
    if (!email) {
      setError((prev) => ({ ...prev, email: "Required" }));
      return;
    }
    if (!validateEmail(email)) {
      setError((prev) => ({ ...prev, email: "Invalid email" }));
      return;
    }
    setError((prev) => ({ ...prev, email: "" }));
  };

  const onPhoneNumberBlurHandler = () => {
    const { phoneNumber } = adminInfo;
    if (!phoneNumber) {
      setError((prev) => ({ ...prev, phoneNumber: "Required" }));
      return;
    }
    if (!isPossiblePhoneNumber(phoneNumber)) {
      setError((prev) => ({ ...prev, phoneNumber: "Invalid phone number" }));
      return;
    }
  };

  const onAddressBlurHandler = () => {
    if (!!adminInfo.address) return;
    setError((prev) => ({ ...prev, address: "Required" }));
  };

  const onRoleBlurHandler = () => {
    if (!!adminInfo.roleId) return;
    setError((prev) => ({ ...prev, role: "Required" }));
  };

  return !loading && !loadingRoles ? (
    <Container>
      <Text
        title={`${pathname.split("/")[2]} information`.toUpperCase()}
        size={"mediumBold"}
        className={"marginBottom16"}
      />
      <CustomInput
        label={"First name"}
        value={adminInfo.firstName}
        setValue={onFirstNameChange}
        className={"marginBottom16"}
        onBlur={onFirstNameBlurHandler}
        error={!!error.firstName}
        errorMessage={error.firstName}
      />
      <CustomInput
        label={"Last name"}
        value={adminInfo.lastName}
        setValue={onLastNameChange}
        className={"marginBottom16"}
        onBlur={onLastNameBlurHandler}
        error={!!error.lastName}
        errorMessage={error.lastName}
      />
      <CustomInput
        label={"Email"}
        value={adminInfo.email}
        setValue={onEmailChange}
        className={"marginBottom16"}
        disabled={!!userId}
        error={!!error.email}
        errorMessage={error.email}
        onBlur={onEmailBlurHandler}
      />
      <CustomPhoneInput
        label={"Phone number"}
        value={adminInfo.phoneNumber}
        setValue={onPhoneNumberChange}
        className={"marginBottom16"}
        onBlur={onPhoneNumberBlurHandler}
        error={!!error.phoneNumber}
        errorMessage={error.phoneNumber}
      />
      <CustomInput
        label={"Address"}
        value={adminInfo.address}
        setValue={onAddressChange}
        className={"marginBottom16"}
        onBlur={onAddressBlurHandler}
        error={!!error.address}
        errorMessage={error.address}
      />
      {adminType !== AdminTypes.rbt && (
        <>
          <CustomSelect
            data={adminRoles as SelectDataProperties[]}
            value={adminInfo.roleId}
            setValue={onRoleChange}
            label={"Role"}
            className={"marginBottom16"}
            onBlur={onRoleBlurHandler}
            error={!!error.role}
            errorMessage={error.role}
          />
          {authorizationTypeAllowed && (
            <div className="marginLeft8 marginBottom8">
              <Text title={"Type of authorization: "} size="tinyBold" />
              {authorizationTypes.map((type) => (
                <CustomCheckBox
                  key={type.id}
                  item={{
                    id: `${type.id}`,
                    label: type.name,
                    checked: type.id === adminInfo.authorizationType,
                  }}
                  onChange={onAuthorizationTypeChange}
                />
              ))}
            </div>
          )}
          <CustomSearchMultipleSelect
            label="Select insurance (multiple if applicable): "
            data={[{ id: "", name: "N/A" }, ...insurances]}
            values={adminInfo.insurances}
            setValues={onInsurancesChange}
            className={"marginBottom16"}
            disabled={adminInfo.authorizationType !== 1}
          />
        </>
      )}
    </Container>
  ) : (
    <Loader />
  );
};

export default AdminForm;
