import { FetchAPI } from "./Main/fetchAPI";
import { USER, USERS } from "./constants";
import {
    SaveUserDataProperties,
    UpdateUserDataProperties,
    UserPaginationProperties,
    section,
} from "./userAPIProperties";

export const UserAPI = {
    getAuthorizationTypes: () => FetchAPI.getJson({
        urlStr: `${USER}/authorization-types`,
    }),
    getUserInsurances: (id: string) => FetchAPI.getJson({
        urlStr: `${USERS}/${id}/insurances`,
    }),
    getUsers: (data: UserPaginationProperties) => {
        const {
            page,
            pageSize,
            direction,
            orderBy,
            role,
            searchString,
            section,
        } = data;
        const query = new URLSearchParams();

        if (!!searchString) query.set('searchString', searchString);
        if (!!direction) query.set('direction', direction);
        if (!!orderBy) query.set('orderBy', orderBy);
        if (!!role) query.set('role', role);
        if (!!section) {
            return FetchAPI.getJson({ urlStr: `${USERS}/${section}/${pageSize}/${page}?${query.toString()}` });
        };

        return FetchAPI.getJson({ urlStr: `${USERS}/${pageSize}/${page}?${query.toString()}` });
    },
    getUsersBySection: (data: UserPaginationProperties, section: section) => {
        const {
            page,
            pageSize,
            direction,
            orderBy,
            role,
            searchString,
        } = data;
        const query = new URLSearchParams();

        if (!!searchString) query.set('searchString', searchString);
        if (!!direction) query.set('direction', direction);
        if (!!orderBy) query.set('orderBy', orderBy);
        if (!!role) query.set('role', role);

        return FetchAPI.getJson({ urlStr: `${USERS}/${section}/${pageSize}/${page}?${query.toString()}` });
    },
    addUser: (data: SaveUserDataProperties) => FetchAPI.postJson({
        urlStr: `${USERS}`,
        data,
    }),
    getUser: (id: string) => FetchAPI.getJson({
        urlStr: `${USERS}/${id}`,
    }),
    updateUser: ({ id, data }: UpdateUserDataProperties) => FetchAPI.putJson({
        urlStr: `${USERS}/${id}`,
        data
    }),
    deleteUser: (id: string) => FetchAPI.deleteJson({
        urlStr: `${USERS}/${id}`,
    })
}