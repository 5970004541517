import { ChangeEvent, useEffect, useState } from "react";
import { unwrapResult } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";
import { EditOutlined as Edit } from "@mui/icons-material";

import {
  CustomInput,
  CustomButton,
  Text,
} from "../../../../../../../shared/uiComponents";
import { errorNormalizer } from "../../../../../../../shared/Helpers/functions";

import { DispatchProperties } from "../../../../../../../redux/store";
import {
  getBehaviorNotes,
  getTargetNotes,
  updateBehaviorNotes,
  updateTargetNotes,
} from "../../../../../../../redux/State/clientSlice/graphSlice";

export const TargetGraphNotes = ({ targetId }: { targetId: string }) => {
  const dispatch = useDispatch<DispatchProperties>();

  const [notes, setNotes] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [disabled, setDisabled] = useState<boolean>(true);

  const targetNotesHandler = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setNotes(value);
    setDisabled(false);
  };

  const saveNotesHandler = () => {
    if (!targetId) return;
    setLoading(true);
    dispatch(updateTargetNotes({ targetId, data: { notes } }))
      .then(unwrapResult)
      .then(() => setDisabled(true))
      .catch(errorNormalizer)
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    if (!targetId) return;
    dispatch(getTargetNotes(targetId))
      .then(unwrapResult)
      .then(({ notes }) => setNotes(notes))
      .catch(errorNormalizer);
  }, [targetId, dispatch]);

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          margin: "8px 0",
        }}
      >
        <Text title={"Note:"} size="smallBold" />{" "}
        {disabled && (
          <Edit
            style={{ cursor: "pointer" }}
            onClick={() => setDisabled(false)}
          />
        )}
      </div>
      {!disabled ? (
        <CustomInput
          value={notes}
          setValue={targetNotesHandler}
          multiline={{ multiline: true, rowCount: 4 }}
          className="marginBottom16"
        />
      ) : (
        <Text title={notes} />
      )}
      {!disabled && (
        <CustomButton
          title="Save"
          onClick={saveNotesHandler}
          loading={loading}
        />
      )}
    </>
  );
};

export const BehaviorGraphNotes = ({ behaviorId }: { behaviorId: string }) => {
  const dispatch = useDispatch<DispatchProperties>();

  const [notes, setNotes] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);

  const behaviorNotesHandler = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setNotes(value);
  };

  const saveNotesHandler = () => {
    if (!behaviorId) return;
    setLoading(true);
    dispatch(updateBehaviorNotes({ behaviorId, data: { notes } }))
      .then(unwrapResult)
      .catch(errorNormalizer)
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    if (!behaviorId) return;
    dispatch(getBehaviorNotes(behaviorId))
      .then(unwrapResult)
      .then(({ notes }) => setNotes(notes))
      .catch(errorNormalizer);
  }, [behaviorId, dispatch]);

  return (
    <div
      style={{
        padding: "10px",
      }}
    >
      <CustomInput
        label="Note:"
        value={notes}
        setValue={behaviorNotesHandler}
        multiline={{ multiline: true, rowCount: 4 }}
        className="marginBottom16"
      />
      <CustomButton
        title="Save"
        onClick={saveNotesHandler}
        loading={loading}
        disabled={loading}
      />
    </div>
  );
};
