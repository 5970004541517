import AddAssessmentInsuranceAuthorization from "./assessmentType";
import AddTreatmentInsuranceAuthorization from "./treatmentType";

const AddInsuranceAuthorization = () => (
  <div style={{ display: "flex", gap: "32px", alignItems: "center" }} className="margin16">
    <AddAssessmentInsuranceAuthorization />
    <AddTreatmentInsuranceAuthorization />
  </div>
);

export default AddInsuranceAuthorization;
