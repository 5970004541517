import { SaveBehaviorTreatmentModificationTypeProperties } from "../../SoapNoteTypesProperties/behaviorTreatmentModificationTypeProperties";

export enum ACTIONS {
    setState = "setState",
    setDate = "setDate",
    setEndTime = "setEndTime",
    setStartTime = "setStartTime",
    setLocationId = "setLocationId",
    setSessionSummary = "setSessionSummary",
    setSessionId = "setSessionId",
    setSignature = "setSignature",
    setPrintedName = "setPrintedName",
    setSubmit = "setSubmit",
    setIntervention = "setIntervention",
    setReinforcement = "setReinforcement",
    setResponseToIntervation = "setResponseToIntervation",
    setBariers = "setBariers",
    setGoalsAddressed = "setGoalsAddressed",
    setDataAnalyzed = 'setDataAnalyzed',
    setTreatmentPlanUpdates = "setTreatmentPlanUpdates",
    setMaladaptiveBehaviorsObserved = "setMaladaptiveBehaviorsObserved",
}

export interface ActionProperties {
    type: keyof typeof ACTIONS,
    payload: any,
};

export const initialBehaviorTreatmentModificationState: SaveBehaviorTreatmentModificationTypeProperties = {
    barrierToTreatmentResponses: [],
    behaviorTreatmentModificationInterventionResponses: [],
    dataAnalyzed: '',
    date: '',
    startTime: "",
    endTime: '',
    goalsAddressed: '',
    locationId: '',
    maladaptiveBehaviorsObserved: "",
    printedName: '',
    reinforcementResponses: [],
    responseToIntervention: null,
    sessionId: "",
    sessionSummary: '',
    signature: '',
    submit: false,
    treatmentPlanUpdates: '',
    forcedOperation: false,
}

export const behaviorTreatmentModificationReducer = (
    state: SaveBehaviorTreatmentModificationTypeProperties,
    action: ActionProperties,
): SaveBehaviorTreatmentModificationTypeProperties => {

    const { type, payload } = action;

    switch (type) {
        case ACTIONS.setState:
            return {
                ...state,
                ...payload,
            }
        case ACTIONS.setLocationId:
            return {
                ...state,
                locationId: payload,
            }
        case ACTIONS.setIntervention:
            return {
                ...state,
                behaviorTreatmentModificationInterventionResponses: payload,
            }
        case ACTIONS.setReinforcement:
            return {
                ...state,
                reinforcementResponses: payload,
            }
        case ACTIONS.setResponseToIntervation:
            return {
                ...state,
                responseToIntervention: payload
            }
        case ACTIONS.setBariers:
            return {
                ...state,
                barrierToTreatmentResponses: payload
            }
        case ACTIONS.setGoalsAddressed:
            return {
                ...state,
                goalsAddressed: payload
            }
        case ACTIONS.setDataAnalyzed:
            return {
                ...state,
                dataAnalyzed: payload
            }
        case ACTIONS.setTreatmentPlanUpdates:
            return {
                ...state,
                treatmentPlanUpdates: payload
            }
        case ACTIONS.setMaladaptiveBehaviorsObserved:
            return {
                ...state,
                maladaptiveBehaviorsObserved: payload
            }
        case ACTIONS.setSessionSummary:
            return {
                ...state,
                sessionSummary: payload
            }
        case ACTIONS.setSignature:
            return {
                ...state,
                signature: payload
            }
        default:
            return state;
    }
}

export enum ERRORS {
    LocationId = "LocationId",
    BehaviorTreatmentModificationInterventionResponses = "BehaviorTreatmentModificationInterventionResponses",
    ResponseToIntervention = "ResponseToIntervention",
    ReinforcementResponses = "ReinforcementResponses",
    BarrierToTreatmentResponses = "BarrierToTreatmentResponses",
    GoalsAddressed = "GoalsAddressed",
    DataAnalyzed = "DataAnalyzed",
    TreatmentPlanUpdates = "TreatmentPlanUpdates",
    MaladaptiveBehaviorsObserved = "MaladaptiveBehaviorsObserved",
    SessionSummary = "SessionSummary",
    Signature = "Signature",
}

interface BehaviorTreatmentModificationErrorStateProperties {
    LocationId: boolean;
    BehaviorTreatmentModificationInterventionResponses: boolean;
    ResponseToIntervention: boolean;
    ReinforcementResponses: boolean;
    BarrierToTreatmentResponses: boolean;
    GoalsAddressed: boolean;
    DataAnalyzed: boolean;
    TreatmentPlanUpdates: boolean;
    MaladaptiveBehaviorsObserved: boolean;
    SessionSummary: boolean;
    Signature: boolean;
}

interface ErrorActionProperties {
    type: keyof typeof ERRORS,
    payload: any;
}

export const initialBehaviorTreatmentModificationErrorState: BehaviorTreatmentModificationErrorStateProperties = {
    LocationId: false,
    BehaviorTreatmentModificationInterventionResponses: false,
    ResponseToIntervention: false,
    ReinforcementResponses: false,
    BarrierToTreatmentResponses: false,
    GoalsAddressed: false,
    DataAnalyzed: false,
    TreatmentPlanUpdates: false,
    MaladaptiveBehaviorsObserved: false,
    SessionSummary: false,
    Signature: false,
}

export const behaviorTreatmentModificationErrorReducer = (state: BehaviorTreatmentModificationErrorStateProperties, action: ErrorActionProperties) => {
    const { payload, type } = action;
    switch (type) {
        case ERRORS[type]:
            return {
                ...state,
                [type]: payload,
            }
        default:
            return state
    }
}