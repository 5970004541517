import { useEffect, ChangeEvent } from "react";
import { useDispatch } from "react-redux";
import { useParams, useSearchParams } from "react-router-dom";

import {
  CustomCheckBox,
  CustomTable,
  Text,
} from "../../../../shared/uiComponents";
import SearchBar from "../../../../components/SearchBar";
import { Headers, TableBody } from "./tableInfo";

import { DispatchProperties, useSelector } from "../../../../redux/store";
import {
  getAvailableNotCredentialedBCBAs,
  getBillableUsers,
  getClientBillableUser,
  setBillingUserId,
} from "../../../../redux/State/clientSlice/userClientSlice";
import { unwrapResult } from "@reduxjs/toolkit";

const NotCredentialedBCBA = () => {
  const dispatch = useDispatch<DispatchProperties>();
  const [params, setParams] = useSearchParams();
  const { clientId } = useParams();

  const bcbas = useSelector((state) => state.userClient.notCredentialedBCBAs);
  const billableUsers = useSelector((state) => state.userClient.billableUsers);
  const loading = useSelector(
    (state) => state.userClient.loadingGetAvailableBCBAs
  );

  useEffect(() => {
    dispatch(getBillableUsers());
  }, [dispatch]);

  useEffect(() => {
    if (!clientId || !!params.get("nc-billing-user")) return;
    dispatch(getClientBillableUser(clientId))
      .then(unwrapResult)
      .then((response) => {
        params.set("nc-billing-user", response.id);
        setParams(params);
      });
  }, [dispatch, clientId, params, setParams]);

  useEffect(() => {
    if (!clientId) return;
    const page = params.get("page") || "1";
    const pageSize = params.get("pageSize") || "8";
    const orderBy = params.get("orderBy") || "";
    const direction = params.get("direction") || "";
    const searchString = params.get("search") || "";
    const status = params.get("status") || "";

    dispatch(
      getAvailableNotCredentialedBCBAs({
        clientId,
        page,
        pageSize,
        orderBy,
        direction,
        searchString,
        status,
      })
    );
  }, [params, clientId, dispatch]);

  const onBillableUserChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name } = event.target;
    if (!name) return;
    dispatch(setBillingUserId(name));
    params.set("nc-billing-user", name);
    setParams(params);
  };

  return (
    <div className="marginTop16">
      <div
        className="marginLeft8 billableWrapper"
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        <div className="billableUserWrapper">
          <Text title={"Choose billable BCBA: "} size="tinyBold" />
          <div>
            {billableUsers.map((user) => (
              <CustomCheckBox
                key={user.id}
                item={{
                  id: user.id,
                  label: user.fullName,
                  checked: user.id === params.get("nc-billing-user"),
                }}
                onChange={onBillableUserChange}
              />
            ))}
          </div>
        </div>
        <SearchBar label="Find" />
      </div>
      <CustomTable
        headers={Headers}
        data={bcbas}
        TableBody={TableBody}
        loading={loading}
        hasMobile={false}
      />
    </div>
  );
};

export default NotCredentialedBCBA;
