import "./App.scss";
import { Navigate, Route, Routes } from "react-router-dom";
import utc from "dayjs/plugin/utc";
import dayjs from "dayjs";

import {
  AdminChecker,
  BCBAChecker,
  LoginChecker,
  ProtectedRoutes,
  RBTChecker,
} from "./helpers";

import Login from "../pages/Login";
import {
  ChangePassword,
  RecoverPassword,
  SetPassword,
} from "../pages/Password";

import Layout from "../components/Menu";
import { AdminRoutes, BCBARoutes, RBTRoutes } from "./panels";

import { useInitializeTabId } from "../shared/Helpers/hooks";

dayjs.extend(utc);

function App() {
  useInitializeTabId();

  return (
    <div className="App">
      <Routes>
        <Route element={<ProtectedRoutes />}>
          <Route
            path="admin/"
            element={
              <AdminChecker>
                <Layout />
              </AdminChecker>
            }
          >
            {AdminRoutes.map(({ Component, path }, index) => (
              <Route path={path} element={<Component />} key={index} />
            ))}
          </Route>
          <Route
            path="bcba/"
            element={
              <BCBAChecker>
                <Layout />
              </BCBAChecker>
            }
          >
            {BCBARoutes.map(({ Component, path }, index) => (
              <Route path={path} element={<Component />} key={index} />
            ))}
          </Route>
          <Route
            path="rbt/"
            element={
              <RBTChecker>
                <Layout />
              </RBTChecker>
            }
          >
            {RBTRoutes.map(({ Component, path }, index) => (
              <Route path={path} element={<Component />} key={index} />
            ))}
          </Route>
        </Route>
        <Route element={<LoginChecker />}>
          <Route path={"login"} element={<Login />} />
          <Route path={"confirm-email"} element={<SetPassword />} />
          <Route path={"recover-password"} element={<RecoverPassword />} />
          <Route path={"change-password"} element={<ChangePassword />} />
        </Route>
        <Route path={"*"} element={<Navigate to={"/login"} />} />
      </Routes>
    </div>
  );
}

export default App;
