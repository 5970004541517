import { ProgramDataProperties } from "../../../../../../redux/API/ClientAPIHelpers/clientProgramProperties";
import Archive from "./archive";
import CopyProgram from "./copyProgram";
import DeleteProgram from "./deleteProgram";
import EditProgram from "./editProgram";
import Unarchive from "./unarchive";

const Actions = ({ data }: { data: ProgramDataProperties }) => {
  return (
    <>
      <EditProgram data={data} />
      <CopyProgram data={data} />
      {!data.isArchived ? <Archive data={data} /> : <Unarchive data={data} />}
      <DeleteProgram data={data} />
    </>
  );
};

export default Actions;
