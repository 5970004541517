import { useEffect } from "react";
import { Container } from "../../pages/AdminPanel/SOAPNotes/helpers";
import {
  Header,
  InfoRow,
  Loader,
} from "../../pages/AdminPanel/StyledComponents";
import { DispatchProperties, useSelector } from "../../redux/store";
import Text from "../../shared/uiComponents/Text";
import { useDispatch } from "react-redux";
import { getAvailableHoursForDashboard } from "../../redux/State/clientSlice/userClientSlice";
import { AdminTypes } from "../Action";
import dayjs from "dayjs";

const Dashboard = () => {
  const dispatch = useDispatch<DispatchProperties>();

  const user = useSelector((state) => state.account.user);
  const role = useSelector((state) => state.account.role);
  const loadingAccount = useSelector((state) => state.account.loading);
  const hoursInfo = useSelector((state) => state.userClient.availableHours);
  const loading = useSelector((state) => state.userClient.loading);

  useEffect(() => {
    if (!user.id || role?.section.id !== AdminTypes.rbt) return;
    dispatch(getAvailableHoursForDashboard(user.id));
  }, [user, role, dispatch]);

  return (
    <>
      <Header>
        <Text title={"Dashboard"} size="mediumBold" />
      </Header>
      <Container style={{ maxWidth: "350px" }}>
        <Text title={"Account Information"} size="mediumBold" />
        {!!user && !!user.id && !loadingAccount && (
          <>
            <InfoRow>
              <Text title={"Name:"} size="smallBold" />
              <Text title={`${user?.firstName} ${user.lastName}`} />
            </InfoRow>
            <InfoRow>
              <Text title={"Email:"} size="smallBold" />
              <Text title={user.email} />
            </InfoRow>
          </>
        )}
        {!!role && !!role.id && !loadingAccount && (
          <>
            <InfoRow>
              <Text title={"Role:"} size="smallBold" />
              <Text title={role.name} />
            </InfoRow>
            <InfoRow>
              <Text title={"Section:"} size="smallBold" />
              <Text title={role.section.name} />
            </InfoRow>
          </>
        )}
        {!loading &&
          !!hoursInfo &&
          hoursInfo.map(({ availableHours, client }) => (
            <div
              style={{
                border: "1px solid black",
                padding: "8px",
                borderRadius: "10px",
                marginBottom: "8px",
              }}
            >
              <InfoRow className="title">
                <Text title={"Name:"} size="mediumBold" />
                <Text title={client.fullName as string} size="mediumBold" />
              </InfoRow>
              {availableHours.map((availableHour) => (
                <div
                  style={{
                    padding: "8px",
                    borderRadius: "10px",
                    marginBottom: "16px",
                  }}
                >
                  <InfoRow>
                    <Text title={"Session type:"} size="smallBold" />
                    <Text title={availableHour.sessionType.name} />
                  </InfoRow>
                  <InfoRow>
                    <Text title={"Frequency:"} size="smallBold" />
                    <Text title={availableHour.frequency.name} />
                  </InfoRow>
                  <InfoRow>
                    <Text title={"Start date:"} size="smallBold" />
                    <Text
                      title={dayjs(availableHour.startDate).format(
                        "MM-DD-YYYY"
                      )}
                    />
                  </InfoRow>
                  <InfoRow>
                    <Text title={"End date:"} size="smallBold" />
                    <Text
                      title={dayjs(availableHour.endDate).format("MM-DD-YYYY")}
                    />
                  </InfoRow>
                  {availableHour.frequency.frequency === 1 && (
                    <>
                      <InfoRow>
                        <Text title={"Remaining:"} size="smallBold" />
                        <Text
                          title={`${availableHour.remainingHoursPerWeek}/${availableHour.hoursPerWeek} h/week`}
                        />
                      </InfoRow>
                      <InfoRow>
                        <Text title={"Total:"} size="smallBold" />
                        <Text
                          title={`${availableHour.remainingTotalHours}/${availableHour.totalHours} h`}
                        />
                      </InfoRow>
                    </>
                  )}
                  {availableHour.frequency.frequency === 2 && (
                    <>
                      <InfoRow>
                        <Text title={"Remaining:"} size="smallBold" />
                        <Text
                          title={`${availableHour.remainingTotalHours} h`}
                        />
                      </InfoRow>
                      <InfoRow>
                        <Text title={"Total:"} size="smallBold" />
                        <Text title={`${availableHour.totalHours} h`} />
                      </InfoRow>
                    </>
                  )}
                </div>
              ))}
            </div>
          ))}
        {(loading || loadingAccount) && <Loader />}
      </Container>
    </>
  );
};

export default Dashboard;
