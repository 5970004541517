import { FC, useState } from "react";
import {
  RemoveRedEyeOutlined as View,
  InfoOutlined as MoreInfo,
} from "@mui/icons-material";
import { TableBody as Body, TableCell, TableRow, Tooltip } from "@mui/material";
import dayjs from "dayjs";

import { HeaderProperties } from "../../../shared/uiComponents/Table/tableProperties";
import { CustomAlert, Text } from "../../../shared/uiComponents";
import { InfoRow } from "../../AdminPanel/StyledComponents";

import { SoapNotesFullProperties } from "../../../redux/API/ClientAPIHelpers/soapNoteProperties";
import { useDispatch } from "react-redux";
import { DispatchProperties } from "../../../redux/store";
import { getSoapNotePDF } from "../../../redux/State/exportSlice";
import { unwrapResult } from "@reduxjs/toolkit";
import {
  dateLocalizer,
  errorNormalizer,
} from "../../../shared/Helpers/functions";
import { SoapNotesStatuses } from "../../../redux/API/ClientAPIHelpers/dataCollectionProperties";

export const Info = ({ note }: { note: SoapNotesFullProperties }) => {
  const {
    client,
    user,
    status,
    createdAt,
    createdBy,
    sessionType,
    submittedAt,
    submittedBy,
    startTime,
    endTime,
  } = note;

  return (
    <>
      <Text title={"Client Info:"} size="mediumBold" className="title" />
      <InfoRow className={"paddingTop8 paddingBottom8"}>
        <Text title={"Client name:"} size={"smallBold"} />
        <Text title={client.fullName} />
      </InfoRow>
      {!!client.insurance && !!client.insurance.id && (
        <InfoRow className={"paddingTop8 paddingBottom8"}>
          <Text title={"Insurance:"} size={"smallBold"} />
          <Text title={client.insurance.name} />
        </InfoRow>
      )}
      {!!client.insuranceNumber && (
        <InfoRow className={"paddingTop8 paddingBottom8"}>
          <Text title={"Insurance number:"} size={"smallBold"} />
          <Text title={client.insuranceNumber} />
        </InfoRow>
      )}
      {!!client.defaultBcba && !!client.defaultBcba.fullName && (
        <InfoRow className={"paddingTop8 paddingBottom8"}>
          <Text title={"Supervisor BCBA:"} size={"smallBold"} />
          <Text title={client.defaultBcba.fullName} />
        </InfoRow>
      )}
      <Text title={"SOAP Note Info:"} size="mediumBold" className="title" />
      <InfoRow className={"paddingTop8 paddingBottom8"}>
        <Text title={"Type:"} size={"smallBold"} />
        <Text title={sessionType.name} />
      </InfoRow>
      <InfoRow className={"paddingTop8 paddingBottom8"}>
        <Text title={"Created by:"} size={"smallBold"} />
        <Text title={createdBy.fullName} />
      </InfoRow>
      <InfoRow className={"paddingTop8 paddingBottom8"}>
        <Text title={"Created:"} size={"smallBold"} />
        <Text title={dateLocalizer(createdAt).format("MM/DD/YYYY hh:mm A")} />
      </InfoRow>
      <InfoRow className={"paddingTop8 paddingBottom8"}>
        <Text title={"Status:"} size={"smallBold"} />
        <Text title={status.name} />
      </InfoRow>

      {!!submittedBy && !!submittedAt && (
        <>
          <InfoRow className={"paddingTop8 paddingBottom8"}>
            <Text title={"Submited by:"} size={"smallBold"} />
            <Text title={submittedBy.fullName} />
          </InfoRow>
          <InfoRow className={"paddingTop8 paddingBottom8"}>
            <Text title={"Submited:"} size={"smallBold"} />
            <Text
              title={dateLocalizer(submittedAt).format("MM/DD/YYYY hh:mm A")}
            />
          </InfoRow>
        </>
      )}
      <Text title={"Session Info:"} size="mediumBold" className="title" />
      <InfoRow className={"paddingTop8 paddingBottom8"}>
        <Text title={"Provider name:"} size={"smallBold"} />
        <Text title={user.fullName} />
      </InfoRow>
      <InfoRow className={"paddingTop8 paddingBottom8"}>
        <Text title={"Provider role:"} size={"smallBold"} />
        <Text title={user.role.name} />
      </InfoRow>

      <InfoRow className={"paddingTop8 paddingBottom8"}>
        <Text title={"Started:"} size={"smallBold"} />
        <Text title={dayjs(startTime).format("MM/DD/YYYY hh:mm A")} />
      </InfoRow>
      <InfoRow className={"paddingTop8 paddingBottom8"}>
        <Text title={"Ended:"} size={"smallBold"} />
        <Text title={dayjs(endTime).format("MM/DD/YYYY hh:mm A")} />
      </InfoRow>
    </>
  );
};

export interface RowRendererProperties {
  data: Array<SoapNotesFullProperties>;
}

export const Headers: Array<HeaderProperties> = [
  { id: "1", name: "Client Name", orderBy: "client.fullName" },
  { id: "2", name: "Type", orderBy: "sessionType.name" },
  { id: "3", name: "Session Date", orderBy: "date" },
  { id: "4", name: "SOAP Note Created", orderBy: "createdAt" },
  { id: "5", name: "Created by", orderBy: "createdBy.fullName" },
  {
    id: "6",
    name: "Status",
    titles: [
      "Draft",
      "Submitted",
      "Expired RBT",
      "Expired BCBA",
      "Unlocked",
      "Pending BT",
      "Time/Location Conflict",
      "All",
    ], //All keyword is for reseting column
    width: "200px",
    titlesHeader: "Select Status:",
  },
  { id: "7", name: "Actions", width: "120px" },
];

export const TableBody: FC<RowRendererProperties> = ({ data }) => {
  return (
    <>
      <Body>
        {data.map((row, index) => (
          <TableRow key={index}>
            <TableCell>{row.client.fullName}</TableCell>
            <TableCell>{row.sessionType.name}</TableCell>
            <TableCell>{dayjs(row.date).format("MM/DD/YYYY")}</TableCell>
            <TableCell>{dayjs(row.createdAt).format("MM/DD/YYYY")}</TableCell>
            <TableCell>{row.createdBy.fullName}</TableCell>
            <TableCell>{row.status.name}</TableCell>
            <TableCell>
              <Actions data={row} />
            </TableCell>
          </TableRow>
        ))}
      </Body>
    </>
  );
};

const Actions = ({ data }: { data: SoapNotesFullProperties }) => {
  const dispatch = useDispatch<DispatchProperties>();

  const [openInfo, setOpenInfo] = useState<boolean>(false);

  const { id, status } = data;

  const viewSoapNoteHandler = () => {
    dispatch(
      getSoapNotePDF({
        reportId: id,
        includeTrials: true,
      })
    )
      .then(unwrapResult)
      .then((response) => {
        const objectURL = URL.createObjectURL(response);
        window.open(objectURL);
      })
      .catch(errorNormalizer);
  };

  return (
    <div style={{ display: "flex", gap: "8px" }}>
      <Tooltip title="More Information">
        <MoreInfo fontSize="small" onClick={() => setOpenInfo(true)} />
      </Tooltip>
      {status.status === SoapNotesStatuses.submitted && (
        <Tooltip title="View SOAP Note">
          <View onClick={viewSoapNoteHandler} style={{ cursor: "pointer" }} />
        </Tooltip>
      )}
      <CustomAlert
        open={openInfo}
        onClose={() => setOpenInfo(false)}
        title={"More Information"}
        Content={() => <Info note={data} />}
      />
    </div>
  );
};
