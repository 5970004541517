import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import API from "../../API/Main/API";
import {
  AnnotationBehaviorDate,
  BehaviorAnnotationProperties,
  DataAnnotationProperties,
  TargetAnnotationProperties,
} from "../../API/ClientAPIHelpers/graphAnnotationProperties";
import { ErrorProperties } from "../../API/identityAPIProperties";
import { AnnotationDateProperties } from "../../API/ClientAPIHelpers/graphProperties";

interface GraphAnnotationStateProperties {
  loading: boolean;

  behaviorAnnotations: Array<AnnotationBehaviorDate>;
  behaviorAnnotation: Array<DataAnnotationProperties>;
  targetAnnotation: Array<DataAnnotationProperties>;
  targetAnnotations: Array<AnnotationDateProperties>;
  error: ErrorProperties;
}

const intialGraphAnnotationState: GraphAnnotationStateProperties = {
  loading: false,
  targetAnnotations: [],
  behaviorAnnotations: [],
  behaviorAnnotation: [],
  targetAnnotation: [],
  error: {
    status: 0,
    title: '',
  }
}

export const addBehaviorAnnotation = createAsyncThunk(
  "/behaviors/annotation/add",
  async (data: BehaviorAnnotationProperties, thunkAPI) => {
    const response = await API.ClientAPI.GraphAnnotation.behaviors.addBehaviorAnnotation(data);
    if (!!response.error) {
      return thunkAPI.rejectWithValue(response.error);
    }
    return response;
  }
);
export const getBehaviorReductionAllAnnotations = createAsyncThunk(
  "/behaviors/annotations",
  async (id: string, thunkAPI) => {
    const response = await API.ClientAPI.GraphAnnotation.behaviors.getAnnotations(id);
    if (!!response.error) {
      return thunkAPI.rejectWithValue(response.error);
    }
    return response as Array<AnnotationBehaviorDate>;
  }
);

export const deleteBehaviorAnnotation = createAsyncThunk(
  "/behaviors/delete/annotation/id",
  async (id: string, thunkAPI) => {
    const response = await API.ClientAPI.GraphAnnotation.behaviors.deleteBehaviorAnnotation(id);
    if (!!response.error) {
      return thunkAPI.rejectWithValue(response.error);
    }
    return response as { isSuccessful: boolean };
  }
);

export const deleteAnnotation = createAsyncThunk(
  "/target/delete/annotation/id",
  async (annotationId: string, thunkAPI) => {
    const response = await API.ClientAPI.GraphAnnotation.targets.deleteAnnotation(
      annotationId
    );
    if (!!response.error) {
      return thunkAPI.rejectWithValue(response.error);
    }
    return response as { isSuccessful: boolean };
  }
);

export const getTargetAllAnnotations = createAsyncThunk(
  "/target/annotations/all",
  async (targetId: string, thunkAPI) => {
    const response = await API.ClientAPI.GraphAnnotation.targets.getAnnotations(
      targetId
    );
    if (!!response.error) {
      return thunkAPI.rejectWithValue(response.error);
    }
    return response as Array<AnnotationDateProperties>;
  }
);

export const addAnnotation = createAsyncThunk(
  "/targets/annotation/add",
  async (data: TargetAnnotationProperties, thunkAPI) => {
    const response = await API.ClientAPI.GraphAnnotation.targets.addAnnotation(data);
    if (!!response.error) {
      return thunkAPI.rejectWithValue(response.error);
    }
    return response;
  }
);

const graphAnnotationSlice = createSlice({
  name: "graphAnnotation",
  initialState: intialGraphAnnotationState,
  reducers: {},
  extraReducers: (builder) => {
    // addBehaviorAnnotation;
    builder.addCase(addBehaviorAnnotation.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(addBehaviorAnnotation.fulfilled, (state, action) => {
      state.loading = false;
      state.behaviorAnnotation = action.payload;
    });
    builder.addCase(addBehaviorAnnotation.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error as ErrorProperties;
    });
    //getAllAnnotations
    builder.addCase(getBehaviorReductionAllAnnotations.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      getBehaviorReductionAllAnnotations.fulfilled,
      (state, action) => {
        state.loading = false;
        state.behaviorAnnotations = action.payload;
      }
    );
    builder.addCase(
      getBehaviorReductionAllAnnotations.rejected,
      (state, action) => {
        state.loading = false;
        state.error = action.error as ErrorProperties;
      }
    );
    //deleteBehaviorAnnotation
    builder.addCase(deleteBehaviorAnnotation.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(deleteBehaviorAnnotation.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(deleteBehaviorAnnotation.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error as ErrorProperties;
    });
    //addAnnotation
    builder.addCase(addAnnotation.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(addAnnotation.fulfilled, (state, action) => {
      state.loading = false;
      state.targetAnnotation = action.payload;
    });
    builder.addCase(addAnnotation.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error as ErrorProperties;
    });
    //getAllAnnotations
    builder.addCase(getTargetAllAnnotations.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getTargetAllAnnotations.fulfilled, (state, action) => {
      state.loading = false;
      state.targetAnnotations = action.payload;
    });
    builder.addCase(getTargetAllAnnotations.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error as ErrorProperties;
    });
    //deleteAnnotation
    builder.addCase(deleteAnnotation.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(deleteAnnotation.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(deleteAnnotation.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error as ErrorProperties;
    });
  }
})

export default graphAnnotationSlice.reducer;