import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { unwrapResult } from "@reduxjs/toolkit";
import dayjs, { Dayjs } from "dayjs";

import {
  dateNormalizer,
  errorNormalizer,
} from "../../../shared/Helpers/functions";
import SearchSelect, {
  SelectDataProperties,
} from "../../../shared/uiComponents/Dropdown/searchSelect";
import { Picker, Text } from "../../../shared/uiComponents";

import { DispatchProperties } from "../../../redux/store";
import { getUsers } from "../../../redux/State/userSlice";
import { getPatients } from "../../../redux/State/clientSlice/clientSlice";
import {
  getTherapists,
  getUserPatients,
} from "../../../redux/State/clientSlice/userClientSlice";
import { red300 } from "../../../shared/Helpers/colors";
import { useCheckPermission } from "../Permissions/helpers";
import { PERMISSIONS } from "../../../App/constants";

const Filters = () => {
  const [params, setParams] = useSearchParams();
  const dispatch = useDispatch<DispatchProperties>();
  const USER = useCheckPermission(PERMISSIONS.USER.READ);
  const CLIENT = useCheckPermission(PERMISSIONS.CLIENT.READ);

  const todayDayjs = dayjs();
  const pastDateMonth = dayjs().startOf("month");

  const clientId = params.get("clientId");
  const userId = params.get("userId");

  const [startDateValue, setStartDateValue] = useState<Dayjs>(pastDateMonth);
  const [endDateValue, setEndDateValue] = useState<Dayjs>(todayDayjs);

  const [users, setUsers] = useState<Array<SelectDataProperties>>([]);
  const [clients, setClients] = useState<Array<SelectDataProperties>>([]);
  const [searchStringUsers, setSearchStringUsers] = useState<string>("");
  const [searchStringClients, setSearchStringClients] = useState<string>("");
  const [pageSizeUsers, setPageSizeUsers] = useState<number>(8);
  const [pageSizeClients, setPageSizeClients] = useState<number>(8);

  const choosenProvider = users.find((x) => x.id === userId);
  const choosenClient = clients.find((x) => x.id === clientId);

  const onTherapistChoose = (value: string) => {
    params.set("userId", value);
    params.delete("clientId");
    setParams(params);
  };

  const onPatientChoose = (value: string) => {
    params.set("clientId", value);
    setParams(params);
  };

  const onChangeStartDate = (value: Dayjs | null) => {
    if (!value) return;
    setStartDateValue(value);
    const start = dateNormalizer(value);
    params.set("startDate", start);
    setParams(params);
  };

  const onChangeEndDate = (value: Dayjs | null) => {
    if (!value) return;
    setEndDateValue(value);
    const end = dateNormalizer(value);
    params.set("endDate", end);
    setParams(params);
  };

  useEffect(() => {
    if (!!clientId) return;
    setSearchStringClients("");
  }, [clientId]);

  useEffect(() => {
    const startDate = params.get("startDate");
    const endDate = params.get("endDate");
    if (!!startDate || !!endDate) return;
    const start = dateNormalizer(startDateValue);
    const end = dateNormalizer(endDateValue);

    params.set("startDate", start);
    params.set("endDate", end);
    setParams(params);
  }, [params, setParams, startDateValue, endDateValue]);

  useEffect(() => {
    if (!USER.permissionGranted) return;
    const timeout = setTimeout(() => {
      if (!clientId) {
        dispatch(
          getUsers({
            page: "1",
            pageSize: `${pageSizeUsers}`,
            searchString: searchStringUsers,
          })
        )
          .then(unwrapResult)
          .then((response) => {
            if (!response.query) return;
            const list = response.query.map((x) => ({
              id: x.id,
              label: x.fullName,
            }));
            setUsers(list);
          })
          .catch(errorNormalizer);
        return;
      }
      dispatch(getTherapists(clientId))
        .then(unwrapResult)
        .then((response) => {
          if (!response) return;
          const list = response.map((x) => ({
            id: x.id,
            label: x.fullName,
          }));
          setUsers(list);
        })
        .catch(errorNormalizer);
    }, 1000);
    return () => clearTimeout(timeout);
  }, [
    clientId,
    dispatch,
    pageSizeUsers,
    searchStringUsers,
    USER.permissionGranted,
  ]);

  useEffect(() => {
    if (!CLIENT.permissionGranted) return;
    const timeout = setTimeout(() => {
      if (!userId) {
        dispatch(
          getPatients({
            page: "1",
            pageSize: `${pageSizeClients}`,
            searchString: searchStringClients,
          })
        )
          .then(unwrapResult)
          .then((response) => {
            if (!response.query) return;
            const data = response.query.map((patient) => ({
              id: patient.id,
              label: patient.fullName,
            }));
            setClients(data);
          })
          .catch(errorNormalizer);
        return;
      }
      dispatch(
        getUserPatients({
          userId,
          page: "1",
          pageSize: `${pageSizeClients}`,
          searchString: searchStringClients,
        })
      )
        .then(unwrapResult)
        .then((response) => {
          if (!response.query) return;
          const data = response.query.map((patient) => ({
            id: patient.id,
            label: patient.fullName,
          }));
          setClients(data);
        })
        .catch(errorNormalizer);
    }, 1000);
    return () => clearTimeout(timeout);
  }, [
    userId,
    dispatch,
    pageSizeClients,
    searchStringClients,
    CLIENT.permissionGranted,
  ]);

  return (
    <>
      <div style={{ display: "flex", gap: "16px", flexWrap: "wrap" }}>
        {USER.permissionGranted && (
          <div>
            <SearchSelect
              data={users}
              className={"marginRight16"}
              label={`Find ${
                !!choosenClient ? `${choosenClient.label}'s` : ""
              } Provider:`}
              setSearchString={setSearchStringUsers}
              searchString={searchStringUsers}
              loading={false}
              setValue={onTherapistChoose}
              loadMore={{
                activate: true,
                setSize: setPageSizeUsers,
              }}
            />
          </div>
        )}
        {CLIENT.permissionGranted && (
          <div>
            <SearchSelect
              label={`Find ${
                !!choosenProvider ? `${choosenProvider.label}'s` : ""
              } Client:`}
              data={clients}
              className={"marginRight16"}
              setSearchString={setSearchStringClients}
              searchString={searchStringClients}
              setValue={onPatientChoose}
              loading={false}
              loadMore={{
                activate: true,
                setSize: setPageSizeClients,
              }}
            />
          </div>
        )}
        <Picker.CustomDate
          label="Start Date:"
          value={startDateValue}
          onChange={onChangeStartDate}
        />
        <Picker.CustomDate
          label="End Date:"
          value={endDateValue}
          onChange={onChangeEndDate}
        />
      </div>
      <Text
        textColor={red300}
        size="smallBold"
        title={`Displaying ${
          !!clientId
            ? `${choosenClient?.label}'s`
            : !!userId
            ? `${choosenProvider?.label}'s`
            : "all"
        } notes`}
      />
    </>
  );
};
export default Filters;
