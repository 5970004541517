import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { v4 as randomUUID } from "uuid";

import { DayProperties } from "../../pages/BCBAPanel/Calendar/Helpers/interfaces";

import { DispatchProperties, StateProperties } from "../../redux/store";
import { setDays } from "../../redux/State/clientSlice/calendarSlice";
import { refresh } from "../../redux/State/identitySlice/accountSlice";
import dayjs, { Dayjs } from "dayjs";
import { getRefreshToken, getToken } from "../../redux/API/Main/helpers";

export const useRefreshToken = () => {
    const dispatch = useDispatch<DispatchProperties>();

    useEffect(() => {
        const storedToken = getToken();
        if (!storedToken) return;

        const parsedtoken = JSON.parse(storedToken) as { expires_in: number };
        const time = (parsedtoken.expires_in - 10) * 1000;

        const interval = setInterval(() => {
            const token = getRefreshToken();
            if (!!token) {
                dispatch(refresh(token));
            }
        }, time);

        return () => clearInterval(interval);
    }, [dispatch]);
}

export const useMultipleTabs = () => {
    useEffect(() => {
        const ls = window.localStorage;
        const ss = window.sessionStorage;
        const remember = JSON.parse(ls.getItem("remember") as string);

        if (!remember) {
            setTimeout(() => {
                const tabsArray: string[] = JSON.parse(ls.getItem("tabs") as string);
                const tab = ss.getItem("id");

                if (!tabsArray && !!tab) {
                    const tabs = [tab];
                    ls.setItem("tabs", JSON.stringify(tabs));
                }

                if (!!tabsArray && !!tab && !tabsArray.some((item) => item === tab)) {
                    tabsArray.push(tab);
                    ls.setItem("tabs", JSON.stringify(tabsArray));
                }
            }, 1000);
        }
    }, []);

    window.onbeforeunload = () => {
        const ls = window.localStorage;
        const ss = window.sessionStorage;

        const remember = JSON.parse(ls.getItem("remember") as string);

        if (!remember) {
            const tabsArray = JSON.parse(ls.getItem("tabs") as string) as string[];

            if (!!tabsArray && tabsArray.length > 1) {
                const tab = ss.getItem("id");
                const tabs = tabsArray.filter((item) => item !== tab);
                ls.setItem("tabs", JSON.stringify(tabs));
            }

            if (!!tabsArray && tabsArray.length === 1) {
                ls.clear();
            }
        }
    };
}

export const useScreenWidth = () => {

    const [screenWidth, setScreenWidth] = useState<number>(window.innerWidth);

    useEffect(() => {
        window.addEventListener("resize", () => setScreenWidth(window.innerWidth));
        return () =>
            window.removeEventListener("resize", () =>
                setScreenWidth(window.innerWidth)
            );
    }, []);


    return screenWidth;

}

export const useInitializeDays = () => {

    const dispatch = useDispatch<DispatchProperties>();

    const weekDay = useSelector((state: StateProperties) => state.calendar.weekDay);
    const month = useSelector((state: StateProperties) => state.calendar.month);
    const year = useSelector((state: StateProperties) => state.calendar.year);
    const lastDayOfPreviousMonth = useSelector((state: StateProperties) => state.calendar.lastDayOfPreviousMonth);
    const lastDayOfMonth = useSelector((state: StateProperties) => state.calendar.lastDayOfMonth);

    useEffect(() => {
        const previousMonthDays: Array<DayProperties> = [];
        for (let i = weekDay; i > 0; i--) {
            previousMonthDays.push({
                day: lastDayOfPreviousMonth - i + 1,
                status: 0,
                month: month - 1 < 0 ? 11 : month - 1,
                year: month - 1 < 0 ? year - 1 : year,
            });
        }

        const currentMonthDays: Array<DayProperties> = [];
        for (let i = 1; i <= lastDayOfMonth; i++) {
            currentMonthDays.push({
                day: i,
                status: 1,
                month,
                year
            });
        }

        const nextMonthDays: Array<DayProperties> = [];
        const lastWeekDay = new Date(year, month + 1, 0).getDay();
        for (let i = 1; i < 7 - lastWeekDay; i++) {
            nextMonthDays.push({
                day: i,
                status: 2,
                month: month + 1 < 12 ? month + 1 : 0,
                year: month + 1 < 12 ? year : year + 1,
            });
        }
        dispatch(setDays([...previousMonthDays, ...currentMonthDays, ...nextMonthDays]));
    }, [weekDay, lastDayOfMonth, year, month, lastDayOfPreviousMonth, dispatch]);

}

export const useInitializeURLParams = () => {
    const [params, setParams] = useSearchParams();
    useEffect(() => {
        if (!params.get("pageSize") || !params.get("page")) {
            setParams({ pageSize: "8", page: "1" });
        }
    }, [params, setParams]);
}

export const useInitializeTabId = () => {
    useEffect(() => {
        const remember = JSON.parse(
            window.localStorage.getItem("remember") as string
        );
        const tab = window.sessionStorage.getItem("id");

        if (!tab && !remember) {
            window.sessionStorage.setItem("id", randomUUID());
        }
    }, []);
}

export const useCompareDates = (startDate: Dayjs | null, endDate: Dayjs | null) => {
    const [errorMessage, setErrorMessage] = useState<string>("")

    useEffect(() => {
        if (!startDate || !endDate) return;
        if (dayjs(startDate).isBefore(endDate) || dayjs(startDate).isSame(endDate)) {
            setErrorMessage("");
            return;
        }
        setErrorMessage("End date should be greater than start date")
    }, [startDate, endDate])

    if (!startDate || !endDate) return {
        message: "",
        hasError: false,
    }

    return {
        message: errorMessage,
        hasError: !!errorMessage,
    }
}
