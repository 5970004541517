import { FC } from "react";
import Text from "../Text";
import { styled } from "@mui/material";
import CustomButton from "../Button";
import {
  TableRow,
  TableCell,
  TableBody,
  CircularProgress,
} from "@mui/material";
import { useSearchParams } from "react-router-dom";
import { TableDataProperties } from "./tableProperties";

const PaginationWrapper = styled("div")(() => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
}));

export const TableLoader: FC<{ spanScope: number }> = ({ spanScope }) => {
  return (
    <TableBody>
      <TableRow>
        <TableCell colSpan={spanScope}>
          <div style={{ width: "max-content", margin: "auto" }}>
            <CircularProgress color="inherit" />
          </div>
        </TableCell>
      </TableRow>
    </TableBody>
  );
};

export const TableNoData: FC<{ spanScope: number }> = ({ spanScope }) => {
  return (
    <TableBody>
      <TableRow>
        <TableCell colSpan={spanScope}>
          <Text
            title={"No results"}
            size={"smallBold"}
            className={"marginAuto"}
          />
        </TableCell>
      </TableRow>
    </TableBody>
  );
};

export const MobilePagination: FC<{ data: TableDataProperties }> = ({
  data,
}) => {
  const [params, setParams] = useSearchParams();
  const { hasNextPage, hasPreviousPage, page, totalPages } = data;

  const onNextClick = () => {
    if (!hasNextPage || !page) return;
    params.set("page", (page + 1).toString());
    params.set("pageSize", "8");
    setParams(params);
  };

  const onPreviousClick = () => {
    if (!hasPreviousPage || !page) return;
    params.set("page", (page - 1).toString());
    params.set("pageSize", "8");
    setParams(params);
  };
  
  return (
    <PaginationWrapper>
      <CustomButton
        disabled={!hasPreviousPage}
        onClick={onPreviousClick}
        title="Previous"
      />
      <Text title={`${page} OF ${totalPages}`} size={"smallBold"} />
      <CustomButton
        disabled={!hasNextPage}
        onClick={onNextClick}
        title="Next"
      />
    </PaginationWrapper>
  );
};
