import { CreateProgramTargetProperties, TargetTypes } from "../../../../../redux/API/ClientAPIHelpers/programTargetsProperties";

export const initialState: CreateProgramTargetProperties = {
    goalName: "",
    name: "",
    sd: "",
    targetInstructions: "",
    typeId: TargetTypes.DTT,
    errorCorrection: "",
    reinforcement: "",
};
