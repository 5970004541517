import { ChangeEvent, FocusEventHandler, FC } from "react";
import { Checkbox, FormControlLabel } from "@mui/material";
import Icons from "../../Assets/svgIcons";

export interface CheckboxDataProperties {
  id: string;
  label?: string;
  disabled?: boolean;
  checked?: boolean;
  indeterminate?: boolean;
}

interface CheckBoxProperties {
  item: CheckboxDataProperties;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  onBlur?: FocusEventHandler<HTMLButtonElement>;
  style?: object;
  labelPlacement?: "end" | "start" | "top" | "bottom";
}

const CustomCheckBox: FC<CheckBoxProperties> = ({
  item,
  onChange,
  onBlur,
  style = {},
  labelPlacement = "end",
}) => {
  return (
    <FormControlLabel
      key={item.id}
      control={
        <Checkbox
          name={item.id}
          checked={item.checked}
          indeterminate={item.indeterminate}
          onChange={onChange}
          onBlur={onBlur}
          disabled={item.disabled}
          icon={<Icons.CheckboxIcon disabled={item.disabled} />}
          checkedIcon={<Icons.CheckboxChecked disabled={item.disabled} />}
          indeterminateIcon={
            <Icons.CheckboxIndeterminate disabled={item.disabled} />
          }
        />
      }
      label={item.label}
      labelPlacement={labelPlacement}
      style={style}
    />
  );
};

export default CustomCheckBox;
