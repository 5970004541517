import { ProgramTargetProperties } from "../../../../redux/API/ClientAPIHelpers/programTargetsProperties";
import TargetHistory from "./targetHistory";
import TargetInstructions from "./targetInstructions";

const TargetInfo = ({
  id,
  programId,
  item,
  open,
}: {
  id: string;
  programId: string;
  item: ProgramTargetProperties;
  open: boolean;
}) => {
  return (
    <div className="targetInfo">
      <TargetInstructions data={item as ProgramTargetProperties} id={id} />
      {open && <TargetHistory id={id} programId={programId} />}
    </div>
  );
};

export default TargetInfo;
