import { ChangeEvent, FC } from "react";
import { FormControlLabel, Radio, RadioGroup } from "@mui/material";
import Icons from "../../Assets/svgIcons";

export interface DataProperties {
  id: string;
  label: string;
}

interface RadioProperties {
  onChange?: (_: ChangeEvent<HTMLInputElement>, value: string) => void;
  id: string;
  value: string | null;
  label?: string;
}

const CustomRadio: FC<RadioProperties> = ({
  onChange,
  id,
  value,
  label = "",
}) => (
  <RadioGroup
    value={value}
    onChange={onChange}
    row
    style={{ justifyContent: "space-between" }}
  >
    <FormControlLabel
      value={id}
      disabled={false}
      control={
        <Radio
          icon={<Icons.RadioIcon disabled={false} />}
          checkedIcon={<Icons.RadioIconChecked disabled={false} />}
        />
      }
      label={label}
    />
  </RadioGroup>
);

export default CustomRadio;
