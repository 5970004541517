import { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { Tooltip } from "@mui/material";
import {
  EditOutlined as Edit,
  InfoOutlined as Info,
  AccountCircleOutlined as Account,
} from "@mui/icons-material";
import { useSelector, useDispatch } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import dayjs from "dayjs";
import { toast } from "react-toastify";

import Delete from "../Delete";
import { CustomAlert, CustomButton, Text } from "../../shared/uiComponents";
import { InfoRow, Loader } from "../../pages/AdminPanel/StyledComponents";
import { errorNormalizer } from "../../shared/Helpers/functions";
import { UserStatusBadge } from "../../shared/uiComponents/StatusBadge";

import { DispatchProperties, StateProperties } from "../../redux/store";
import {
  getUserAccountDetails,
  resendVerificationEmail,
} from "../../redux/State/identitySlice/userSlice";
import { UserInfo } from "../../pages/AdminPanel/Users/tableInfo";
import { UserProperties } from "../../redux/API/userAPIProperties";
import { deleteUser } from "../../redux/State/userSlice";
import { useCheckPermission } from "../../pages/AdminPanel/Permissions/helpers";
import { PERMISSIONS } from "../../App/constants";

export type PanelTypes = "admin" | "bcba" | "rbt";
export enum AdminTypes {
  "administrator",
  "bcba",
  "rbt",
}

const Action = ({
  item,
  fetchUsers,
}: {
  item: UserProperties;
  fetchUsers: () => void;
}) => {
  const dispatch = useDispatch<DispatchProperties>();
  const EDIT = useCheckPermission(PERMISSIONS.USER.EDIT);
  const DELETE = useCheckPermission(PERMISSIONS.USER.DELETE);
  const { pathname } = useLocation();

  const loading = useSelector(
    (state: StateProperties) => state.identityUser.loadingAccountInfo
  );
  const [open, setOpen] = useState<boolean>(false);

  const [infoOpen, setInfoOpen] = useState<boolean>(false);

  const onAccountInfoClick = (userId: string) => {
    if (!userId) return;
    dispatch(getUserAccountDetails(userId));
    setOpen(true);
  };

  const showUserInfo = () => {
    setInfoOpen(true);
  };

  const onDeleteClick = (id: string) => {
    dispatch(deleteUser(id))
      .then(unwrapResult)
      .then(() => {
        toast(`Deleted`);
        fetchUsers();
      })
      .catch(errorNormalizer);
  };

  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      {EDIT.permissionGranted && (
        <>
          <Link to={`${pathname}/${item.id}`} className={"marginRight16"}>
            <Tooltip title="Edit">
              <Edit fontSize="small" />
            </Tooltip>
          </Link>
        </>
      )}
      <div
        onClick={showUserInfo}
        className={"marginRight16"}
        style={{ cursor: "pointer" }}
      >
        <Tooltip title="More Information">
          <Info />
        </Tooltip>
      </div>
      <div
        onClick={() => onAccountInfoClick(item.id)}
        className={"marginRight16"}
        style={{ cursor: "pointer" }}
      >
        <Tooltip title="Account Information">
          <Account />
        </Tooltip>
      </div>
      {DELETE.permissionGranted && (
        <Delete deleteHandler={() => onDeleteClick(item.id)} />
      )}
      <CustomAlert
        open={open}
        onClose={() => setOpen(false)}
        title="Account Information"
        Content={({ onClose }) =>
          loading ? <Loader /> : <UserAccountInfo onClose={onClose} />
        }
      />
      <CustomAlert
        open={infoOpen}
        onClose={() => setInfoOpen(false)}
        title={"Detailed Information"}
        Content={() => <UserInfo {...item} />}
      />
    </div>
  );
};

const UserAccountInfo = ({ onClose }: { onClose: () => void }) => {
  const dispatch = useDispatch<DispatchProperties>();

  const {
    userId,
    email,
    emailConfirmed,
    lastConfirmationEmailSendDate,
    lastPasswordChangedDate,
  } = useSelector((state: StateProperties) => state.identityUser.accountInfo);

  const resendVerificationHandler = (userId: string) => {
    if (!userId) return;
    dispatch(resendVerificationEmail(userId))
      .then(unwrapResult)
      .then(() => toast("Success"))
      .catch(errorNormalizer)
      .finally(() => onClose());
  };

  return (
    <>
      <InfoRow>
        <Text title={"Email:"} />
        <Text title={email} />
      </InfoRow>
      <InfoRow>
        <Text title={"Email confirmed:"} />
        <div className="paddingLeft16">
          <UserStatusBadge emailConfirmed={emailConfirmed} />
        </div>
      </InfoRow>
      {lastConfirmationEmailSendDate && (
        <InfoRow>
          <Text title={"Recent Resend Date:"} />
          <Text
            title={dayjs(lastConfirmationEmailSendDate).format(
              "MM/DD/YYYY hh:mm A"
            )}
          />
        </InfoRow>
      )}
      {lastPasswordChangedDate && (
        <InfoRow>
          <Text title={"Last Password Change Date:"} />
          <Text title={dayjs(lastPasswordChangedDate).format("MM/DD/YYYY")} />
        </InfoRow>
      )}
      {!emailConfirmed ? (
        <CustomButton
          title="Resend Verification"
          onClick={() => resendVerificationHandler(userId)}
        />
      ) : null}
    </>
  );
};

export default Action;
