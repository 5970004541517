import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import { useParams } from "react-router-dom";

import { white100 } from "../../../shared/Helpers/colors";
import FrequencyTypeBehavior from "./frequencyType";
import DurationTypeBehavior from "./durationType";
import { errorNormalizer } from "../../../shared/Helpers/functions";

import { DispatchProperties, StateProperties } from "../../../redux/store";
import { getBehaviors } from "../../../redux/State/clientSlice/behaviorReductionSlice";
import { recordingType } from "../../../redux/API/ClientAPIHelpers/behaviorReductionProperties";

const Behaviours = () => {
  const dispatch = useDispatch<DispatchProperties>();
  const { clientId } = useParams();

  const clientBehaviors = useSelector(
    (state: StateProperties) => state.behaviorReduction.behaviors
  );

  useEffect(() => {
    if (!clientId) return;
    dispatch(getBehaviors(clientId))
      .then(unwrapResult)
      .catch(errorNormalizer);
  }, [clientId, dispatch]);

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        maxWidth: "760px",
        flexWrap: "wrap",
        columnGap: "16px",
        rowGap: "16px",
      }}
      className="marginTop32 marginBottom32"
    >
      {clientBehaviors.map((x) => (
        <div
          key={x.id}
          className="padding8 marginRight8"
          style={{
            backgroundColor: white100,
            display: "flex",
            borderRadius: "10px",
          }}
        >
          {recordingType.frequency === x.recordingType.id && (
            <FrequencyTypeBehavior behavior={x} />
          )}
          {recordingType.duration === x.recordingType.id && (
            <DurationTypeBehavior behavior={x} />
          )}
        </div>
      ))}
    </div>
  );
};

export default Behaviours;
