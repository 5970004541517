import { FC } from "react";
import { useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";
import {
  PersonOutlined as Person,
  EmailOutlined as Email,
  PhoneIphoneOutlined as Phone,
} from "@mui/icons-material";

import { getRBTs } from "../../../redux/State/userSlice";
import { DispatchProperties, useSelector } from "../../../redux/store";

import { AdminDataProperties } from "./tableInfo";
import { Text, MobilePagination } from "../../../shared/uiComponents";
import { Loader, Row, Wrapper } from "../StyledComponents";
import Action from "../../../components/Action";
import MobileSort from "../../../components/MobileSort";
import { sortData } from "../Users/mobileView";
import { UserStatusBadge } from "../../../shared/uiComponents/StatusBadge";

const MobileView: FC<AdminDataProperties> = (data) => {
  const { query, totalNumberOfItems } = data;
  const [params] = useSearchParams();

  const dispatch = useDispatch<DispatchProperties>();
  const loading = useSelector((state) => state.user.loading);

  const fetchUsers = () => {
    const page = params.get("page") || "1";
    const pageSize = params.get("pageSize") || "8";
    const orderBy = params.get("orderBy") || "";
    const direction = params.get("direction") || "";
    const searchString = params.get("search") || "";

    dispatch(getRBTs({ page, pageSize, orderBy, direction, searchString }));
  };

  return (
    <div className={"mobileView"}>
      <MobileSort data={sortData} />
      {loading && <Loader />}
      {!loading && !totalNumberOfItems && (
        <div className={"mobileView"}>
          <Text
            title={"No results"}
            size={"smallBold"}
            className={"marginAuto"}
          />
        </div>
      )}
      {!loading &&
        !!query &&
        query.map((item, index) => (
          <Wrapper key={index}>
            <Row style={{ justifyContent: "space-between" }}>
              <div className="paddingLeft16">
                <UserStatusBadge emailConfirmed={item.emailConfirmed} />
              </div>
              <Action item={item} fetchUsers={fetchUsers} />
            </Row>
            <Row>
              <Person />
              <Text title={item.fullName} className={"marginLeft8"} />
            </Row>
            <Row>
              <Email /> <Text title={item.email} className={"marginLeft8"} />
            </Row>
            <Row>
              <Phone />
              <Text
                title={!!item.phoneNumber ? item.phoneNumber : "-"}
                className={"marginLeft8"}
              />
            </Row>
          </Wrapper>
        ))}
      <MobilePagination data={data} />
    </div>
  );
};

export default MobileView;
