import "./annotationsStyle.scss";
import AddAnnotationPopup from "./addAnnotationPopup";
import ViewAnnotationsPopup from "./viewAnnotationsPopup";

const AnnotationsTarget = ({ behaviorReductionId, name }) => {
  return (
    <div className="btns-block">
      <AddAnnotationPopup behaviorReductionId={behaviorReductionId} />
      <ViewAnnotationsPopup
        behaviorReductionId={behaviorReductionId}
        name={name}
      />
    </div>
  );
};

export default AnnotationsTarget;
