import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import API from "../../API/Main/API";
import { resetState } from "../../resetState";
import {
    GetUserSoapNotesProperties,
    InitialSoapNoteStateProperties,
    GetSoapNoteProperties,
    SoapNotesDataProperties,
    GetClientSoapNotesProperties,
    GetSoapNotesProperties,
    UpdateBillingCredentialedProperties,
} from "../../API/ClientAPIHelpers/soapNoteProperties";
import {
    SaveBehaviorTreatmentDataProperties,
    UpdateBehaviorTreatmentDataProperties,
} from "../../../pages/BCBAPanel/SOAPNote/SoapNoteTypesProperties/behaviorTreatmentTypeProperties";
import {
    SaveDirectSupervisionDataProperties,
    UpdateDirectSupervisionDataProperties,
} from "../../../pages/BCBAPanel/SOAPNote/SoapNoteTypesProperties/directSupervisionTypeProperties";
import {
    SaveAssessmentTypeDataProperties,
    UpdateAssessmentTypeDataProperties,
} from "../../../pages/BCBAPanel/SOAPNote/SoapNoteTypesProperties/assessmentTypeProperties";
import {
    SaveBehaviorTreatmentModificationTypeDataProperties,
    UpdateBehaviorTreatmentModificationTypeDataProperties
} from "../../../pages/BCBAPanel/SOAPNote/SoapNoteTypesProperties/behaviorTreatmentModificationTypeProperties";
import {
    SaveParentTrainingTypeDataProperties,
    UpdateParentTrainingTypeDataProperties
} from "../../../pages/BCBAPanel/SOAPNote/SoapNoteTypesProperties/parentTrainingTypeProperties";
import {
    SaveTreatmentPlanningTypeDataProperties,
    UpdateTreatmentPlanningTypeDataProperties
} from "../../../pages/BCBAPanel/SOAPNote/SoapNoteTypesProperties/treatmentPlanningTypeProperties";
import { ErrorProperties } from "../../API/identityAPIProperties";

export const initialSoapNoteState: InitialSoapNoteStateProperties = {
    loading: false,
    behaviorTreatmentOptions: {
        barrierToTreatmentOptions: [],
        behaviorTreatmentTechniqueOptions: [],
        promptLevelOptions: [],
        reinforcementOptions: [],
        behaviorTreatmentFuturePlanOptions: [],
        clientMoodOptions: [],
        clientResponseToTreatmentOptions: [],
        levelOfMaladaptiveBehaviorOptions: [],
        maladaptiveBehaviorTechniqueOptions: [],
        skillDomainAddressedOptions: [],
    },
    directSupervisionOptions: {
        feedbackOptions: [],
    },
    assessmentOptions: {
        assessmentToolOptions: [],
        assessmentParticipantOptions: [],
        treatmentPlanOptions: [],
    },
    behaviorTreatmentModificationOptions: {
        barrierToTreatmentOptions: [],
        behaviorTreatmentModificationInterventionOptions: [],
        reinforcementOptions: [],
        responseToInterventionOptions: [],
    },
    parentTrainingOptions: {
        directParentTrainingOptions: [],
        parentTrainingParticipantOptions: [],
    },
    treatmentPlanningOptions: {
        treatmentPlanningParticipantOptions: [],
        treatmentUpdateOptions: [],
    },
    soapNotes: {
        query: null,
        page: 1,
        totalNumberOfItems: 0,
        numberOfItems: 0,
        totalPages: 1,
        hasNextPage: false,
        hasPreviousPage: false,
    },
    soapNote: {},
    clientSoapNotes: {
        query: null,
        hasNextPage: false,
        hasPreviousPage: false,
        numberOfItems: 0,
        page: 1,
        totalNumberOfItems: 0,
        totalPages: 1,
    },
    userSoapNotes: {
        query: null,
        page: 1,
        totalNumberOfItems: 0,
        numberOfItems: 0,
        totalPages: 1,
        hasNextPage: false,
        hasPreviousPage: false,
    },
    locations: null,
    error: {
        status: 0,
        title: "",
    },
    soapNoteData: {
        supervisor: {
            authoritationType: {
                id: 0,
                name: "",
            },
            fullName: "",
            id: "",
            role: "",
        }
    }
}

export const updateBillingCredentialed = createAsyncThunk(
    'soapNote/update/user/notes',
    async (data: UpdateBillingCredentialedProperties, thunkAPI) => {
        const response = await API.ClientAPI.SoapNote.updateBillingCredentialed(data);
        if (!!response.error) {
            return thunkAPI.rejectWithValue(response.error);
        }
        return response
    }
)

export const getLocations = createAsyncThunk(
    'soapNote/locations',
    async (clientId: string, thunkAPI) => {
        const response = await API.ClientAPI.Location.getLocations(clientId)
        if (!!response.error) {
            return thunkAPI.rejectWithValue(response.error);
        }
        return response
    }
)

export const getSoapNotes = createAsyncThunk(
    'soapNote/get/notes',
    async (data: GetSoapNotesProperties, thunkAPI) => {
        const response = await API.ClientAPI.SoapNote.getSoapNotes(data)
        if (!!response.error) {
            return thunkAPI.rejectWithValue(response.error);
        }
        return response as SoapNotesDataProperties
    }
)

export const getClientSoapNotes = createAsyncThunk(
    'soapNote/get/client/notes',
    async (data: GetClientSoapNotesProperties, thunkAPI) => {
        const response = await API.ClientAPI.SoapNote.getClientSoapNotes(data)
        if (!!response.error) {
            return thunkAPI.rejectWithValue(response.error);
        }
        return response
    }
)

export const getUserSoapNotes = createAsyncThunk(
    'soapNote/get/user/notes',
    async (data: GetUserSoapNotesProperties, thunkAPI) => {
        const response = await API.ClientAPI.SoapNote.getUserSoapNotes(data);
        if (!!response.error) {
            return thunkAPI.rejectWithValue(response.error);
        }
        return response
    }
)

export const getSoapNote = createAsyncThunk(
    'soapNote/get',
    async ({ clientId, reportId }: GetSoapNoteProperties, thunkAPI) => {
        const response = await API.ClientAPI.SoapNote.getSoapNote({ clientId, reportId })
        if (!!response.error) {
            return thunkAPI.rejectWithValue(response.error);
        }
        return response;
    }
)

export const unlockSoapNote = createAsyncThunk(
    'soapNote/unlock',
    async ({ clientId, reportId }: GetSoapNoteProperties, thunkAPI) => {
        const response = await API.ClientAPI.SoapNote.unlockSoapNote({ clientId, reportId })
        if (!!response.error) {
            return thunkAPI.rejectWithValue(response.error);
        }
        return response;
    }
)

export const getSoapNotePDF = createAsyncThunk(
    'soapNote/get/pdf',
    async ({ clientId, reportId }: GetSoapNoteProperties, thunkAPI) => {
        const response = await API.ClientAPI.SoapNote.getSoapNotePDF({ clientId, reportId })
        if (!!response.error) {
            return thunkAPI.rejectWithValue(response.error);
        }
        return response
    }
)

export const getBehaviorTreatmentOptions = createAsyncThunk(
    'soapNote/behaviorTreatment/options',
    async ({ clientId, sessionId }: { clientId: string, sessionId: string }, thunkAPI) => {
        const response = await API.ClientAPI.SoapNote.getBehaviorTreatmentOptions(clientId, sessionId);
        if (!!response.error) {
            return thunkAPI.rejectWithValue(response.error);
        }
        return response
    }
)

export const saveBehaviorTreatment = createAsyncThunk(
    'soapNote/behaviorTreatment/save',
    async ({ clientId, data }: SaveBehaviorTreatmentDataProperties, thunkAPI) => {
        const response = await API.ClientAPI.SoapNote.saveBehaviorTreatment({ clientId, data });
        if (!!response.error) {
            return thunkAPI.rejectWithValue(response.error);
        }
        return response;
    }
)

export const updateBehaviorTreatment = createAsyncThunk(
    'soapNote/behaviorTreatment/update',
    async ({ clientId, reportId, data }: UpdateBehaviorTreatmentDataProperties, thunkAPI) => {
        const response = await API.ClientAPI.SoapNote.updateBehaviorTreatment({ clientId, reportId, data });
        if (!!response.error) {
            return thunkAPI.rejectWithValue(response.error);
        }
        return response;
    }
)

export const getBehaviorTreatmentModificationOptions = createAsyncThunk(
    'soapNote/behaviorTreatmentModification/options',
    async ({ clientId, sessionId }: { clientId: string, sessionId: string }, thunkAPI) => {
        const response = await API.ClientAPI.SoapNote.getBehaviorTreatmentModificationOptions(clientId, sessionId);
        if (!!response.error) {
            return thunkAPI.rejectWithValue(response.error);
        }
        return response
    }
)

export const saveBehaviorTreatmentModification = createAsyncThunk(
    'soapNote/behaviorTreatmentModification/save',
    async ({ clientId, data }: SaveBehaviorTreatmentModificationTypeDataProperties, thunkAPI) => {
        const response = await API.ClientAPI.SoapNote.saveBehaviorTreatmentModification({ clientId, data });
        if (!!response.error) {
            return thunkAPI.rejectWithValue(response.error);
        }
        return response;
    }
)

export const updateBehaviorTreatmentModification = createAsyncThunk(
    'soapNote/behaviorTreatmentModification/update',
    async ({ clientId, reportId, data }: UpdateBehaviorTreatmentModificationTypeDataProperties, thunkAPI) => {
        const response = await API.ClientAPI.SoapNote.updateBehaviorTreatmentModification({ clientId, reportId, data });
        if (!!response.error) {
            return thunkAPI.rejectWithValue(response.error);
        }
        return response;
    }
)

export const getAssessmentOptions = createAsyncThunk(
    'soapNote/assessment/options',
    async ({ clientId, sessionId }: { clientId: string, sessionId: string }, thunkAPI) => {
        const response = await API.ClientAPI.SoapNote.getAssessmentOptions(clientId, sessionId);
        if (!!response.error) {
            return thunkAPI.rejectWithValue(response.error);
        }
        return response
    }
)

export const saveAssessment = createAsyncThunk(
    'soapNote/assessment/save',
    async ({ clientId, data }: SaveAssessmentTypeDataProperties, thunkAPI) => {
        const response = await API.ClientAPI.SoapNote.saveAssessment({ clientId, data });
        if (!!response.error) {
            return thunkAPI.rejectWithValue(response.error);
        }
        return response;
    }
)

export const updateAssessment = createAsyncThunk(
    'soapNote/assessment/update',
    async ({ clientId, reportId, data }: UpdateAssessmentTypeDataProperties, thunkAPI) => {
        const response = await API.ClientAPI.SoapNote.updateAssessment({ clientId, reportId, data });
        if (!!response.error) {
            return thunkAPI.rejectWithValue(response.error);
        }
        return response;
    }
)

export const getDirectSupervisionOptions = createAsyncThunk(
    'soapNote/directSupervision/options',
    async ({ clientId, sessionId }: { clientId: string, sessionId: string }, thunkAPI) => {
        const response = await API.ClientAPI.SoapNote.getDirectSupervisionOptions(clientId, sessionId);
        if (!!response.error) {
            return thunkAPI.rejectWithValue(response.error);
        }
        return response
    }
)

export const saveDirectSupervision = createAsyncThunk(
    'soapNote/directSupervision/save',
    async ({ clientId, data }: SaveDirectSupervisionDataProperties, thunkAPI) => {
        const response = await API.ClientAPI.SoapNote.saveDirectSupervision({ clientId, data });
        if (!!response.error) {
            return thunkAPI.rejectWithValue(response.error);
        }
        return response;
    }
)

export const updateDirectSupervision = createAsyncThunk(
    'soapNote/directSupervision/update',
    async ({ clientId, reportId, data }: UpdateDirectSupervisionDataProperties, thunkAPI) => {
        const response = await API.ClientAPI.SoapNote.updateDirectSupervision({ clientId, reportId, data });
        if (!!response.error) {
            return thunkAPI.rejectWithValue(response.error);
        }
        return response;
    }
)

export const getParentTrainingOptions = createAsyncThunk(
    'soapNote/parentTraining/options',
    async ({ clientId, sessionId }: { clientId: string, sessionId: string }, thunkAPI) => {
        const response = await API.ClientAPI.SoapNote.getParentTrainingOptions(clientId, sessionId);
        if (!!response.error) {
            return thunkAPI.rejectWithValue(response.error);
        }
        return response
    }
)

export const saveParentTraining = createAsyncThunk(
    'soapNote/parentTraining/save',
    async ({ clientId, data }: SaveParentTrainingTypeDataProperties, thunkAPI) => {
        const response = await API.ClientAPI.SoapNote.saveParentTraining({ clientId, data });
        if (!!response.error) {
            return thunkAPI.rejectWithValue(response.error);
        }
        return response;
    }
)

export const updateParentTraining = createAsyncThunk(
    'soapNote/parentTraining/update',
    async ({ clientId, reportId, data }: UpdateParentTrainingTypeDataProperties, thunkAPI) => {
        const response = await API.ClientAPI.SoapNote.updateParentTraining({ clientId, reportId, data });
        if (!!response.error) {
            return thunkAPI.rejectWithValue(response.error);
        }
        return response;
    }
)

export const getTreatmentPlanningOptions = createAsyncThunk(
    'soapNote/TreatmentPlanning/options',
    async ({ clientId, sessionId }: { clientId: string, sessionId: string }, thunkAPI) => {
        const response = await API.ClientAPI.SoapNote.getTreatmentPlanningOptions(clientId, sessionId);
        if (!!response.error) {
            return thunkAPI.rejectWithValue(response.error);
        }
        return response
    }
)

export const saveTreatmentPlanning = createAsyncThunk(
    'soapNote/TreatmentPlanning/save',
    async ({ clientId, data }: SaveTreatmentPlanningTypeDataProperties, thunkAPI) => {
        const response = await API.ClientAPI.SoapNote.saveTreatmentPlanning({ clientId, data });
        if (!!response.error) {
            return thunkAPI.rejectWithValue(response.error);
        }
        return response;
    }
)

export const updateTreatmentPlanning = createAsyncThunk(
    'soapNote/TreatmentPlanning/update',
    async ({ clientId, reportId, data }: UpdateTreatmentPlanningTypeDataProperties, thunkAPI) => {
        const response = await API.ClientAPI.SoapNote.updateTreatmentPlanning({ clientId, reportId, data });
        if (!!response.error) {
            return thunkAPI.rejectWithValue(response.error);
        }
        return response;
    }
)

const soapNoteSlice = createSlice({
    name: "soapNote",
    initialState: initialSoapNoteState,
    reducers: {
        clearErrors(state) {
            state.error = initialSoapNoteState.error;
        }
    },
    extraReducers: (builder) => {
        //getSoapNote
        builder.addCase(getSoapNote.pending, (state) => {
            state.loading = true;
        })
        builder.addCase(getSoapNote.fulfilled, (state, action) => {
            state.loading = false;
            state.soapNote = action.payload;
        })
        builder.addCase(getSoapNote.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload as ErrorProperties;
        })
        //getSoapNotes
        builder.addCase(getSoapNotes.pending, (state) => {
            state.loading = true;
        })
        builder.addCase(getSoapNotes.fulfilled, (state, action) => {
            state.loading = false;
            state.soapNotes = action.payload;
        })
        builder.addCase(getSoapNotes.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload as ErrorProperties;
        })
        //getLocations
        builder.addCase(getLocations.pending, (state) => {
            state.loading = true;
        })
        builder.addCase(getLocations.fulfilled, (state, action) => {
            state.loading = false;
            // state.locations = action.payload;
        })
        builder.addCase(getLocations.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload as ErrorProperties;
        })
        //getClientSoapNotes
        builder.addCase(getClientSoapNotes.pending, (state) => {
            state.loading = true;
        })
        builder.addCase(getClientSoapNotes.fulfilled, (state, action) => {
            state.loading = false;
            state.clientSoapNotes = action.payload;
            state.soapNotes = action.payload;
        })
        builder.addCase(getClientSoapNotes.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload as ErrorProperties;
        })
        //getUserSoapNotes
        builder.addCase(getUserSoapNotes.pending, (state) => {
            state.loading = true;
        })
        builder.addCase(getUserSoapNotes.fulfilled, (state, action) => {
            state.loading = false;
            state.userSoapNotes = action.payload;
            state.soapNotes = action.payload;
        })
        builder.addCase(getUserSoapNotes.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload as ErrorProperties;
        })
        //getBehaviorTreatmentOptions
        builder.addCase(getBehaviorTreatmentOptions.pending, (state) => {
            state.loading = true;
        })
        builder.addCase(getBehaviorTreatmentOptions.fulfilled, (state, action) => {
            state.loading = false;
            state.behaviorTreatmentOptions = action.payload.options;
            state.soapNoteData = action.payload.data;
            state.locations = action.payload.locations
        })
        builder.addCase(getBehaviorTreatmentOptions.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload as ErrorProperties;
        })
        //getBehaviorTreatmentModificationOptions
        builder.addCase(getBehaviorTreatmentModificationOptions.pending, (state) => {
            state.loading = true;
        })
        builder.addCase(getBehaviorTreatmentModificationOptions.fulfilled, (state, action) => {
            state.loading = false;
            state.behaviorTreatmentModificationOptions = action.payload.options;
            state.locations = action.payload.locations
            state.soapNoteData = action.payload.data;
        })
        builder.addCase(getBehaviorTreatmentModificationOptions.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload as ErrorProperties;
        })
        //getDirectSupervisionOptions
        builder.addCase(getDirectSupervisionOptions.pending, (state) => {
            state.loading = true;
        })
        builder.addCase(getDirectSupervisionOptions.fulfilled, (state, action) => {
            state.loading = false;
            state.directSupervisionOptions = action.payload.options;
            state.locations = action.payload.locations
            state.soapNoteData = action.payload.data;
        })
        builder.addCase(getDirectSupervisionOptions.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload as ErrorProperties;
        })
        //getAssessmentOptions
        builder.addCase(getAssessmentOptions.pending, (state) => {
            state.loading = true;
        })
        builder.addCase(getAssessmentOptions.fulfilled, (state, action) => {
            state.loading = false;
            state.assessmentOptions = action.payload.options;
            state.locations = action.payload.locations
            state.soapNoteData = action.payload.data;
        })
        builder.addCase(getAssessmentOptions.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload as ErrorProperties;
        })
        //getParentTrainingOptions
        builder.addCase(getParentTrainingOptions.pending, (state) => {
            state.loading = true;
        })
        builder.addCase(getParentTrainingOptions.fulfilled, (state, action) => {
            state.loading = false;
            state.soapNoteData = action.payload.data;
            state.locations = action.payload.locations
            state.parentTrainingOptions = action.payload.options;
        })
        builder.addCase(getParentTrainingOptions.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload as ErrorProperties;
        })
        //getTreatmentPlanningOptions
        builder.addCase(getTreatmentPlanningOptions.pending, (state) => {
            state.loading = true;
        })
        builder.addCase(getTreatmentPlanningOptions.fulfilled, (state, action) => {
            state.loading = false;
            state.soapNoteData = action.payload.data;
            state.locations = action.payload.locations
            state.treatmentPlanningOptions = action.payload.options;
        })
        builder.addCase(getTreatmentPlanningOptions.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload as ErrorProperties;
        })
        //saveBehaviorTreatment
        builder.addCase(saveBehaviorTreatment.pending, (state) => {
            state.loading = true;
            state.error = initialSoapNoteState.error;
        })
        builder.addCase(saveBehaviorTreatment.fulfilled, (state) => {
            state.loading = false;
        })
        builder.addCase(saveBehaviorTreatment.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload as ErrorProperties;
        })
        //saveBehaviorTreatmentModification
        builder.addCase(saveBehaviorTreatmentModification.pending, (state) => {
            state.loading = true;
            state.error = initialSoapNoteState.error;
        })
        builder.addCase(saveBehaviorTreatmentModification.fulfilled, (state) => {
            state.loading = false;
        })
        builder.addCase(saveBehaviorTreatmentModification.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload as ErrorProperties;
        })
        //saveDirectSupervision
        builder.addCase(saveDirectSupervision.pending, (state) => {
            state.loading = true;
            state.error = initialSoapNoteState.error;
        })
        builder.addCase(saveDirectSupervision.fulfilled, (state) => {
            state.loading = false;
        })
        builder.addCase(saveDirectSupervision.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload as ErrorProperties;
        })
        //saveParentTraining
        builder.addCase(saveParentTraining.pending, (state) => {
            state.loading = true;
            state.error = initialSoapNoteState.error;
        })
        builder.addCase(saveParentTraining.fulfilled, (state) => {
            state.loading = false;
        })
        builder.addCase(saveParentTraining.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload as ErrorProperties;
        })
        //saveAssessment
        builder.addCase(saveAssessment.pending, (state) => {
            state.loading = true;
            state.error = initialSoapNoteState.error;
        })
        builder.addCase(saveAssessment.fulfilled, (state) => {
            state.loading = false;
        })
        builder.addCase(saveAssessment.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload as ErrorProperties;
        })
        //saveTreatmentPlanning
        builder.addCase(saveTreatmentPlanning.pending, (state) => {
            state.loading = true;
            state.error = initialSoapNoteState.error;
        })
        builder.addCase(saveTreatmentPlanning.fulfilled, (state) => {
            state.loading = false;
        })
        builder.addCase(saveTreatmentPlanning.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload as ErrorProperties;
        })
        //updateAssessment
        builder.addCase(updateAssessment.pending, (state) => {
            state.loading = true;
            state.error = initialSoapNoteState.error;
        })
        builder.addCase(updateAssessment.fulfilled, (state) => {
            state.loading = false;
        })
        builder.addCase(updateAssessment.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload as ErrorProperties;
        })
        //updateBehaviorTreatmentModification
        builder.addCase(updateBehaviorTreatmentModification.pending, (state) => {
            state.loading = true;
            state.error = initialSoapNoteState.error;
        })
        builder.addCase(updateBehaviorTreatmentModification.fulfilled, (state) => {
            state.loading = false;
        })
        builder.addCase(updateBehaviorTreatmentModification.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload as ErrorProperties;
        })
        //updateBehaviorTreatment
        builder.addCase(updateBehaviorTreatment.pending, (state) => {
            state.loading = true;
            state.error = initialSoapNoteState.error;
        })
        builder.addCase(updateBehaviorTreatment.fulfilled, (state) => {
            state.loading = false;
        })
        builder.addCase(updateBehaviorTreatment.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload as ErrorProperties;
        })
        //updateDirectSupervision
        builder.addCase(updateDirectSupervision.pending, (state) => {
            state.loading = true;
            state.error = initialSoapNoteState.error;
        })
        builder.addCase(updateDirectSupervision.fulfilled, (state) => {
            state.loading = false;
        })
        builder.addCase(updateDirectSupervision.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload as ErrorProperties;
        })
        //updateParentTraining
        builder.addCase(updateParentTraining.pending, (state) => {
            state.loading = true;
            state.error = initialSoapNoteState.error;
        })
        builder.addCase(updateParentTraining.fulfilled, (state) => {
            state.loading = false;
        })
        builder.addCase(updateParentTraining.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload as ErrorProperties;
        })
        //updateTreatmentPlanning
        builder.addCase(updateTreatmentPlanning.pending, (state) => {
            state.loading = true;
            state.error = initialSoapNoteState.error;
        })
        builder.addCase(updateTreatmentPlanning.fulfilled, (state) => {
            state.loading = false;
        })
        builder.addCase(updateTreatmentPlanning.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload as ErrorProperties;
        })
        //reset state
        builder.addCase(resetState, () => initialSoapNoteState);
    }
})

export const { clearErrors } = soapNoteSlice.actions
export default soapNoteSlice.reducer;