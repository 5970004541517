import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import SearchBar from "../../../components/SearchBar";
import { CustomButton, Text, CustomTable } from "../../../shared/uiComponents";
import { useInitializeURLParams } from "../../../shared/Helpers/hooks";

import { TableBody, Headers } from "./tableInfo";
import MobileView from "./mobileView";
import { Container, Header } from "../StyledComponents";

import { DispatchProperties, StateProperties } from "../../../redux/store";
import { getRBTs } from "../../../redux/State/userSlice";
import { useCheckPermission } from "../Permissions/helpers";
import { PERMISSIONS } from "../../../App/constants";

const RBT = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<DispatchProperties>();
  const { permissionGranted } = useCheckPermission(PERMISSIONS.USER.CREATE);
  const [params] = useSearchParams();
  useInitializeURLParams();

  const usersData = useSelector((state: StateProperties) => state.user.rbts);
  const loading = useSelector((state: StateProperties) => state.user.loading);

  useEffect(() => {
    const page = params.get("page");
    const pageSize = params.get("pageSize");
    const orderBy = params.get("orderBy") || "";
    const direction = params.get("direction") || "";
    const searchString = params.get("search") || "";

    if (!page || !pageSize) return;

    dispatch(getRBTs({ page, pageSize, orderBy, direction, searchString }));
  }, [params, dispatch]);

  const addAdminHandler = () => navigate("/admin/rbt/new");

  return (
    <Container>
      <Header>
        <Text title={"RBT"} size="mediumBold" />
        <Header>
          {permissionGranted && (
            <CustomButton
              title={"Add RBT"}
              className={"marginRight16"}
              onClick={addAdminHandler}
            />
          )}
          <SearchBar />
        </Header>
      </Header>
      <CustomTable
        headers={Headers}
        data={usersData}
        TableBody={TableBody}
        loading={loading}
      />
      <MobileView {...usersData} />
    </Container>
  );
};

export default RBT;
