import { FetchAPI } from "../Main/fetchAPI";
import { BEHAVIORS, CLIENT, TARGETS, TARGETS_ANNOTATIONS } from "../constants";
import {
    BehaviorAnnotationProperties,
    TargetAnnotationProperties,
} from "./graphAnnotationProperties";

export const GraphAnnotation = {
    targets: {
        getAnnotations: (targetId: string) =>
            FetchAPI.getJson({
                urlStr: `${TARGETS}/${targetId}/annotations`,
            }),
        addAnnotation: (data: TargetAnnotationProperties) =>
            FetchAPI.postJson({
                urlStr: `${TARGETS_ANNOTATIONS}`,
                data,
            }),
        getAnnotation: (id: string) =>
            FetchAPI.getJson({
                urlStr: `${TARGETS_ANNOTATIONS}/${id}`,
            }),
        updateAnnotation: (id: string) =>
            FetchAPI.putJson({
                urlStr: `${TARGETS_ANNOTATIONS}/${id}`,
            }),
        deleteAnnotation: (id: string) =>
            FetchAPI.deleteJson({
                urlStr: `${TARGETS_ANNOTATIONS}/${id}`,
            }),
    },
    behaviors: {
        getAnnotations: (behaviorId: string) =>
            FetchAPI.getJson({
                urlStr: `${BEHAVIORS}/${behaviorId}/annotations`,
            }),
        addBehaviorAnnotation: (data: BehaviorAnnotationProperties) =>
            FetchAPI.postJson({
                urlStr: `${CLIENT}/behavior/annotations`,
                data,
            }),
        getBehaviorAnnotation: (id: string) =>
            FetchAPI.getJson({
                urlStr: `${BEHAVIORS}/annotations/${id}`,
            }),
        updateBehaviorAnnotation: (id: string) =>
            FetchAPI.putJson({
                urlStr: `${BEHAVIORS}/annotations/${id}`,
            }),
        deleteBehaviorAnnotation: (id: string) =>
            FetchAPI.deleteJson({
                urlStr: `${BEHAVIORS}/annotations/${id}`,
            }),
    },
}