import { useDispatch } from "react-redux";
import { ChangeEvent, useCallback, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { SelectChangeEvent } from "@mui/material/Select";
import { unwrapResult } from "@reduxjs/toolkit";
import {
  ChevronLeft as Left,
  ChevronRight as Right,
} from "@mui/icons-material";

import {
  Text,
  CustomSelect,
  CustomCheckBox,
  CustomButton,
} from "../../../shared/uiComponents";
import { useScreenWidth } from "../../../shared/Helpers/hooks";
import SearchSelect from "../../../shared/uiComponents/Dropdown/searchSelect";
import { months } from "./Helpers/constants";
import { SelectDataProperties } from "../../../shared/uiComponents/Dropdown/searchSelect";
import { AdminTypes } from "../../../components/Action";
import StartSession from "./Popups/startSession";
import { errorNormalizer } from "../../../shared/Helpers/functions";
import { useCheckPermission } from "../../AdminPanel/Permissions/helpers";
import { PERMISSIONS } from "../../../App/constants";

import { DispatchProperties, useSelector } from "../../../redux/store";
import { clearEvents } from "../../../redux/State/clientSlice/calendarSlice";
import {
  setMonth,
  setLastDayOfMonth,
  setLastDayOfPreviousMonth,
  setWeekDay,
  setCalendarView,
  setYear,
} from "../../../redux/State/clientSlice/calendarSlice";
import { getUsers } from "../../../redux/State/userSlice";
import { SoapNotesStatuses } from "../../../redux/API/ClientAPIHelpers/dataCollectionProperties";
import { getPatients } from "../../../redux/State/clientSlice/clientSlice";
import { getUserPatients } from "../../../redux/State/clientSlice/userClientSlice";

const CalendarHeader = () => {
  const dispatch = useDispatch<DispatchProperties>();
  const screenWidth = useScreenWidth();
  const [params, setParams] = useSearchParams();
  const includeRbtEvents =
    params.get("includeRbtEvents") === "true" ? true : false;

  const role = useSelector((state) => state.account.role);
  const userId = useSelector((state) => state.account.user.id);
  const viewer = useSelector((state) => state.calendar.calendarView);
  const month = useSelector((state) => state.calendar.month);
  const year = useSelector((state) => state.calendar.year);

  const events = useSelector((state) => state.calendar.events);
  const label = `Show Completed Sessions (${
    !!events && !!events.length
      ? includeRbtEvents
        ? `${
            events.filter(
              (x) =>
                x.isCompleted &&
                !!x.reportStatus &&
                x.reportStatus.status === SoapNotesStatuses.submitted &&
                x.user.id === userId
            ).length
          } - ${
            events.filter(
              (x) =>
                x.isCompleted &&
                !!x.reportStatus &&
                x.reportStatus.status === SoapNotesStatuses.submitted &&
                x.user.id !== userId
            ).length
          }`
        : events.filter(
            (x) =>
              x.isCompleted &&
              !!x.reportStatus &&
              x.reportStatus.status === SoapNotesStatuses.submitted
          ).length
      : 0
  })`;

  const labelRbt = `Show RBTs sessions ${
    !!events &&
    !!events.length &&
    !!events.filter((x) => x.user.id !== userId).length
      ? `(${events.filter((x) => x.user.id !== userId).length})`
      : ""
  }`;

  const labelDrafts = `Show only drafts ${
    !!events &&
    !!events.length &&
    !!events.filter((x) => x.reportStatus?.status === SoapNotesStatuses.draft)
      .length
      ? `(${
          events.filter(
            (x) => x.reportStatus?.status === SoapNotesStatuses.draft
          ).length
        })`
      : ""
  }`;

  const actionHandler = (month: number, year: number) => {
    dispatch(clearEvents());
    dispatch(setMonth(month));

    const lastDayOfMonth = new Date(year, month + 1, 0).getDate();
    dispatch(setLastDayOfMonth(lastDayOfMonth));

    const lastDayOfLastMonth = new Date(year, month, 0).getDate();
    dispatch(setLastDayOfPreviousMonth(lastDayOfLastMonth));

    const nextWeekDay = new Date(year, month, 1).getDay();
    dispatch(setWeekDay(nextWeekDay));
  };

  const nextHandler = () => {
    dispatch(clearEvents());

    if (month === 11) {
      const nextYear = year + 1;

      dispatch(setYear(nextYear));
      actionHandler(0, nextYear);
      return;
    }

    const nextMonth = month + 1;
    actionHandler(nextMonth, year);
  };

  const prevHandler = () => {
    dispatch(clearEvents());

    if (month === 0) {
      const previousYear = year - 1;

      dispatch(setYear(previousYear));
      actionHandler(11, previousYear);
      return;
    }

    const prevMonth = month - 1;
    actionHandler(prevMonth, year);
  };

  const onChangeView = (event: SelectChangeEvent<string>) => {
    const value = parseInt(event.target.value);
    dispatch(setCalendarView(value));
  };

  const showCompletedSessionHandler = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    const { checked } = event.target;
    params.set("showCompleted", `${checked}`);
    setParams(params);
  };

  const showDraftsOnlyHandler = (event: ChangeEvent<HTMLInputElement>) => {
    const { checked } = event.target;
    params.set("showDraftsOnly", `${checked}`);
    setParams(params);
  };

  const showRBTSessionsHandler = (event: ChangeEvent<HTMLInputElement>) => {
    const { checked } = event.target;
    params.set("includeRbtEvents", `${checked}`);
    setParams(params);
  };

  return (
    <>
      <div className="calendarHeader">
        <div className="date">
          <div onClick={prevHandler}>
            <Left fontSize="large" style={{ cursor: "pointer" }} />
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              minWidth: "200px",
              width: "100%",
              maxWidth: "270px",
            }}
          >
            <Text
              title={months[month]}
              className={"marginRight16"}
              size={"largeBold"}
            />
            <Text title={`${year}`} size={"largeBold"} />
          </div>

          <div onClick={nextHandler}>
            <Right fontSize="large" style={{ cursor: "pointer" }} />
          </div>
        </div>
        <div>
          <CustomCheckBox
            item={{
              checked: params.get("showDraftsOnly") === "true",
              id: "1",
              label: labelDrafts,
            }}
            onChange={showDraftsOnlyHandler}
          />
          <CustomCheckBox
            item={{
              checked: params.get("showCompleted") === "true",
              id: "0",
              label,
            }}
            onChange={showCompletedSessionHandler}
          />
          {!params.get("userId") &&
            !params.get("clientId") &&
            !!role &&
            role.section.id !== AdminTypes.rbt && (
              <CustomCheckBox
                item={{
                  checked: params.get("includeRbtEvents") === "true",
                  id: "1",
                  label: labelRbt,
                }}
                onChange={showRBTSessionsHandler}
              />
            )}
        </div>
        {screenWidth > 425 && (
          <CustomSelect
            data={[
              { id: "0", name: "month" },
              { id: "1", name: "week" },
            ]}
            value={`${viewer}`}
            setValue={onChangeView}
            className={"marginRight16"}
          />
        )}
        {!params.get("userId") && !params.get("clientId") && <StartSession />}
      </div>
      {!!role && role.section.id === AdminTypes.administrator && <AdminTools />}
    </>
  );
};

const AdminTools = () => {
  const dispatch = useDispatch<DispatchProperties>();
  const [params, setParams] = useSearchParams();
  const USER = useCheckPermission(PERMISSIONS.USER.READ);
  const CLIENT = useCheckPermission(PERMISSIONS.CLIENT.READ);

  const loadingPatientList = useSelector((state) => state.client.loading);
  const loadingUserList = useSelector((state) => state.user.loading);

  const [users, setUsers] = useState<Array<SelectDataProperties>>([]);
  const [patients, setPatients] = useState<Array<SelectDataProperties>>([]);
  const [pageSize, setPageSize] = useState<number>(8);
  const [pageSizePatient, setPageSizePatient] = useState<number>(8);
  const [searchString, setSearchString] = useState<string>("");
  const [searchStringPatient, setSearchStringPatient] = useState<string>("");

  const onTherapistChoose = (value: string) => {
    params.set("userId", value);
    params.delete("clientId");
    setParams(params);
    dispatch(clearEvents());
  };

  const onPatientChoose = (value: string) => {
    params.set("clientId", value);
    setParams(params);
    dispatch(clearEvents());
  };

  const displayDefaultViewHandler = useCallback(() => {
    params.delete("userId");
    params.delete("clientId");
    setParams(params);
    setSearchString("");
    setSearchStringPatient("");
    dispatch(clearEvents());
  }, [params, setParams, dispatch]);

  useEffect(() => {
    if (!USER.permissionGranted) return;
    const timeout = setTimeout(() => {
      dispatch(getUsers({ page: "1", pageSize: `${pageSize}`, searchString }))
        .then(unwrapResult)
        .then((response) => {
          if (!response.query) return;
          const userData: Array<SelectDataProperties> = response.query.map(
            (x) => ({
              id: x.id,
              label: `${x.fullName} (${x.role.section.name})`,
            })
          );
          setUsers(userData);
        })
        .catch(errorNormalizer);
    }, 1000);
    return () => clearTimeout(timeout);
  }, [pageSize, searchString, dispatch, USER.permissionGranted]);

  useEffect(() => {
    if (!CLIENT.permissionGranted) return;
    const timeout = setTimeout(() => {
      const userId = params.get("userId");
      if (!!userId) {
        dispatch(
          getUserPatients({
            userId,
            page: "1",
            pageSize: `${pageSizePatient}`,
            searchString: searchStringPatient,
          })
        )
          .then(unwrapResult)
          .then((response) => {
            if (!response.query) return;
            const data: Array<SelectDataProperties> = response.query.map(
              (x) => ({
                id: x.id,
                label: x.fullName,
              })
            );
            setPatients(data);
          })
          .catch(errorNormalizer);
      }

      dispatch(
        getPatients({
          page: "1",
          pageSize: `${pageSizePatient}`,
          searchString: searchStringPatient,
        })
      )
        .then(unwrapResult)
        .then((response) => {
          if (!response.query) return;
          const data: Array<SelectDataProperties> = response.query.map((x) => ({
            id: x.id,
            label: x.fullName,
          }));
          setPatients(data);
        })
        .catch(errorNormalizer);
    }, 1000);
    return () => clearTimeout(timeout);
  }, [
    searchStringPatient,
    dispatch,
    pageSizePatient,
    params,
    CLIENT.permissionGranted,
  ]);

  return (
    <div style={{ display: "flex", gap: "16px", flexWrap: "wrap" }}>
      {USER.permissionGranted && (
        <div style={{ width: "250px" }}>
          <SearchSelect
            data={users}
            label="Find Provider:"
            setSearchString={setSearchString}
            searchString={searchString}
            loading={loadingUserList}
            setValue={onTherapistChoose}
            loadMore={{
              activate: true,
              setSize: setPageSize,
            }}
          />
        </div>
      )}
      {CLIENT.permissionGranted && (
        <div style={{ width: "250px" }}>
          <SearchSelect
            data={patients}
            label="Find Client:"
            setSearchString={setSearchStringPatient}
            searchString={searchStringPatient}
            loading={loadingPatientList}
            setValue={onPatientChoose}
            loadMore={{
              activate: true,
              setSize: setPageSizePatient,
            }}
          />
        </div>
      )}
      <CustomButton
        title="Reset view"
        className="marginTop24 marginBottom8"
        secondaryButton
        onClick={displayDefaultViewHandler}
      />
    </div>
  );
};

export default CalendarHeader;
