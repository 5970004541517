import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import API from "../../API/Main/API";
import { AddProgramProperties, GetProgramsProperties, ProgramDataProperties, ProgramProperties, UpdateProgramProperties } from "../../API/ClientAPIHelpers/clientProgramProperties";
import { ErrorProperties } from "../../API/identityAPIProperties";


interface ClientSkillAreaStateProperties {
    loading: boolean;
    programs: Array<ProgramDataProperties>;
    activePrograms: Array<{ id: string, name: string }>;
    error: ErrorProperties;
}

const initialProgramState: ClientSkillAreaStateProperties = {
    loading: false,
    programs: [],
    activePrograms: [],
    error: {
        status: 0,
        title: ""
    },
};

export const getPrograms = createAsyncThunk(
    '/client/programs',
    async (data: GetProgramsProperties, thunkAPI) => {
        const response = await API.ClientAPI.ClientProgram.getPrograms(data);
        if (!!response.error) {
            return thunkAPI.rejectWithValue(response.error);
        }
        return response as Array<ProgramDataProperties>;
    }
)

export const addProgram = createAsyncThunk(
    '/client/program/add',
    async ({ skillAreaId, data }: AddProgramProperties, thunkAPI) => {
        const response = await API.ClientAPI.ClientProgram.addProgram(skillAreaId, data);
        if (!!response.error) {
            return thunkAPI.rejectWithValue(response.error);
        }
        return response;
    }
)

export const copyProgram = createAsyncThunk(
    '/client/program/copy',
    async (data: ProgramProperties, thunkAPI) => {
        const response = await API.ClientAPI.ClientProgram.copyProgram(data);
        if (!!response.error) {
            return thunkAPI.rejectWithValue(response.error);
        }
        return response;
    }
)

export const updateProgram = createAsyncThunk(
    '/client/program/update',
    async ({ data, id }: { data: UpdateProgramProperties, id: string }, thunkAPI) => {
        const response = await API.ClientAPI.ClientProgram.updateProgram(data.skillAreaId, id, data);
        if (!!response.error) {
            return thunkAPI.rejectWithValue(response.error);
        }
        return response;
    }
)

export const deleteProgram = createAsyncThunk(
    '/client/program/delete',
    async (data: ProgramProperties, thunkAPI) => {
        const response = await API.ClientAPI.ClientProgram.deleteProgram(data);
        if (!!response.error) {
            return thunkAPI.rejectWithValue(response.error);
        }
        return response;
    }
)

export const archiveProgram = createAsyncThunk(
    '/client/program/archive',
    async (data: ProgramProperties, thunkAPI) => {
        const response = await API.ClientAPI.ClientProgram.archiveProgram(data);
        if (!!response.error) {
            return thunkAPI.rejectWithValue(response.error);
        }
        return response;
    }
)

export const unarchiveProgram = createAsyncThunk(
    '/client/program/unarchive',
    async (data: ProgramProperties, thunkAPI) => {
        const response = await API.ClientAPI.ClientProgram.unarchiveProgram(data);
        if (!!response.error) {
            return thunkAPI.rejectWithValue(response.error);
        }
        return response;
    }
)

export const getActivePrograms = createAsyncThunk(
    '/client/program/active',
    async (clientId: string, thunkAPI) => {
        const response = await API.ClientAPI.ClientProgram.getActivePrograms(clientId);
        if (!!response.error) {
            return thunkAPI.rejectWithValue(response.error);
        }
        return response as Array<{ id: string; name: string }>;
    }
)

const ClientProgramSlice = createSlice({
    name: "clientProgram",
    initialState: initialProgramState,
    reducers: {},
    extraReducers: (builder) => {
        //get program
        builder.addCase(getPrograms.pending, (state) => {
            state.loading = true;
        })
        builder.addCase(getPrograms.fulfilled, (state, action) => {
            state.loading = false;
            state.programs = action.payload;
        })
        builder.addCase(getPrograms.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload as ErrorProperties;
        })

        //add program
        builder.addCase(addProgram.pending, (state) => {
            state.loading = true;
        })
        builder.addCase(addProgram.fulfilled, (state) => {
            state.loading = false;
        })
        builder.addCase(addProgram.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload as ErrorProperties;
        })

        //copy program
        builder.addCase(copyProgram.pending, (state) => {
            state.loading = true;
        })
        builder.addCase(copyProgram.fulfilled, (state) => {
            state.loading = false;
        })
        builder.addCase(copyProgram.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload as ErrorProperties;
        })

        //update program
        builder.addCase(updateProgram.pending, (state) => {
            state.loading = true;
        })
        builder.addCase(updateProgram.fulfilled, (state) => {
            state.loading = false;
        })
        builder.addCase(updateProgram.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload as ErrorProperties;
        })

        //deleteProgram
        builder.addCase(deleteProgram.pending, (state) => {
            state.loading = true;
        })
        builder.addCase(deleteProgram.fulfilled, (state) => {
            state.loading = false;
        })
        builder.addCase(deleteProgram.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload as ErrorProperties;
        })

        //archiveProgram
        builder.addCase(archiveProgram.pending, (state) => {
            state.loading = true;
        })
        builder.addCase(archiveProgram.fulfilled, (state) => {
            state.loading = false;
        })
        builder.addCase(archiveProgram.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload as ErrorProperties;
        })

        //unarchiveProgram
        builder.addCase(unarchiveProgram.pending, (state) => {
            state.loading = true;
        })
        builder.addCase(unarchiveProgram.fulfilled, (state) => {
            state.loading = false;
        })
        builder.addCase(unarchiveProgram.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload as ErrorProperties;
        })

        //get active programs
        builder.addCase(getActivePrograms.pending, (state) => {
            state.loading = true;
        })
        builder.addCase(getActivePrograms.fulfilled, (state, action) => {
            state.loading = false;
            state.activePrograms = action.payload;
        })
        builder.addCase(getActivePrograms.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload as ErrorProperties;
        })
    }
})

// export const {  } = ClientProgramSlice.actions;

export default ClientProgramSlice.reducer;