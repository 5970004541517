import { useEffect } from "react";
import { useParams } from "react-router";
import { useDispatch } from "react-redux";
import { styled } from "@mui/material";

import { useScreenWidth } from "../../../../shared/Helpers/hooks";
import {
  CustomTabs,
  CustomAccordion,
  Text,
} from "../../../../shared/uiComponents";
import PatientNotes from "./patientNotes";
import TherapistsList from "./therapistsList";
import PatientInfo from "./patientInfo";

import SkillAcquisition from "./SkillAcquisition";
import BehaviorReduction from "./BehaviorReduction";

import { DispatchProperties, useSelector } from "../../../../redux/store";
import { getPatient } from "../../../../redux/State/clientSlice/clientSlice";
import { InsuranceAuthorizationTab } from "./InsuranceAuthorization";
import { AdminTypes } from "../../../../components/Action";

const Wrapper = styled("div")(() => ({
  display: "flex",
  width: "100%",
  "@media (max-width: 1024px)": {
    flexDirection: "column",
  },
}));

const NotesWrapper = styled("div")(() => ({
  width: "100%",
  paddingLeft: "24px",
  "@media (max-width: 1024px)": {
    paddingLeft: 0,
  },
}));

const tabHeaders = [
  "Skill Acquisition",
  "Behavior Reduction",
  "Insurance Authorization",
];

const tabHeadersRBT = ["Skill Acquisition", "Behavior Reduction"];

const tabPanels = [
  SkillAcquisition,
  BehaviorReduction,
  InsuranceAuthorizationTab,
];
const tabPanelsRBT = [SkillAcquisition, BehaviorReduction];

const Patient = () => {
  const screenWidth = useScreenWidth();
  const dispatch = useDispatch<DispatchProperties>();
  const roleId = useSelector((state) => state.account.role?.section.id);
  const { clientId } = useParams();

  useEffect(() => {
    if (!clientId) return;
    dispatch(getPatient(clientId));
  }, [clientId, dispatch]);

  return (
    <div className="container">
      <Wrapper>
        <div style={{ minWidth: "270px" }}>
          <PatientInfo />
          <TherapistsList />
          <PatientNotes />
        </div>
        <NotesWrapper>
          {screenWidth > 768 ? (
            <CustomTabs
              headers={AdminTypes.rbt !== roleId ? tabHeaders : tabHeadersRBT}
              panels={AdminTypes.rbt !== roleId ? tabPanels : tabPanelsRBT}
            />
          ) : (
            (AdminTypes.rbt !== roleId ? tabHeaders : tabHeadersRBT).map(
              (header, index) => (
                <CustomAccordion
                  key={index}
                  HeaderContent={() => <Text title={header} size="smallBold" />}
                  Content={tabPanels[index]}
                />
              )
            )
          )}
        </NotesWrapper>
      </Wrapper>
    </div>
  );
};

export default Patient;
