import { StepProperties, TargetStepsDataProperties, TargetTypes } from "./programTargetsProperties";
import { TargetSessionStatuses } from "./sessionsProperties";

interface TargetSessionProperties {
    sessionId: string;
    targetId: string;
}

export interface BehaviorsProperties {
    sessionId: string;
    behaviorId: string;
}

export interface TargetResponseProperties {
    responseId: string;
    promptCodeId: string;
}

export interface GetSessionTrialsInfoProperties extends TargetSessionProperties {
    type: TargetTypes;
}

export interface TargetDTTSessionProperties extends TargetSessionProperties {
    promptCodeId: string;
}

export interface TargetTASessionProperties extends TargetSessionProperties {
    responses: Array<StepProperties>
}

export interface GetFrequencyProperties extends BehaviorsProperties { }

export interface SaveFrequencyProperties extends BehaviorsProperties { }
export interface UpdateFrequencyProperties extends BehaviorsProperties {
    count: number;
}

export interface GetDurationProperties extends BehaviorsProperties { }
export interface SaveDurationProperties extends BehaviorsProperties {
    seconds: number;
}

interface StepsProperties extends TargetStepsDataProperties {
    id: string;
}

export interface SessionTrialProperties {
    trials: number;
    independent: number;
    prompt: number;
    correctPercentage: number;
}

export interface SessionTargetsProperties extends SessionTrialProperties {
    skillArea: {
        id: string;
        name: string;
    },
    program: {
        id: string;
        name: string;
    },
    target: {
        id: string;
        name: string;
        sd: string;
        targetInstructions: string;
        type: number;
        status: number;
        steps: Array<StepsProperties>
    },
    targetStatus: TargetSessionStatuses,
}

export interface UpdatePromptsProperties {
    targetId: string;
    data: Array<TargetResponseProperties>;
}

export interface PromptCodeProperties {
    id: string;
    abbreviation: string; // "I","P"
    name: string; // "Independent", "Prompt"
}

export enum SoapNotesStatuses {
    "draft",
    "submitted",
    "expired48",
    "expiredWeek",
    "unlocked",
    "pendingBT",
    "timeMismatch"
}