import { SOAPNoteWrapper } from "../helpers";
import CreateSoapNote from "./createBehaviorTreatmentSoapNote";
import UpdateSoapNote from "./updateBehaviorTreatmentSoapNote";

const BehaviorTreatment = () => (
  <SOAPNoteWrapper
    CreateSoapNote={CreateSoapNote}
    UpdateSoapNote={UpdateSoapNote}
  />
);

export default BehaviorTreatment;
