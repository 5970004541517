import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Outlet, useNavigate, useOutlet } from "react-router-dom";
import { IconButton } from "@mui/material";
import {
  Menu as MenuIcon,
  ChevronLeft as ChevronLeftIcon,
} from "@mui/icons-material";

import {
  useMultipleTabs,
  useRefreshToken,
  useScreenWidth,
} from "../../shared/Helpers/hooks";
import Lighthouse from "../../shared/Assets/logos/IlluminateLogoMain.png";
import { adminMenu, bcbaMenu, rbtMenu } from "../../App/constants";
import { CustomButton } from "../../shared/uiComponents";
import {
  AppBar,
  Container,
  CustomToolbar,
  DrawerHeader,
  DrawerWrapper,
  FlexCenter,
  IMG,
  Main,
  Wrapper,
} from "./styledComponents";
import { AdminTypes } from "../Action";
import MenuElements from "./menuElements";
import { Loader } from "../../pages/AdminPanel/StyledComponents";
import Previous from "./previous";

import { DispatchProperties, useSelector } from "../../redux/store";
import { resetState } from "../../redux/resetState";
import {
  getAccountRoles,
  refresh,
} from "../../redux/State/identitySlice/accountSlice";
import { getRefreshToken } from "../../redux/API/Main/helpers";
import SavedDraftsInfo from "./savedDraftsInfo";

export interface AdminTypeProperties {
  isSuperAdmin?: boolean;
  isAdmin: boolean;
  isBCBA: boolean;
  isRBT: boolean;
}

const initialAdminTypesState: AdminTypeProperties = {
  isAdmin: false,
  isSuperAdmin: false,
  isBCBA: false,
  isRBT: false,
};

const Layout = () => {
  useRefreshToken();
  useMultipleTabs();

  const navigate = useNavigate();
  const screenWidth = useScreenWidth();
  const outlet = useOutlet();
  const dispatch = useDispatch<DispatchProperties>();

  const [open, setOpen] = useState<boolean>(false);
  const [{ isAdmin, isBCBA, isRBT }, setAdminType] =
    useState<AdminTypeProperties>(initialAdminTypesState);

  const role = useSelector((state) => state.account.role);
  const user = useSelector((state) => state.account.user);
  const loading = useSelector((state) => state.account.loading);

  useEffect(() => {
    const token = getRefreshToken();
    if (!!user.id || !token) return;
    dispatch(refresh(token));
  }, [user, dispatch]);

  useEffect(() => {
    if (!role) return;
    setAdminType({
      isSuperAdmin: role.name === "super-admin",
      isAdmin: role.section.id === AdminTypes.administrator,
      isBCBA: role.section.id === AdminTypes.bcba,
      isRBT: role.section.id === AdminTypes.rbt,
    });
  }, [role]);

  useEffect(() => {
    if (!!role) return;
    dispatch(getAccountRoles());
  }, [role, dispatch]);

  useEffect(() => {
    if (!outlet && isAdmin) navigate("/admin/dashboard");
    if (!outlet && isBCBA) navigate("/bcba/dashboard");
    if (!outlet && isRBT) navigate("/rbt/dashboard");
  }, [outlet, isAdmin, isBCBA, isRBT, navigate]);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const logOutHandler = () => {
    dispatch(resetState());
    navigate("/login");
  };

  return (
    <Container>
      <AppBar position="fixed" open={open}>
        <CustomToolbar>
          <FlexCenter>
            {screenWidth < 1025 && (
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={handleDrawerOpen}
                edge="start"
                sx={{ mr: 2, ...(open && { display: "none" }) }}
              >
                <MenuIcon />
              </IconButton>
            )}
            <Previous />
          </FlexCenter>
          <SavedDraftsInfo />
          <FlexCenter>
            <CustomButton
              title={"Log out"}
              onClick={logOutHandler}
              secondaryButton
            />
          </FlexCenter>
        </CustomToolbar>
      </AppBar>
      <DrawerWrapper
        variant={screenWidth > 1024 ? "permanent" : "temporary"}
        anchor="left"
        open={open}
        onClose={handleDrawerClose}
      >
        <DrawerHeader>
          <IMG src={Lighthouse} />
          {screenWidth < 1025 && (
            <div style={{ position: "fixed", top: 12, left: 0 }}>
              <IconButton onClick={handleDrawerClose}>
                <ChevronLeftIcon />
              </IconButton>
            </div>
          )}
        </DrawerHeader>
        {!loading ? (
          <div onClick={handleDrawerClose}>
            {isAdmin && <MenuElements menu={adminMenu} />}
            {isBCBA && <MenuElements menu={bcbaMenu} />}
            {isRBT && <MenuElements menu={rbtMenu} />}
          </div>
        ) : (
          <Loader />
        )}
      </DrawerWrapper>

      <Main open={open}>
        <DrawerHeader />
        <Wrapper>
          <Outlet />
        </Wrapper>
      </Main>
    </Container>
  );
};

export default Layout;
