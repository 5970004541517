import { SOAPNoteWrapper } from "../helpers";
import CreateSoapNote from "./createParentTrainingSoapNote";
import UpdateSoapNote from "./updateParentTrainingSoapNote";

const ParentTraining = () => (
  <SOAPNoteWrapper
    CreateSoapNote={CreateSoapNote}
    UpdateSoapNote={UpdateSoapNote}
  />
);

export default ParentTraining;
