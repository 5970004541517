import { useDispatch } from "react-redux";
import { useState } from "react";
import { VisibilityOffOutlined as Unhide } from "@mui/icons-material";

import { CustomAlert, CustomButton, Text } from "../../shared/uiComponents";

import { setHiddenTargetActive } from "../../redux/State/clientSlice/sessionSlice";
import { DispatchProperties, useSelector } from "../../redux/store";
import { TargetSessionStatuses } from "../../redux/API/ClientAPIHelpers/sessionsProperties";

const HiddenTargets = () => {
  const [open, setOpen] = useState<boolean>(false);
  const targets = useSelector((state) => state.session.targets);

  return targets.filter(
    (x) => x.targetSessionStatus === TargetSessionStatuses.hidden
  ).length ? (
    <>
      <CustomButton
        secondaryButton={true}
        title={`Hidden targets: ${
          targets.filter(
            (x) => x.targetSessionStatus === TargetSessionStatuses.hidden
          ).length
        }`}
        onClick={() => setOpen(true)}
      />
      <CustomAlert
        title="Hidden targets:"
        onClose={() => setOpen(false)}
        open={open}
        Content={() => <HiddenTargetsContent />}
      />
    </>
  ) : null;
};

const HiddenTargetsContent = () => {
  const targets = useSelector((state) => state.session.targets);

  return (
    <>
      {targets
        .filter((x) => x.targetSessionStatus === TargetSessionStatuses.hidden)
        .map((target) => (
          <HiddenTarget id={target.id} name={target.name} />
        ))}
    </>
  );
};

const HiddenTarget = ({ id, name }: { id: string; name: string }) => {
  const dispatch = useDispatch<DispatchProperties>();

  const unHideTargetHandler = () => {
    dispatch(setHiddenTargetActive(id));
  };
  return (
    <div>
      <Text title={name} />
      <Unhide onClick={unHideTargetHandler} style={{ cursor: "pointer" }} />
    </div>
  );
};

export default HiddenTargets;
