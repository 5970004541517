import {FC} from "react";
import { Background, ContentWrapper } from "./styledComponents";

interface WrapperProperties {
    Component: FC;
}

const Wrapper = ({Component}: WrapperProperties) => (
    <Background>
        <ContentWrapper>
            <Component/>
        </ContentWrapper>
    </Background>
)

export default Wrapper;