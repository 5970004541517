import { Container, Header } from "../StyledComponents";
import { CustomTabs, Text } from "../../../shared/uiComponents";
import GraphReports from "./GraphReports";
import DownloadSoapNotes from "./SoapNotes";
import MBHReport from "./BillingReports/MBHReport";
import NonCredentialedReport from "./BillingReports/NonCredentialedReport";
import { useScreenWidth } from "../../../shared/Helpers/hooks";
import { styled } from "@mui/material";
import { useEffect, useState } from "react";
import { white100 } from "../../../shared/Helpers/colors";
import CustomRadio from "../../../shared/uiComponents/Radio";
import { useSearchParams } from "react-router-dom";
import UtilizationReport from "./UtilizationReport";
import { useCheckPermission } from "../Permissions/helpers";
import { PERMISSIONS } from "../../../App/constants";

export const ReportWrapper = styled("div")(() => ({
  minWidth: "250px",
  maxWidth: "720px",
  marginTop: "16px",
  ">div": {
    display: "flex",
    alignItems: "center",
    gap: "16px",
    marginBottom: "16px",
  },
}));

const reports = [
  MBHReport,
  NonCredentialedReport,
  GraphReports,
  DownloadSoapNotes,
  UtilizationReport,
];

const Reports = () => {
  const sccreenWidth = useScreenWidth();

  const MBH = useCheckPermission(PERMISSIONS.REPORT.MBH);
  const NC_DETAILED = useCheckPermission(PERMISSIONS.REPORT.NONCREDENTIALED);
  const NC_TOTAL = useCheckPermission(PERMISSIONS.REPORT.NONCREDENTIALED_TOTAL);
  const GRAPH = useCheckPermission(PERMISSIONS.REPORT.GRAPH);
  const SOAPNOTE = useCheckPermission(PERMISSIONS.REPORT.SOAPNOTE);
  const UTILIZATION = useCheckPermission(PERMISSIONS.REPORT.UTILIZATION);

  const [page, setPage] = useState<string>("0");
  const [headers, setHeaders] = useState<
    Array<{ title: string; permissionGranted: boolean }>
  >([]);
  const [, setParams] = useSearchParams();
  const Content = reports[page];

  const onPageChange = (page: string) => {
    setPage(page);
    const resetParams = new URLSearchParams();
    setParams(resetParams);
  };

  useEffect(() => {
    const permissions = [
      { title: "MBH Report", permissionGranted: MBH.permissionGranted },
      {
        title: "Non Credentialed Report",
        permissionGranted:
          NC_DETAILED.permissionGranted || NC_TOTAL.permissionGranted,
      },
      { title: "Graph Reports", permissionGranted: GRAPH.permissionGranted },
      { title: "SOAP Notes", permissionGranted: SOAPNOTE.permissionGranted },
      {
        title: "Utilization Report",
        permissionGranted: UTILIZATION.permissionGranted,
      },
    ];

    setHeaders(permissions);
  }, [
    MBH.permissionGranted,
    SOAPNOTE.permissionGranted,
    GRAPH.permissionGranted,
    NC_DETAILED.permissionGranted,
    NC_TOTAL.permissionGranted,
    UTILIZATION.permissionGranted,
  ]);

  return (
    <Container>
      <Header>
        <Text title={"Reports"} size="mediumBold" />
      </Header>
      {sccreenWidth > 720 ? (
        <CustomTabs
          headers={headers
            .filter((x) => x.permissionGranted)
            .map((x) => x.title)}
          panels={reports.filter(
            (_, index) => headers[index]?.permissionGranted
          )}
        />
      ) : (
        <>
          <div>
            {MBH.permissionGranted && (
              <CustomRadio
                id={page}
                value={"0"}
                label="MBH Report"
                onChange={() => onPageChange("0")}
              />
            )}
            {(NC_DETAILED.permissionGranted || NC_TOTAL.permissionGranted) && (
              <CustomRadio
                id={page}
                value={"1"}
                label="Non Credentialed Report"
                onChange={() => onPageChange("1")}
              />
            )}
            {GRAPH && (
              <CustomRadio
                id={page}
                value={"2"}
                label="Graph Reports"
                onChange={() => onPageChange("2")}
              />
            )}
            {SOAPNOTE && (
              <CustomRadio
                id={page}
                value={"3"}
                label="SOAP Notes"
                onChange={() => onPageChange("3")}
              />
            )}
            {UTILIZATION && (
              <CustomRadio
                id={page}
                value={"4"}
                label="Utilization Report"
                onChange={() => onPageChange("4")}
              />
            )}
          </div>
          <div
            style={{
              backgroundColor: white100,
              padding: "16px",
              borderRadius: "12px",
            }}
          >
            <Content />
          </div>
        </>
      )}
    </Container>
  );
};

export default Reports;
