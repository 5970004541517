import { v4 as randomUUID } from "uuid";

export interface MenuProperties {
  id: string;
  title: string;
  url: string;
  permission: string | null;
}

export const PERMISSIONS = {
  SUPERADMIN: "all",
  CLIENT: {
    READ: "client.read",
    CREATE: "client.create",
    EDIT: "client.edit",
    DELETE: "client.delete",
  },
  USER: {
    READ: "user.read",
    CREATE: "user.create",
    EDIT: "user.edit",
    DELETE: "user.delete",
  },
  SOAPNOTE: {
    READ: "soapnote.read",
    UNLOCK: "admin.soapnote.unlock",
    PDF: "soapnote.pdf",
    CREATE: "soapnote.create",
    EDIT: "soapnote.edit",
  },
  REPORT: {
    UTILIZATION: "admin.report.utilization.download",
    GRAPH: "report.graph.download",
    SOAPNOTE: "admin.report.soapnote.download",
    NONCREDENTIALED: "admin.report.noncredentialed.detailed.download",
    NONCREDENTIALED_TOTAL: "admin.report.noncredentialed.total.download",
    MBH: "admin.report.mbh.detailed.download",
  }

}

export const adminMenu: Array<MenuProperties> = [
  { id: randomUUID(), title: "Dashboard", url: "/admin/dashboard", permission: null },
  { id: randomUUID(), title: 'Calendar', url: '/admin/calendar', permission: PERMISSIONS.SOAPNOTE.CREATE },
  { id: randomUUID(), title: "Clients", url: "/admin/patients", permission: PERMISSIONS.CLIENT.READ },
  { id: randomUUID(), title: "All Users", url: "/admin/user", permission: PERMISSIONS.USER.READ },
  { id: randomUUID(), title: "Administrators", url: "/admin/administrator", permission: PERMISSIONS.USER.READ },
  { id: randomUUID(), title: "BCBA", url: "/admin/bcba", permission: PERMISSIONS.USER.READ },
  { id: randomUUID(), title: "RBT", url: "/admin/rbt", permission: PERMISSIONS.USER.READ },
  { id: randomUUID(), title: "SOAP Notes", url: "/admin/soap-notes", permission: PERMISSIONS.SOAPNOTE.READ },
  { id: randomUUID(), title: "Permissions", url: "/admin/permissions", permission: PERMISSIONS.SUPERADMIN },
  { id: randomUUID(), title: "Reports", url: "/admin/reports", permission: 'report' },
];

export const bcbaMenu: Array<MenuProperties> = [
  { id: randomUUID(), title: 'Dashboard', url: '/bcba/dashboard', permission: null },
  { id: randomUUID(), title: 'Calendar', url: '/bcba/calendar', permission: PERMISSIONS.SOAPNOTE.CREATE },
  { id: randomUUID(), title: 'Clients', url: '/bcba/patients', permission: PERMISSIONS.CLIENT.READ },
  { id: randomUUID(), title: 'SOAP Notes', url: '/bcba/soap-notes', permission: PERMISSIONS.SOAPNOTE.READ },
  { id: randomUUID(), title: 'Reports', url: '/bcba/reports', permission: PERMISSIONS.REPORT.GRAPH },
]

export const rbtMenu: Array<MenuProperties> = [
  { id: randomUUID(), title: 'Dashboard', url: '/rbt/dashboard', permission: null },
  { id: randomUUID(), title: 'Calendar', url: '/rbt/calendar', permission: PERMISSIONS.SOAPNOTE.CREATE },
  { id: randomUUID(), title: 'Clients', url: '/rbt/patients', permission: PERMISSIONS.CLIENT.READ },
  { id: randomUUID(), title: 'SOAP Notes', url: '/rbt/soap-notes', permission: PERMISSIONS.SOAPNOTE.READ },
]
