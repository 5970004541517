import { useEffect, useState, useCallback } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { unwrapResult } from "@reduxjs/toolkit";

import { white200 } from "../../../shared/Helpers/colors";
import { Text } from "../../../shared/uiComponents";
import { errorNormalizer } from "../../../shared/Helpers/functions";

import { DispatchProperties } from "../../../redux/store";
import {
  getDuration,
  saveDuration,
} from "../../../redux/State/clientSlice/dataCollectionSlice";
import { BehaviorReductionProperties } from "../../../redux/API/ClientAPIHelpers/behaviorReductionProperties";

const DurationTypeBehavior = ({
  behavior,
}: {
  behavior: BehaviorReductionProperties;
}) => {
  const { sessionId } = useParams();
  const dispatch = useDispatch<DispatchProperties>();

  const [duration, setDuration] = useState<string>("");
  const [seconds, setSeconds] = useState<number>(0);
  const [start, setStart] = useState<boolean>(false);

  const calculateTime = () => {
    let hour = Math.floor(seconds / 3600);
    let minute = Math.floor((seconds % 3600) / 60);
    const second = (seconds % 3600) % 60;
    return `${hour}:${minute}:${second}`;
  };

  const fetchData = useCallback(() => {
    if (!behavior.id || !sessionId) return;
    dispatch(getDuration({ behaviorId: behavior.id, sessionId }))
      .then(unwrapResult)
      .then(({ duration }) => {
        setDuration(duration);
      })
      .catch(errorNormalizer);
  }, [behavior.id, sessionId, dispatch]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    if (!start || !sessionId) return;

    const interval = setInterval(() => {
      setSeconds((prev) => prev + 1);
    }, 1000);

    return () => clearInterval(interval);
  }, [start, dispatch, sessionId, behavior.id, fetchData]);

  const durationHandler = () => {
    if (!sessionId) return;
    setStart(!start);
    if (!seconds) return;
    dispatch(saveDuration({ behaviorId: behavior.id, sessionId, seconds }))
      .then(unwrapResult)
      .then(() => {
        setSeconds(0);
        fetchData();
      })
      .catch(errorNormalizer);
  };

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-around",
        cursor: "pointer",
      }}
      onClick={() => durationHandler()}
    >
      <div
        className="marginRight8"
        style={{
          width: "80px",
          height: "32px",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-around",
          backgroundColor: white200,
          borderRadius: "8px",
          cursor: "pointer",
        }}
      >
        <Text
          title={!!duration ? duration : calculateTime()}
          onClick={() => {}}
          size="mediumBold"
        />
      </div>
      <Text title={behavior.name} onClick={() => {}} className="marginRight8" />
    </div>
  );
};

export default DurationTypeBehavior;
