import { FetchAPI } from "../Main/fetchAPI";
import { CLIENT, SESSIONS, TARGETS } from "../constants";
import {
    GetDurationProperties,
    GetFrequencyProperties,
    GetSessionTrialsInfoProperties,
    SaveDurationProperties,
    SaveFrequencyProperties,
    TargetDTTSessionProperties,
    TargetResponseProperties,
    TargetTASessionProperties,
    UpdateFrequencyProperties,
} from "./dataCollectionProperties";

export const DataCollection = {
    targets: {
        getDTTPrompts: (targetId: string, sessionId?: string) => FetchAPI.getJson({
            urlStr: `${TARGETS}/dtt/${targetId}/responses?sessionId=${!!sessionId ? sessionId : ''}`,
        }),
        getTAPrompts: (targetId: string, sessionId?: string) => FetchAPI.getJson({
            urlStr: `${TARGETS}/ta/${targetId}/responses?sessionId=${!!sessionId ? sessionId : ''}`,
        }),
        updateDTTPrompts: (
            targetId: string,
            data: Array<TargetResponseProperties>
        ) =>
            FetchAPI.putJson({
                urlStr: `${TARGETS}/dtt/${targetId}/responses`,
                data,
            }),
        updateTAPrompts: (
            targetId: string,
            data: Array<TargetResponseProperties>
        ) =>
            FetchAPI.putJson({
                urlStr: `${TARGETS}/ta/${targetId}/responses`,
                data,
            }),
    },
    sessions: {
        targets: {
            getSessionTargets: (sessionId: string) =>
                FetchAPI.getJson({
                    urlStr: `${SESSIONS}/${sessionId}/targets`,
                }),
            getDDTSessionTrialInfo: (data: GetSessionTrialsInfoProperties) => {
                const { targetId, sessionId } = data;
                return FetchAPI.getJson({
                    urlStr: `${SESSIONS}/${sessionId}/targets/dtt/${targetId}/responses`,
                });
            },
            getTASessionTrialInfo: (data: GetSessionTrialsInfoProperties) => {
                const { targetId, sessionId } = data;
                return FetchAPI.getJson({
                    urlStr: `${SESSIONS}/${sessionId}/targets/ta/${targetId}/responses`,
                });
            },
            saveDTTSessionInfo: (data: TargetDTTSessionProperties) => {
                const { targetId, sessionId } = data;
                return FetchAPI.postJson({
                    urlStr: `${SESSIONS}/${sessionId}/targets/dtt/${targetId}`,
                    data,
                });
            },
            saveTASessionInfo: (data: TargetTASessionProperties) => {
                const { targetId, sessionId } = data;
                return FetchAPI.postJson({
                    urlStr: `${SESSIONS}/${sessionId}/targets/ta/${targetId}`,
                    data,
                });
            },
        },
        behaviors: {
            getBehaviors: (sessionId: string) => FetchAPI.getJson({
                urlStr: `${SESSIONS}/${sessionId}/behaviors`,
            }),
            getFrequency: (data: GetFrequencyProperties) => {
                const { behaviorId, sessionId } = data;
                return FetchAPI.getJson({
                    urlStr: `${SESSIONS}/${sessionId}/behaviors/frequency/${behaviorId}`,
                });
            },
            saveFrequency: (data: SaveFrequencyProperties) => {
                const { behaviorId, sessionId } = data;
                return FetchAPI.postJson({
                    urlStr: `${SESSIONS}/${sessionId}/behaviors/frequency/${behaviorId}`,
                });
            },
            updateFrequency: (data: UpdateFrequencyProperties) => {
                const { behaviorId, sessionId } = data;
                return FetchAPI.putJson({
                    urlStr: `${SESSIONS}/${sessionId}/behaviors/frequency/${behaviorId}`,
                    data,
                });
            },
            getDuration: (data: GetDurationProperties) => {
                const { behaviorId, sessionId } = data;
                return FetchAPI.getJson({
                    urlStr: `${SESSIONS}/${sessionId}/behaviors/duration/${behaviorId}`,
                });
            },
            saveDuration: (data: SaveDurationProperties) => {
                const { behaviorId, sessionId, seconds } = data;
                return FetchAPI.postJson({
                    urlStr: `${SESSIONS}/${sessionId}/behaviors/duration/${behaviorId}`,
                    data: { seconds },
                });
            },
        },
    },
    responses: {
        deletePrompt: (data: Array<string>) =>
            FetchAPI.deleteJson({
                urlStr: `${CLIENT}/responses`,
                data,
            }),
    }
}