import { useEffect, useState, useCallback } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { unwrapResult } from "@reduxjs/toolkit";
import {
  AddOutlined as Add,
  RemoveOutlined as Remove,
} from "@mui/icons-material";

import { white200 } from "../../../shared/Helpers/colors";
import { CustomAlert, Text } from "../../../shared/uiComponents";
import { errorNormalizer } from "../../../shared/Helpers/functions";

import { DispatchProperties } from "../../../redux/store";
import { BehaviorReductionProperties } from "../../../redux/API/ClientAPIHelpers/behaviorReductionProperties";
import {
  getFrequency,
  saveFrequency,
  updateFrequency,
} from "../../../redux/State/clientSlice/dataCollectionSlice";

const FrequencyTypeBehavior = ({
  behavior,
}: {
  behavior: BehaviorReductionProperties;
}) => {
  const { sessionId } = useParams();
  const dispatch = useDispatch<DispatchProperties>();

  const [count, setCount] = useState<number>(0);
  const [open, setOpen] = useState<boolean>(false);

  const fetchData = useCallback(() => {
    if (!behavior.id || !sessionId) return;
    dispatch(getFrequency({ behaviorId: behavior.id, sessionId }))
      .then(unwrapResult)
      .then((response) => setCount(response.count))
      .catch(errorNormalizer);
  }, [behavior.id, sessionId, dispatch]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const addBehaviourFrequency = () => {
    if (!behavior.id || !sessionId) return;
    dispatch(saveFrequency({ sessionId, behaviorId: behavior.id }))
      .then(unwrapResult)
      .then(() => fetchData())
      .catch(errorNormalizer);
  };

  const removeBehaviourFrequency = () => {
    if (!behavior.id || !sessionId) return;
    dispatch(
      updateFrequency({ behaviorId: behavior.id, sessionId, count: count - 1 })
    )
      .then(unwrapResult)
      .then(() => fetchData())
      .catch(errorNormalizer);
  };

  return (
    <>
      <div
        className="marginRight8 "
        style={{
          width: "32px",
          height: "32px",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-around",
          backgroundColor: white200,
          borderRadius: "8px",
        }}
      >
        <Text title={count} onClick={() => setOpen(true)} size="mediumBold" />
      </div>
      <div
        className="marginRight8"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-around",
          cursor: "pointer",
        }}
      >
        <Text
          title={behavior.name}
          onClick={addBehaviourFrequency}
          className="marginRight8"
        />
      </div>
      <CustomAlert
        open={open}
        onClose={() => setOpen(false)}
        Content={() => {
          return (
            <div
              style={{
                display: "flex",
                justifyContent: "space-around",
                alignItems: "center",
              }}
              className="padding16"
            >
              <Remove fontSize="large" onClick={removeBehaviourFrequency} />
              <Text title={count} size="largeBold" />
              <Add fontSize="large" onClick={addBehaviourFrequency} />
            </div>
          );
        }}
      />
    </>
  );
};

export default FrequencyTypeBehavior;
