import { FC } from "react";
import LinearProgress from "@mui/material/LinearProgress";
import { blue400, white100, white200 } from "../../Helpers/colors";
import Text from "../Text";
import { styled } from "@mui/material/styles";

const CustomizedProgress = styled(LinearProgress)<{ barColor: string }>(
  ({ barColor }) => ({
    height: 15,
    borderRadius: 10,
    backgroundColor: white200,
    ">span": {
      backgroundColor: barColor,
    },
  })
);

const Wrapper = styled("div")(() => ({
  backgroundColor: white100,
  padding: "8px",
  margin: "8px 0",
  borderRadius: "12px",
}));

interface ProgressBarProperties {
  label: string;
  value: number;
  maxValue: number;
  className?: string;
  barColor?: string;
}

const ProgressBar: FC<ProgressBarProperties> = ({
  label = "",
  value = 0,
  maxValue = 100,
  className = "",
  barColor = blue400,
}) => {
  return (
    <Wrapper className={className}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
        className={"marginBottom8"}
      >
        <Text title={label} size={"smallBold"} />
        <Text title={`${value}h/${maxValue}h`} size={"smallBold"} />
      </div>
      <CustomizedProgress
        variant="determinate"
        value={(value / maxValue) * 100}
        barColor={barColor}
      />
    </Wrapper>
  );
};

export default ProgressBar;
