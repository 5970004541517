import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import API from "../API/Main/API";
import { ExportClientGraphsProperties, ExportClientReportsProperties, SoapNotesPDFProperties } from "../API/exportProperties";
import { ErrorProperties } from "../API/identityAPIProperties";

interface ExportStateProperties {
  loading: boolean;
  error: ErrorProperties;
}

const initialExportState: ExportStateProperties = {
  loading: false,
  error: {
    status: 0,
    title: "",
  },
};

export const getClientGraphs = createAsyncThunk(
  "/export/graphs",
  async (data: ExportClientGraphsProperties, thunkAPI) => {
    const response = await API.ExportAPI.Pdf.getClientGraph(data);
    if (!!response.error) {
      return thunkAPI.rejectWithValue(response.error);
    }
    return response;
  }
);

export const getClientReports = createAsyncThunk(
  "/export/reports",
  async (data: ExportClientReportsProperties, thunkAPI) => {
    const response = await API.ExportAPI.Pdf.getClientReports(data);
    if (!!response.error) {
      return thunkAPI.rejectWithValue(response.error);
    }
    return response;
  }
);

export const getSoapNotePDF = createAsyncThunk(
  "/export/soap-note/pdf",
  async (data: SoapNotesPDFProperties, thunkAPI) => {
    const response = await API.ExportAPI.Pdf.getSoapNotePDF(data);
    if (!!response.error) {
      return thunkAPI.rejectWithValue(response.error);
    }
    return response;
  }
);

export const downloadSoapNotePDF = createAsyncThunk(
  "/export/soap-note/pdf/download",
  async (data: SoapNotesPDFProperties, thunkAPI) => {
    const response = await API.ExportAPI.Pdf.downloadSoapNotePDF(data);
    if (!!response.error) {
      return thunkAPI.rejectWithValue(response.error);
    }
    return response;
  }
);

const exportSlice = createSlice({
  name: "export",
  initialState: initialExportState,
  reducers: {},
  extraReducers: (builder) => {
    //getClientGraphs
    builder.addCase(getClientGraphs.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getClientGraphs.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(getClientGraphs.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error as ErrorProperties;
    });
    //getClientReports
    builder.addCase(getClientReports.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getClientReports.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(getClientReports.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error as ErrorProperties;
    });
    //getSoapNotePDF
    builder.addCase(getSoapNotePDF.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getSoapNotePDF.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(getSoapNotePDF.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error as ErrorProperties;
    });
    //downloadSoapNotePDF
    builder.addCase(downloadSoapNotePDF.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(downloadSoapNotePDF.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(downloadSoapNotePDF.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error as ErrorProperties;
    });
  },
});

export default exportSlice.reducer;
