import { useState } from "react";
import { Link, useLocation, useSearchParams } from "react-router-dom";
import { Tooltip } from "@mui/material";
import {
  EditOutlined as Edit,
  InfoOutlined as Info,
} from "@mui/icons-material";
import { useDispatch } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

import { InfoClientProperties } from "../../../redux/API/ClientAPIHelpers/clientProperties";
import { DispatchProperties } from "../../../redux/store";
import {
  deletePatient,
  getPatients,
} from "../../../redux/State/clientSlice/clientSlice";
import { errorNormalizer } from "../../../shared/Helpers/functions";
import Delete from "../../../components/Delete";
import { CustomAlert } from "../../../shared/uiComponents";
import { useCheckPermission } from "../Permissions/helpers";
import { PERMISSIONS } from "../../../App/constants";
import { PatientDetailedInfo } from "./tableInfo";

export type PanelTypes = "admin" | "bcba" | "rbt";

const Action = ({ item }: { item: InfoClientProperties }) => {
  const dispatch = useDispatch<DispatchProperties>();
  const { pathname } = useLocation();
  const [params] = useSearchParams();
  const EDIT = useCheckPermission(PERMISSIONS.CLIENT.EDIT);
  const DELETE = useCheckPermission(PERMISSIONS.CLIENT.DELETE);

  const [infoOpen, setInfoOpen] = useState<boolean>(false);

  const showUserInfo = () => {
    setInfoOpen(true);
  };

  const fetchPatients = () => {
    const page = params.get("page") || "1";
    const pageSize = params.get("pageSize") || "8";
    const searchString = params.get("search") || "";
    const orderBy = params.get("orderBy") || "";
    const direction = params.get("direction") || "";
    const status = params.get("status");

    dispatch(
      getPatients({ page, pageSize, direction, orderBy, searchString, status })
    );
  };

  const deletePatientHandler = () => {
    dispatch(deletePatient(item.id))
      .then(unwrapResult)
      .then(() => {
        toast(`Deleted`);
        fetchPatients();
      })
      .catch(errorNormalizer);
  };

  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      {EDIT.permissionGranted && pathname.includes("admin") && (
        <>
          <Link to={`${pathname}/${item.id}`} className={"marginRight16"}>
            <Tooltip title="Edit">
              <Edit fontSize="small" />
            </Tooltip>
          </Link>
        </>
      )}
      <div
        onClick={showUserInfo}
        className={"marginRight16"}
        style={{ cursor: "pointer" }}
      >
        <Tooltip title="More info">
          <Info />
        </Tooltip>
      </div>
      {DELETE.permissionGranted && (
        <Delete deleteHandler={deletePatientHandler} />
      )}
      <CustomAlert
        open={infoOpen}
        onClose={() => setInfoOpen(false)}
        title={"Detailed Information"}
        Content={() => <PatientDetailedInfo {...item} />}
      />
    </div>
  );
};

export default Action;
