import { FetchAPI } from "../Main/fetchAPI";
import { CLIENTS, CLIENT_USERS } from "../constants";
import {
    AssignClientsToUserProperties,
    AssignUsersToClientProperties,
    ClientUserPaginationProperties,
    DismissClientProperties,
    UpdateDefaultBCBAProperties,
    UserClientPaginationProperties,
} from "./userClientProperties";

export const UserClient = {
    users: {
        getAvailableHoursInfo: (userId: string) => FetchAPI.getJson({
            urlStr: `${CLIENT_USERS}/${userId}/insurance-authorization/available-hours`,
        }),
        getBillableUsers: () => FetchAPI.getJson({
            urlStr: `${CLIENT_USERS}/billable`,
        }),
        getSupervisors: () => FetchAPI.getJson({
            urlStr: `${CLIENT_USERS}/supervisors`,
        }),
        getPatients: (data: UserClientPaginationProperties) => {
            const {
                userId,
                page,
                pageSize,
                direction,
                orderBy,
                searchString,
                status,
            } = data;
            const query = new URLSearchParams();

            if (!!searchString) query.set("searchString", searchString);
            if (!!direction) query.set("direction", direction);
            if (!!orderBy) query.set("orderBy", orderBy);
            if (!!status) query.set("status", status);
            if (!!page) query.set("page", page);
            if (!!pageSize) query.set("pageSize", pageSize);
            if (!!status) query.set("status", status);

            return FetchAPI.getJson({
                urlStr: `${CLIENT_USERS}/${userId}/patients?${query.toString()}`,
            });
        },
        getAvailablePatients: (data: UserClientPaginationProperties) => {
            const {
                userId,
                page,
                pageSize,
                direction,
                orderBy,
                searchString,
                status,
            } = data;
            const query = new URLSearchParams();

            if (!!searchString) query.set("searchString", searchString);
            if (!!direction) query.set("direction", direction);
            if (!!orderBy) query.set("orderBy", orderBy);
            if (!!status) query.set("status", status);
            if (!!page) query.set("page", page);
            if (!!pageSize) query.set("pageSize", pageSize);
            if (!!status) query.set("status", status);

            return FetchAPI.getJson({
                urlStr: `${CLIENT_USERS}/${userId}/patients/available?${query.toString()}`,
            });
        },
        assignClientsToUser: (data: AssignClientsToUserProperties) =>
            FetchAPI.postJson({
                urlStr: `${CLIENT_USERS}/${data.userId}/patients`,
                data,
            }),
        getRBTs: (userId: string) =>
            FetchAPI.getJson({
                urlStr: `${CLIENT_USERS}/${userId}/rbts`,
            }),
    },
    client: {
        getBillingUserOfClient: (clientId: string) => FetchAPI.getJson({
            urlStr: `${CLIENTS}/${clientId}/billing-therapist`,
        }),
        getTherapists: (clientId: string) =>
            FetchAPI.getJson({
                urlStr: `${CLIENTS}/${clientId}/therapists`,
            }),
        assignUsersToClient: (data: AssignUsersToClientProperties) =>
            FetchAPI.postJson({
                urlStr: `${CLIENTS}/${data.clientId}/therapists`,
                data,
            }),
        getAvailableTherapists: (data: ClientUserPaginationProperties) => {
            const {
                clientId,
                page,
                pageSize,
                direction,
                orderBy,
                searchString,
                status,
            } = data;
            const query = new URLSearchParams();

            if (!!searchString) query.set("searchString", searchString);
            if (!!direction) query.set("direction", direction);
            if (!!orderBy) query.set("orderBy", orderBy);
            if (!!status) query.set("status", status);
            if (!!page) query.set("page", page);
            if (!!pageSize) query.set("pageSize", pageSize);

            return FetchAPI.getJson({
                urlStr: `${CLIENTS}/${clientId}/therapists/available?${query.toString()}`,
            });
        },
        getLicensedBCBAs: (data: ClientUserPaginationProperties) => {
            const {
                clientId,
                page,
                pageSize,
                searchString,
            } = data;
            const query = new URLSearchParams();
            query.set("authorizationType", "1")

            if (!!searchString) query.set("searchString", searchString);
            if (!!page) query.set("page", page);
            if (!!pageSize) query.set("pageSize", pageSize);

            return FetchAPI.getJson({
                urlStr: `${CLIENTS}/${clientId}/therapists/bcba/available?${query.toString()}`,
            });
        },
        getAvailableCredentialedBCBAs: (data: ClientUserPaginationProperties) => {
            const {
                clientId,
                page,
                pageSize,
                direction,
                orderBy,
                searchString,
                status,
                authorizationType,
                section,
            } = data;
            const query = new URLSearchParams();
            query.set("credentialed", "true");

            if (!!searchString) query.set("searchString", searchString);
            if (!!direction) query.set("direction", direction);
            if (!!orderBy) query.set("orderBy", orderBy);
            if (!!status) query.set("status", status);
            if (!!page) query.set("page", page);
            if (!!pageSize) query.set("pageSize", pageSize);
            if (!!status) query.set("status", status);
            if (!!authorizationType) query.set("authorizationType", authorizationType);
            if (!!section) query.set("section", section);

            return FetchAPI.getJson({
                urlStr: `${CLIENTS}/${clientId}/therapists/bcba/available?${query.toString()}`,
            });
        },
        getAvailableNotCredentialedBCBAs: (data: ClientUserPaginationProperties) => {
            const {
                clientId,
                page,
                pageSize,
                direction,
                orderBy,
                searchString,
                status,
                authorizationType,
                section,
            } = data;
            const query = new URLSearchParams();
            query.set("credentialed", "false");

            if (!!searchString) query.set("searchString", searchString);
            if (!!direction) query.set("direction", direction);
            if (!!orderBy) query.set("orderBy", orderBy);
            if (!!status) query.set("status", status);
            if (!!page) query.set("page", page);
            if (!!pageSize) query.set("pageSize", pageSize);
            if (!!status) query.set("status", status);
            if (!!authorizationType) query.set("authorizationType", authorizationType);
            if (!!section) query.set("section", section);

            return FetchAPI.getJson({
                urlStr: `${CLIENTS}/${clientId}/therapists/bcba/available?${query.toString()}`,
            });
        },
        getAvailableRBTs: (data: ClientUserPaginationProperties) => {
            const {
                clientId,
                page,
                pageSize,
                direction,
                orderBy,
                searchString,
                status,
                authorizationType,
                section,
            } = data;
            const query = new URLSearchParams();

            if (!!searchString) query.set("searchString", searchString);
            if (!!direction) query.set("direction", direction);
            if (!!orderBy) query.set("orderBy", orderBy);
            if (!!status) query.set("status", status);
            if (!!page) query.set("page", page);
            if (!!pageSize) query.set("pageSize", pageSize);
            if (!!status) query.set("status", status);
            if (!!authorizationType) query.set("authorizationType", authorizationType);
            if (!!section) query.set("section", section);

            return FetchAPI.getJson({
                urlStr: `${CLIENTS}/${clientId}/therapists/rbt/available?${query.toString()}`,
            });
        },
        dissmissClient: (data: DismissClientProperties) =>
            FetchAPI.deleteJson({
                urlStr: `${CLIENTS}/${data.clientId}/therapists/${data.userId}`,
            }),
        getDefaultBCBAOptions: (clientId: string) => FetchAPI.getJson({
            urlStr: `${CLIENTS}/${clientId}/therapists/default-bcba/options`,
        }),
        getDefaultBCBA: (clientId: string) => FetchAPI.getJson({
            urlStr: `${CLIENTS}/${clientId}/therapists/default-bcba`,
        }),
        updateDefaultBCBA: (data: UpdateDefaultBCBAProperties) => FetchAPI.postJson({
            urlStr: `${CLIENTS}/${data.clientId}/therapists/default-bcba`,
            data: data.data
        }),
    },
}