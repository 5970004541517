import { useReducer } from "react";
import { useDispatch } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import { useNavigate } from "react-router-dom";

import { patientReducer } from "./patientReducer";
import { initialAddClientState } from "../patientInitialStates";
import PatientForm from "./patientForm";
import { errorNormalizer } from "../../../../shared/Helpers/functions";

import { DispatchProperties } from "../../../../redux/store";
import { addPatient } from "../../../../redux/State/clientSlice/clientSlice";

const AddPatient = () => {
  const dispatch = useDispatch<DispatchProperties>();
  const navigate = useNavigate();

  const [patientInfo, setPatientInfo] = useReducer(
    patientReducer,
    initialAddClientState
  );

  const addPatientHandler = () => {
    dispatch(addPatient(patientInfo))
      .then(unwrapResult)
      .then(() => navigate(-1))
      .catch(errorNormalizer);
  };

  return (
    <PatientForm
      patientInfo={patientInfo}
      setPatientInfo={setPatientInfo}
      savePatientHandler={addPatientHandler}
    />
  );
};

export default AddPatient;
