import { FetchAPI } from "../Main/fetchAPI";
import { GetBillingProperties, GetTotalBillingProperties } from "./billingProperties";
import { BILLING } from "../constants";

const BILLING_SESSIONS = `${BILLING}/sessions`;
const BILLING_NC_SESSIONS = `${BILLING}/non-credentialed/sessions`;

export const Billing = {
    getSessions: (data: GetBillingProperties) => {
        const {
            page,
            pageSize,
            direction,
            orderBy,
            searchString,
            startDate,
            endDate,
            onlyNew,
            userId,
            clientId,
        } = data;
        const query = new URLSearchParams();

        if (!!direction) query.set("direction", direction);
        if (!!orderBy) query.set("orderBy", orderBy);
        query.set("searchString", searchString);
        query.set("userId", userId);
        query.set("clientId", clientId);
        query.set("startDate", startDate);
        query.set("endDate", endDate);
        query.set("page", page);
        query.set("pageSize", pageSize);
        query.set("onlyNew", `${onlyNew}`);

        return FetchAPI.getJson({
            urlStr: `${BILLING_SESSIONS}/?${query.toString()}`
        })
    },
    getNonCredentialedSessions: (data: GetBillingProperties) => {
        const {
            page,
            pageSize,
            direction,
            orderBy,
            searchString,
            startDate,
            endDate,
            onlyNew,
            userId,
            clientId,
        } = data;
        const query = new URLSearchParams();

        if (!!direction) query.set("direction", direction);
        if (!!orderBy) query.set("orderBy", orderBy);
        query.set("startDate", startDate);
        query.set("clientId", clientId);
        query.set("userId", userId);
        query.set("searchString", searchString);
        query.set("endDate", endDate);
        query.set("page", page);
        query.set("pageSize", pageSize);
        query.set("onlyNew", `${onlyNew}`);

        return FetchAPI.getJson({
            urlStr: `${BILLING_NC_SESSIONS}/?${query.toString()}`
        })
    },
    downloadMBHExcelReport: (data: GetBillingProperties) => {
        const {
            page,
            pageSize,
            direction,
            orderBy,
            searchString,
            startDate,
            endDate,
            onlyNew,
            userId,
            clientId,
        } = data;
        const query = new URLSearchParams();

        if (!!direction) query.set("direction", direction);
        if (!!orderBy) query.set("orderBy", orderBy);
        query.set("searchString", searchString);
        query.set("clientId", clientId);
        query.set("userId", userId);
        query.set("startDate", startDate);
        query.set("endDate", endDate);
        query.set("page", page);
        query.set("pageSize", pageSize);
        query.set("onlyNew", `${onlyNew}`);

        return FetchAPI.postJson({
            urlStr: `${BILLING_SESSIONS}/xlsx?${query.toString()}`
        })
    },
    downloadNCExcelReport: (data: GetBillingProperties) => {
        const {
            page,
            pageSize,
            direction,
            orderBy,
            searchString,
            startDate,
            endDate,
            onlyNew,
            userId,
            clientId,
        } = data;
        const query = new URLSearchParams();

        if (!!direction) query.set("direction", direction);
        if (!!orderBy) query.set("orderBy", orderBy);
        query.set("searchString", searchString);
        query.set("userId", userId);
        query.set("clientId", clientId);
        query.set("startDate", startDate);
        query.set("endDate", endDate);
        query.set("page", page);
        query.set("pageSize", pageSize);
        query.set("onlyNew", `${onlyNew}`);

        return FetchAPI.postJson({
            urlStr: `${BILLING_NC_SESSIONS}/xlsx?${query.toString()}`
        })
    },
    downloadTotalSesions: (data: GetTotalBillingProperties) => {
        const {
            startDate,
            endDate,
            userId,
        } = data;
        const query = new URLSearchParams();

        query.set("startDate", startDate);
        query.set("endDate", endDate);
        if (!!userId) query.set("userId", userId);

        return FetchAPI.getJson({
            urlStr: `${BILLING_NC_SESSIONS}/total/xlsx?${query.toString()}`
        })
    },
}