import { useReducer, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import {
  Link,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import dayjs, { Dayjs } from "dayjs";
import { toast } from "react-toastify";

import AdminForm from "./adminForm";
import { ACTIONS, adminReducer, initialState } from "./adminReducer";
import { errorNormalizer } from "../../../shared/Helpers/functions";
import {
  CustomAccordion,
  CustomAlert,
  CustomButton,
  Picker,
  Text,
} from "../../../shared/uiComponents";
import { AdminContainer, Loader } from "../StyledComponents";
import { blue100 } from "../../../shared/Helpers/colors";

import { DispatchProperties, useSelector } from "../../../redux/store";
import {
  getUser,
  getUserInsurances,
  updateUser,
} from "../../../redux/State/userSlice";
import { assignRole } from "../../../redux/State/identitySlice/userSlice";
import { getUserPatients } from "../../../redux/State/clientSlice/userClientSlice";
import { updateBillingCredentialed } from "../../../redux/State/clientSlice/soapNoteSlice";

const today = new Date();
const month = today.getMonth();
const year = today.getFullYear();
const firstDayOfMonth = new Date(year, month, 1);

const UpdateAdmin = () => {
  const { userId } = useParams();
  const [params] = useSearchParams();

  const dispatch = useDispatch<DispatchProperties>();
  const navigate = useNavigate();
  const loading = useSelector((state) => state.user.loading);

  const [adminInfo, setAdminInfo] = useReducer(adminReducer, initialState);
  const [date, setDate] = useState<Dayjs>(dayjs(firstDayOfMonth));
  const [open, setOpen] = useState<boolean>(false);

  useEffect(() => {
    if (!userId) return;
    dispatch(getUser(userId))
      .then(unwrapResult)
      .then((response) => {
        const {
          firstName,
          lastName,
          fullName,
          email,
          phoneNumber,
          address,
          role,
          authorizationType,
        } = response;
        setAdminInfo({ type: ACTIONS.setFirstName, payload: firstName });
        setAdminInfo({ type: ACTIONS.setLastName, payload: lastName });
        setAdminInfo({ type: ACTIONS.setFullName, payload: fullName });
        setAdminInfo({ type: ACTIONS.setEmail, payload: email });
        setAdminInfo({ type: ACTIONS.setPhoneNumber, payload: phoneNumber });
        setAdminInfo({ type: ACTIONS.setAddress, payload: address });
        setAdminInfo({ type: ACTIONS.setRoleId, payload: role.id });
        setAdminInfo({
          type: ACTIONS.setAuthorizationType,
          payload: authorizationType?.id,
        });
      });
  }, [userId, dispatch]);

  useEffect(() => {
    if (!userId) return;
    dispatch(getUserInsurances(userId))
      .then(unwrapResult)
      .then((response) => {
        if (!response) return;
        const payload = response.map((x) => x.id);
        setAdminInfo({ type: ACTIONS.setInsurances, payload });
      });
  }, [userId, dispatch]);

  const updateAdminInfoHandler = () => {
    if (!userId) return;
    dispatch(
      assignRole({
        roleId: adminInfo.roleId,
        userId,
      })
    )
      .then(unwrapResult)
      .then(() => {
        dispatch(updateUser({ id: userId, data: adminInfo }))
          .then(unwrapResult)
          .then(() => {
            toast("Success");
            setOpen(true);
          })
          .catch(errorNormalizer);
      })
      .finally(() => setOpen(false));
  };

  const dateHandler = (value: Dayjs | null) => {
    if (!value) return;
    setDate(value);
  };

  const updateSoapNotesHandler = () => {
    if (!userId || !date) return;
    const startDate = date.format("MM-DD-YYYY");
    dispatch(updateBillingCredentialed({ userId, startDate }))
      .then(unwrapResult)
      .then(() => toast("Success"))
      .catch(errorNormalizer)
      .finally(() => {
        navigate("/admin/soap-notes");
        setOpen(false);
      });
  };

  return (
    <>
      <AdminForm adminInfo={adminInfo} setAdminInfo={setAdminInfo} />
      <AdminContainer>
        <CustomButton
          title={"Save"}
          onClick={updateAdminInfoHandler}
          disabled={params.get("saveDisabled") === "true" ? true : false}
          className="marginBottom16"
          loading={loading}
        />
      </AdminContainer>
      <CustomAlert
        title="User information change"
        open={open}
        onClose={() => setOpen(false)}
        popupHeight="600px"
        Content={() => (
          <div>
            <Text
              title={
                "Please make sure to set correct Supervising BCBA in all client profiles"
              }
              className="marginBottom16"
            />
            <Text
              title={
                "Please make sure billing information is correct in all client profiles"
              }
              className="marginBottom16"
            />
            <CustomAccordion
              headerStyle={{ padding: 0 }}
              HeaderContent={() => (
                <Text title={"Clients Assigned: "} size={"smallBold"} />
              )}
              Content={() => <PatientListContent />}
            />
            <Picker.CustomDate
              label="Date"
              onChange={dateHandler}
              value={date}
              className="marginBottom16"
            />
            <CustomButton
              title={"Update"}
              onClick={updateSoapNotesHandler}
              className="marginBottom16"
            />
          </div>
        )}
      />
    </>
  );
};

const PatientListContent = () => {
  const { userId } = useParams();
  const dispatch = useDispatch<DispatchProperties>();

  const patients = useSelector((state) => state.userClient.userPatients);
  const loading = useSelector(
    (state) => state.userClient.loadingGetUserPatients
  );
  const [patientsPageSize, setPatientsPageSize] = useState<number>(8);

  useEffect(() => {
    const page = "1";
    if (!userId) return;
    dispatch(
      getUserPatients({ userId, page, pageSize: patientsPageSize.toString() })
    );
  }, [dispatch, userId, patientsPageSize]);

  const navigatePatientPageHandler = () => {};

  return (
    <>
      {!!patients.query?.length && !loading ? (
        <>
          <>
            <Text title={"Client name"} size="tinyBold" />
            {patients.query?.map((patient, index) => (
              <Link
                to={`/admin/patients/patient/${patient.id}`}
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  color: blue100,
                  lineHeight: "20px",
                }}
                key={index}
                onClick={navigatePatientPageHandler}
              >
                {`${index + 1}. ${patient.fullName}`}
              </Link>
            ))}
          </>
          {patients.hasNextPage && (
            <div style={{ justifyContent: "center", display: "flex" }}>
              <Text
                title="Load More"
                size="smallBold"
                onClick={() => setPatientsPageSize((prev) => prev + 8)}
              />
            </div>
          )}
        </>
      ) : (
        <Text title={"None"} />
      )}
      {loading && <Loader />}
    </>
  );
};

export default UpdateAdmin;
