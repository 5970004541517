import { useDispatch } from "react-redux";
import { useParams, useSearchParams } from "react-router-dom";
import { ChangeEvent, useState } from "react";
import { unwrapResult } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import dayjs, { Dayjs } from "dayjs";

import {
  CustomAlert,
  CustomButton,
  CustomInput,
  CustomSelect,
  Picker,
  Text,
} from "../../../../../../shared/uiComponents";
import {
  dateNormalizer,
  errorNormalizer,
  validateDecimalNumber,
} from "../../../../../../shared/Helpers/functions";
import { useCompareDates } from "../../../../../../shared/Helpers/hooks";

import { DispatchProperties, useSelector } from "../../../../../../redux/store";
import { AuthorizationProperties } from "../../../../../../redux/API/ClientAPIHelpers/insuranceAuthorizationProperties";
import {
  getInsuranceAuthorizationSessionTypes,
  getInsuranceAuthorizations,
  saveAssessmentInsuranceAuthorizations,
} from "../../../../../../redux/State/clientSlice/insuranceAuthorizationSlice";

const AssessmentContent = ({ onClose }: { onClose: () => void }) => {
  const dispatch = useDispatch<DispatchProperties>();
  const { clientId } = useParams();
  const [params] = useSearchParams();

  const today = new Date();
  const todayDayjs = dayjs(today);
  const futureDateMonth = dayjs(today).add(1, "month");

  const [startDateValue, setStartDateValue] = useState<Dayjs>(todayDayjs);
  const [endDateValue, setEndDateValue] = useState<Dayjs>(futureDateMonth);
  const [totalHours, setTotalHours] = useState<string>("");

  const sessionTypes = useSelector(
    (state) => state.authorization.insuranceAuthorizationSessionTypes
  );
  const { hasError, message } = useCompareDates(startDateValue, endDateValue);

  const onChangeAssessmentStartDate = (value: Dayjs | null) => {
    if (!value) return;
    setStartDateValue(value);
  };

  const onChangeAssessmentEndDate = (value: Dayjs | null) => {
    if (!value) return;
    setEndDateValue(value);
  };

  const fetchAuthorizations = () => {
    const page = params.get("page") || "1";
    const pageSize = params.get("pageSize") || "8";
    if (!clientId) return;
    dispatch(getInsuranceAuthorizations({ clientId, page, pageSize }));
  };

  const saveAuthorizationAssessment = () => {
    if (!startDateValue || !endDateValue || !clientId) return;

    const startDate = dateNormalizer(startDateValue);
    const endDate = dateNormalizer(endDateValue);

    const data: AuthorizationProperties = {
      startDate,
      endDate,
      authorizationDetails: [
        {
          authorizationSessionTypeId: sessionTypes[0].id,
          totalHours: parseFloat(totalHours),
          frequency: 2,
        },
      ],
    };

    dispatch(saveAssessmentInsuranceAuthorizations({ clientId, data }))
      .then(unwrapResult)
      .then(() => {
        onClose();
        toast("Success");
        fetchAuthorizations();
      })
      .catch(errorNormalizer);
  };

  const onTotalHoursChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    if (!validateDecimalNumber(value.replace(/^0+/, ""))) return;
    setTotalHours(value.replace(/^0+/, ""));
  };

  return (
    <>
      <Text title={"Assessment"} size="mediumBold" className="title" />
      <Picker.CustomDate
        label="Start Date:"
        value={startDateValue}
        onChange={onChangeAssessmentStartDate}
        className="marginBottom16 marginTop16"
      />
      <Picker.CustomDate
        label="End Date:"
        value={endDateValue}
        onChange={onChangeAssessmentEndDate}
        className="marginBottom16"
        errorMessage={message}
        error={hasError}
      />
      <div
        style={{ display: "flex", gap: "16px", alignItems: "center" }}
        className={"marginBottom16"}
      >
        <CustomSelect
          label="Session type"
          data={sessionTypes}
          value={sessionTypes[0]?.id}
          setValue={() => {}}
          disabled={true}
        />
        <CustomInput
          label="Total hours"
          value={totalHours}
          setValue={onTotalHoursChange}
        />
      </div>
      <CustomButton
        title="Save Assessment"
        onClick={saveAuthorizationAssessment}
        disabled={hasError || !totalHours}
      />
    </>
  );
};

const AddAssessmentInsuranceAuthorization = () => {
  const dispatch = useDispatch<DispatchProperties>();
  const [open, setOpen] = useState<boolean>(false);

  const openHandler = () => {
    dispatch(getInsuranceAuthorizationSessionTypes("1"));
    setOpen(true);
  };

  const closeHandler = () => setOpen(false);

  return (
    <>
      <CustomButton title="Add Assessment" onClick={openHandler} />
      <CustomAlert
        open={open}
        onClose={closeHandler}
        popupHeight="550px"
        Content={({ onClose }) => <AssessmentContent onClose={onClose} />}
      />
    </>
  );
};

export default AddAssessmentInsuranceAuthorization;
