import { useState } from "react";
import { Tooltip } from "@mui/material";
import { EqualizerOutlined as Graph } from "@mui/icons-material";

import { CustomAlert } from "../../../../../../../shared/uiComponents";
import GraphBehaviorContent from "./graphBehaviorContent";
import "./graphBehavior";
import { BehaviorReductionProperties } from "../../../../../../../redux/API/ClientAPIHelpers/behaviorReductionProperties";


const GraphBehavior = ({
  data,
  behaviorId,
}: {
  data: BehaviorReductionProperties;
  behaviorId: string;
}) => {
  const [open, setOpen] = useState<boolean>(false);
  const closeHandler = () => setOpen(false);
  return (
    <>
      <Tooltip title="Graph">
        <Graph onClick={() => setOpen(true)} />
      </Tooltip>
      <CustomAlert
        title={data.name}
        open={open}
        onClose={closeHandler}
        Content={() => (
          <GraphBehaviorContent data={data} behaviorId={behaviorId} />
        )}
      />
    </>
  );
};

export default GraphBehavior;
