import {
  FC,
  SetStateAction,
  Dispatch,
  FocusEventHandler,
  useState,
  MouseEvent,
} from "react";
import { FormControl, MenuItem, Select } from "@mui/material";
import { SelectChangeEvent } from "@mui/material/Select";
import { CloseOutlined as Close } from "@mui/icons-material";

import { CustomizedInput } from "../Input";
import Text from "../Text";
import { red500 } from "../../Helpers/colors";

export interface SelectDataProperties {
  id: string | number;
  name?: string;
  text?: string;
  label?: string;
}

interface SelectProperties {
  data: Array<SelectDataProperties> | null;
  setValue: (event: SelectChangeEvent<string>) => void;
  value: string;
  disabled?: boolean;
  label?: string;
  placeholder?: string;
  description?: string;
  error?: boolean;
  errorMessage?: string;
  className?: string;
  loadMore?: {
    activate: boolean;
    setSize: Dispatch<SetStateAction<number>>;
  };
  onBlur?: FocusEventHandler<HTMLInputElement>;
}

const CustomSelect: FC<SelectProperties> = ({
  data,
  value,
  setValue,
  label = "",
  description = "",
  error = false,
  disabled = false,
  errorMessage = "",
  className = "",
  loadMore = {
    activate: false,
  },
  onBlur,
}) => {
  const [open, setOpen] = useState<boolean>(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCloseButton = (event: MouseEvent<SVGSVGElement>) => {
    event.stopPropagation();
    setOpen(false);
  };

  return (
    <div className={className}>
      <FormControl variant="standard" style={{ width: "100%" }}>
        <Text
          title={label}
          className={`inputLabel ${!!label ? "active" : ""}`}
          size={"tinyBold"}
        />
        <Select
          open={open}
          value={value}
          onClose={handleClose}
          onOpen={handleOpen}
          onChange={setValue}
          input={<CustomizedInput error={error} />}
          disabled={disabled}
          onBlur={onBlur}
          MenuProps={{
            style: {
              maxWidth: "350px",
            },
          }}
        >
          {open && (
            <MenuItem
              style={{ position: "absolute", top: 0, right: 0, zIndex: 1500 }}
            >
              <Close fontSize="small" onClick={handleCloseButton} />
            </MenuItem>
          )}
          {!!data && !!data.length ? (
            data.map((item, index) => (
              <MenuItem key={index} value={item.id}>
                <Text
                  title={
                    item.name ?? (item.text as string) ?? (item.label as string)
                  }
                />
              </MenuItem>
            ))
          ) : (
            <MenuItem style={{ justifyContent: "center" }}>
              <Text title="No Data" size="smallBold" />
            </MenuItem>
          )}
          {loadMore.activate && (
            <MenuItem
              style={{ justifyContent: "center" }}
              onClick={() => loadMore.setSize((prev) => prev + 8)}
            >
              <Text title="Load More" size="smallBold" />
            </MenuItem>
          )}
        </Select>
        <Text
          className={`error ${error ? "active" : ""}`}
          title={errorMessage}
          size={"tiny"}
          textColor={red500}
        />
        <Text
          className={`inputDescription ${
            !error && !!description ? "active" : ""
          }`}
          title={description}
          size={"tiny"}
        />
      </FormControl>
    </div>
  );
};

export default CustomSelect;
