import { FetchAPI } from "../Main/fetchAPI";
import { CLIENTS, PROGRAM_TARGET } from "../constants";
import {
    AddTargetSteps,
    CreateProgramTargetProperties,
    GetClientTargetsProperties,
    GetTargetProperties,
    MoveTargetProperties,
    UpdateTargetProperties,
} from "./programTargetsProperties";

export const ProgramTargets = {
    getTargets: (programId: string) =>
        FetchAPI.getJson({
            urlStr: PROGRAM_TARGET(programId),
        }),
    getHistory: ({ id, programId }: GetTargetProperties) =>
        FetchAPI.getJson({
            urlStr: `${PROGRAM_TARGET(programId)}/${id}/history`,
        }),
    getMoveOptions: ({ id, programId }: GetTargetProperties) =>
        FetchAPI.getJson({
            urlStr: `${PROGRAM_TARGET(programId)}/${id}/move/options`,
        }),
    createTarget: (programId: string, data: CreateProgramTargetProperties) =>
        FetchAPI.postJson({
            urlStr: PROGRAM_TARGET(programId),
            data,
        }),
    copyTarget: ({ id, programId }: GetTargetProperties) =>
        FetchAPI.postJson({
            urlStr: `${PROGRAM_TARGET(programId)}/${id}/copy`,
        }),
    updateTarget: ({ id, programId, data }: UpdateTargetProperties) =>
        FetchAPI.putJson({
            urlStr: `${PROGRAM_TARGET(programId)}/${id}`,
            data,
        }),
    deleteTarget: ({ id, programId }: GetTargetProperties) =>
        FetchAPI.deleteJson({
            urlStr: `${PROGRAM_TARGET(programId)}/${id}`,
        }),
    moveTarget: ({ id, programId, toProgramId }: MoveTargetProperties) =>
        FetchAPI.postJson({
            urlStr: `${PROGRAM_TARGET(programId)}/${id}/move`,
            data: { toProgramId },
        }),
    openTarget: ({ id, programId }: GetTargetProperties) =>
        FetchAPI.postJson({
            urlStr: `${PROGRAM_TARGET(programId)}/${id}/open`,
        }),
    holdTarget: ({ id, programId }: GetTargetProperties) =>
        FetchAPI.postJson({
            urlStr: `${PROGRAM_TARGET(programId)}/${id}/hold`,
        }),
    masterOutTarget: ({ id, programId }: GetTargetProperties) =>
        FetchAPI.postJson({
            urlStr: `${PROGRAM_TARGET(programId)}/${id}/master-out`,
        }),
    getTargetSteps: ({ id, programId }: GetTargetProperties) =>
        FetchAPI.getJson({
            urlStr: `${PROGRAM_TARGET(programId)}/${id}/steps`,
        }),
    addTargetSteps: ({ id, programId, data }: AddTargetSteps) =>
        FetchAPI.postJson({
            urlStr: `${PROGRAM_TARGET(programId)}/${id}/steps`,
            data,
        }),
    getInTreatmentTargets: (programId: string) =>
        FetchAPI.getJson({
            urlStr: `${PROGRAM_TARGET(programId)}/in-treatment`,
        }),
    client: {
        getClientTargets: (data: GetClientTargetsProperties) => {
            const { clientId, page, pageSize } = data;
            const query = new URLSearchParams();

            if (!!page) query.set("page", page);
            if (!!pageSize) query.set("pageSize", pageSize);
            // query.set("searchString", !!searchString ? searchString : "");

            return FetchAPI.getJson({
                urlStr: `${CLIENTS}/${clientId}/targets?${query.toString()}`,
            })
        },
        importTargets: (clientId: string, file: any) => {
            const data = new FormData();
            data.append("spreadsheet", file);
            return FetchAPI.post({
                urlStr: `${CLIENTS}/${clientId}/targets/import`,
                data,
            });
        },
    }
}