import dayjs from "dayjs";
import { useSearchParams } from "react-router-dom";

import {
    black500,
    blue100,
    red100,
    white100,
    white200
} from "../../../../shared/Helpers/colors";
import { sessionTypeColors } from "./constants";
import {
    DayProperties,
    FindColorProperties,
    FindColorReturnProperties,
} from "./interfaces";
import { EventDataProperties } from "../../../../redux/API/ClientAPIHelpers/calendarProperties";

export const isDevelopment = process.env.NODE_ENV === 'development';

export const sortByTime = ({
    a,
    b,
}: {
    a: EventDataProperties;
    b: EventDataProperties;
}): number => {
    const c = new Date(a.startTime).getHours();
    const d = new Date(b.startTime).getHours();
    if (c > d) return 1;
    if (c < d) return -1;
    return 0;
};

export const findDateRange = (days: Array<DayProperties>) => {
    const start = days[0];
    const end = days[days.length - 1];

    const startDate = dayjs(new Date(start.year, start.month, start.day)).format("MM-DD-YYYY").split("T")[0];
    const endDate = dayjs(new Date(end.year, end.month, end.day)).format("MM-DD-YYYY").split("T")[0];

    return { startDate, endDate }
}

export const findColor = ({
    item,
    index,
    screenWidth,
}: FindColorProperties): FindColorReturnProperties => {

    if (!item) {
        return { backgroundColor: white200, color: black500 };
    }

    if (item.status !== 1) {
        return { backgroundColor: blue100, color: white100 };
    }

    if (index % 7 === 0) {
        return { backgroundColor: red100, color: black500 };
    }

    if (screenWidth < 821) {
        return { backgroundColor: white100, color: black500 };
    }

    return { backgroundColor: white200, color: black500 };
};

export const isPastTime = (date: Date, allowedAfterDate: Date) => {
    return date >= allowedAfterDate;
}

export const findDuration = (start: string, end: string) => {

    if (dayjs(end).isBefore(start)) return 'Incorrect time range';
    const startDate = new Date(start);
    const endDate = new Date(end);

    const startHour = startDate.getHours();
    const endHour = endDate.getHours();
    const startMinute = startDate.getMinutes();
    const endMinute = endDate.getMinutes();

    let hours = endHour - startHour;
    let minutes = endMinute - startMinute;

    if (minutes < 0) {
        hours -= 1;
        minutes += 60;
    }

    if (hours === 0) {
        return `${minutes} minutes`;
    }

    if (hours === 1) {
        return `${hours} hour ${minutes > 0 ? `${minutes} minutes` : ''}`;
    }
    
    return `${hours} hours ${minutes > 0 ? `${minutes} minutes` : ''}`;
};

export const findEventColor = (sessionType: number) => sessionTypeColors.find(
    (x) => x.type === sessionType
)?.color

export const useShowRBTsSessions = (id: string, userId: string) => {
    const [params] = useSearchParams();
    const includeRbtEvents = params.get('includeRbtEvents');
    if (!includeRbtEvents) return 1;
    return includeRbtEvents === 'true' && userId === id ? 0.5 : 1;
}