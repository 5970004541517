import { useDispatch, useSelector } from "react-redux";
import { Navigate, Outlet, useNavigate } from "react-router-dom";

import { DispatchProperties, StateProperties } from "../redux/store";
import { AdminTypes } from "../components/Action";

import API from "../redux/API/Main/API";
import { resetState } from "../redux/resetState";
import { getToken } from "../redux/API/Main/helpers";

export const ProtectedRoutes = () => {
  const token = getToken();

  if (!token) {
    return <Navigate to="/login" />;
  }
  const parsedToken = JSON.parse(token);
  API.setToken(parsedToken.access_token);

  return <Outlet />;
};

export const AdminChecker = ({ children }: { children: any }) => {
  const role = useSelector((state: StateProperties) => state.account.role);

  const isAdmin = !!role && role.section.id === AdminTypes.administrator;

  if (!isAdmin && !!role) {
    return <Navigate to={"/bcba/dashboard"} />;
  }

  return children;
};

export const BCBAChecker = ({ children }: { children: any }) => {
  const role = useSelector((state: StateProperties) => state.account.role);

  const isBCBA = !!role && role.section.id === AdminTypes.bcba;
  const isAdmin = !!role && role.section.id === AdminTypes.administrator;

  if (!isBCBA && !isAdmin && !!role) {
    return <Navigate to={"/rbt/dashboard"} />;
  }

  return children;
};

export const RBTChecker = ({ children }: { children: any }) => {
  const dispatch = useDispatch<DispatchProperties>();
  const navigate = useNavigate();

  const role = useSelector((state: StateProperties) => state.account.role);

  const isRBT = !!role && role.section.id === AdminTypes.rbt;
  const isSuperAdmin =
    !!role &&
    role.section.id === AdminTypes.administrator &&
    role.name === "super-admin";

  if (!isRBT && !isSuperAdmin && !!role) {
    dispatch(resetState());
    navigate("/login");
    return;
  }

  return children;
};

export const LoginChecker = () => {
  const token = getToken();

  if (!!token) {
    return <Navigate to="/bcba" />;
  }

  return <Outlet />;
};
