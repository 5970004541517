import { styled } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import dayjs from "dayjs";

import {
  CustomButton,
  PatientStatusBadge,
  Text,
} from "../../../../shared/uiComponents";
import { AdminTypes } from "../../../../components/Action";

import { clearActiveAuthorization } from "../../../../redux/State/clientSlice/insuranceAuthorizationSlice";
import {
  clearPatients,
  clearTherapists,
} from "../../../../redux/State/clientSlice/clientSlice";
import { DispatchProperties, useSelector } from "../../../../redux/store";

const Wrapper = styled("div")(() => ({
  paddingRight: "16px",
  paddingBottom: "16px",
}));

const PatientInfo = () => {
  const dispatch = useDispatch<DispatchProperties>();
  const patientInfo = useSelector((state) => state.client.patient);
  const role = useSelector((state) => state.account.role);

  const navigate = useNavigate();

  const navigateAssignTherapistHandler = () => {
    navigate(
      `/admin/patients/${patientInfo.id}/assign/${patientInfo.fullName}`
    );
    dispatch(clearActiveAuthorization());
    dispatch(clearTherapists());
    dispatch(clearPatients());
  };

  const navigateAllocateHours = () => {
    dispatch(clearActiveAuthorization());
    navigate(`/admin/patients/${patientInfo.id}/insurance-authorization`);
  };

  return (
    <Wrapper>
      <Text
        title={patientInfo.fullName}
        size="largeBold"
        className="marginBottom8"
      />
      <Text
        title={`Date of birth: ${dayjs(patientInfo.dateOfBirthday).format(
          "MM-DD-YYYY"
        )}`}
        className="marginBottom8"
      />
      <Text
        title={`Address: ${patientInfo.address}`}
        className="marginBottom8"
      />
      {patientInfo.insurance && (
        <Text
          title={`Insurance: ${patientInfo.insurance.name}`}
          className="marginBottom8"
        />
      )}
      {patientInfo.insuranceNumber && (
        <Text
          title={`Insurance: ${patientInfo.insuranceNumber}`}
          className="marginBottom8"
        />
      )}
      <div className="marginLeft16 marginBottom16">
        <PatientStatusBadge statusCode={patientInfo.status.id} />
      </div>
      {role?.section.id === AdminTypes.administrator && (
        <>
          <CustomButton
            title="Assign Provider"
            onClick={navigateAssignTherapistHandler}
            className="marginTop16"
            disabled={!patientInfo.id}
          />
          <CustomButton
            title="Allocate hours"
            secondaryButton
            onClick={navigateAllocateHours}
            className="marginTop16"
            disabled={!patientInfo.id}
          />
        </>
      )}
    </Wrapper>
  );
};

export default PatientInfo;
