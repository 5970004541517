import { CreateEventProperties, EventDataProperties } from "./calendarProperties";

export interface SessionDataProperties extends CreateEventProperties { }

export enum TargetSessionStatuses {
    "new",
    "open",
    "active",
    "hidden",
}

export interface ActiveSessionTargetsProperties {
    id: string;
    name: string;
    targetInstructions: string;
    typeId: number;
    typeName: string;
    programId: string;
    programName: string;
    trials: number;
    independent: number;
    prompt: number;
    correctPercentage: number;
    targetSessionStatus: TargetSessionStatuses,
    orderId: number;
}

export interface UpdateSessionProperties {
    id: string;
    date: string;
    startTime: string;
    endTime: string;
}

export interface SupervisedSessionInfoProperties extends EventDataProperties {
    location: { id: string, name: string, locationType: number }
}
