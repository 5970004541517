import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { unwrapResult } from "@reduxjs/toolkit";
import dayjs, { Dayjs } from "dayjs";
import { SelectChangeEvent, styled } from "@mui/material";

import { months } from "../Helpers/constants";
import { DayProperties } from "../Helpers/interfaces";
import { AdminTypes } from "../../../../components/Action";
import {
  dateNormalizer,
  errorNormalizer,
} from "../../../../shared/Helpers/functions";
import {
  CustomAlert,
  CustomSelect,
  Picker,
  CustomButton,
} from "../../../../shared/uiComponents";
import { SelectDataProperties } from "../../../../shared/uiComponents/Dropdown";
import { useCompareDates } from "../../../../shared/Helpers/hooks";
import { useResetCalendarData } from "./hooks";

import { DispatchProperties, useSelector } from "../../../../redux/store";
import { setAddEvent } from "../../../../redux/State/clientSlice/calendarSlice";
import { createEvent } from "../../../../redux/State/clientSlice/calendarSlice";
import { CreateEventProperties } from "../../../../redux/API/ClientAPIHelpers/calendarProperties";
import { getUserPatients } from "../../../../redux/State/clientSlice/userClientSlice";

export const TimeInputsWrapper = styled("div")(() => ({
  display: "flex",
  justifyContent: "space-between",
  flexWrap: "wrap",
  "@media (max-width: 525px)": {
    flexDirection: "column",
    "&>div": {
      marginBottom: "16px",
    },
  },
}));

const AddEvent = () => {
  const dispatch = useDispatch<DispatchProperties>();

  const open = useSelector((state) => state.calendar.popups.add);
  const selectedDay = useSelector((state) => state.calendar.selectedDay);

  const closeHandler = () => {
    dispatch(setAddEvent(false));
  };

  return (
    <CustomAlert
      open={open}
      onClose={closeHandler}
      title={`Add calendar event to ${months[selectedDay.month]} ${
        selectedDay.day
      } ${selectedDay.year}`}
      Content={() => (
        <AddContent selectedDay={selectedDay} onClose={closeHandler} />
      )}
    />
  );
};

const AddContent = ({
  selectedDay,
  onClose,
}: {
  selectedDay: DayProperties;
  onClose: () => void;
}) => {
  const dispatch = useDispatch<DispatchProperties>();
  const { resetData } = useResetCalendarData();

  const [pageSize, setPageSize] = useState<number>(8);
  const [sessionTypeId, setSessionTypeId] = useState<string>("0");
  const [patientId, setPatientId] = useState<string>("0");
  const [patientList, setPatientList] = useState<Array<SelectDataProperties>>(
    []
  );
  const [startTimeValue, setStartTimeValue] = useState<Dayjs | null>(null);
  const [endTimeValue, setEndTimeValue] = useState<Dayjs | null>(null);
  const [sessionTypeDisabled, setSessionTypeDisabled] =
    useState<boolean>(false);

  const sessionTypes = useSelector((state) => state.session.sessionTypes);
  const userId = useSelector((state) => state.account.user.id);
  const role = useSelector((state) => state.account.role);
  const userPatients = useSelector((state) => state.userClient.userPatients);
  const days = useSelector((state) => state.calendar.displayedCalendarPageDays);

  const { hasError, message } = useCompareDates(startTimeValue, endTimeValue);

  useEffect(() => {
    if (!userId) return;
    dispatch(
      getUserPatients({ userId, page: "1", pageSize: pageSize.toString() })
    );
  }, [userId, pageSize, dispatch]);

  useEffect(() => {
    if (!userPatients.query) return;
    const patientList = userPatients.query.map((patient) => ({
      id: patient.id as string,
      name: `${patient.firstName} ${patient.lastName}`,
    }));
    if (!patientList.length) {
      setPatientId("");
      return;
    }
    setPatientList([{ id: "0", name: "Select Client" }, ...patientList]);
  }, [userPatients]);

  const onPatientChange = (event: SelectChangeEvent<string>) => {
    const value = event.target.value;
    setPatientId(value);
  };

  const onTypeChange = (event: SelectChangeEvent<string>) => {
    const value = event.target.value;
    setSessionTypeId(value);
  };

  useEffect(() => {
    if (!!startTimeValue) {
      setEndTimeValue(startTimeValue.add(1, "h"));
    }
  }, [startTimeValue]);

  const addSession = () => {
    const { day, month, year } = selectedDay;
    if (!startTimeValue || !endTimeValue || !days.length) return;
    const hour = startTimeValue.hour();
    const minute = startTimeValue.minute();
    const dateValue = new Date(year, month, day, hour, minute);
    const startTime = dayjs(dateValue).format("YYYY-MM-DDTHH:mm");

    const endHour = endTimeValue.hour();
    const endMinute = endTimeValue.minute();
    const endTime = dayjs(
      new Date(year, month, day, endHour, endMinute)
    ).format("YYYY-MM-DDTHH:mm");

    if (!startTime || !endTime) return;

    const data: CreateEventProperties = {
      clientId: patientId,
      date: dateNormalizer(dayjs(dateValue)),
      startTime,
      endTime,
      sessionTypeId: parseInt(sessionTypeId),
    };

    dispatch(createEvent(data))
      .then(unwrapResult)
      .then(() => {
        resetData();
        toast("Created");
        onClose();
      })
      .catch(errorNormalizer);
  };

  useEffect(() => {
    if (!role || role.section.id !== AdminTypes.rbt) return;
    setSessionTypeDisabled(true);
    setSessionTypeId("3");
  }, [role]);

  return (
    <>
      <CustomSelect
        label="Client"
        data={patientList}
        value={patientId}
        setValue={onPatientChange}
        className={"marginBottom16"}
        loadMore={{
          activate: !!userPatients.totalNumberOfItems
            ? userPatients.totalNumberOfItems > 8
            : false,
          setSize: setPageSize,
        }}
      />
      {!!sessionTypes && (
        <CustomSelect
          label="Session type"
          data={sessionTypes}
          value={sessionTypeId}
          setValue={onTypeChange}
          className={"marginBottom16"}
          disabled={sessionTypeDisabled}
        />
      )}
      <TimeInputsWrapper className="marginBottom16">
        <Picker.CustomTime
          label="Start time"
          value={startTimeValue}
          onChange={setStartTimeValue}
          // error={!!error}
          // errorMessage={error}
        />
        <Picker.CustomTime
          label="End time"
          value={endTimeValue}
          onChange={setEndTimeValue}
          error={hasError}
          errorMessage={message}
        />
      </TimeInputsWrapper>
      <CustomButton
        onClick={addSession}
        title={"Add calendar event"}
        disabled={
          !startTimeValue ||
          !endTimeValue ||
          !patientId ||
          hasError ||
          patientId === "0" ||
          sessionTypeId === "0"
        }
      />
    </>
  );
};

export default AddEvent;
