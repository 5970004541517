import {
  Dispatch,
  FC,
  useState,
  SetStateAction,
  useEffect,
  FocusEventHandler,
  MouseEvent,
} from "react";
import Chip from "@mui/material/Chip";
import { FormControl, MenuItem, Select } from "@mui/material";
import { SelectChangeEvent } from "@mui/material/Select";
import { CloseOutlined as Close } from "@mui/icons-material";

import { CustomizedInput } from "../Input";
import Text from "../Text";
import { red500 } from "../../Helpers/colors";
import { SelectDataProperties } from ".";

interface MultipleSelectProperties {
  data: Array<SelectDataProperties> | null;
  setValue: (event: SelectChangeEvent<string[]>) => void;
  onBlur?: FocusEventHandler<HTMLInputElement>;
  disabled?: boolean;
  label?: string;
  placeholder?: string;
  description?: string;
  error?: boolean;
  errorMessage?: string;
  className?: string;
  loadMore?: {
    activate: boolean;
    setSize: Dispatch<SetStateAction<number>>;
  };
  initialValue?: Array<string>;
}

export const MultipleSelect: FC<MultipleSelectProperties> = ({
  data,
  setValue,
  onBlur,
  label = "",
  description = "",
  error = false,
  errorMessage = "",
  className = "",
  loadMore = {
    activate: false,
  },
  initialValue = [],
  disabled,
}) => {
  const [open, setOpen] = useState<boolean>(false);
  const [values, setValues] = useState<string[]>([]);

  const handleChange = (event: SelectChangeEvent<string[]>) => {
    const { value } = event.target;
    if (!value || typeof value === "string") return;
    const filteredValues = value.filter((x) => !!x);
    setValues(filteredValues);
    const filteredEvent = {
      ...event,
      target: {
        ...event.target,
        value: filteredValues,
      },
    };
    setValue(filteredEvent as SelectChangeEvent<string[]>);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCloseButton = (
    event: MouseEvent<SVGSVGElement | HTMLLIElement>
  ) => {
    event.stopPropagation();
    setOpen(false);
  };

  useEffect(() => {
    if (!initialValue || !initialValue.length) return;
    setValues(initialValue);
  }, [initialValue]);

  return (
    <div className={className}>
      <FormControl variant="standard" style={{ width: "100%" }}>
        <Text
          title={label}
          className={`inputLabel ${!!label ? "active" : ""}`}
          size={"tinyBold"}
        />
        <Select
          multiple
          open={open}
          onClose={handleClose}
          onOpen={handleOpen}
          value={values}
          onChange={handleChange}
          onBlur={onBlur}
          disabled={disabled}
          input={
            <CustomizedInput
              disabled={disabled}
              error={error}
              style={{ height: "max-content" }}
            />
          }
          renderValue={(selected) => (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              {selected.map((value) => {
                if (!data) return null;
                if (!value)
                  return (
                    <Chip
                      key={value}
                      label={"N/A"}
                      className={"marginBottom8"}
                      style={{ width: "max-content" }}
                    />
                  );
                return (
                  <Chip
                    key={value}
                    label={
                      data.find((x) => x.id === value)?.name ??
                      data.find((x) => x.id === value)?.text
                    }
                    className={"marginBottom8"}
                    style={{ width: "max-content" }}
                  />
                );
              })}
            </div>
          )}
        >
          {open && (
            <MenuItem
              onClick={handleCloseButton}
              style={{ position: "absolute", top: 0, right: 0, zIndex: 1500 }}
            >
              <Close fontSize="small" onClick={handleCloseButton} />
            </MenuItem>
          )}
          {!!data &&
            data.map((item, index) => (
              <MenuItem key={index} value={item.id}>
                {item.name ?? item.text}
              </MenuItem>
            ))}
          {loadMore.activate && (
            <MenuItem style={{ justifyContent: "center" }}>
              <Text
                title="Load More"
                size="smallBold"
                onClick={() => loadMore.setSize((prev) => prev + 8)}
              />
            </MenuItem>
          )}
        </Select>
        <Text
          className={`error ${error ? "active" : ""}`}
          title={errorMessage}
          size={"tiny"}
          textColor={red500}
        />
        <Text
          className={`inputDescription ${
            !error && !!description ? "active" : ""
          }`}
          title={description}
          size={"tiny"}
        />
      </FormControl>
    </div>
  );
};

export default MultipleSelect;
