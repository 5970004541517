import { FC, useEffect } from "react";
import { black500, blue400 } from "../../Helpers/colors";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { styled } from "@mui/material";
import Text, { sizes } from "../Text";
import { useSearchParams } from "react-router-dom";

const CustomTab = styled(Tab)(() => ({
  color: black500,
  "&.Mui-selected": {
    color: blue400,
  },
}));

const CustomTabs: FC<{
  headers: Array<string>;
  panels: Array<FC>;
  headerFontSize?: sizes;
}> = ({ headers, panels, headerFontSize = "smallBold" }) => {
  const [params, setParams] = useSearchParams();

  const setTabType = (value: string) => {
    const resetParams = new URLSearchParams();
    resetParams.set("tab", value);
    setParams(resetParams);
  };

  useEffect(() => {
    const tab = params.get("tab");
    if (!!tab || !headers || !headers.length) return;
    params.set("tab", headers[0]);
    setParams(params);
  }, [params, setParams, headers]);

  return (
    <div>
      <Tabs value={params.get("tab")}>
        {!!headers &&
          headers.map((header, index) => (
            <CustomTab
              key={index}
              label={
                <Text
                  title={header}
                  size={headerFontSize}
                  onClick={() => setTabType(header)}
                  textColor={params.get("tab") === header ? blue400 : black500}
                />
              }
              id={`${index}`}
            />
          ))}
      </Tabs>
      {!!panels &&
        panels.map((Panel, index) => (
          <div
            key={index}
            hidden={headers.indexOf(params.get("tab") as string) !== index}
            id={`${index}`}
          >
            {headers.indexOf(params.get("tab") as string) === index && (
              <Panel />
            )}
          </div>
        ))}
    </div>
  );
};

export default CustomTabs;
