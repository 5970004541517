import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";
import { CircularProgress, SelectChangeEvent, styled } from "@mui/material";
import { toast } from "react-toastify";
import { unwrapResult } from "@reduxjs/toolkit";

import {
  CustomAlert,
  CustomButton,
  CustomSelect,
  Text,
} from "../../../../shared/uiComponents";
import { black500, blue400, red300 } from "../../../../shared/Helpers/colors";
import Delete from "../../../../components/Delete";
import { AdminTypes } from "../../../../components/Action";
import { errorNormalizer } from "../../../../shared/Helpers/functions";

import { DispatchProperties, useSelector } from "../../../../redux/store";
import {
  dismissPatient,
  getDefaultBCBA,
  getDefaultBCBAOptions,
  getTherapists,
  updateDefaultBCBA,
} from "../../../../redux/State/clientSlice/userClientSlice";
import { TherapistProperties } from "../../../../redux/API/ClientAPIHelpers/clientProperties";
import {
  DismissClientProperties,
  UpdateDefaultBCBAProperties,
} from "../../../../redux/API/ClientAPIHelpers/userClientProperties";

const Wrapper = styled("div")(() => ({
  display: "flex",
  flexDirection: "column",
  "@media (max-width: 1024px)": {
    flexDirection: "row",
    justifyContent: "space-around",
  },
  "@media (max-width: 425px)": {
    flexDirection: "column",
  },
}));

const PatientTherapists = () => {
  const dispatch = useDispatch<DispatchProperties>();
  const { clientId } = useParams();
  const therapists = useSelector((state) => state.userClient.therapists);

  const [open, setOpen] = useState<boolean>(false);
  const [value, setValue] = useState<number>(0);
  const [bcbas, setBcbas] = useState<Array<TherapistProperties>>([]);
  const [rbts, setRbts] = useState<Array<TherapistProperties>>([]);

  useEffect(() => {
    if (!clientId) return;
    dispatch(getTherapists(clientId));
  }, [clientId, dispatch]);

  useEffect(() => {
    if (!therapists) return;
    const bcbas = therapists.filter(
      (therapist) => therapist.role.section.id === AdminTypes.bcba
    );
    const rbts = therapists.filter(
      (therapist) => therapist.role.section.id === AdminTypes.rbt
    );
    setBcbas(bcbas);
    setRbts(rbts);
  }, [therapists]);

  if (!therapists) {
    return (
      <Wrapper>
        <div style={{ width: "max-content", margin: "auto" }}>
          <CircularProgress />
        </div>
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <div style={{ display: "flex", gap: "8px" }}>
        {["All", "BCBA", "RBT"].map((x, index) => (
          <Text
            key={index}
            title={x}
            size={"mediumBold"}
            onClick={() => setValue(index)}
            textColor={value === index ? blue400 : black500}
            className="paddingLeft8 paddingRight8"
          />
        ))}
      </div>
      {[therapists, bcbas, rbts].map((users, index) => (
        <div hidden={value !== index} key={index}>
          <TherapistsList therapists={users} />
        </div>
      ))}
      <CustomButton
        title="Set Default BCBA"
        onClick={() => setOpen(true)}
        className="marginTop8 marginBottom8"
      />
      <CustomAlert
        open={open}
        title="Update default BCBA"
        onClose={() => setOpen(false)}
        Content={() => <DefaultBCBAContent />}
      />
    </Wrapper>
  );
};

const TherapistsList = ({
  therapists,
}: {
  therapists: Array<TherapistProperties>;
}) => {
  const dispatch = useDispatch<DispatchProperties>();
  const { clientId } = useParams();
  // const [params] = useSearchParams();

  // const fetchPatients = () => {
  //   const page = params.get("page") || "1";
  //   const pageSize = params.get("pageSize") || "8";
  //   const searchString = params.get("search") || "";
  //   const orderBy = params.get("orderBy") || "";
  //   const direction = params.get("direction") || "";
  //   const status = params.get("status");

  //   dispatch(
  //     getPatients({ page, pageSize, searchString, orderBy, direction, status })
  //   );
  // };

  const dismissHandler = ({ clientId, userId }: DismissClientProperties) => {
    if (!clientId || !userId) return;
    dispatch(dismissPatient({ clientId, userId }))
      .then(unwrapResult)
      .then(() => {
        toast("Removed");
        dispatch(getTherapists(clientId));
        // fetchPatients();
      })
      .catch(errorNormalizer);
  };

  return (
    <div className="paddingTop8">
      {!!therapists &&
        therapists.map((therapist, index) => (
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Text
              key={index}
              title={therapist.fullName}
              textColor={
                therapist.role.section.id === AdminTypes.bcba
                  ? red300
                  : therapist.role.section.id === AdminTypes.rbt
                  ? blue400
                  : black500
              }
              size="tinyBold"
            />
            {!!therapist.id && !!clientId && (
              <Delete
                deleteHandler={() =>
                  dismissHandler({
                    clientId: clientId,
                    userId: therapist.id,
                  })
                }
              />
            )}
          </div>
        ))}
    </div>
  );
};

const DefaultBCBAContent = () => {
  const dispatch = useDispatch<DispatchProperties>();
  const { clientId } = useParams();
  const [userId, setUserId] = useState<string>("");

  const error = useSelector((state) => state.userClient.error);
  const defaultBCBA = useSelector((state) => state.userClient.defaultBCBA);
  const defaultBCBAoptions = useSelector(
    (state) => state.userClient.defaultBCBAOptions
  );

  const updateDefaultBCBAHandler = () => {
    if (!userId || !clientId) return;
    const data: UpdateDefaultBCBAProperties = {
      clientId,
      data: { userId },
    };
    dispatch(updateDefaultBCBA(data))
      .then(unwrapResult)
      .then(() => {
        toast("Success");
      })
      .catch(errorNormalizer);
  };

  const selectDefaultBCBAHandler = (event: SelectChangeEvent<string>) => {
    const { value } = event.target;
    if (!value) return;
    setUserId(value);
  };

  useEffect(() => {
    if (!defaultBCBA) return;
    setUserId(defaultBCBA.id);
  }, [defaultBCBA]);

  useEffect(() => {
    if (!clientId) return;
    dispatch(getDefaultBCBA(clientId));
    dispatch(getDefaultBCBAOptions(clientId));
  }, [clientId, dispatch]);

  return (
    <div>
      <CustomSelect
        value={userId}
        setValue={selectDefaultBCBAHandler}
        data={defaultBCBAoptions.map((x) => ({ id: x.id, label: x.fullName }))}
        error={!!error.title}
        errorMessage={error.title}
        className="marginBottom16"
      />
      <CustomButton
        title="Save"
        onClick={updateDefaultBCBAHandler}
        disabled={defaultBCBA?.id === userId}
      />
    </div>
  );
};

export default PatientTherapists;
