import { ChangeEvent, MouseEvent, useState } from "react";
import { useDispatch } from "react-redux";
import { Done } from "@mui/icons-material";
import { unwrapResult } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { useSearchParams } from "react-router-dom";

import {
  CustomInput,
  CustomButton,
  CustomAlert,
  Text,
} from "../../../../../shared/uiComponents";
import {
  black500,
  blue100,
  red200,
  white100,
} from "../../../../../shared/Helpers/colors";
import { errorNormalizer } from "../../../../../shared/Helpers/functions";

import { DispatchProperties } from "../../../../../redux/store";
import {
  archiveSkillArea,
  copySkillArea,
  deleteSkillArea,
  getSkillAreas,
  unArchiveSkillArea,
  updateSkillAreas,
} from "../../../../../redux/State/clientSlice/skillAreaSlice";
import {
  SkillAreaProperties,
  SkillProperties,
} from "../../../../../redux/API/ClientAPIHelpers/skillAreaProperties";

const SkillAreaMenu = ({ item }: { item: SkillProperties }) => {
  const dispatch = useDispatch<DispatchProperties>();
  const [params, setParams] = useSearchParams();
  const choosenId = params.get("skillAreaId");

  const [name, setName] = useState<string>(item.name);
  const [menu, setMenu] = useState<boolean>(false);
  const [rename, setRename] = useState<boolean>(false);
  const [copy, setCopy] = useState<boolean>(false);
  const [deleteSkillAreaOpen, setDeleteSkillAreaOpen] =
    useState<boolean>(false);

  const onRightClick = (event: MouseEvent<HTMLDivElement>) => {
    event.preventDefault();
    setMenu(true);
  };

  const renameHandler = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setName(value);
  };

  const onRenameClick = () => {
    setMenu(false);
    setRename(true);
  };

  const onCopyClick = () => {
    setMenu(false);
    setCopy(true);
  };
  const onDeleteClick = () => {
    setMenu(false);
    setDeleteSkillAreaOpen(true);
  };

  const refreshSkillAreas = () => {
    const { clientId } = item;
    const includeArchived =
      !!params.get("includeArchived") &&
      params.get("includeArchived") === "true";

    if (!clientId) return;
    dispatch(getSkillAreas({ clientId, includeArchived }));
  };

  const updateNameHandler = () => {
    const { clientId, id } = item;
    const data: SkillAreaProperties = {
      clientId,
      name,
    };
    dispatch(updateSkillAreas({ data, id }))
      .then(unwrapResult)
      .then(() => {
        setMenu(false);
        setRename(false);
        refreshSkillAreas();
      })
      .catch(errorNormalizer);
  };

  const chooseSkillAreaHandler = (id: string) => {
    if (!id) return;
    params.set("skillAreaId", id);
    setParams(params);
    setMenu(false);
  };

  const copySkillAreaHandler = () => {
    const { clientId, id } = item;

    if (!clientId || !id) return;

    dispatch(copySkillArea({ clientId, id }))
      .then(unwrapResult)
      .then(() => {
        refreshSkillAreas();
        setCopy(false);
      })
      .catch(errorNormalizer);
  };

  const deleteSkillAreaHandler = () => {
    const { clientId, id } = item;

    if (!clientId || !id) return;

    dispatch(deleteSkillArea({ clientId, id }))
      .then(unwrapResult)
      .then(() => {
        refreshSkillAreas();
        params.delete("skillAreaId");
        setParams(params);
        setDeleteSkillAreaOpen(false);
      })
      .catch(errorNormalizer);
  };

  const onArchiveClick = () => {
    setMenu(false);
    const { clientId, id } = item;
    if (!clientId || !id) return;
    dispatch(archiveSkillArea({ clientId, id }))
      .then(unwrapResult)
      .then(() => {
        refreshSkillAreas();
        toast("Success");
      })
      .catch(errorNormalizer);
  };

  const onUnarchiveClick = () => {
    setMenu(false);
    const { clientId, id } = item;
    if (!clientId || !id) return;
    dispatch(unArchiveSkillArea({ clientId, id }))
      .then(unwrapResult)
      .then(() => {
        refreshSkillAreas();
        toast("Success");
      })
      .catch(errorNormalizer);
  };

  return (
    <div
      onContextMenu={onRightClick}
      onClick={() => chooseSkillAreaHandler(item.id)}
      className={"padding8 margin8 lightBlackBorder"}
      style={{
        position: "relative",
        borderBottom: choosenId === item.id ? `2px solid ${blue100}` : "none",
      }}
    >
      {rename ? (
        <CustomInput
          value={name}
          setValue={renameHandler}
          EndAdornment={{
            Icon: Done,
            onClickEndAdornment: updateNameHandler,
          }}
        />
      ) : (
        <Text
          title={item.name}
          size="tinyBold"
          textColor={
            choosenId === item.id
              ? blue100
              : item.isArchived
              ? red200
              : black500
          }
          onClick={() => {}}
        />
      )}
      <div
        style={{
          position: "absolute",
          display: menu && choosenId === item.id ? "block" : "none",
          top: "100%",
          left: "50%",
          backgroundColor: white100,
          border: "1px solid black",
          marginTop: "10px",
          padding: "10px",
          zIndex: 100,
          width: "90px",
        }}
        onClick={() => setMenu(false)}
        onMouseLeave={() => setMenu(false)}
      >
        <Text title="Rename" onClick={onRenameClick} />
        <Text title="Delete" onClick={onDeleteClick} />
        <Text title="Copy" onClick={onCopyClick} />
        {!item.isArchived ? (
          <Text title="Archive" onClick={onArchiveClick} />
        ) : (
          <Text title="Unarchive" onClick={onUnarchiveClick} />
        )}
      </div>
      <CustomAlert
        open={copy}
        onClose={() => setCopy(false)}
        title={"Do you want to copy?"}
        Content={() => {
          return (
            <div style={{ display: "flex", justifyContent: "space-around" }}>
              <CustomButton
                title="Yes"
                className="marginRight8"
                onClick={copySkillAreaHandler}
              />
              <CustomButton
                title="No"
                secondaryButton
                onClick={() => setCopy(false)}
              />
            </div>
          );
        }}
      />
      <CustomAlert
        open={deleteSkillAreaOpen}
        onClose={() => setDeleteSkillAreaOpen(false)}
        title={"Do you want to delete?"}
        Content={() => {
          return (
            <div style={{ display: "flex", justifyContent: "space-around" }}>
              <CustomButton
                title="Yes"
                className="marginRight8"
                onClick={deleteSkillAreaHandler}
              />
              <CustomButton
                title="No"
                secondaryButton
                onClick={() => setDeleteSkillAreaOpen(false)}
              />
            </div>
          );
        }}
      />
    </div>
  );
};

export default SkillAreaMenu;
