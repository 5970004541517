import { useState } from "react";
import { DeleteOutlineOutlined } from "@mui/icons-material";
import { Tooltip } from "@mui/material";
import { useDispatch } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";

import {
  CustomAlert,
  CustomButton,
} from "../../../../../../shared/uiComponents";
import { ButtonWrapper } from "../../../../../AdminPanel/StyledComponents";
import { errorNormalizer } from "../../../../../../shared/Helpers/functions";

import { DispatchProperties } from "../../../../../../redux/store";
import {
  deleteBehavior,
  getBehaviors,
} from "../../../../../../redux/State/clientSlice/behaviorReductionSlice";
import { DeleteClientBehaviorsProperties } from "../../../../../../redux/API/ClientAPIHelpers/behaviorReductionProperties";

const DeleteBehavior = ({
  data,
}: {
  data: DeleteClientBehaviorsProperties;
}) => {
  const [open, setOpen] = useState<boolean>(false);
  const closeHandler = () => setOpen(false);

  return (
    <>
      <Tooltip title="Delete">
        <DeleteOutlineOutlined onClick={() => setOpen(true)} />
      </Tooltip>
      <CustomAlert
        title="Are you sure you want to delete?"
        open={open}
        onClose={closeHandler}
        Content={() => <DeleteContent data={data} onClose={closeHandler} />}
      />
    </>
  );
};

const DeleteContent = ({
  data,
  onClose,
}: {
  data: DeleteClientBehaviorsProperties;
  onClose: () => void;
}) => {
  const dispatch = useDispatch<DispatchProperties>();

  const deleteHandler = () => {
    dispatch(deleteBehavior(data))
      .then(unwrapResult)
      .then(() => {
        dispatch(getBehaviors(data.clientId));
        onClose();
      })
      .catch(errorNormalizer);
  };
  return (
    <ButtonWrapper>
      <CustomButton title="Yes" onClick={deleteHandler} />
      <CustomButton title="Cancel" secondaryButton />
    </ButtonWrapper>
  );
};
export default DeleteBehavior;
