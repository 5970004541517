import { blue100, blue400 } from "../Helpers/colors";

interface SVGProperties {
  disabled?: boolean;
}

const CheckboxIcon = ({ disabled = false }: SVGProperties) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="0.5"
      y="0.5"
      width="19"
      height="19"
      rx="3.5"
      fill={disabled ? "#DFDDDD" : "#FCFCFC"}
    />
    <rect
      x="0.5"
      y="0.5"
      width="19"
      height="19"
      rx="3.5"
      stroke={disabled ? "#DFDDDD" : "#BBBBBC"}
    />
  </svg>
);

const CheckboxChecked = ({ disabled = false }: SVGProperties) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="20" height="20" rx="4" fill={disabled ? "#DFDDDD" : blue400} />
    <path
      d="M14.8945 5.04688L7.70312 12.2383L5.07812 9.58594C4.94141 9.47656 4.72266 9.47656 4.61328 9.58594L3.82031 10.3789C3.71094 10.4883 3.71094 10.707 3.82031 10.8438L7.48438 14.4805C7.62109 14.6172 7.8125 14.6172 7.94922 14.4805L16.1523 6.27734C16.2617 6.16797 16.2617 5.94922 16.1523 5.8125L15.3594 5.04688C15.25 4.91016 15.0312 4.91016 14.8945 5.04688Z"
      fill="white"
    />
  </svg>
);

const CheckboxIndeterminate = ({ disabled = false }: SVGProperties) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="20" height="20" rx="4" fill={disabled ? "#DFDDDD" : blue400} />
    <path
      d="M14.8125 8.875H5.1875C4.94141 8.875 4.75 9.09375 4.75 9.3125V10.1875C4.75 10.4336 4.94141 10.625 5.1875 10.625H14.8125C15.0312 10.625 15.25 10.4336 15.25 10.1875V9.3125C15.25 9.09375 15.0312 8.875 14.8125 8.875Z"
      fill="white"
    />
  </svg>
);

const CheckIcon = ({ color = "#3C3C3F" }) => (
  <svg
    width="16"
    height="12"
    viewBox="0 0 16 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.5938 0.625L5.375 8.84375L2.375 5.8125C2.21875 5.6875 1.96875 5.6875 1.84375 5.8125L0.9375 6.71875C0.8125 6.84375 0.8125 7.09375 0.9375 7.25L5.125 11.4062C5.28125 11.5625 5.5 11.5625 5.65625 11.4062L15.0312 2.03125C15.1562 1.90625 15.1562 1.65625 15.0312 1.5L14.125 0.625C14 0.46875 13.75 0.46875 13.5938 0.625Z"
      fill={color}
    />
  </svg>
);

const RadioIcon = ({ disabled = false }: SVGProperties) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="0.5"
      y="0.5"
      width="19"
      height="19"
      rx="9.5"
      fill={disabled ? "#DFDDDD" : "#FCFCFC"}
    />
    <rect x="0.5" y="0.5" width="19" height="19" rx="9.5" stroke="#DFDDDD" />
  </svg>
);

const RadioIconChecked = ({ disabled = false }: SVGProperties) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="0.5" y="0.5" width="19" height="19" rx="9.5" fill="white" />
    <circle cx="10" cy="10" r="6" fill={disabled ? blue100 : blue400} />
    <rect
      x="0.5"
      y="0.5"
      width="19"
      height="19"
      rx="9.5"
      stroke={disabled ? blue100 : blue400}
    />
  </svg>
);

const Icons = {
  CheckboxIcon,
  CheckboxChecked,
  CheckboxIndeterminate,
  CheckIcon,
  RadioIcon,
  RadioIconChecked,
};

export default Icons;
