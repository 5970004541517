import { useState } from "react";
import { useDispatch } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import { SelectChangeEvent } from "@mui/material";
import { AddOutlined as Add } from "@mui/icons-material";

import { InfoRow, Wrapper } from "../AdminPanel/StyledComponents";
import { CustomSelect, Text } from "../../shared/uiComponents";

import { DispatchProperties, useSelector } from "../../redux/store";
import { setTargets } from "../../redux/State/clientSlice/sessionSlice";
import { TargetSessionStatuses } from "../../redux/API/ClientAPIHelpers/sessionsProperties";
import {
  ClientTargetProperties,
  TargetTypes,
  UpdateTargetStepsProperties,
} from "../../redux/API/ClientAPIHelpers/programTargetsProperties";
import { getTargetSteps } from "../../redux/State/clientSlice/programTargetSlice";

const SessionData = () => {
  const dispatch = useDispatch<DispatchProperties>();

  const [targetId, setTargetId] = useState<string>("");
  const [target, setTarget] = useState<ClientTargetProperties | null>(null);
  const [steps, setSteps] = useState<Array<UpdateTargetStepsProperties> | null>(
    null
  );
  const activeClientTargets = useSelector(
    (state) => state.session.activeClientTargets
  );
  const allTargets = useSelector((state) => state.session.targets);

  const setTargetHandler = (event: SelectChangeEvent<string>) => {
    const { value } = event.target;
    setTargetId(value);
    const target = activeClientTargets.find((x) => x.id === value);
    if (!target) return;
    if (target.type.id === TargetTypes.TA) {
      dispatch(getTargetSteps({ id: target.id, programId: target.programId }))
        .then(unwrapResult)
        .then((response) => setSteps(response));
    }
    setTarget(target);
  };

  const addTargetHandler = () => {
    if (!target) return;
    const orderId = allTargets.filter(
      (x) => x.targetSessionStatus !== TargetSessionStatuses.new
    ).length;
    const newTargets = allTargets.map((x) => {
      if (x.id === target.id) {
        return {
          ...x,
          targetSessionStatus: TargetSessionStatuses.open,
          orderId,
          steps,
        };
      }
      return x;
    });
    dispatch(setTargets(newTargets));
    setTarget(null);
  };

  return (
    <Wrapper style={{ maxWidth: "760px" }}>
      <div style={{ display: "flex", alignItems: "center" }}>
        <div style={{ width: "100%" }}>
          <CustomSelect
            label={"Target:"}
            className={"marginBottom8"}
            data={allTargets.filter(
              (x) => x.targetSessionStatus === TargetSessionStatuses.new
            )}
            value={targetId}
            setValue={setTargetHandler}
          />
        </div>
        <Add className="margin8" fontSize="large" onClick={addTargetHandler} />
      </div>
      {!!target && (
        <>
          <InfoRow>
            <Text title={"Program:"} size="tinyBold" />
            <Text title={target.program.name} />
          </InfoRow>
          <InfoRow>
            <Text title={"Target type:"} size="tinyBold" />
            <Text title={target.type.name} />
          </InfoRow>
          <InfoRow>
            <Text title={"Target instructions:"} size="tinyBold" />
            <Text title={target.targetInstructions} />
          </InfoRow>
          <InfoRow>
            <Text title={"SD:"} size="tinyBold" />
            <Text title={target.sd} />
          </InfoRow>
        </>
      )}
    </Wrapper>
  );
};

export default SessionData;
