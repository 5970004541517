import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { unwrapResult } from "@reduxjs/toolkit";
import { SelectChangeEvent } from "@mui/material";
import dayjs, { Dayjs } from "dayjs";

import {
  CustomAlert,
  CustomButton,
  CustomSelect,
  Picker,
} from "../../../../shared/uiComponents";
import { SelectDataProperties } from "../../../../shared/uiComponents/Dropdown";
import { TimeInputsWrapper } from "./addEvent";
import {
  dateNormalizer,
  errorNormalizer,
} from "../../../../shared/Helpers/functions";
import { AdminTypes } from "../../../../components/Action";

import { DispatchProperties, useSelector } from "../../../../redux/store";
import { SessionDataProperties } from "../../../../redux/API/ClientAPIHelpers/sessionsProperties";
import { getUserPatientsStartSession } from "../../../../redux/State/clientSlice/userClientSlice";
import { startSession } from "../../../../redux/State/clientSlice/sessionSlice";
import { useResetCalendarData } from "./hooks";

const StartSession = () => {
  const [open, setOpen] = useState<boolean>(false);

  const closeHandler = () => {
    setOpen(false);
  };

  return (
    <div>
      <CustomButton
        title="Start session"
        onClick={() => setOpen(true)}
        className="marginRight16"
      />
      <CustomAlert
        open={open}
        onClose={closeHandler}
        title={"Add session"}
        Content={() => <StartSessionContent onClose={closeHandler} />}
      />
    </div>
  );
};

const StartSessionContent = ({ onClose }: { onClose: () => void }) => {
  const dispatch = useDispatch<DispatchProperties>();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { resetData } = useResetCalendarData();

  const today = new Date();
  const todayDayjs = dayjs(today);

  const userId = useSelector((state) => state.account.user.id);
  const sessionTypes = useSelector((state) => state.session.sessionTypes);
  const role = useSelector((state) => state.account.role);

  const [patientList, setPatientList] = useState<Array<SelectDataProperties>>(
    []
  );
  const [clientId, setClientId] = useState<string>("0");
  const [endTimeValue, setEndTimeValue] = useState<Dayjs>(
    todayDayjs.add(1, "hour")
  );
  const [pageSize, setPageSize] = useState<number>(8);
  const [sessionTypeId, setSessionTypeId] = useState<string>("0");
  const [sessionTypeDisabled, setSessionTypeDisabled] =
    useState<boolean>(false);

  const onPatientChange = (event: SelectChangeEvent<string>) => {
    const value = event.target.value;
    setClientId(value);
  };

  const onTypeChange = (event: SelectChangeEvent<string>) => {
    const value = event.target.value;
    setSessionTypeId(value);
  };

  const onEndDateChange = (date: Dayjs | null) => {
    if (!date) return;
    setEndTimeValue(date);
  };

  const startSessionHandler = () => {
    if (!clientId || !sessionTypeId) return;

    const date = dateNormalizer(todayDayjs);

    const data: SessionDataProperties = {
      clientId,
      date,
      endTime: endTimeValue?.format("YYYY-MM-DDTHH:mm"),
      sessionTypeId: parseInt(sessionTypeId),
      startTime: todayDayjs.format("YYYY-MM-DDTHH:mm"),
    };
    dispatch(startSession(data))
      .then(unwrapResult)
      .then((response) => {
        if (!response.id || !response.client.id) return;
        onClose();
        resetData();
        navigate(
          `${pathname}/start-session/${response.id}/${response.client.id}`,
          {
            state: response,
          }
        );
      })
      .catch(errorNormalizer);
  };

  useEffect(() => {
    if (!userId) return;
    dispatch(
      getUserPatientsStartSession({
        userId,
        page: "1",
        pageSize: pageSize.toString(),
      })
    )
      .then(unwrapResult)
      .then((response) => {
        const { query } = response;
        if (!query) return;
        const patientList = query.map((patient) => ({
          id: patient.id as string,
          name: `${patient.firstName} ${patient.lastName}`,
        }));
        if (!patientList.length) {
          setClientId("");
          return;
        }
        setPatientList([{ id: "0", name: "Select Client" }, ...patientList]);
      })
      .catch(errorNormalizer);
  }, [userId, pageSize, dispatch]);

  useEffect(() => {
    if (!role || role.section.id !== AdminTypes.rbt) return;
    setSessionTypeDisabled(true);
    setSessionTypeId("3");
  }, [role]);

  return (
    <>
      <CustomSelect
        label="Client"
        data={patientList}
        value={clientId}
        setValue={onPatientChange}
        className={"marginBottom16"}
        loadMore={{
          activate: true,
          setSize: setPageSize,
        }}
      />
      {!!sessionTypes && (
        <CustomSelect
          label="Session type"
          data={sessionTypes}
          value={sessionTypeId}
          setValue={onTypeChange}
          className={"marginBottom16"}
          disabled={sessionTypeDisabled}
        />
      )}
      <Picker.CustomDate
        label={"Date of session"}
        onChange={() => {}}
        value={todayDayjs}
        className={"marginBottom16"}
        disabled={true}
      />
      <TimeInputsWrapper className="marginBottom16">
        <Picker.CustomTime
          label="Start time"
          value={todayDayjs}
          onChange={() => {}}
          disabled={true}
        />
        <Picker.CustomTime
          label="End time"
          value={endTimeValue}
          onChange={onEndDateChange}
        />
      </TimeInputsWrapper>

      <CustomButton
        onClick={startSessionHandler}
        title={"Start session"}
        disabled={
          !endTimeValue ||
          !clientId ||
          clientId === "0" ||
          sessionTypeId === "0"
        }
      />
    </>
  );
};
export default StartSession;
