import { useState } from "react";
import AllocationDetails from "./allocationDetails";
import {
  CustomAlert,
  CustomButton,
} from "../../../../../../shared/uiComponents";
import { useSearchParams } from "react-router-dom";

const AllocateHours = ({ therapistId }: { therapistId: string }) => {
  const [open, setOpen] = useState<boolean>(false);
  const [params] = useSearchParams();
  return (
    <>
      <CustomButton
        title="Allocate hours"
        onClick={() => setOpen(true)}
        className="margin16"
        disabled={!therapistId || !params.get("authId")}
      />
      <CustomAlert
        open={open}
        onClose={() => setOpen(false)}
        Content={({ onClose }) => (
          <AllocationDetails therapistId={therapistId} onClose={onClose} />
        )}
      />
    </>
  );
};

export default AllocateHours;
