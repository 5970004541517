import { FC } from "react";
import { useDispatch } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { TableBody as Body, TableCell, TableRow } from "@mui/material";

import {
  Text,
  TableNoData,
  PatientStatusBadge,
} from "../../../shared/uiComponents";
import {
  HeaderProperties,
  TableDataProperties,
} from "../../../shared/uiComponents/Table/tableProperties";
import { blue400 } from "../../../shared/Helpers/colors";
import Action from "../../AdminPanel/Patients/actions";

import { DispatchProperties } from "../../../redux/store";
import { InfoClientProperties } from "../../../redux/API/ClientAPIHelpers/clientProperties";
import { ProgramDataProperties } from "../../../redux/API/ClientAPIHelpers/clientProgramProperties";
import { clearSkillAreas } from "../../../redux/State/clientSlice/skillAreaSlice";

export interface RowRendererProperties {
  data: Array<InfoClientProperties>;
}

export interface ProgramRowRendererProperties {
  data: Array<ProgramDataProperties>;
}

export interface PatientsDataProperties extends TableDataProperties {
  query: Array<InfoClientProperties> | null;
}

export const Headers: Array<HeaderProperties> = [
  { id: "0", name: "Full Name", orderBy: "fullName" },
  { id: "1", name: "Email", orderBy: "email" },
  { id: "2", name: "Provider", orderBy: "numberOfTherapist" },
  { id: "3", name: "Parent Phone", orderBy: "parentPhoneNumber" },
  {
    id: "4",
    name: "Status",
    orderBy: "status",
    titlesHeader: "Select Status:",
    titles: ["Pending", "Active", "Paused", "Discharged", "All"], //all keyword is for reseting column
  },
  { id: "5", name: "Actions", width: "120px" },
];

export const initialState: InfoClientProperties = {
  id: "",
  firstName: "",
  lastName: "",
  numberOfTherapist: 0,
  dateOfBirthday: "",
  gender: "",
  email: "",
  parentPhoneNumber: "",
  address: "",
  insuranceNumber: "",
  city: "",
  zip: "",
  patronymic: "",
  status: {
    id: 0,
    name: "",
  },
  fullName: "",
  defaultBcba: {
    id: "",
    fullName: "",
    firstName: "",
    lastName: "",
    email: "",
    role: {
      id: "",
      name: "",
      section: {
        id: 0,
        name: "",
      },
    },
    authorizationType: {
      id: 0,
      name: "",
    },
  },
  insurance: {
    id: "",
    name: "",
  },
  diagnosisCode: {
    id: "",
    name: "",
  },
};

export const TableBody: FC<RowRendererProperties> = ({ data }) => {
  const dispatch = useDispatch<DispatchProperties>();
  const { pathname } = useLocation();

  const navigatePatientHandler = () => {
    dispatch(clearSkillAreas());
  };

  if (!data || !data.length) {
    return <TableNoData spanScope={Headers.length} />;
  }

  return (
    <>
      <Body>
        {data.map((row, index) => (
          <TableRow key={index}>
            <TableCell padding="none">
              <Link to={`${pathname}/patient/${row.id}`}>
                <Text
                  title={row.fullName}
                  textColor={blue400}
                  size="tiny"
                  onClick={navigatePatientHandler}
                  className="paddingLeft16"
                />
              </Link>
            </TableCell>
            <TableCell>{row.email}</TableCell>
            <TableCell>{row.numberOfTherapist}</TableCell>
            <TableCell>{row.parentPhoneNumber}</TableCell>
            <TableCell>
              <PatientStatusBadge statusCode={row.status.id} />
            </TableCell>
            <TableCell>
              <Action item={row} />
            </TableCell>
          </TableRow>
        ))}
      </Body>
    </>
  );
};
