import { SOAPNoteWrapper } from "../helpers";
import CreateSoapNote from "./createDirectSupervision";
import UpdateSoapNote from "./updateDirectSupervision";

const DirectSupervision = () => (
  <SOAPNoteWrapper
    CreateSoapNote={CreateSoapNote}
    UpdateSoapNote={UpdateSoapNote}
  />
);

export default DirectSupervision;
