import { useEffect, useReducer } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { unwrapResult } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

import BehaviorTreatmentData from "./behaviorTreatmentData";
import { AdminTypes } from "../../../../../components/Action";
import { SaveBehaviorTreatmentProperties } from "../../SoapNoteTypesProperties/behaviorTreatmentTypeProperties";
import { errorNormalizer } from "../../../../../shared/Helpers/functions";
import { useResetCalendarData } from "../../../Calendar/Popups/hooks";
import {
  SOAP_NOTES,
  SaveSoapNote,
  SoapNoteButtons,
  hasAnyData,
  isActionAllowed,
  removeSavedSoapNoteData,
} from "../helpers";
import {
  ACTIONS,
  behaviorTreatmentReducer,
  initialBehaviorTreatmentState,
} from "./behaviorTreatmentReducer";

import { DispatchProperties, useSelector } from "../../../../../redux/store";
import { saveBehaviorTreatment } from "../../../../../redux/State/clientSlice/soapNoteSlice";

const CreateSoapNote = () => {
  const dispatch = useDispatch<DispatchProperties>();
  const [params] = useSearchParams();
  const navigate = useNavigate();
  const { resetData } = useResetCalendarData();

  const role = useSelector((state) => state.account.role);
 
  const sessionInfo = useSelector((state) => state.session.sessionInfo);
  const defaultBcba = useSelector((state) => state.client.patient.defaultBcba);

  const isRBT = !!role && role.section.id === AdminTypes.rbt;
  const paramsStatus = params.get("soapNoteStatus");
  const soapNoteStatus = !!paramsStatus ? parseInt(paramsStatus) : 0;

  const [data, setData] = useReducer(
    behaviorTreatmentReducer,
    initialBehaviorTreatmentState
  );

  useEffect(() => {
    const sessionId = params.get("sessionId");
    const notes = window.localStorage.getItem(SOAP_NOTES);
    const soapNotes: Array<any> = notes ? JSON.parse(notes) : [];
    const savedData = soapNotes.find((x) => x.sessionId === sessionId);

    if (!sessionId) return;
    if (!hasAnyData(data) && !!savedData) {
      setData({
        type: ACTIONS.setState,
        payload: savedData,
      });
      return;
    }

    const { date, startTime, endTime, user, client } = sessionInfo;
    const allData = {
      ...data,
      sessionId,
      date,
      startTime,
      endTime,
      printedName: user.fullName.toUpperCase(),
      submit: false,
      client,
    };
    SaveSoapNote(allData);
  }, [data, params, sessionInfo]);

  useEffect(() => {
    if (!defaultBcba || !defaultBcba.id) return;
    setData({ type: ACTIONS.setSupervisorId, payload: defaultBcba.id });
    setData({ type: ACTIONS.setSupervisor, payload: defaultBcba });
  }, [defaultBcba]);

  const createSoapNoteHandler = (submit: boolean) => {
    const sessionId = params.get("sessionId");
    if (!sessionId || !data) return;
    const { date, startTime, endTime, user, client } = sessionInfo;
    const allData: SaveBehaviorTreatmentProperties = {
      ...data,
      date,
      startTime,
      endTime,
      sessionId,
      printedName: user.fullName.toUpperCase(),
      submit,
    };
    dispatch(
      saveBehaviorTreatment({
        clientId: client.id,
        data: allData,
      })
    )
      .then(unwrapResult)
      .then(() => {
        resetData();
        toast("Success");
        removeSavedSoapNoteData(sessionId);
        if (isRBT) {
          navigate("/rbt/calendar");
          return;
        }
        navigate("/bcba/calendar");
      })
      .catch(errorNormalizer);
  };

  return (
    <div>
      <BehaviorTreatmentData
        data={data}
        setData={setData}
        disabled={!isActionAllowed(soapNoteStatus)}
      />
      <SoapNoteButtons
        status={soapNoteStatus}
        save={() => createSoapNoteHandler(false)}
        submit={() => createSoapNoteHandler(true)}
      />
    </div>
  );
};

export default CreateSoapNote;
