import { useEffect, FC } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useSearchParams } from "react-router-dom";
import { TableBody as Body, TableCell, TableRow } from "@mui/material";
import { unwrapResult } from "@reduxjs/toolkit";
import dayjs from "dayjs";

import { errorNormalizer } from "../../../../shared/Helpers/functions";
import { Container, Header } from "../../../AdminPanel/StyledComponents";
import { CustomTable, Text } from "../../../../shared/uiComponents";
import { HeaderProperties } from "../../../../shared/uiComponents/Table/tableProperties";

import { SoapNotesFullProperties } from "../../../../redux/API/ClientAPIHelpers/soapNoteProperties";
import { getClientSoapNotes } from "../../../../redux/State/clientSlice/soapNoteSlice";
import { DispatchProperties, StateProperties } from "../../../../redux/store";

export interface RowRendererProperties {
  data: Array<SoapNotesFullProperties>;
}

export const Headers: Array<HeaderProperties> = [
  { id: "0", name: "Name" },
  { id: "1", name: "Type" },
  { id: "2", name: "Created by" },
  { id: "3", name: "Created date" },
  {
    id: "4",
    name: "Status",
    orderBy: "status",
  },
  { id: "5", name: "Actions", width: "120px" },
];

export const TableBody: FC<RowRendererProperties> = ({ data }) => {
  return (
    <>
      <Body>
        {data.map((row, index) => (
          <TableRow key={index}>
            <TableCell>{`${row.client.fullName}`}</TableCell>
            <TableCell>{row.sessionType.name}</TableCell>
            <TableCell>{`${row.createdBy.firstName} ${row.createdBy.lastName}`}</TableCell>
            <TableCell>{dayjs(row.createdAt).format("MM/DD/YYYY")}</TableCell>
            <TableCell>{row.status.name}</TableCell>
            <TableCell></TableCell>
          </TableRow>
        ))}
      </Body>
    </>
  );
};

const PatientSoapNotes = () => {
  const [params] = useSearchParams();
  const { clientId } = useParams();

  const dispatch = useDispatch<DispatchProperties>();

  const clientSoapNotes = useSelector(
    (state: StateProperties) => state.soapNote.clientSoapNotes
  );

  useEffect(() => {
    const page = params.get("page") || "1";
    const pageSize = params.get("pageSize") || "12";
    if (!clientId) return;

    dispatch(getClientSoapNotes({ clientId, page, pageSize }))
      .then(unwrapResult)
      .catch(errorNormalizer);
  }, [params, clientId, dispatch]);

  return (
    <Container>
      <Header>
        <Text title={"SOAP Notes"} size="mediumBold" />
      </Header>
      <CustomTable
        headers={Headers}
        data={clientSoapNotes}
        TableBody={TableBody}
        loading={false}
      />
    </Container>
  );
};

export default PatientSoapNotes;
