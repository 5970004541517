import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import API from "../../API/Main/API";
import {
  GraphDateProperties,
  AnnotationPeriodDateProperties,
  TargetGraphProperties,
  BehaviorGraphProperties,
} from "../../API/ClientAPIHelpers/graphProperties";
import {
  AnnotationBehaviorDate,
  BehaviorsAnnotationsProperties,
  GraphBehaviorDurationDate,
  GraphBehaviorFrequencyDate,
  TargetAnnotationProperties,
} from "../../API/ClientAPIHelpers/graphAnnotationProperties";
import { ErrorProperties } from "../../API/identityAPIProperties";

interface GraphStateProperties {
  data: Array<GraphDateProperties>;
  dataPeriodAnnotations: Array<AnnotationPeriodDateProperties>;
  loading: boolean;
  behaviorFGraph: Array<GraphBehaviorFrequencyDate>;
  behaviorDGraph: Array<GraphBehaviorDurationDate>;
  error: ErrorProperties;
}

const initialState: GraphStateProperties = {
  loading: false,
  data: [],
  dataPeriodAnnotations: [],
  behaviorDGraph: [],
  behaviorFGraph: [],
  error: {
    status: 0,
    title: "",
  },
};

export const getDTTGraph = createAsyncThunk(
  "/targets/dtt",
  async (data: TargetGraphProperties, thunkAPI) => {
    const response = await API.ClientAPI.Graph.targets.getDTTGraph(data);
    if (!!response.error) {
      return thunkAPI.rejectWithValue(response.error);
    }
    return response as Array<GraphDateProperties>;
  }
);

export const getTAGraph = createAsyncThunk(
  "/targets/ta",
  async (data: TargetGraphProperties, thunkAPI) => {
    const response = await API.ClientAPI.Graph.targets.getTAGraph(data);
    if (!!response.error) {
      return thunkAPI.rejectWithValue(response.error);
    }
    return response as Array<GraphDateProperties>;
  }
);

export const getTargetAnnotations = createAsyncThunk(
  "target/annotations",
  async (data: TargetAnnotationProperties, thunkAPI) => {
    const response = await API.ClientAPI.Graph.targets.getTargetAnnotations(data);
    if (!!response.error) {
      return thunkAPI.rejectWithValue(response.error);
    }
    return response as Array<AnnotationPeriodDateProperties>;
  }
);

export const getTargetNotes = createAsyncThunk(
  "/target/notes",
  async (targetId: string, thunkAPI) => {
    const response = await API.ClientAPI.Graph.targets.getTargetNotes(targetId);
    if (!!response.error) {
      return thunkAPI.rejectWithValue(response.error);
    }
    return response as { notes: string };
  }
);

export const updateTargetNotes = createAsyncThunk(
  "/target/notes/update",
  async ({ targetId, data }: { targetId: string, data: { notes: string } }, thunkAPI) => {
    const response = await API.ClientAPI.Graph.targets.updateTargetNotes(targetId, data);
    if (!!response.error) {
      return thunkAPI.rejectWithValue(response.error);
    }
    return response;
  }
);

export const getBehaviorNotes = createAsyncThunk(
  "/behavior/notes",
  async (behaviorId: string, thunkAPI) => {
    const response = await API.ClientAPI.Graph.behaviors.getBehaviorNotes(behaviorId);
    if (!!response.error) {
      return thunkAPI.rejectWithValue(response.error);
    }
    return response as { notes: string };
  }
);

export const updateBehaviorNotes = createAsyncThunk(
  "/behavior/notes/update",
  async ({ behaviorId, data }: { behaviorId: string, data: { notes: string } }, thunkAPI) => {
    const response = await API.ClientAPI.Graph.behaviors.updateBehaviorNotes(behaviorId, data);
    if (!!response.error) {
      return thunkAPI.rejectWithValue(response.error);
    }
    return response;
  }
);

export const getBehaviorAnnotations = createAsyncThunk(
  "/behavior/period/annotations",
  async (data: BehaviorsAnnotationsProperties, thunkAPI) => {
    const response = await API.ClientAPI.Graph.behaviors.getBehaviorsAnnotations(data);
    if (!!response.error) {
      return thunkAPI.rejectWithValue(response.error);
    }
    return response as Array<AnnotationBehaviorDate>;
  }
);

export const getBehaviorFrequencyGraph = createAsyncThunk(
  "/behavior/frequency/graph",
  async (data: BehaviorGraphProperties, thunkAPI) => {
    const response =
      await API.ClientAPI.Graph.behaviors.getBehaviorFrequencyGraph(
        data
      );
    if (!!response.error) {
      return thunkAPI.rejectWithValue(response.error);
    }
    return response as Array<GraphBehaviorFrequencyDate>;
  }
);

export const getBehaviorDurationGraph = createAsyncThunk(
  "/behavior/duration/graph",
  async (data: BehaviorGraphProperties, thunkAPI) => {
    const response =
      await API.ClientAPI.Graph.behaviors.getBehaviorDurationGraph(
        data
      );
    if (!!response.error) {
      return thunkAPI.rejectWithValue(response.error);
    }
    return response as Array<GraphBehaviorDurationDate>;
  }
);

const graphTargetSlice = createSlice({
  name: "graph",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    //getDTTGraph
    builder.addCase(getDTTGraph.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getDTTGraph.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
    });
    builder.addCase(getDTTGraph.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error as ErrorProperties;
    });
    //getTAGraph
    builder.addCase(getTAGraph.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getTAGraph.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
    });
    builder.addCase(getTAGraph.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error as ErrorProperties;
    });
    //getBehaviorFrequencyGraph
    builder.addCase(getBehaviorFrequencyGraph.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getBehaviorFrequencyGraph.fulfilled, (state, action) => {
      state.loading = false;
      state.behaviorFGraph = action.payload;
    });
    builder.addCase(getBehaviorFrequencyGraph.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error as ErrorProperties;
    });
    //getBehaviorDurationGraph
    builder.addCase(getBehaviorDurationGraph.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getBehaviorDurationGraph.fulfilled, (state, action) => {
      state.loading = false;
      state.behaviorDGraph = action.payload;
    });
    builder.addCase(getBehaviorDurationGraph.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error as ErrorProperties;
    });
    // getPeriodAnnotations
    builder.addCase(getTargetAnnotations.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getTargetAnnotations.fulfilled, (state, action) => {
      state.loading = false;
      state.dataPeriodAnnotations = action.payload;
    });
    builder.addCase(getTargetAnnotations.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error as ErrorProperties;
    });
  },
});

export default graphTargetSlice.reducer;
