import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import API from "../API/Main/API";
import {
    SaveUserDataProperties,
    UpdateUserDataProperties,
    UserPaginationProperties,
    UserProperties,
    UserTableDataProperties,
} from "../API/userAPIProperties";
import { resetState } from "../resetState";

import { PaginationProperties } from "../../shared/uiComponents/Table/tableProperties";
import { ErrorProperties } from "../API/identityAPIProperties";

interface UserStateProperties {
    loading: boolean;
    error: ErrorProperties;
    admins: UserTableDataProperties;
    users: UserTableDataProperties;
    bcbas: UserTableDataProperties;
    rbts: UserTableDataProperties;
    user: UserProperties;
    authorizationTypes: Array<{ id: number, name: string }>;
    insurances: Array<{ id: string; name: string }>
}

const initialUserState: UserStateProperties = {
    loading: false,
    error: {
        status: 0,
        title: ''
    },
    users: {
        query: null,
        page: 1,
        totalNumberOfItems: 0,
        numberOfItems: 0,
        totalPages: 1,
        hasNextPage: false,
        hasPreviousPage: false,
    },
    admins: {
        query: null,
        page: 1,
        totalNumberOfItems: 0,
        numberOfItems: 0,
        totalPages: 1,
        hasNextPage: false,
        hasPreviousPage: false,
    },
    bcbas: {
        query: null,
        page: 1,
        totalNumberOfItems: 0,
        numberOfItems: 0,
        totalPages: 1,
        hasNextPage: false,
        hasPreviousPage: false,
    },
    rbts: {
        query: null,
        page: 1,
        totalNumberOfItems: 0,
        numberOfItems: 0,
        totalPages: 1,
        hasNextPage: false,
        hasPreviousPage: false,
    },
    user: {
        id: "",
        firstName: "",
        lastName: "",
        fullName: "",
        patronymic: "",
        numberOfClients: 0,
        phoneNumber: "",
        address: "",
        notes: "",
        email: "",
        numberOfNotes: 0,
        role: {
            id: "",
            name: "",
            section: {
                id: 0,
                name: "",
            }
        },
        createdBy: {
            id: "",
            fullName: "",
            role: "",
        },
        authorizationType: {
            id: 0,
            name: "",
        },
        emailConfirmed: false,
    },
    authorizationTypes: [],
    insurances: [],
}

export const getAuthorizationTypes = createAsyncThunk(
    'user/authorization/types',
    async (_, thunkAPI) => {
        const response = await API.UserAPI.getAuthorizationTypes();
        if (!!response.error) {
            return thunkAPI.rejectWithValue(response.error);
        }
        return response as Array<{ id: number, name: string }>;
    }
)

export const getUserInsurances = createAsyncThunk(
    '/user/user/insurances',
    async (id: string, thunkAPI) => {
        const response = await API.UserAPI.getUserInsurances(id);
        if (!!response.error) {
            return thunkAPI.rejectWithValue(response.error);
        }
        return response as Array<{ id: string; name: string }>;
    }
)

export const getUsers = createAsyncThunk(
    '/user/users',
    async (data: UserPaginationProperties, thunkAPI) => {
        const response = await API.UserAPI.getUsers(data);
        if (!!response.error) {
            return thunkAPI.rejectWithValue(response.error);
        }
        return response as UserTableDataProperties;
    }
)

export const getAdmins = createAsyncThunk(
    '/user/users/admin',
    async (data: UserPaginationProperties, thunkAPI) => {
        const response = await API.UserAPI.getUsersBySection(data, 0);
        if (!!response.error) {
            return thunkAPI.rejectWithValue(response.error);
        }
        return response as UserTableDataProperties;
    }
)

export const getBCBAs = createAsyncThunk(
    '/user/users/bcba',
    async (data: PaginationProperties, thunkAPI) => {
        const response = await API.UserAPI.getUsersBySection(data, 1);
        if (!!response.error) {
            return thunkAPI.rejectWithValue(response.error);
        }
        return response as UserTableDataProperties;
    }
)

export const getRBTs = createAsyncThunk(
    '/user/users/rbt',
    async (data: PaginationProperties, thunkAPI) => {
        const response = await API.UserAPI.getUsersBySection(data, 2);
        if (!!response.error) {
            return thunkAPI.rejectWithValue(response.error);
        }
        return response as UserTableDataProperties;
    }
)

export const addUser = createAsyncThunk(
    '/user/add',
    async (data: SaveUserDataProperties, thunkAPI) => {
        const response = await API.UserAPI.addUser(data);
        if (!!response.error) {
            return thunkAPI.rejectWithValue(response.error);
        }
        return response as UserProperties;
    }
)

export const getUser = createAsyncThunk(
    '/user/user',
    async (id: string, thunkAPI) => {
        const response = await API.UserAPI.getUser(id);
        if (!!response.error) {
            return thunkAPI.rejectWithValue(response.error);
        }
        return response as UserProperties;
    }
)

export const updateUser = createAsyncThunk(
    '/user/update',
    async (data: UpdateUserDataProperties, thunkAPI) => {
        const response = await API.UserAPI.updateUser(data);
        if (!!response.error) {
            return thunkAPI.rejectWithValue(response.error);
        }
        return response;
    }
)

export const deleteUser = createAsyncThunk(
    '/user/delete',
    async (id: string, thunkAPI) => {
        const response = await API.UserAPI.deleteUser(id);
        if (!!response.error) {
            return thunkAPI.rejectWithValue(response.error);
        }
        return response;
    }
)

const userSlice = createSlice({
    name: 'user',
    initialState: initialUserState,
    reducers: {},
    extraReducers: (builder) => {
        //getAuthorizationTypes
        builder.addCase(getAuthorizationTypes.pending, (state) => {
            state.loading = true;
        })
        builder.addCase(getAuthorizationTypes.fulfilled, (state, action) => {
            state.loading = false;
            state.authorizationTypes = action.payload;
        })
        builder.addCase(getAuthorizationTypes.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload as ErrorProperties;
        })
        //get users
        builder.addCase(getUsers.pending, (state) => {
            state.loading = true;
            state.error = initialUserState.error;
        })
        builder.addCase(getUsers.fulfilled, (state, action) => {
            state.loading = false;
            state.users = action.payload;
        })
        builder.addCase(getUsers.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload as ErrorProperties;
        })
        //get admin
        builder.addCase(getAdmins.pending, (state) => {
            state.loading = true;
            state.error = initialUserState.error;
        })
        builder.addCase(getAdmins.fulfilled, (state, action) => {
            state.loading = false;
            state.admins = action.payload;
        })
        builder.addCase(getAdmins.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload as ErrorProperties;
        })
        //get BCBA
        builder.addCase(getBCBAs.pending, (state) => {
            state.loading = true;
            state.error = initialUserState.error;
        })
        builder.addCase(getBCBAs.fulfilled, (state, action) => {
            state.loading = false;
            state.bcbas = action.payload;
        })
        builder.addCase(getBCBAs.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload as ErrorProperties;
        })
        //get RBT
        builder.addCase(getRBTs.pending, (state) => {
            state.loading = true;
            state.error = initialUserState.error;
        })
        builder.addCase(getRBTs.fulfilled, (state, action) => {
            state.loading = false;
            state.rbts = action.payload;
        })
        builder.addCase(getRBTs.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload as ErrorProperties;
        })
        //get user
        builder.addCase(getUser.pending, (state) => {
            state.loading = true;
            state.error = initialUserState.error;
        })
        builder.addCase(getUser.fulfilled, (state, action) => {
            state.loading = false;
            state.user = action.payload;
        })
        builder.addCase(getUser.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload as ErrorProperties;
        })
        //add user
        builder.addCase(addUser.pending, (state) => {
            state.loading = true;
            state.error = initialUserState.error;
        })
        builder.addCase(addUser.fulfilled, (state) => {
            state.loading = false;
        })
        builder.addCase(addUser.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload as ErrorProperties;
        })
        //update user
        builder.addCase(updateUser.pending, (state) => {
            state.loading = true;
            state.error = initialUserState.error;
        })
        builder.addCase(updateUser.fulfilled, (state) => {
            state.loading = false;
        })
        builder.addCase(updateUser.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload as ErrorProperties;
        })
        //delete user
        builder.addCase(deleteUser.pending, (state) => {
            state.loading = true;
            state.error = initialUserState.error;
        })
        builder.addCase(deleteUser.fulfilled, (state) => {
            state.loading = false;
        })
        builder.addCase(deleteUser.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload as ErrorProperties;
        })
        //reset state
        builder.addCase(resetState, () => initialUserState);
    }
})

export default userSlice.reducer;