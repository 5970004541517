import { SOAPNoteWrapper } from "../helpers";
import CreateSoapNote from "./createBehaviorTreatmentModificationSoapNote";
import UpdateSoapNote from "./updateBehaviorTreatmentModificationSoapNote";

const BehaviorTreatmentModification = () => (
  <SOAPNoteWrapper
    CreateSoapNote={CreateSoapNote}
    UpdateSoapNote={UpdateSoapNote}
  />
);

export default BehaviorTreatmentModification;
