import { useReducer } from "react";
import { useDispatch } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import { useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";

import { adminReducer, initialState } from "./adminReducer";
import AdminForm from "./adminForm";
import { errorNormalizer } from "../../../shared/Helpers/functions";
import { CustomButton } from "../../../shared/uiComponents";
import { AdminContainer } from "../StyledComponents";

import { DispatchProperties } from "../../../redux/store";
import { addUser } from "../../../redux/State/userSlice";

const AddAdmin = () => {
  const dispatch = useDispatch<DispatchProperties>();
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const [adminInfo, setAdminInfo] = useReducer(adminReducer, initialState);

  const AddAdminHandler = () => {
    dispatch(addUser(adminInfo))
      .then(unwrapResult)
      .then(() => {
        navigate(-1);
        toast("Success");
      })
      .catch(errorNormalizer);
  };

  return (
    <>
      <AdminForm adminInfo={adminInfo} setAdminInfo={setAdminInfo} />
      <AdminContainer>
        <CustomButton
          title={"Save"}
          onClick={AddAdminHandler}
          disabled={params.get("saveDisabled") === "true" ? true : false}
          className="marginBottom16"
        />
      </AdminContainer>
    </>
  );
};

export default AddAdmin;
