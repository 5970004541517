import { useState } from "react";
import { useDispatch } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

import { CustomAlert, CustomButton } from "../../../../shared/uiComponents";
import { ButtonWrapper } from "../../../AdminPanel/StyledComponents";
import { errorNormalizer } from "../../../../shared/Helpers/functions";

import { DispatchProperties, useSelector } from "../../../../redux/store";
import { activateSession } from "../../../../redux/State/clientSlice/sessionSlice";

import { useResetCalendarData } from "./hooks";

const ActivateSession = () => {
  const dispatch = useDispatch<DispatchProperties>();
  const { resetData } = useResetCalendarData();
  const [open, setOpen] = useState<boolean>(false);

  const loading = useSelector((state) => state.session.loading);
  const data = useSelector((state) => state.calendar.infoEventData);

  const activateSessionHandler = () => {
    if (!data || !data.sessionId) return;
    dispatch(activateSession(data.sessionId))
      .then(unwrapResult)
      .then(() => {
        resetData();
        toast("Success");
      })
      .catch(errorNormalizer)
      .finally(() => setOpen(false));
  };

  return (
    <>
      <CustomButton
        title="Activate"
        onClick={() => setOpen(true)}
        secondaryButton
      />
      <CustomAlert
        title="Are you sure?"
        open={open}
        onClose={() => setOpen(false)}
        Content={() => (
          <ButtonWrapper>
            <CustomButton title="Cancel" />
            <CustomButton
              secondaryButton
              loading={loading}
              title="Activate"
              onClick={activateSessionHandler}
            />
          </ButtonWrapper>
        )}
      />
    </>
  );
};

export default ActivateSession;
