import BehaviorTreatmentModification from "./BehaviorTreatmentModification/index";
import BehaviorTreatment from "./BehaviorTreatment/index";
import Assessment from "./Assessment/index";
import DirectSupervision from "./DirectSupervision/index";
import ParentTraining from "./ParentTraining/index";
import TreatmentPlanning from "./TreatmentPlanning";

export const SoapNoteTypes = {
  BehaviorTreatmentModification,
  BehaviorTreatment,
  Assessment,
  DirectSupervision,
  ParentTraining,
  TreatmentPlanning,
};
