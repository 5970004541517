import { ChangeEvent, useEffect, useState, useReducer } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { SelectChangeEvent } from "@mui/material";
import { toast } from "react-toastify";
import { unwrapResult } from "@reduxjs/toolkit";

import {
  CustomAlert,
  CustomButton,
  Text,
  CustomInput,
  CustomSelect,
} from "../../../../../shared/uiComponents";
import { initialState } from "./initialState";
import { ACTIONS, TargetReducer } from "./reducer";
import { errorNormalizer } from "../../../../../shared/Helpers/functions";

import {
  DispatchProperties,
  StateProperties,
} from "../../../../../redux/store";
import {
  createTarget,
  getTargets,
} from "../../../../../redux/State/clientSlice/programTargetSlice";
import {
  CreateProgramTargetProperties,
  TargetTypes,
} from "../../../../../redux/API/ClientAPIHelpers/programTargetsProperties";

const Create = () => {
  const [createTargetOpen, setCreateTargetOpen] = useState<boolean>(false);
  const createTargetCloseHandler = () => setCreateTargetOpen(false);

  return (
    <>
      <CustomButton
        title="Create new target"
        onClick={() => setCreateTargetOpen(true)}
        className="marginBottom16"
      />
      <CustomAlert
        open={createTargetOpen}
        onClose={createTargetCloseHandler}
        Content={() => (
          <CreateTargetContent closeHandler={createTargetCloseHandler} />
        )}
      />
    </>
  );
};

const CreateTargetContent = ({
  closeHandler,
}: {
  closeHandler: () => void;
}) => {
  const { programId } = useParams();
  const dispatch = useDispatch<DispatchProperties>();
  const loading = useSelector(
    (state: StateProperties) => state.programTarget.loading
  );

  const [targetData, setTargetData] = useReducer(TargetReducer, initialState);
  const {
    name,
    goalName,
    sd,
    targetInstructions,
    typeId,
    errorCorrection,
    reinforcement,
  } = targetData;

  const [stepsValue, setStepsValue] = useState<string>("");

  const [error, setError] = useState<string>("");

  const onNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setTargetData({ type: ACTIONS.setName, payload: value });
  };

  const onGoalNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setTargetData({ type: ACTIONS.setGoalName, payload: value });
  };

  const onSdChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setTargetData({ type: ACTIONS.setSD, payload: value });
  };

  const onTargetInstructionsChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setTargetData({ type: ACTIONS.setTargetInstructions, payload: value });
  };

  const onErrorCorrectionChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setTargetData({ type: ACTIONS.setErrorCorrection, payload: value });
  };

  const onReinforcementChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setTargetData({ type: ACTIONS.setReinforcement, payload: value });
  };

  const onTypeIdChange = (event: SelectChangeEvent<string>) => {
    const { value } = event.target;
    setTargetData({ type: ACTIONS.setTypeId, payload: value });
  };

  const onStepsChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setStepsValue(value);
  };

  const saveTargetHandler = () => {
    if (!programId) return;

    let data: CreateProgramTargetProperties = { ...targetData };

    if (!!stepsValue) {
      const steps = stepsValue.split(",").map((s) => ({ name: s.trim() }));
      data = { ...data, steps };
    }

    dispatch(createTarget({ data, programId }))
      .then(unwrapResult)
      .then(() => {
        dispatch(getTargets(programId));
        closeHandler();
        toast("Created");
      })
      .catch(errorNormalizer);
  };

  useEffect(() => {
    if (!!name) {
      setError("");
      return;
    }

    setError("Required");
  }, [name]);

  return (
    <div>
      <Text
        title="Target Information"
        size="mediumBold"
        className="marginBottom16"
      />
      <CustomInput
        label="Target Name"
        className="marginBottom16"
        value={name}
        setValue={onNameChange}
        error={!name}
        errorMessage={error}
      />
      <CustomInput
        label="Goal Name (for reporting)"
        className="marginBottom16"
        value={goalName}
        setValue={onGoalNameChange}
        multiline={{
          multiline: true,
          rowCount: 4,
        }}
      />
      <CustomInput
        label="SD"
        className="marginBottom16"
        value={sd}
        setValue={onSdChange}
      />
      <CustomInput
        label="Target Instructions"
        className="marginBottom16"
        value={targetInstructions}
        setValue={onTargetInstructionsChange}
        multiline={{
          multiline: true,
          rowCount: 4,
        }}
      />
      <CustomInput
        label="Error Correction"
        className="marginBottom16"
        value={errorCorrection}
        setValue={onErrorCorrectionChange}
        multiline={{
          multiline: true,
          rowCount: 4,
        }}
      />
      <CustomInput
        label="Reinforcement"
        className="marginBottom16"
        value={reinforcement}
        setValue={onReinforcementChange}
        multiline={{
          multiline: true,
          rowCount: 4,
        }}
      />
      <CustomSelect
        label="Target type"
        className="marginBottom16"
        value={typeId.toString()}
        setValue={onTypeIdChange}
        data={[
          { id: TargetTypes.DTT, name: "DTT" },
          { id: TargetTypes.TA, name: "Task Analysis" },
        ]}
      />
      {typeId === TargetTypes.TA && (
        <CustomInput
          label="Steps"
          className="marginBottom16"
          value={stepsValue}
          setValue={onStepsChange}
          multiline={{
            multiline: true,
            rowCount: 2,
          }}
          description="Separate with comma"
        />
      )}
      <CustomButton
        title="Save"
        onClick={saveTargetHandler}
        loading={loading}
        disabled={loading || !name}
      />
    </div>
  );
};

export default Create;
