import { ChangeEvent, useCallback, useRef } from "react";
import { useDispatch } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import { useParams, useSearchParams } from "react-router-dom";

import { errorNormalizer } from "../../../../../../shared/Helpers/functions";
import { CustomButton } from "../../../../../../shared/uiComponents";

import { DispatchProperties, useSelector } from "../../../../../../redux/store";
import { importTargets } from "../../../../../../redux/State/clientSlice/programTargetSlice";
import { getSkillAreas } from "../../../../../../redux/State/clientSlice/skillAreaSlice";

const UploadExcelFile = () => {
  const dispatch = useDispatch<DispatchProperties>();
  const { clientId } = useParams();
  const [params, setParams] = useSearchParams();
  const inputRef = useRef<HTMLInputElement | null>(null);

  const loading = useSelector((state) => state.programTarget.loading);

  const fetchSkillAreas = useCallback(() => {
    if (!clientId) return;
    const includeArchived = !!params.get("includeArchived") ? true : false;
    dispatch(getSkillAreas({ clientId, includeArchived }))
      .then(unwrapResult)
      .then((response) => {
        if (!response.length) return;
        const id = response[0].id;
        params.set("skillAreaId", id);
        setParams(params);
      })
      .catch(errorNormalizer);
  }, [clientId, params, setParams, dispatch]);

  const uploadXLSXFile = ({ target }: ChangeEvent<HTMLInputElement>) => {
    if (!target) return;
    const { files } = target;
    if (!files) return;
    const file = files[0];
    if (!file || !clientId) return;

    dispatch(importTargets({ file, clientId }))
      .then(unwrapResult)
      .then(() => {
        fetchSkillAreas();
        target.value = ""; //enable reupload same file
      })
      .catch(errorNormalizer);
  };

  const onUploadClick = () => {
    if (!inputRef || !inputRef.current) return;
    inputRef.current.click();
  };

  return (
    <>
      <input
        ref={inputRef}
        type="file"
        accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
        id="upload-excel-file"
        onChange={uploadXLSXFile}
        formEncType="multipart/form-data"
      />
      <CustomButton
        title="Upload excel file"
        onClick={onUploadClick}
        loading={loading}
      />
    </>
  );
};

export default UploadExcelFile;
