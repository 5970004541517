import { useState } from "react";
import { useDispatch } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import { ArchiveOutlined } from "@mui/icons-material";
import { Tooltip } from "@mui/material";

import {
  CustomAlert,
  CustomButton,
} from "../../../../../../shared/uiComponents";
import { errorNormalizer } from "../../../../../../shared/Helpers/functions";

import { DispatchProperties } from "../../../../../../redux/store";
import { ProgramProperties } from "../../../../../../redux/API/ClientAPIHelpers/clientProgramProperties";
import {
  archiveProgram,
  getPrograms,
} from "../../../../../../redux/State/clientSlice/clientProgramSlice";
import { ButtonWrapper } from "../../../../../AdminPanel/StyledComponents";

const Archive = ({ data }: { data: ProgramProperties }) => {
  const [open, setOpen] = useState<boolean>(false);
  const closeHandler = () => setOpen(false);
  return (
    <>
      <Tooltip title="Archive">
        <ArchiveOutlined onClick={() => setOpen(true)} />
      </Tooltip>
      <CustomAlert
        title="Do you want to archive?"
        open={open}
        onClose={closeHandler}
        Content={() => <ArchiveContent data={data} onClose={closeHandler} />}
      />
    </>
  );
};

const ArchiveContent = ({
  data,
  onClose,
}: {
  data: ProgramProperties;
  onClose: () => void;
}) => {
  const dispatch = useDispatch<DispatchProperties>();

  const archiveHandler = () => {
    dispatch(archiveProgram(data))
      .then(unwrapResult)
      .then(() => {
        dispatch(getPrograms({ skillAreaId: data.skillAreaId }));
        onClose();
      })
      .catch(errorNormalizer);
  };

  return (
    <ButtonWrapper>
      <CustomButton title="Yes" onClick={archiveHandler} />
      <CustomButton title="Cancel" secondaryButton />
    </ButtonWrapper>
  );
};

export default Archive;
