export const blue100 = '#A6D0D7';
export const blue400 = '#68B0BC';
export const blue500 = '#72A0C1';
export const blue700 = '#6693BC';

export const red100 = '#F2BDAD';
export const red200 = '#E5837A';
export const red300 = '#FE6F5E';
export const red500 = '#FF665B';
export const red700 = '#FF6347';

export const white100 = '#FFFFFF';
export const white200 = '#FFF7E1';

export const green200 = '#90BA3C';
export const green400 = '#39642F';

export const black500 = '#212121';
export const black900 = '#000000';

export const gray100 = '#F1F1F1';