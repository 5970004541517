import { useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";

import SearchBar from "../../../components/SearchBar";
import { CustomButton, Text, CustomTable } from "../../../shared/uiComponents";
import { useInitializeURLParams } from "../../../shared/Helpers/hooks";

import MobileView from "./mobileView";
import { TableBody, Headers } from "./tableInfo";
import { Container, Header } from "../StyledComponents";

import { DispatchProperties, StateProperties } from "../../../redux/store";
import { getUsers } from "../../../redux/State/userSlice";

const AllUsers = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<DispatchProperties>();

  const [params] = useSearchParams();
  useInitializeURLParams();

  const usersData = useSelector((state: StateProperties) => state.user.users);
  const loading = useSelector((state: StateProperties) => state.user.loading);

  useEffect(() => {
    const page = params.get("page");
    const pageSize = params.get("pageSize");
    const orderBy = params.get("orderBy") || "";
    const direction = params.get("direction") || "";
    const searchString = params.get("search") || "";
    const section = params.get("status");

    if (!page || !pageSize) return;

    dispatch(
      getUsers({ page, pageSize, orderBy, direction, searchString, section })
    );
  }, [params, dispatch]);

  const addAdminHandler = () => navigate("/admin/user/new");

  return (
    <Container>
      <Header>
        <Text title={"All Users"} size="mediumBold" />
        <Header>
          <CustomButton
            title={"Add user"}
            className={"marginRight16"}
            onClick={addAdminHandler}
          />
          <SearchBar />
        </Header>
      </Header>
      <CustomTable
        headers={Headers}
        data={usersData}
        TableBody={TableBody}
        loading={loading}
      />
      <MobileView {...usersData} />
    </Container>
  );
};

export default AllUsers;
