import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";

import { blue100 } from "../../../shared/Helpers/colors";
import { CustomButton } from "../../../shared/uiComponents";
import InfoEvent from "./Popups/InfoEvent/index";
import { weeks } from "./Helpers/constants";
import { DayProperties } from "./Helpers/interfaces";
import { findColor, sortByTime } from "./Helpers/functions";
import { Session, SessionHeader } from "./Helpers/session";
import { useScreenWidth } from "../../../shared/Helpers/hooks";

import { DispatchProperties, useSelector } from "../../../redux/store";
import { setSelectedDay } from "../../../redux/State/clientSlice/calendarSlice";
import { SoapNotesStatuses } from "../../../redux/API/ClientAPIHelpers/dataCollectionProperties";

const WeekView = () => {
  const today = new Date();
  const screenWidth = useScreenWidth();

  const currentDay = today.getDate();
  const currentMonth = today.getMonth();
  const currentWeekDay = today.getDay();

  const [startOfWeek, setStartOfWeek] = useState<number>(0);
  const [endOfWeek, setEndOfWeek] = useState<number>(7);

  const [params] = useSearchParams();
  const showCompletedSession =
    params.get("showCompleted") === "true" ? true : false;
  const showDraftsOnly = params.get("showDraftsOnly") === "true" ? true : false;

  const dispatch = useDispatch<DispatchProperties>();

  const days = useSelector((state) => state.calendar.displayedCalendarPageDays);
  const events = useSelector((state) => state.calendar.events);

  const month = useSelector((state) => state.calendar.month);

  useEffect(() => {
    if (month === currentMonth) {
      const currentDayObject = days.find(
        (x) => x.day === currentDay && x.status === 1
      );
      const currentStart = Math.floor(
        days.indexOf(currentDayObject as DayProperties) / 7
      );

      setStartOfWeek(currentStart * 7);
      setEndOfWeek(currentStart * 7 + 7);
      return;
    }
    setStartOfWeek(0);
    setEndOfWeek(7);
  }, [days, currentDay, month, currentMonth]);

  const onNextClick = () => {
    setStartOfWeek((prev) => prev + 7);
    setEndOfWeek((prev) => prev + 7);
  };

  const onPrevClick = () => {
    setStartOfWeek((prev) => prev - 7);
    setEndOfWeek((prev) => prev - 7);
  };

  const weeksMapper = (item: string, index: number) => {
    const weekDay: DayProperties = days.slice(startOfWeek, endOfWeek)[
      index
    ] || { day: "", status: 0, month: 0 };

    const { backgroundColor } = findColor({
      item: weekDay,
      index,
      screenWidth,
    });

    const selectDayHandler = (item: DayProperties) => {
      dispatch(setSelectedDay(item));
    };

    return (
      <div
        key={index}
        className="dayWeek"
        style={{
          backgroundColor,
          outline:
            index === currentWeekDay && weekDay.day === currentDay
              ? `5px solid ${blue100}`
              : "none",
        }}
      >
        <div
          style={{
            width: "5%",
            minWidth: "80px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            cursor: "pointer",
          }}
          className={"padding8"}
          onClick={() => selectDayHandler(weekDay)}
        >
          <SessionHeader item={weekDay} index={index} />
        </div>
        <div
          style={{
            width: "95%",
          }}
        >
          {!!events &&
            !!events.length &&
            [...events]
              .sort((a, b) => sortByTime({ a, b }))
              .filter((x) =>
                showCompletedSession
                  ? x.isCompleted &&
                    x.reportStatus?.status === SoapNotesStatuses.submitted
                  : true
              )
              .filter((x) =>
                showDraftsOnly
                  ? x.reportStatus?.status === SoapNotesStatuses.draft
                  : true
              )
              .filter(
                (x) =>
                  new Date(x.startTime).getDate() === weekDay.day &&
                  new Date(x.startTime).getMonth() === weekDay.month
              )
              .map((res) => (
                <Session key={res.id} event={res} className="sessionWeek" />
              ))}
        </div>
      </div>
    );
  };

  return (
    <div>
      <div style={{ display: "flex", marginBottom: "16px" }}>
        <CustomButton
          title="Previous week"
          onClick={onPrevClick}
          disabled={!startOfWeek}
          className="marginRight8"
        />
        <CustomButton
          title="Next week"
          onClick={onNextClick}
          disabled={days.length === endOfWeek}
        />
      </div>
      <div>{weeks.map(weeksMapper)}</div>
      <InfoEvent />
    </div>
  );
};

export default WeekView;
