import { ChangeEvent, useEffect, useState, useReducer } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { unwrapResult } from "@reduxjs/toolkit";

import { DispatchProperties, useSelector } from "../../../../../redux/store";
import {
  getTargets,
  updateTarget,
} from "../../../../../redux/State/clientSlice/programTargetSlice";

import {
  CustomAlert,
  CustomButton,
  CustomInput,
  Text,
} from "../../../../../shared/uiComponents";
import { errorNormalizer } from "../../../../../shared/Helpers/functions";

import { initialState } from "./initialState";
import { ACTIONS, TargetReducer } from "./reducer";
import {
  ProgramTargetProperties,
  TargetProperties,
} from "../../../../../redux/API/ClientAPIHelpers/programTargetsProperties";

const Edit = ({
  item,
  programId,
}: {
  item: TargetProperties;
  programId: string;
}) => {
  const [openEdit, setOpenEdit] = useState<boolean>(false);
  const { id } = item;

  return (
    <>
      <CustomButton title="Edit Details" onClick={() => setOpenEdit(true)} />
      <CustomAlert
        open={openEdit}
        onClose={() => setOpenEdit(false)}
        Content={() => (
          <UpdateTargetContent
            closeHandler={() => setOpenEdit(false)}
            id={id}
            programId={programId}
            props={item}
          />
        )}
      />
    </>
  );
};

export const UpdateTargetContent = ({
  closeHandler,
  props,
  id,
  programId,
}: {
  closeHandler: () => void;
  props: ProgramTargetProperties;
  id: string;
  programId: string;
}) => {
  const dispatch = useDispatch<DispatchProperties>();

  const loading = useSelector((state) => state.programTarget.loading);

  const [targetData, setTargetData] = useReducer(TargetReducer, initialState);
  const {
    name,
    goalName,
    errorCorrection,
    reinforcement,
    sd,
    targetInstructions,
  } = targetData;

  const [error, setError] = useState<string>("");

  useEffect(() => {
    if (!props) return;
    setTargetData({ type: ACTIONS.setState, payload: props });
  }, [props]);

  useEffect(() => {
    if (!!name) {
      setError("");
      return;
    }

    setError("Required");
  }, [name]);

  const onNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setTargetData({ type: ACTIONS.setName, payload: value });
  };

  const onGoalNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setTargetData({ type: ACTIONS.setGoalName, payload: value });
  };

  const onSdChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setTargetData({ type: ACTIONS.setSD, payload: value });
  };

  const onTargetInstructionsChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setTargetData({ type: ACTIONS.setTargetInstructions, payload: value });
  };

  const onErrorCorrectionChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setTargetData({ type: ACTIONS.setErrorCorrection, payload: value });
  };

  const onReinforcementChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setTargetData({ type: ACTIONS.setReinforcement, payload: value });
  };

  const updateTagetHandler = () => {
    if (!programId || !id) return;
    dispatch(updateTarget({ data: targetData, programId, id }))
      .then(unwrapResult)
      .then(() => {
        dispatch(getTargets(programId));
        closeHandler();
        toast("Updated");
      })
      .catch(errorNormalizer);
  };

  return (
    <div>
      <Text
        title="Target Information"
        size="mediumBold"
        className="marginBottom16"
      />
      <CustomInput
        label="Target Name"
        className="marginBottom16"
        value={name}
        setValue={onNameChange}
        error={!name}
        errorMessage={error}
      />
      <CustomInput
        label="Goal Name (for reporting)"
        className="marginBottom16"
        value={goalName}
        setValue={onGoalNameChange}
        multiline={{
          multiline: true,
          rowCount: 4,
        }}
      />
      <CustomInput
        label="SD"
        placeholder="Required"
        className="marginBottom16"
        value={sd}
        setValue={onSdChange}
      />
      <CustomInput
        label="Target Instructions"
        placeholder="Required"
        className="marginBottom16"
        value={targetInstructions}
        setValue={onTargetInstructionsChange}
        multiline={{
          multiline: true,
          rowCount: 4,
        }}
      />
      <CustomInput
        placeholder="Required"
        label="Error Correction"
        className="marginBottom16"
        value={errorCorrection}
        setValue={onErrorCorrectionChange}
        multiline={{
          multiline: true,
          rowCount: 4,
        }}
      />
      <CustomInput
        placeholder="Required"
        label="Reinforcement"
        className="marginBottom16"
        value={reinforcement}
        setValue={onReinforcementChange}
        multiline={{
          multiline: true,
          rowCount: 4,
        }}
      />
      <CustomButton
        title="Save"
        onClick={updateTagetHandler}
        loading={loading}
        disabled={loading || !!error}
      />
    </div>
  );
};

export default Edit;
