import { useState } from "react";
import { EditOutlined } from "@mui/icons-material";

import { CustomAlert } from "../../../../../../shared/uiComponents";
import EditAuthorizationContent from "./editAuthorizationContent";

import { ActiveAuthorizationProperties } from "../../../../../../redux/API/ClientAPIHelpers/insuranceAuthorizationProperties";

const EditAuthorization = ({
  authorizationData,
}: {
  authorizationData: ActiveAuthorizationProperties;
}) => {
  const [editOpen, setEditOpen] = useState<boolean>(false);

  const editHandler = () => {
    setEditOpen(true);
  };

  return (
    <>
      <EditOutlined onClick={editHandler} />
      <CustomAlert
        open={editOpen}
        onClose={() => setEditOpen(false)}
        title="Edit Insurance Authorization"
        Content={({ onClose }) => (
          <EditAuthorizationContent
            authorizationData={authorizationData}
            onClose={onClose}
          />
        )}
      />
    </>
  );
};

export default EditAuthorization;
