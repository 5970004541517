import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { unwrapResult } from "@reduxjs/toolkit";

import { errorNormalizer } from "../../../../../shared/Helpers/functions";
import { CustomButton } from "../../../../../shared/uiComponents";
import { SessionInfoButtonsWrapper } from "./sessionDate";
import DeleteSession from "./deleteSession";

import { DispatchProperties, useSelector } from "../../../../../redux/store";
import { startEventSession } from "../../../../../redux/State/clientSlice/sessionSlice";
import { setInfoEvent } from "../../../../../redux/State/clientSlice/calendarSlice";
import { useResetCalendarData } from "../hooks";
import { AdminTypes } from "../../../../../components/Action";

const SessionActions = () => {
  const dispatch = useDispatch<DispatchProperties>();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { resetData } = useResetCalendarData();

  const role = useSelector((state) => state.account.role);
  const days = useSelector((state) => state.calendar.displayedCalendarPageDays);
  const data = useSelector((state) => state.calendar.infoEventData);

  const isAdmin = !!role && role.section.id === AdminTypes.administrator;
  const isRBT = !!role && role.section.id === AdminTypes.rbt;

  const collectDataHandler = () => {
    if (!data) return;
    resetData();
    navigate(`${pathname}/start-session/${data.sessionId}/${data.client.id}`, {
      state: data,
    });
  };

  const startSessionHandler = () => {
    if (!data || !data.id) return;
    dispatch(startEventSession(data.id))
      .then(unwrapResult)
      .then((response) => {
        if (!response.id || !response.client.id || !days.length) return;
        dispatch(setInfoEvent(false));
        resetData();
        if (isRBT) {
          navigate(
            `${pathname}/start-session/${response.id}/${response.client.id}`,
            {
              state: data,
            }
          );
        }
      })
      .catch(errorNormalizer);
  };

  return (
    <SessionInfoButtonsWrapper>
      {!!data && !data.isCompleted ? (
        !!data.sessionId ? (
          <CustomButton
            title="Collect Data"
            secondaryButton
            onClick={collectDataHandler}
          />
        ) : (
          <CustomButton
            title={isRBT ? "Start Session" : "Collect Data"}
            onClick={startSessionHandler}
          />
        )
      ) : (
        <CustomButton
          title="Session Ended"
          disabled={!isAdmin}
          onClick={collectDataHandler}
        />
      )}
      <DeleteSession resetData={resetData} />
    </SessionInfoButtonsWrapper>
  );
};

export default SessionActions;
