import { unwrapResult } from "@reduxjs/toolkit";
import { useCallback, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";

import { CustomButton, CustomTable } from "../../../../../shared/uiComponents";
import { Headers, TableBody } from "./tableInfo";
import { errorNormalizer } from "../../../../../shared/Helpers/functions";

import { DispatchProperties, useSelector } from "../../../../../redux/store";
import { GetBillingProperties } from "../../../../../redux/API/ClientAPIHelpers/billingProperties";
import {
  downloadMBHExcelReport,
  getBillingSessions,
} from "../../../../../redux/State/clientSlice/billingSlice";
import { ReportWrapper } from "../..";
import { BillingFilters } from "../../filters";
import { downloadFile } from "../downloadFile";

const MBHReport = () => {
  const dispatch = useDispatch<DispatchProperties>();
  const [params] = useSearchParams();

  const sessions = useSelector((state) => state.billing.sessions);
  const loading = useSelector((state) => state.billing.loading);

  const fetchData = useCallback(() => {
    const page = params.get("page") || "1";
    const pageSize = params.get("pageSize") || "8";
    const direction = params.get("direction") || "";
    const orderBy = params.get("orderBy") || "";
    const onlyNew = params.get("onlyNew");
    const startDate = params.get("startDate");
    const endDate = params.get("endDate");
    const searchString = params.get("searchString") || "";
    const userId = params.get("userId") || "";
    const clientId = params.get("clientId") || "";

    if (!startDate || !endDate) return;
    const data: GetBillingProperties = {
      page,
      pageSize,
      startDate,
      endDate,
      onlyNew: !!onlyNew,
      direction,
      orderBy,
      searchString,
      userId,
      clientId,
    };

    dispatch(getBillingSessions(data))
      .then(unwrapResult)
      .catch(errorNormalizer);
  }, [dispatch, params]);

  const downloadHandler = useCallback(() => {
    const page = params.get("page") || "1";
    const direction = params.get("direction") || "";
    const orderBy = params.get("orderBy") || "";
    const searchString = params.get("searchString") || "";
    const pageSize = !!sessions.totalNumberOfItems
      ? sessions.totalNumberOfItems.toString()
      : "8";
    const userId = params.get("userId") || "";
    const clientId = params.get("clientId") || "";
    const onlyNew = params.get("onlyNew");
    const startDate = params.get("startDate");
    const endDate = params.get("endDate");

    if (!startDate || !endDate) return;

    const data: GetBillingProperties = {
      page,
      pageSize,
      startDate,
      endDate,
      onlyNew: !!onlyNew,
      direction,
      orderBy,
      searchString,
      userId,
      clientId,
    };
    dispatch(downloadMBHExcelReport(data))
      .then(unwrapResult)
      .then((response) => {
        downloadFile(response);
        fetchData();
      })
      .catch(errorNormalizer);
  }, [dispatch, fetchData, params, sessions]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <>
      <ReportWrapper>
        <BillingFilters />
        <CustomButton
          onClick={downloadHandler}
          title={"Download"}
          loading={loading}
        />
      </ReportWrapper>
      <CustomTable
        headers={Headers}
        TableBody={TableBody}
        data={sessions}
        loading={loading}
        hasMobile={false}
      />
    </>
  );
};

export default MBHReport;
