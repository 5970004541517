import { FC, FocusEventHandler } from "react";
import Text from "../Text";
import { red500 } from "../../Helpers/colors";
import { DatePicker } from "antd";
import { Dayjs } from "dayjs";
import { DatePickerProps, RangePickerProps } from "antd/es/date-picker";

interface DatePickerProperties {
  label: string;
  value: Dayjs | null;
  onChange: DatePickerProps["onChange"];
  className?: string;
  error?: boolean;
  disabled?: boolean;
  showToday?: boolean;
  errorMessage?: string;
  description?: string;
  disabledDate?: RangePickerProps["disabledDate"];
  onBlur?: FocusEventHandler<HTMLInputElement>;
}

const CustomDate: FC<DatePickerProperties> = ({
  label,
  value,
  onChange,
  className = "",
  error = false,
  disabled = false,
  showToday = false,
  errorMessage = "",
  description = "",
  disabledDate,
  onBlur,
}) => {
  return (
    <div className={className}>
      <Text
        className={`inputLabel ${!!label ? "active" : ""}`}
        title={label}
        size={"tinyBold"}
      />
      <DatePicker
        value={value}
        format={"MM/DD/YYYY"}
        placeholder={"01/01/2005"}
        onChange={onChange}
        onBlur={onBlur}
        size={"large"}
        inputReadOnly={false}
        allowClear={false}
        changeOnBlur={true}
        disabled={disabled}
        getPopupContainer={(triggerNode) => triggerNode}
        disabledDate={disabledDate}
        showToday={showToday}
      />
      <Text
        className={`error ${error ? "active" : ""}`}
        title={errorMessage}
        size={"tiny"}
        textColor={red500}
      />
      <Text
        className={`inputDescription ${
          !error && !!description ? "active" : ""
        }`}
        title={description}
        size={"tiny"}
      />
    </div>
  );
};

export default CustomDate;
