import { useState } from "react";
import { useSearchParams } from "react-router-dom";
import { SelectChangeEvent } from "@mui/material";

import { CustomSelect } from "../../shared/uiComponents";
import { SelectDataProperties } from "../../shared/uiComponents/Dropdown";

const MobileSort = ({
  data,
  hasStatus = false,
  statusData = [],
}: {
  data: Array<SelectDataProperties>;
  hasStatus?: boolean;
  statusData?: Array<SelectDataProperties>;
}) => {
  const [params, setParams] = useSearchParams();

  const [orderBy, setOrderBy] = useState<string>(data[0]?.id as string);
  const [direction, setDirection] = useState<string>("asc");
  const [status, setStatus] = useState<string>(statusData[0]?.id as string);

  const handleOrderBy = (event: SelectChangeEvent<string>) => {
    const value = event.target.value;
    params.set("orderBy", value);
    setParams(params);
    setOrderBy(value);
  };

  const handleDirection = (event: SelectChangeEvent<string>) => {
    const value = event.target.value;
    params.set("direction", value);
    setParams(params);
    setDirection(value);
  };

  const handleStatus = (event: SelectChangeEvent<string>) => {
    const value = event.target.value;
    if (value === "all") {
      params.delete("status");
      setStatus("");
      setParams(params);
      return;
    }
    params.set("status", value);
    setParams(params);
    setStatus(value);
  };

  return (
    <>
      <CustomSelect
        label="Select sort"
        data={data}
        value={orderBy}
        setValue={handleOrderBy}
        className="marginBottom16"
      />
      <CustomSelect
        label="Select direction"
        data={[
          { id: "asc", name: "Ascending" },
          { id: "desc", name: "Descending" },
        ]}
        value={direction}
        setValue={handleDirection}
        className="marginBottom16"
      />
      {hasStatus && (
        <CustomSelect
          label="Select status"
          data={statusData}
          value={status}
          setValue={handleStatus}
          className="marginBottom16"
        />
      )}
    </>
  );
};

export default MobileSort;
