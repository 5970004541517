import { ChangeEvent, useState, FC, useEffect } from "react";
import { useSearchParams } from "react-router-dom";

import {
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TablePagination,
  TableBody as Body,
} from "@mui/material";
import {
  ImportExportOutlined as ImportExport,
  CloseOutlined as Close,
} from "@mui/icons-material/";

import "./table.scss";

import { TableProperties } from "./tableProperties";
import { TableLoader } from "./tableHelpers";
import Text from "../Text";

const CustomTable: FC<TableProperties> = ({
  headers,
  data,
  TableBody,
  loading,
  hasMobile = true,
  hasPagination = true,
}) => {
  const [params, setParams] = useSearchParams();
  const [order, setOrder] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const [title, setTitle] = useState<string>("");

  const handleChangePage = (event: unknown, newPage: number) => {
    const page = newPage + 1;
    const pageSize = params.get("pageSize") || "8";
    params.set("page", `${page}`);
    params.set("pageSize", pageSize);
    if (page > 0) {
      setParams(params);
    }
  };

  const handlePageSize = (event: ChangeEvent<HTMLInputElement>) => {
    const pageSize = event.target.value;
    params.set("page", "1");
    params.set("pageSize", pageSize);
    setParams(params);
  };

  const handleSort = (orderBy: string) => {
    const direction = !order ? "asc" : "desc";
    params.set("direction", direction);
    params.set("orderBy", orderBy);
    setOrder((prev) => !prev);

    setParams(params);
  };

  const selectStatusHandler = (name: string, id: number) => {
    if (name.toLowerCase() === "all") {
      params.delete("status");
      setTitle("");
      setParams(params);
      setOpen(false);
      return;
    }
    params.set("status", `${id}`);
    setTitle(name);
    setOpen(false);
    setParams(params);
  };

  useEffect(() => {
    if (!params.get("status")) setTitle("");
  }, [params]);

  return (
    <TableContainer className={hasMobile ? "tableContainer" : ""}>
      <Table>
        <TableHead className={"tableHeader"}>
          <TableRow>
            {!!headers &&
              headers.map((item) => (
                <TableCell key={item.id} width={item.width}>
                  {!item.titles ? (
                    <div className="nameTitle">
                      <Text
                        title={item.name}
                        size="smallBold"
                        style={{ wordBreak: "normal" }}
                      />
                      {!!item.orderBy ? (
                        <ImportExport
                          onClick={() => handleSort(item.orderBy as string)}
                        />
                      ) : (
                        <div style={{ width: "24px" }} />
                      )}
                    </div>
                  ) : (
                    <div className="nameTitleFilter">
                      <Text
                        title={title || item.name}
                        size="smallBold"
                        onClick={() => setOpen(true)}
                      />
                      {!!item.orderBy ? (
                        <ImportExport
                          onClick={() => handleSort(item.orderBy as string)}
                        />
                      ) : (
                        <div style={{ width: "24px" }} />
                      )}
                      <div
                        className="filterWrapper"
                        style={{
                          display: open ? "block" : "none",
                        }}
                        onMouseLeave={() => setOpen(false)}
                      >
                        <div
                          className="title marginBottom8"
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Text
                            title={item.titlesHeader as string}
                            size="smallBold"
                          />
                          <Close onClick={() => setOpen(false)} />
                        </div>
                        {item.titles.map((name, key) => (
                          <div key={key}>
                            <Text
                              title={name}
                              size="smallBold"
                              onClick={() => selectStatusHandler(name, key)}
                            />
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                </TableCell>
              ))}
          </TableRow>
        </TableHead>
        {!loading ? (
          !!data.query && !!data.query.length ? (
            <TableBody data={data.query} />
          ) : (
            <Body>
              <TableRow>
                <TableCell colSpan={headers.length}>
                  <Text title={"No Data"} size="mediumBold" center />
                </TableCell>
              </TableRow>
            </Body>
          )
        ) : (
          <TableLoader spanScope={headers.length} />
        )}
      </Table>
      {hasPagination && (
        <TablePagination
          rowsPerPageOptions={[4, 8, 12, 25, 50]}
          count={data.totalNumberOfItems as number}
          page={parseInt(params.get("page") || "1") - 1}
          rowsPerPage={parseInt(params.get("pageSize") || "8")}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handlePageSize}
          component={"div"}
        />
      )}
    </TableContainer>
  );
};

export default CustomTable;
