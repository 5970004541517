import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

import { CustomButton, CustomAlert } from "../../../../shared/uiComponents";

import { DispatchProperties, StateProperties } from "../../../../redux/store";
import {
  deleteTarget,
  getTargets,
} from "../../../../redux/State/clientSlice/programTargetSlice";
import { errorNormalizer } from "../../../../shared/Helpers/functions";

const Delete = ({ id, programId }: { id: string; programId: string }) => {
  const [openDelete, setOpenDelete] = useState<boolean>(false);
  const dispatch = useDispatch<DispatchProperties>();
  const loading = useSelector(
    (state: StateProperties) => state.programTarget.loading
  );

  const deleteTargetHandler = () => {
    if (!id || !programId) return;

    dispatch(deleteTarget({ id, programId }))
      .then(unwrapResult)
      .then(() => {
        dispatch(getTargets(programId));
        setOpenDelete(false);
        toast("Deleted");
      })
      .catch(errorNormalizer);
  };

  return (
    <>
      <CustomButton title="Delete" onClick={() => setOpenDelete(true)} />
      <CustomAlert
        title="Are you sure?"
        onClose={() => setOpenDelete(false)}
        open={openDelete}
        Content={() => (
          <div
            style={{
              display: "flex",
              justifyContent: "space-around",
            }}
          >
            <CustomButton
              title="Delete target"
              onClick={deleteTargetHandler}
              loading={loading}
              disabled={loading}
            />
            <CustomButton
              title="Cancel"
              secondaryButton
              onClick={() => setOpenDelete(false)}
            />
          </div>
        )}
      />
    </>
  );
};

export default Delete;
