import { configureStore } from '@reduxjs/toolkit';
import rootReducer from './rootReducer';
import { localStorageMiddleware } from "./middleware";
import { TypedUseSelectorHook, useSelector as useReduxSelector } from 'react-redux';

const store = configureStore({
    reducer: rootReducer,
    middleware: getDefaultMiddleware => getDefaultMiddleware().concat(localStorageMiddleware)
})

export type StateProperties = ReturnType<typeof store.getState>
export type DispatchProperties = typeof store.dispatch
export const useSelector: TypedUseSelectorHook<StateProperties> = useReduxSelector

export default store;