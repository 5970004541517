import { SOAPNoteWrapper } from "../helpers";
import CreateSoapNote from "./createTreatmentPlanningSoapNote";
import UpdateSoapNote from "./updateTreatmentPlanningSoapNote";

const TreatmentPlanning = () => (
  <SOAPNoteWrapper
    CreateSoapNote={CreateSoapNote}
    UpdateSoapNote={UpdateSoapNote}
  />
);

export default TreatmentPlanning;
