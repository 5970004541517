import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import API from "../../API/Main/API";
import { BillingSessionsProperties, GetBillingProperties, GetTotalBillingProperties } from "../../API/ClientAPIHelpers/billingProperties";
import { ErrorProperties } from "../../API/identityAPIProperties";

interface BillingInitialStateProperties {
    loading: boolean,
    sessions: BillingSessionsProperties,
    nonCredentialedSessions: BillingSessionsProperties,
    error: ErrorProperties,
}

const initialState: BillingInitialStateProperties = {
    loading: false,
    sessions: {
        query: null,
    },
    nonCredentialedSessions: {
        query: null,
    },
    error: {
        status: 0,
        title: "",
    }
}

export const getBillingSessions = createAsyncThunk(
    'billing/sessions',
    async (data: GetBillingProperties, thunkAPI) => {
        const response = await API.ClientAPI.Billing.getSessions(data);
        if (!!response.error) {
            return thunkAPI.rejectWithValue(response.error);
        }
        return response as BillingSessionsProperties;
    }
)

export const getNonCredentialedSessions = createAsyncThunk(
    'billing/sessions/non-credentialed',
    async (data: GetBillingProperties, thunkAPI) => {
        const response = await API.ClientAPI.Billing.getNonCredentialedSessions(data);
        if (!!response.error) {
            return thunkAPI.rejectWithValue(response.error);
        }
        return response as BillingSessionsProperties;
    }
)

export const downloadMBHExcelReport = createAsyncThunk(
    'billing/sessions/download/mbh',
    async (data: GetBillingProperties, thunkAPI) => {
        const response = await API.ClientAPI.Billing.downloadMBHExcelReport(data);
        if (!!response.error) {
            return thunkAPI.rejectWithValue(response.error);
        }
        return response as {
            file: Blob,
            fileName: string,
        };;
    }
)

export const downloadNCExcelReport = createAsyncThunk(
    'billing/sessions/download/nc',
    async (data: GetBillingProperties, thunkAPI) => {
        const response = await API.ClientAPI.Billing.downloadNCExcelReport(data);
        if (!!response.error) {
            return thunkAPI.rejectWithValue(response.error);
        }
        return response as {
            file: Blob,
            fileName: string,
        };
    }
)

export const downloadTotalReport = createAsyncThunk(
    'billing/sessions/download/total',
    async (data: GetTotalBillingProperties, thunkAPI) => {
        const response = await API.ClientAPI.Billing.downloadTotalSesions(data);
        if (!!response.error) {
            return thunkAPI.rejectWithValue(response.error);
        }
        return response;
    }
)

const BillingSlice = createSlice({
    name: 'billing',
    initialState: initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getBillingSessions.pending, (state) => {
            state.loading = true;
        })
        builder.addCase(getBillingSessions.fulfilled, (state, action) => {
            state.loading = false;
            state.sessions = action.payload;
            state.error = initialState.error;
        })
        builder.addCase(getBillingSessions.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload as ErrorProperties;
        })
        builder.addCase(getNonCredentialedSessions.pending, (state) => {
            state.loading = true;
        })
        builder.addCase(getNonCredentialedSessions.fulfilled, (state, action) => {
            state.loading = false;
            state.nonCredentialedSessions = action.payload;
            state.error = initialState.error;
        })
        builder.addCase(getNonCredentialedSessions.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload as ErrorProperties;
        })
        builder.addCase(downloadMBHExcelReport.pending, (state) => {
            state.loading = true;
        })
        builder.addCase(downloadMBHExcelReport.fulfilled, (state) => {
            state.loading = false;
            state.error = initialState.error;
        })
        builder.addCase(downloadMBHExcelReport.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload as ErrorProperties;
        })
        builder.addCase(downloadTotalReport.pending, (state) => {
            state.loading = true;
        })
        builder.addCase(downloadTotalReport.fulfilled, (state) => {
            state.loading = false;
            state.error = initialState.error;
        })
        builder.addCase(downloadTotalReport.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload as ErrorProperties;
        })
        builder.addCase(downloadNCExcelReport.pending, (state) => {
            state.loading = true;
        })
        builder.addCase(downloadNCExcelReport.fulfilled, (state) => {
            state.loading = false;
            state.error = initialState.error;
        })
        builder.addCase(downloadNCExcelReport.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload as ErrorProperties;
        })
    }
})

export default BillingSlice.reducer;