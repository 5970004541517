import { useState } from "react";
import { AutoGraphOutlined as Graph } from "@mui/icons-material";

import "./graphTarget.scss";
import { CustomAlert, CustomButton } from "../../../../../shared/uiComponents";
import GraphTargetContent from "./graphTargetContent";
import { TargetTypes } from "../../../../../redux/API/ClientAPIHelpers/programTargetsProperties";

const GraphsTarget = ({
  targetId,
  targetName,
  type,
  isIcon,
}: {
  targetId: string;
  targetName: string;
  type: TargetTypes;
  isIcon: boolean;
}) => {
  const [openGraphs, setOpenGraphs] = useState<boolean>(false);
  return (
    <>
      {isIcon ? (
        <Graph
          onClick={() => setOpenGraphs(true)}
          style={{ cursor: "pointer" }}
        />
      ) : (
        <CustomButton title="Graph" onClick={() => setOpenGraphs(true)} />
      )}
      <CustomAlert
        title={targetName}
        onClose={() => setOpenGraphs(false)}
        open={openGraphs}
        Content={() => (
          <GraphTargetContent
            targetId={targetId}
            type={type}
            targetName={targetName}
          />
        )}
      />
    </>
  );
};

export default GraphsTarget;
