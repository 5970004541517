import { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { styled } from "@mui/material";
import { useDispatch } from "react-redux";
import dayjs from "dayjs";

import { CustomSelect, Text } from "../../../shared/uiComponents";
import { SoapNoteTypes } from "../../BCBAPanel/SOAPNote/SOAPNoteTypes";

import { DispatchProperties, useSelector } from "../../../redux/store";
import {
  getSessionInfo,
  getSessionTypes,
} from "../../../redux/State/clientSlice/sessionSlice";

const EditSoapNote = () => {
  const dispatch = useDispatch<DispatchProperties>();
  const [params] = useSearchParams();

  const [service, setService] = useState<string>("");

  const sessionTypes = useSelector((state) => state.session.sessionTypes);
  const { client, date } = useSelector(
    (state) => state.session.sessionInfo
  );

  useEffect(() => {
    if (!!sessionTypes.length) return;
    dispatch(getSessionTypes());
  }, [dispatch, sessionTypes]);

  useEffect(() => {
    const sessionId = params.get("sessionId");
    if (!sessionId) return;

    dispatch(getSessionInfo(sessionId));
  }, [params, dispatch]);

  useEffect(() => {
    const type = params.get("type");
    if (!type) return;
    setService(type);
  }, [params]);

  return (
    <div
      className="container"
      style={{ flexDirection: "column", maxWidth: "900px" }}
    >
      <HeaderWrapper className="title marginBottom16">
        <Text title="SOAP Note" size="mediumBold" />
        <Text title={`Date: ${dayjs(date).format("MM/DD/YYYY")}`} />
        <Text title={client?.fullName} size="smallBold" />
      </HeaderWrapper>

      <CustomSelect
        label="Service:"
        value={service}
        data={sessionTypes}
        setValue={() => {}}
        disabled={true}
      />
      <div className="marginBottom16" />

      {service === "1" && <SoapNoteTypes.Assessment />}
      {service === "2" && <SoapNoteTypes.TreatmentPlanning />}
      {service === "3" && <SoapNoteTypes.BehaviorTreatment />}
      {service === "4" && <SoapNoteTypes.DirectSupervision />}
      {service === "5" && <SoapNoteTypes.BehaviorTreatmentModification />}
      {service === "6" && <SoapNoteTypes.ParentTraining />}
      {service === "7" && <SoapNoteTypes.TreatmentPlanning />}
    </div>
  );
};

export const HeaderWrapper = styled("div")(() => ({
  display: "flex",
  alignItems: "center",
  "&>div": {
    width: "50%",
  },
}));

export default EditSoapNote;
