import { styled } from "@mui/material/styles";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import { Drawer, DrawerProps, Toolbar } from "@mui/material";

import { black900, white200 } from "../../shared/Helpers/colors";
import { useScreenWidth } from "../../shared/Helpers/hooks";

export const drawerWidth = 300;

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

export const Container = styled("div")(() => ({
  display: "flex",
  backgroundColor: white200,
}));

export const Main = styled("main", {
  shouldForwardProp: (prop) => prop !== "open",
})<{
  open?: boolean;
}>(({ theme, open }) => {
  const { transitions } = theme;
  const { create, duration, easing } = transitions;
  return {
    flexGrow: 1,
    height: "100%",
    overflow: "auto",
    width: "100%",
    transition: create("margin", {
      easing: easing.sharp,
      duration: duration.leavingScreen,
    }),
    ...(open && {
      transition: create("margin", {
        easing: easing.easeOut,
        duration: duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  };
});

export const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => {
  const { transitions } = theme;
  const { create, duration, easing } = transitions;
  const screenWidth = useScreenWidth();
  return {
    transition: create(["margin", "width"], {
      easing: easing.sharp,
      duration: duration.leavingScreen,
    }),
    ...(open && {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: `${drawerWidth}px`,
      transition: create(["margin", "width"], {
        easing: easing.easeOut,
        duration: duration.enteringScreen,
      }),
      ">div": { display: "none" },
    }),
    ...(screenWidth > 1024 && {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: `${drawerWidth}px`,
    }),
    backgroundColor: white200,
    color: black900,
  };
});

export const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-start",
  width: '100%',
  height: '64px',
  overflow: 'hidden',
  position: 'relative',
}));

export const DrawerWrapper = styled(Drawer)<DrawerProps>(() => ({
  width: drawerWidth,
  flexShrink: 0,
  "& .MuiDrawer-paper": {
    width: drawerWidth,
    backgroundColor: white200,
  },
}));

export const IMG = styled("img")(() => ({
  height: 'auto',
  width: '100%',
}));

export const Wrapper = styled("div")(() => ({
  backgroundColor: white200,
  padding: "24px",
  height: "100%",
  minHeight: "calc(100vh - 64px)",
}));

export const FlexCenter = styled("div")(() => ({
  display: "flex",
  alignItems: "center",
}));

export const CustomToolbar = styled(Toolbar)(() => ({
  justifyContent: "space-between",
  alignItems: "center",
}));
