import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import API from "../../API/Main/API";
import {
    ActiveTargetProperties,
    AddTargetSteps,
    CreateProgramTargetProperties,
    GetTargetProperties,
    MoveOptionProperties,
    MoveTargetProperties,
    TargetHistoryProperties,
    TargetProperties,
    TargetStepsDataProperties,
    UpdateTargetProperties,
    UpdateTargetStepsProperties,
} from "../../API/ClientAPIHelpers/programTargetsProperties";
import { ErrorProperties } from "../../API/identityAPIProperties";

interface ProgramTargetStateProperties {
    loading: boolean;
    loadingHistory: boolean;
    loadingTargetOptions: boolean;
    loadingGetTargets: boolean;
    loadingGetTargetSteps: boolean;
    targets: Array<TargetProperties>;
    programTargetsInTreatment: Array<ActiveTargetProperties>;
    targetSteps: Array<TargetStepsDataProperties | UpdateTargetProperties>;
    history: Array<TargetHistoryProperties>;
    moveOptions: Array<MoveOptionProperties>;
    error: ErrorProperties;
}

export const getTargets = createAsyncThunk(
    '/client/targets/all',
    async (programId: string, thunkAPI) => {
        const response = await API.ClientAPI.ProgramTargets.getTargets(programId);
        if (!!response.error) {
            return thunkAPI.rejectWithValue(response.error);
        }
        return response;
    }
)

export const getTargetHistory = createAsyncThunk(
    '/client/targets/history',
    async ({ programId, id }: GetTargetProperties, thunkAPI) => {
        const response = await API.ClientAPI.ProgramTargets.getHistory({ programId, id });
        if (!!response.error) {
            return thunkAPI.rejectWithValue(response.error);
        }
        return response;
    }
)

export const getMoveTargetOptions = createAsyncThunk(
    '/client/targets/move/options',
    async ({ programId, id }: GetTargetProperties, thunkAPI) => {
        const response = await API.ClientAPI.ProgramTargets.getMoveOptions({ programId, id });
        if (!!response.error) {
            return thunkAPI.rejectWithValue(response.error);
        }
        return response;
    }
)

export const updateTarget = createAsyncThunk(
    '/client/targets/update',
    async (data: UpdateTargetProperties, thunkAPI) => {
        const response = await API.ClientAPI.ProgramTargets.updateTarget(data);
        if (!!response.error) {
            return thunkAPI.rejectWithValue(response.error);
        }
        return response;
    }
)

export const deleteTarget = createAsyncThunk(
    '/client/targets/target',
    async (data: GetTargetProperties, thunkAPI) => {
        const response = await API.ClientAPI.ProgramTargets.deleteTarget(data);

        if (!!response.error) {
            return thunkAPI.rejectWithValue(response.error);
        }
        return response;
    }
)

export const createTarget = createAsyncThunk(
    '/client/targets/create',
    async ({ programId, data }: { data: CreateProgramTargetProperties, programId: string }, thunkAPI) => {
        const response = await API.ClientAPI.ProgramTargets.createTarget(programId, data);
        if (!!response.error) {
            return thunkAPI.rejectWithValue(response.error);
        }
        return response;
    }
)

export const copyTarget = createAsyncThunk(
    '/client/targets/copy',
    async ({ programId, id }: GetTargetProperties, thunkAPI) => {
        const response = await API.ClientAPI.ProgramTargets.copyTarget({ programId, id });
        if (!!response.error) {
            return thunkAPI.rejectWithValue(response.error);
        }
        return response;
    }
)

export const moveTarget = createAsyncThunk(
    '/client/targets/move',
    async (data: MoveTargetProperties, thunkAPI) => {
        const response = await API.ClientAPI.ProgramTargets.moveTarget(data);
        if (!!response.error) {
            return thunkAPI.rejectWithValue(response.error);
        }
        return response;
    }
)

export const openTarget = createAsyncThunk(
    '/client/targets/open',
    async ({ programId, id }: GetTargetProperties, thunkAPI) => {
        const response = await API.ClientAPI.ProgramTargets.openTarget({ programId, id });
        if (!!response.error) {
            return thunkAPI.rejectWithValue(response.error);
        }
        return response;
    }
)

export const holdTarget = createAsyncThunk(
    '/client/targets/hold',
    async ({ programId, id }: GetTargetProperties, thunkAPI) => {
        const response = await API.ClientAPI.ProgramTargets.holdTarget({ programId, id });
        if (!!response.error) {
            return thunkAPI.rejectWithValue(response.error);
        }
        return response;
    }
)

export const masterOutTarget = createAsyncThunk(
    '/client/targets/master-out',
    async ({ programId, id }: GetTargetProperties, thunkAPI) => {
        const response = await API.ClientAPI.ProgramTargets.masterOutTarget({ programId, id });
        if (!!response.error) {
            return thunkAPI.rejectWithValue(response.error);
        }
        return response;
    }
)

export const importTargets = createAsyncThunk(
    '/client/targets/import',
    async ({ file, clientId }: { file: any; clientId: string }, thunkAPI) => {
        const response = await API.ClientAPI.ProgramTargets.client.importTargets(clientId, file);
        if (!!response.error) {
            return thunkAPI.rejectWithValue(response.error);
        }
        return response;
    }
)

export const getTargetSteps = createAsyncThunk(
    '/client/targets/steps',
    async (data: GetTargetProperties, thunkAPI) => {
        const response = await API.ClientAPI.ProgramTargets.getTargetSteps(data);
        if (!!response.error) {
            return thunkAPI.rejectWithValue(response.error);
        }
        return response as Array<UpdateTargetStepsProperties>;
    }
)

export const addTargetSteps = createAsyncThunk(
    '/client/targets/steps/add',
    async (data: AddTargetSteps, thunkAPI) => {
        const response = await API.ClientAPI.ProgramTargets.addTargetSteps(data);
        if (!!response.error) {
            return thunkAPI.rejectWithValue(response.error);
        }
        return response;
    }
)

export const getInTreatmentTargets = createAsyncThunk(
    '/client/targets/inTreatment/get',
    async (programId: string, thunkAPI) => {
        const response = await API.ClientAPI.ProgramTargets.getInTreatmentTargets(programId);
        if (!!response.error) {
            return thunkAPI.rejectWithValue(response.error);
        }
        return response as Array<ActiveTargetProperties>;
    }
)

const initialProgramTargetState: ProgramTargetStateProperties = {
    loading: false,
    loadingHistory: false,
    loadingTargetOptions: false,
    loadingGetTargets: false,
    loadingGetTargetSteps: false,
    targets: [],
    programTargetsInTreatment: [],
    targetSteps: [],
    history: [],
    moveOptions: [],
    error: {
        status: 0,
        title: '',
    },
}

const ClientProgramTargetSlice = createSlice({
    name: 'programTarget',
    initialState: initialProgramTargetState,
    reducers: {
        clearActiveTargets(state) {
            state.programTargetsInTreatment = initialProgramTargetState.programTargetsInTreatment;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(createTarget.pending, (state) => {
            state.loading = true;
        })
        builder.addCase(createTarget.fulfilled, (state) => {
            state.loading = false;
            state.error = initialProgramTargetState.error;
        })
        builder.addCase(createTarget.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload as ErrorProperties;
        })

        builder.addCase(openTarget.pending, (state) => {
            state.loading = true;
        })
        builder.addCase(openTarget.fulfilled, (state) => {
            state.loading = false;
            state.error = initialProgramTargetState.error;
        })
        builder.addCase(openTarget.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload as ErrorProperties;
        })

        builder.addCase(updateTarget.pending, (state) => {
            state.loading = true;
        })
        builder.addCase(updateTarget.fulfilled, (state) => {
            state.loading = false;
            state.error = initialProgramTargetState.error;
        })
        builder.addCase(updateTarget.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload as ErrorProperties;
        })

        builder.addCase(copyTarget.pending, (state) => {
            state.loading = true;
        })
        builder.addCase(copyTarget.fulfilled, (state) => {
            state.loading = false;
            state.error = initialProgramTargetState.error;
        })
        builder.addCase(copyTarget.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload as ErrorProperties;
        })

        builder.addCase(moveTarget.pending, (state) => {
            state.loading = true;
        })
        builder.addCase(moveTarget.fulfilled, (state) => {
            state.loading = false;
            state.error = initialProgramTargetState.error;
        })
        builder.addCase(moveTarget.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload as ErrorProperties;
        })

        builder.addCase(masterOutTarget.pending, (state) => {
            state.loading = true;
        })
        builder.addCase(masterOutTarget.fulfilled, (state) => {
            state.loading = false;
            state.error = initialProgramTargetState.error;
        })
        builder.addCase(masterOutTarget.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload as ErrorProperties;
        })

        builder.addCase(holdTarget.pending, (state) => {
            state.loading = true;
        })
        builder.addCase(holdTarget.fulfilled, (state) => {
            state.loading = false;
            state.error = initialProgramTargetState.error;
        })
        builder.addCase(holdTarget.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload as ErrorProperties;
        })

        builder.addCase(deleteTarget.pending, (state) => {
            state.loading = true;
        })
        builder.addCase(deleteTarget.fulfilled, (state) => {
            state.loading = false;
            state.error = initialProgramTargetState.error;
        })
        builder.addCase(deleteTarget.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload as ErrorProperties;
        })

        builder.addCase(getTargets.pending, (state) => {
            state.loadingGetTargets = true;
        })
        builder.addCase(getTargets.fulfilled, (state, action) => {
            state.loadingGetTargets = false;
            state.targets = action.payload;
            state.error = initialProgramTargetState.error;
        })
        builder.addCase(getTargets.rejected, (state, action) => {
            state.loadingGetTargets = false;
            state.error = action.payload as ErrorProperties;
        })

        builder.addCase(getTargetHistory.pending, (state) => {
            state.loadingHistory = true;
        })
        builder.addCase(getTargetHistory.fulfilled, (state, action) => {
            state.loadingHistory = false;
            state.history = action.payload;
            state.error = initialProgramTargetState.error;
        })
        builder.addCase(getTargetHistory.rejected, (state, action) => {
            state.loadingHistory = false;
            state.error = action.payload as ErrorProperties;
        })

        builder.addCase(getMoveTargetOptions.pending, (state) => {
            state.loadingTargetOptions = true;
        })
        builder.addCase(getMoveTargetOptions.fulfilled, (state, action) => {
            state.loadingTargetOptions = false;
            state.moveOptions = action.payload;
            state.error = initialProgramTargetState.error;
        })
        builder.addCase(getMoveTargetOptions.rejected, (state, action) => {
            state.loadingTargetOptions = false;
            state.error = action.payload as ErrorProperties;
        })

        builder.addCase(importTargets.pending, (state) => {
            state.loading = true;
        })
        builder.addCase(importTargets.fulfilled, (state) => {
            state.loading = false;
            state.error = initialProgramTargetState.error;
        })
        builder.addCase(importTargets.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload as ErrorProperties;
        })

        builder.addCase(getTargetSteps.pending, (state) => {
            state.loadingGetTargetSteps = true;
        })
        builder.addCase(getTargetSteps.fulfilled, (state, action) => {
            state.loadingGetTargetSteps = false;
            state.targetSteps = action.payload;
            state.error = initialProgramTargetState.error;
        })
        builder.addCase(getTargetSteps.rejected, (state, action) => {
            state.loadingGetTargetSteps = false;
            state.error = action.payload as ErrorProperties;
        })

        builder.addCase(addTargetSteps.pending, (state) => {
            state.loadingGetTargetSteps = true;
            state.error = initialProgramTargetState.error;
        })
        builder.addCase(addTargetSteps.fulfilled, (state) => {
            state.loadingGetTargetSteps = false;
            state.error = initialProgramTargetState.error;
        })
        builder.addCase(addTargetSteps.rejected, (state, action) => {
            state.loadingGetTargetSteps = false;
            state.error = action.payload as ErrorProperties;
        })

        builder.addCase(getInTreatmentTargets.pending, (state) => {
            state.loading = true;
            state.error = initialProgramTargetState.error;
        })
        builder.addCase(getInTreatmentTargets.fulfilled, (state, action) => {
            state.loading = false;
            state.programTargetsInTreatment = action.payload;
            state.error = initialProgramTargetState.error;
        })
        builder.addCase(getInTreatmentTargets.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload as ErrorProperties;
        })
    }
});

export const {
    clearActiveTargets,
} = ClientProgramTargetSlice.actions;
export default ClientProgramTargetSlice.reducer;