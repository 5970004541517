import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import { useSearchParams } from "react-router-dom";

import { errorNormalizer } from "../../../../shared/Helpers/functions";
import { CustomButton } from "../../../../shared/uiComponents";
import "../reportStyles.scss";
import { Filters } from "../filters";

import { DispatchProperties, useSelector } from "../../../../redux/store";
import { ReportWrapper } from "..";
import { getUtilizationReport } from "../../../../redux/State/clientSlice/insuranceAuthorizationSlice";
import { downloadFile } from "../BillingReports/downloadFile";

const UtilizationReport = () => {
  const dispatch = useDispatch<DispatchProperties>();

  const [params] = useSearchParams();

  const loading = useSelector((state) => state.authorization.loading);

  const downloadHandler = useCallback(() => {
    const clientId = params.get("clientId");
    const startDate = params.get("startDate");
    const endDate = params.get("endDate");

    if (!clientId || !startDate || !endDate) return;
    dispatch(
      getUtilizationReport({
        clientId,
        startDate,
        endDate,
      })
    )
      .then(unwrapResult)
      .then((response) => downloadFile(response))
      .catch(errorNormalizer);
  }, [dispatch, params]);

  return (
    <ReportWrapper>
      <Filters />
      <CustomButton
        title="Download"
        onClick={downloadHandler}
        loading={loading}
        disabled={!params.get("clientId")}
      />
    </ReportWrapper>
  );
};

export default UtilizationReport;
