import { FetchAPI } from "../Main/fetchAPI";
import { SKILL_AREA } from "../constants";
import { GetSkillAreaProperties, SkillAreaProperties } from "./skillAreaProperties";

export const SkillArea = {
    getSkillAreas: ({
        clientId,
        includeArchived = false,
    }: GetSkillAreaProperties) =>
        FetchAPI.getJson({
            urlStr: `${SKILL_AREA(clientId)}?includeArchived=${includeArchived}`,
        }),
    addSkillArea: (clientId: string, data: SkillAreaProperties) =>
        FetchAPI.postJson({
            urlStr: `${SKILL_AREA(clientId)}`,
            data,
        }),
    copySkillArea: (clientId: string, id: string) =>
        FetchAPI.postJson({
            urlStr: `${SKILL_AREA(clientId)}/${id}/copy`,
        }),
    updateSkillArea: (
        clientId: string,
        id: string,
        data: SkillAreaProperties
    ) =>
        FetchAPI.putJson({
            urlStr: `${SKILL_AREA(clientId)}/${id}`,
            data,
        }),
    deleteSkillArea: (clientId: string, id: string) =>
        FetchAPI.deleteJson({
            urlStr: `${SKILL_AREA(clientId)}/${id}`,
        }),
    archiveSkillArea: (clientId: string, id: string) =>
        FetchAPI.postJson({
            urlStr: `${SKILL_AREA(clientId)}/${id}/archive`,
        }),
    unarchiveSkillArea: (clientId: string, id: string) =>
        FetchAPI.postJson({
            urlStr: `${SKILL_AREA(clientId)}/${id}/un-archive`,
        }),
}