import { FetchAPI } from "../Main/fetchAPI";
import {
    AddClientProgramProperties,
    GetProgramsProperties,
    ProgramProperties,
    UpdateProgramProperties,
} from "./clientProgramProperties";
import { CLIENTS, PROGRAMS } from "../constants";

export const ClientProgram = {
    getPrograms: ({ skillAreaId, includeArchived = false }: GetProgramsProperties) =>
        FetchAPI.getJson({
            urlStr: `${PROGRAMS(skillAreaId)}?includeArchived=${includeArchived}`,
        }),
    addProgram: (skillAreaId: string, data: AddClientProgramProperties) =>
        FetchAPI.postJson({
            urlStr: `${PROGRAMS(skillAreaId)}`,
            data,
        }),
    copyProgram: ({ id, skillAreaId }: ProgramProperties) =>
        FetchAPI.postJson({
            urlStr: `${PROGRAMS(skillAreaId)}/${id}/copy`,
        }),
    updateProgram: (
        skillAreaId: string,
        id: string,
        data: UpdateProgramProperties
    ) =>
        FetchAPI.putJson({
            urlStr: `${PROGRAMS(skillAreaId)}/${id}`,
            data,
        }),
    deleteProgram: ({ id, skillAreaId }: ProgramProperties) =>
        FetchAPI.deleteJson({
            urlStr: `${PROGRAMS(skillAreaId)}/${id}`,
        }),
    archiveProgram: ({ id, skillAreaId }: ProgramProperties) =>
        FetchAPI.postJson({
            urlStr: `${PROGRAMS(skillAreaId)}/${id}/archive`,
        }),
    unarchiveProgram: ({ id, skillAreaId }: ProgramProperties) =>
        FetchAPI.postJson({
            urlStr: `${PROGRAMS(skillAreaId)}/${id}/un-archive`,
        }),
    getActivePrograms: (clientId: string) =>
        FetchAPI.getJson({
            urlStr: `${CLIENTS}/${clientId}/programs/active`,
        }),
}