import { TargetProperties } from "../../../redux/API/ClientAPIHelpers/programTargetsProperties";
import { Text } from "../../../shared/uiComponents";
import { InfoRow as Row } from "../../AdminPanel/StyledComponents";
import TargetHistory from "../../BCBAPanel/ProgramTargets/TargetInfo/targetHistory";

const RBTRow = ({ item }: { item: TargetProperties }) => {
  const { targetInstructions, id, programId, name, goalName, sd } = item;

  return (
    <div className="targetInfo">
      <div
        style={{
          padding: "10px",
        }}
      >
        <div>
          <Text
            title="Target Details"
            className="title marginBottom8"
            size="mediumBold"
          />
          <Row>
            <Text title="Target Name:" size="smallBold" />
            <Text title={name} />
          </Row>
          <Row>
            <Text title="Goal:" size="smallBold" />
            <Text title={goalName} />
          </Row>
          <Row>
            <Text title="SD:" size="smallBold" />
            <Text title={sd} />
          </Row>
          <div>
            <Text title="Instructions:" size="smallBold" />
            <Text title={targetInstructions} />
          </div>
        </div>
      </div>
      <TargetHistory id={id} programId={programId} />
    </div>
  );
};

export default RBTRow;
