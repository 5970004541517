import { useCallback, useState, ChangeEvent } from "react";
import { useDispatch } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import { useSearchParams } from "react-router-dom";

import { errorNormalizer } from "../../../../shared/Helpers/functions";
import { CustomButton, CustomCheckBox } from "../../../../shared/uiComponents";
import "../reportStyles.scss";
import { Filters } from "../filters";

import { DispatchProperties, useSelector } from "../../../../redux/store";
import { getClientReports } from "../../../../redux/State/exportSlice";
import { ReportWrapper } from "..";

const DownloadSoapNotes = () => {
  const dispatch = useDispatch<DispatchProperties>();

  const [params] = useSearchParams();
  const [includeTrials, setIncludeTrials] = useState<boolean>(false);

  const loading = useSelector((state) => state.export.loading);

  const downloadHandler = useCallback(() => {
    const clientId = params.get("clientId");
    const startDate = params.get("startDate");
    const endDate = params.get("endDate");

    if (!clientId || !startDate || !endDate) return;
    dispatch(
      getClientReports({
        clientId,
        startDate,
        endDate,
        includeTrials,
      })
    )
      .then(unwrapResult)
      .then((response) => {
        const objectURL = URL.createObjectURL(response);
        const link = document.createElement("a");
        link.href = objectURL;
        link.setAttribute(
          "download",
          `SOAP note ${startDate} - ${endDate}.zip`
        );
        document.body.appendChild(link);
        link.click();
      })
      .catch(errorNormalizer);
  }, [dispatch, includeTrials, params]);

  const includeTrialsHandler = (event: ChangeEvent<HTMLInputElement>) => {
    const { checked } = event.target;
    setIncludeTrials(checked);
  };

  return (
    <ReportWrapper>
      <Filters />
      <div>
        <CustomCheckBox
          item={{
            label: "Include Trials",
            id: "0",
            checked: includeTrials,
            disabled: loading,
          }}
          onChange={includeTrialsHandler}
        />
        <CustomButton
          title="Download"
          onClick={downloadHandler}
          loading={loading}
          disabled={!params.get("clientId")}
        />
      </div>
    </ReportWrapper>
  );
};

export default DownloadSoapNotes;
