import { useEffect } from "react";
import { useDispatch } from "react-redux";
import "./calendar.scss";

import CalendarHeader from "./calendarHeader";
import {
  useInitializeDays,
  useScreenWidth,
} from "../../../shared/Helpers/hooks";
import MonthView from "./monthView";
import WeekView from "./weekView";
import CalendarPopups from "./Popups";

import { DispatchProperties, useSelector } from "../../../redux/store";
import { getEvents } from "../../../redux/State/clientSlice/calendarSlice";
import { clearActiveTargets } from "../../../redux/State/clientSlice/programTargetSlice";
import { getSessionTypes } from "../../../redux/State/clientSlice/sessionSlice";
import { useSearchParams } from "react-router-dom";
import { getRoleConfigruation } from "../../../redux/State/identitySlice/accountSlice";

const Calendar = () => {
  useInitializeDays();

  const screenWidth = useScreenWidth();
  const [params] = useSearchParams();
  const dispatch = useDispatch<DispatchProperties>();

  const view = useSelector((state) => state.calendar.calendarView);
  const days = useSelector((state) => state.calendar.displayedCalendarPageDays);

  useEffect(() => {
    dispatch(clearActiveTargets());
    dispatch(getSessionTypes());
    dispatch(getRoleConfigruation());
  }, [dispatch]);

  useEffect(() => {
    if (!days.length) return;

    const clientId = params.get("clientId");
    const userId = params.get("userId");
    const includeRbtEvents =
      params.get("includeRbtEvents") === "true" ? true : false;

    dispatch(getEvents({ days, clientId, userId, includeRbtEvents }));
  }, [dispatch, days, params]);

  return (
    <div className="container" style={{ flexDirection: "column" }}>
      <CalendarHeader />
      {view === 0 && <MonthView />}
      {(view === 1 || screenWidth < 426) && <WeekView />}
      <CalendarPopups />
    </div>
  );
};

export default Calendar;
