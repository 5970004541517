import { useNavigate } from "react-router-dom";

import Wrapper from "../../components/WrapperWithoutMenu";
import { CustomButton, Text } from "../../shared/uiComponents";
import { CheckboxWrapper } from "./StyledComponents";

const Component = () => {
  const navigate = useNavigate();

  const goBack = () => navigate(-1);

  return (
    <CheckboxWrapper>
      <Text
        title="Page not found"
        size="largeBold"
        className="marginBottom24"
      />
      <CustomButton title="Go back" onClick={goBack} />
    </CheckboxWrapper>
  );
};

const AdminNotFound = () => {
  return <Wrapper Component={Component} />;
};

export default AdminNotFound;
