import "./annotationsStyle.scss";
import AddAnnotationPopup from "./addAnnotationPopup";
import ViewAnnotationsPopup from "./viewAnnotationsPopup";

const AnnotationsTarget = ({ targetId, targetName }) => {
  return (
    <div className="btns-block">
      <AddAnnotationPopup targetId={targetId} />
      <ViewAnnotationsPopup targetId={targetId} targetName={targetName} />
    </div>
  );
};

export default AnnotationsTarget;
