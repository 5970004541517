import { useState, FC } from "react";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import { black900, white100 } from "../../Helpers/colors";

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme, color }) => ({
  backgroundColor: "rgba(0, 0, 0, .03)",
  borderRadius: "12px",
  "& .MuiAccordionSummary-expandIconWrapper": {
    color,
  },
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
  borderRadius: "12px",
  outline: "none",
}));

interface AccordionProperties {
  Content: FC<{ open: boolean }>;
  HeaderContent: FC;
  backgroundColor?: string;
  color?: string;
  className?: string;
  headerStyle?: object;
  defaultExpanded?: boolean;
}

const CustomAccordion: FC<AccordionProperties> = ({
  Content,
  HeaderContent,
  backgroundColor = white100,
  color = black900,
  className = "",
  headerStyle = {},
  defaultExpanded = false,
}) => {
  const [expanded, setExpanded] = useState<boolean>(defaultExpanded);

  return (
    <div className={className}>
      <Accordion expanded={expanded} onChange={() => setExpanded(!expanded)}>
        <AccordionSummary
          color={color}
          style={{ backgroundColor, ...headerStyle }}
        >
          <HeaderContent />
        </AccordionSummary>
        <AccordionDetails>
          <Content open={expanded} />
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default CustomAccordion;
