import { styled } from "@mui/material/styles";
import { white200 } from "../../shared/Helpers/colors";

export const Background = styled('div')(() => ({
    width: '100%',
    height: '100vh',
    backgroundColor: white200,
}));

export const ContentWrapper = styled('div')(() => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100vh',
}));