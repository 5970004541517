import { API_URL, UrlProperties, getToken, handleResponse } from "./helpers";

const storedToken = getToken();
let token = !!storedToken ? JSON.parse(storedToken)?.access_token : "";

export const setToken = (newToken: string) => {
    token = newToken;
};

export const FetchAPI = {
    getJson: async ({ urlStr }: UrlProperties): Promise<any> => {
        const response = await fetch(`${API_URL}${urlStr}`, {
            headers: {
                Authorization: "Bearer " + token,
            },
        });
        return await handleResponse({ response, urlStr, method: "getJson" });
    },
    postJson: async ({ urlStr, data }: UrlProperties): Promise<any> => {
        const response = await fetch(`${API_URL}${urlStr}`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + token,
            },
            body: JSON.stringify(data),
        });
        return await handleResponse({ response, urlStr, data, method: "postJson" });
    },
    post: async ({
        urlStr,
        data,
    }: {
        urlStr: string;
        data: any;
    }): Promise<any> => {
        const response = await fetch(`${API_URL}${urlStr}`, {
            method: "POST",
            headers: {
                Authorization: "Bearer " + token,
            },
            body: data,
        });
        return await handleResponse({ response, urlStr, data, method: "post" });
    },
    putJson: async ({ urlStr, data }: UrlProperties): Promise<any> => {
        const response = await fetch(`${API_URL}${urlStr}`, {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + token,
            },
            body: JSON.stringify(data),
        });
        return await handleResponse({ response, urlStr, data, method: "putJson" });
    },
    deleteJson: async ({ urlStr, data }: UrlProperties): Promise<any> => {
        const response = await fetch(`${API_URL}${urlStr}`, {
            method: "DELETE",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + token,
            },
            body: JSON.stringify(data),
        });
        return await handleResponse({
            response,
            urlStr,
            data,
            method: "deleteJson",
        });
    },
};