interface ClientBehaviorsProperties {
    clientId: string;
    name: string;
    goalName: string;
    definition: string;
    doNotZeroOut: boolean;
}

export interface SaveClientBehaviorsProperties extends ClientBehaviorsProperties {
    categoryId: number;
    recordingType: number;
}

export interface UpdateClientBehaviorsProperties extends ClientBehaviorsProperties {
    id: string;
}

export interface ArchiveClientBehaviorsProperties {
    id: string;
    clientId: string;
}

export interface DeleteClientBehaviorsProperties extends ArchiveClientBehaviorsProperties { }

export enum recordingType {
    "frequency" = 1,
    "duration" = 3,
}

export interface RecordingTypeProperties {
    id: number;
    text: string;
}

export interface BehaviorsCategoryProperties {
    id: number;
    name: string;
}

export interface BehaviorReductionProperties {
    id: string;
    name: string;
    goalName: string;
    definition: string;
    createdAt: string;
    recordingType: RecordingTypeProperties;
    category: BehaviorsCategoryProperties;
    dataToday: number;
    isArchived: boolean;
}

export interface BehaviorInfoProperties {
    id: string;
    name: string;
    recordingType: RecordingTypeProperties;
    category: BehaviorsCategoryProperties;
    count: number | null;
    duration: number | null; //seconds
}
