import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { unwrapResult } from "@reduxjs/toolkit";
import dayjs from "dayjs";
import { Collapse, TableCell, TableRow, Tooltip } from "@mui/material";

import { Text, CustomButton, CustomAlert } from "../../../shared/uiComponents";
import { blue400 } from "../../../shared/Helpers/colors";
import Actions from "./Actions";
import TargetInfo from "./TargetInfo";
import { RowStateProperties } from "./tableInfo";
import RBTRow from "../../RBTpanel/ProgramTargets";
import { AdminTypes } from "../../../components/Action";

import { DispatchProperties, StateProperties } from "../../../redux/store";
import {
  getTargets,
  openTarget,
} from "../../../redux/State/clientSlice/programTargetSlice";
import GraphsTarget from "./Actions/GraphsTargetBCBA/graphsTarget";
import { errorNormalizer } from "../../../shared/Helpers/functions";
import { UpdateTargetContent } from "./Actions/EditTarget";
import { TargetProperties } from "../../../redux/API/ClientAPIHelpers/programTargetsProperties";

const Row = ({
  item,
  rowStates,
  setRowStates,
}: {
  item: TargetProperties;
  rowStates: Array<RowStateProperties>;
  setRowStates: Dispatch<SetStateAction<Array<RowStateProperties>>>;
}) => {
  const {
    id,
    name,
    sd,
    status,
    responsePercentage,
    dateOpened,
    dateMastered,
    type,
    programId,
  } = item;
  const [openRowActions, setOpenRowActions] = useState<boolean>(false);
  const [openSure, setOpenSure] = useState<boolean>(false);
  const [editOpen, setEditOpen] = useState<boolean>(false);

  const [isRBT, setIsRBT] = useState<boolean>(false);
  const loading = useSelector(
    (state: StateProperties) => state.programTarget.loading
  );
  const role = useSelector((state: StateProperties) => state.account.role);

  useEffect(() => {
    if (!role) return;
    setIsRBT(role.section.id === AdminTypes.rbt);
  }, [role]);

  const dispatch = useDispatch<DispatchProperties>();

  useEffect(() => {
    const open = rowStates.find((row) => row.id === id)?.open ?? false;

    setOpenRowActions(open);
  }, [rowStates, id]);

  const openTargetHandler = () => {
    if (!id || !programId) return;

    dispatch(openTarget({ id, programId }))
      .then(unwrapResult)
      .then(() => {
        dispatch(getTargets(programId));
        setOpenSure(false);
        toast("Opened");
      })
      .catch((error) => {
        errorNormalizer(error);
        setEditOpen(true);
      });
  };

  const openActionsHandler = () => {
    setRowStates((prev) =>
      prev.map((x) => {
        if (x.id === id) {
          return { ...x, open: !x.open };
        }
        return { ...x, open: false };
      })
    );
  };

  return (
    <>
      <TableRow key={id}>
        <Tooltip arrow title={!!sd ? sd : "No SD"} placement="top-start">
          <TableCell>
            <Text title={name} />
            <Text
              title={`SD: ${!!sd ? sd.substring(0, 60) : "No SD"}`}
              size="tiny"
            />
            <GraphsTarget
              targetId={id}
              targetName={name}
              type={type.id}
              isIcon={true}
            />
          </TableCell>
        </Tooltip>
        <TableCell>
          <Text title={status.name} />
        </TableCell>
        <TableCell>
          <Text title={`${responsePercentage}%`} />
        </TableCell>
        {!isRBT && (
          <TableCell>
            {!!dateOpened ? (
              <Text title={dayjs(dateOpened).format("MM/DD/YYYY")} />
            ) : !openSure ? (
              <CustomButton
                title="Open Target"
                onClick={() => setOpenSure(true)}
              />
            ) : (
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <CustomButton
                  title="Open"
                  onClick={openTargetHandler}
                  loading={loading}
                  disabled={loading}
                />
                <CustomButton
                  title="Cancel"
                  secondaryButton
                  onClick={() => setOpenSure(false)}
                />
              </div>
            )}
          </TableCell>
        )}
        {isRBT && (
          <TableCell>
            {!!dateOpened ? (
              <Text title={dayjs(dateOpened).format("MM/DD/YYYY")} />
            ) : (
              <Text title="-" />
            )}
          </TableCell>
        )}
        <TableCell>
          <Text
            title={
              !!dateMastered ? dayjs(dateMastered).format("MM/DD/YYYY") : "-"
            }
          />
        </TableCell>
        <TableCell>
          <Text title={type.name} />
        </TableCell>
        <TableCell>
          <Text
            title={openRowActions ? "Close" : "Open"}
            textColor={blue400}
            size="smallBold"
            onClick={openActionsHandler}
          />
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ padding: "10px 0" }} colSpan={7}>
          <Collapse in={openRowActions} timeout={"auto"} unmountOnExit>
            {!isRBT && <Actions item={item} />}
            {!isRBT && (
              <TargetInfo
                item={item}
                id={id}
                programId={programId}
                open={openRowActions}
              />
            )}
            {isRBT && <RBTRow item={item} />}
          </Collapse>
        </TableCell>
      </TableRow>
      <CustomAlert
        open={editOpen}
        onClose={() => setEditOpen(false)}
        title="Please, fill required fields."
        Content={() => (
          <UpdateTargetContent
            props={item}
            id={id}
            programId={programId}
            closeHandler={() => setEditOpen(false)}
          />
        )}
      />
    </>
  );
};

export default Row;
