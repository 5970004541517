import { FC, FocusEventHandler } from "react";
import { TimePicker } from "antd";
import Text from "../Text";
import { red500 } from "../../Helpers/colors";
import type { Dayjs } from "dayjs";

interface TimePickerProperties {
  label: string;
  value: Dayjs | null;
  onChange: (date: Dayjs | null) => void;
  onBlur?: FocusEventHandler<HTMLInputElement>;
  className?: string;
  error?: boolean;
  disabled?: boolean;
  errorMessage?: string;
  description?: string;
}

const CustomTime: FC<TimePickerProperties> = ({
  label,
  value,
  onChange = () => {},
  className = "",
  error = false,
  disabled = false,
  errorMessage = "",
  description = "",
  onBlur,
}) => {
  return (
    <div className={className}>
      <Text
        className={`inputLabel ${!!label ? "active" : ""}`}
        title={label}
        size={"tinyBold"}
      />
      <TimePicker
        value={value}
        format={"hh:mm A"}
        placeholder="09:00 AM"
        onChange={onChange}
        onBlur={onBlur}
        size={"large"}
        inputReadOnly={false}
        allowClear={false}
        changeOnBlur={true}
        disabled={disabled}
        getPopupContainer={(triggerNode) => triggerNode}
        showNow={false}
        use12Hours={true}
        style={{ width: "200px" }}
        popupStyle={{ width: "100%" }}
      />
      <Text
        className={`error ${error ? "active" : ""}`}
        title={errorMessage}
        size={"tiny"}
        textColor={red500}
      />
      <Text
        className={`inputDescription ${
          !error && !!description ? "active" : ""
        }`}
        title={description}
        size={"tiny"}
      />
    </div>
  );
};

export default CustomTime;
