import Wrapper from "../../components/WrapperWithoutMenu";
import { ChangePasswordContent } from "./changePassword";
import { RecoveryPasswordContent } from "./recoveryPassword";
import { SetPasswordContent } from "./setPassword";

export const RecoverPassword = () => {
  return <Wrapper Component={RecoveryPasswordContent} />;
};

export const ChangePassword = () => {
  return <Wrapper Component={ChangePasswordContent} />;
};

export const SetPassword = () => {
  return <Wrapper Component={SetPasswordContent} />;
};
