import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useParams, useSearchParams } from "react-router-dom";

import { HeadersRBT, TableBody } from "./tableInfo";

import { DispatchProperties, useSelector } from "../../../../redux/store";
import { getAvailablePatientRBTs } from "../../../../redux/State/clientSlice/userClientSlice";
import SearchBar from "../../../../components/SearchBar";
import { CustomTable } from "../../../../shared/uiComponents";

const RBT = () => {
  const dispatch = useDispatch<DispatchProperties>();
  const [params] = useSearchParams();
  const { clientId } = useParams();

  const rbts = useSelector((state) => state.userClient.availableRBTs);
  const loading = useSelector(
    (state) => state.userClient.loadingGetAvailableRBTs
  );

  useEffect(() => {
    if (!clientId) return;
    const page = params.get("page") || "1";
    const pageSize = params.get("pageSize") || "8";
    const orderBy = params.get("orderBy") || "";
    const direction = params.get("direction") || "";
    const searchString = params.get("search") || "";
    const status = params.get("status") || "";

    dispatch(
      getAvailablePatientRBTs({
        clientId,
        page,
        pageSize,
        orderBy,
        direction,
        searchString,
        status,
      })
    );
  }, [params, clientId, dispatch]);

  return (
    <>
      <div style={{ float: "right" }}>
        <SearchBar label="Find" />
      </div>
      <CustomTable
        headers={HeadersRBT}
        data={rbts}
        TableBody={TableBody}
        loading={loading}
        hasMobile={false}
      />
    </>
  );
};

export default RBT;
