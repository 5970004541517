import { useReducer, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { unwrapResult } from "@reduxjs/toolkit";

import { ACTIONS, patientReducer } from "./patientReducer";
import { initialUpdateClientState } from "../patientInitialStates";
import PatientForm from "./patientForm";
import { errorNormalizer } from "../../../../shared/Helpers/functions";

import { DispatchProperties } from "../../../../redux/store";
import {
  getPatient,
  updatePatient,
} from "../../../../redux/State/clientSlice/clientSlice";
import { UpdateClientInfoProperties } from "../../../../redux/API/ClientAPIHelpers/clientProperties";

const UpdatePatient = () => {
  const dispatch = useDispatch<DispatchProperties>();
  const navigate = useNavigate();
  const { patientId } = useParams();

  const [patientInfo, setPatientInfo] = useReducer(
    patientReducer,
    initialUpdateClientState
  );

  useEffect(() => {
    if (!patientId) return;
    dispatch(getPatient(patientId))
      .then(unwrapResult)
      .then((response) => {
        const insuranceId = response.insurance?.id;
        const status = response.status.id;
        const diagnosisCodeId = response.diagnosisCode.id;
        const payload: UpdateClientInfoProperties = {
          ...response,
          insuranceId,
          status,
          diagnosisCodeId,
        };

        setPatientInfo({ type: ACTIONS.setState, payload });
      })
      .catch(errorNormalizer);
  }, [patientId, dispatch]);

  const updatePatientHandler = () => {
    dispatch(updatePatient(patientInfo))
      .then(unwrapResult)
      .then(() => navigate(-1))
      .catch(errorNormalizer);
  };

  return (
    <PatientForm
      patientInfo={patientInfo}
      setPatientInfo={setPatientInfo}
      savePatientHandler={updatePatientHandler}
    />
  );
};

export default UpdatePatient;
