import behaviorReductionSlice from "./behaviorReductionSlice";
import billingSlice from "./billingSlice";
import calendarSlice from "./calendarSlice";
import clientProgramSlice from "./clientProgramSlice";
import clientSlice from "./clientSlice";
import dataCollectionSlice from "./dataCollectionSlice";
import graphAnnotationSlice from "./graphAnnotationSlice";
import graphSlice from "./graphSlice";
import programTargetSlice from "./programTargetSlice";
import sessionSlice from "./sessionSlice";
import skillAreaSlice from "./skillAreaSlice";
import soapNoteSlice from "./soapNoteSlice";
import userClientSlice from "./userClientSlice";

const Client = {
    behaviorReduction: behaviorReductionSlice,
    billing: billingSlice,
    calendar: calendarSlice,
    client: clientSlice,
    clientProgram: clientProgramSlice,
    dataCollaction: dataCollectionSlice,
    graph: graphSlice,
    graphAnnotation: graphAnnotationSlice,
    programTarget: programTargetSlice,
    session: sessionSlice,
    skillArea: skillAreaSlice,
    soapNote: soapNoteSlice,
    userClient: userClientSlice,
}

export default Client;