import { useParams } from "react-router-dom";
import GraphBehavior from "./BehaviorReductionGraph/graphBehavior";
import Archive from "./archive";
import DeleteBehavior from "./deleteBehavior";
import Details from "./details";
import EditBehaviorDetails from "./editBehaviorDetails";
import Unarchive from "./unarchive";
import { BehaviorReductionProperties } from "../../../../../../redux/API/ClientAPIHelpers/behaviorReductionProperties";
import { Text } from "../../../../../../shared/uiComponents";

const Actions = ({ row }: { row: BehaviorReductionProperties }) => {
  const { clientId } = useParams();
  if (!clientId) return <Text title={"Can't apply actions"} />;
  return (
    <>
      <Details data={row} />
      <GraphBehavior data={row} behaviorId={row.id} />
      <EditBehaviorDetails data={{ ...row, clientId, doNotZeroOut: false }} />
      {!row.isArchived ? (
        <Archive data={{ clientId, id: row.id }} />
      ) : (
        <Unarchive data={{ clientId, id: row.id }} />
      )}
      <DeleteBehavior data={{ clientId, id: row.id }} />
    </>
  );
};

export default Actions;
