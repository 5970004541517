import {
  AddAdmin,
  AddPatient,
  AddPermission,
  AdminNotFound,
  BCBA,
  Dashboard,
  PatientAssign,
  Patients,
  Permissions,
  RBT,
  UpdateAdmin,
  UpdatePatient,
  UpdatePermission,
  Reports,
} from "../pages/AdminPanel";
import AdminSOAPNotes from "../pages/AdminPanel/SOAPNotes";
import UserSOAPNotes from "../pages/BCBAPanel/SOAPNotes";
import { InsuranceAuthorization } from "../pages/AdminPanel/Patients/Patient/InsuranceAuthorization/";
import EditSoapNote from "../pages/AdminPanel/SOAPNotes/editSoapNote";
import PatientSoapNotes from "../pages/AdminPanel/Patients/Patient/patientSoapNotes";

import { Calendar, PatientsBCBA, ProgramTargets } from "../pages/BCBAPanel";

import SOAPNote from "../pages/BCBAPanel/SOAPNote";
import Session from "../pages/Session";
import ReportsBCBA from "../pages/BCBAPanel/Reports";
import Patient from "../pages/AdminPanel/Patients/Patient";
import AllUsers from "../pages/AdminPanel/Users";
import Administrators from "../pages/AdminPanel/Admins";

export const AdminRoutes = [
  { path: "dashboard", Component: Dashboard },
  { path: "notifications", Component: Dashboard },
  { path: "insurance-companies", Component: Dashboard },
  { path: "therapy-programs", Component: Dashboard },
  { path: "user", Component: AllUsers },
  { path: "administrator", Component: Administrators },
  { path: "bcba", Component: BCBA },
  { path: "rbt", Component: RBT },
  { path: "reports", Component: Reports },
  { path: "user/new", Component: AddAdmin },
  { path: "administrator/new", Component: AddAdmin },
  { path: "bcba/new", Component: AddAdmin },
  { path: "rbt/new", Component: AddAdmin },
  { path: "user/:userId", Component: UpdateAdmin },
  { path: "administrator/:userId", Component: UpdateAdmin },
  { path: "bcba/:userId", Component: UpdateAdmin },
  { path: "rbt/:userId", Component: UpdateAdmin },
  { path: "patients/:clientId/assign/:fullName", Component: PatientAssign },
  {
    path: "patients/:clientId/insurance-authorization",
    Component: InsuranceAuthorization,
  },
  { path: "patients", Component: Patients },
  { path: "patients/new", Component: AddPatient },
  { path: "patients/:patientId", Component: UpdatePatient },
  { path: "patients/patient/:clientId", Component: Patient },
  {
    path: "patients/patient/:clientId/:programName/:programId",
    Component: ProgramTargets,
  },
  { path: "permissions", Component: Permissions },
  { path: "permissions/new", Component: AddPermission },
  { path: "permissions/:roleId", Component: UpdatePermission },
  { path: "soap-notes", Component: AdminSOAPNotes },
  { path: "soap-notes/edit", Component: EditSoapNote },
  { path: "soap-notes/info", Component: SOAPNote },
  { path: "calendar/start-session/:sessionId/:clientId", Component: Session },
  { path: "calendar", Component: Calendar },
  { path: "*", Component: AdminNotFound },
];

export const BCBARoutes = [
  { path: "dashboard", Component: Dashboard },
  { path: "notifications", Component: Dashboard },
  { path: "schedule", Component: Dashboard },
  { path: "calendar", Component: Calendar },
  { path: "calendar/start-session/:sessionId/:clientId", Component: Session },
  { path: "patients", Component: PatientsBCBA },
  { path: "patients/:clientId/assign/:fullName", Component: PatientAssign },
  { path: "patients/new", Component: AddPatient },
  { path: "patients/:patientId", Component: UpdatePatient },
  { path: "patients/patient/:clientId", Component: Patient },
  {
    path: "patients/patient/:clientId/soap-notes",
    Component: PatientSoapNotes,
  },
  {
    path: "patients/patient/:clientId/soap-notes/info/:reportId",
    Component: SOAPNote,
  },
  {
    path: "patients/patient/:clientId/:programName/:programId",
    Component: ProgramTargets,
  },
  { path: "soap-notes", Component: UserSOAPNotes },
  { path: "soap-notes/info", Component: SOAPNote },
  { path: "reports", Component: ReportsBCBA },
];

export const RBTRoutes = [
  { path: "dashboard", Component: Dashboard },
  { path: "notifications", Component: Dashboard },
  { path: "schedule", Component: Dashboard },
  { path: "calendar", Component: Calendar },
  { path: "calendar/start-session/:sessionId/:clientId", Component: Session },
  { path: "patients", Component: PatientsBCBA },
  { path: "patients/:clientId/assign/:fullName", Component: PatientAssign },
  { path: "patients/new", Component: AddPatient },
  { path: "patients/:patientId", Component: UpdatePatient },
  { path: "patients/patient/:clientId", Component: Patient },
  {
    path: "patients/patient/:clientId/soap-notes",
    Component: PatientSoapNotes,
  },
  {
    path: "patients/patient/:clientId/soap-notes/info/:reportId",
    Component: SOAPNote,
  },
  {
    path: "patients/patient/:clientId/:programName/:programId",
    Component: ProgramTargets,
  },
  { path: "soap-notes", Component: UserSOAPNotes },
  { path: "soap-notes/info", Component: SOAPNote },
];
