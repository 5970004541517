import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  ArchiveClientBehaviorsProperties,
  BehaviorReductionProperties,
  BehaviorsCategoryProperties,
  DeleteClientBehaviorsProperties,
  RecordingTypeProperties,
  SaveClientBehaviorsProperties,
  UpdateClientBehaviorsProperties
} from "../../API/ClientAPIHelpers/behaviorReductionProperties";
import API from "../../API/Main/API";
import { AnnotationBehaviorDate } from "../../API/ClientAPIHelpers/graphAnnotationProperties";
import { ErrorProperties } from "../../API/identityAPIProperties";

interface BehaviorReductionStateProperties {
  loading: boolean;
  behaviors: Array<BehaviorReductionProperties>;
  behaviorTypes: Array<RecordingTypeProperties>;
  behaviorCategories: Array<BehaviorsCategoryProperties>;
  behaviorDataPeriodAnnotations: Array<AnnotationBehaviorDate>;
  error: ErrorProperties;
}

const initialBehaviorReductionState: BehaviorReductionStateProperties = {
  loading: false,
  behaviors: [],
  behaviorTypes: [],
  behaviorCategories: [],
  behaviorDataPeriodAnnotations: [],
  error: {
    status: 0,
    title: "",
  },
};

export const getBehaviorTypes = createAsyncThunk(
  "/behaviors/types",
  async (_, thunkAPI) => {
    const response = await API.ClientAPI.BehaviorReduction.behaviors.getTypes();
    if (!!response.error) {
      return thunkAPI.rejectWithValue(response.error);
    }
    return response as Array<RecordingTypeProperties>;
  }
);

export const getBehaviorCategories = createAsyncThunk(
  "/behaviors/categories",
  async (_, thunkAPI) => {
    const response =
      await API.ClientAPI.BehaviorReduction.behaviors.getCategories();
    if (!!response.error) {
      return thunkAPI.rejectWithValue(response.error);
    }
    return response as Array<BehaviorsCategoryProperties>;
  }
);

export const getBehaviors = createAsyncThunk(
  "/behaviors/get",
  async (clientId: string, thunkAPI) => {
    const response =
      await API.ClientAPI.BehaviorReduction.clients.getClientBehaviors(
        clientId
      );
    if (!!response.error) {
      return thunkAPI.rejectWithValue(response.error);
    }
    return response as Array<BehaviorReductionProperties>;
  }
);

export const saveBehavior = createAsyncThunk(
  "/behaviors/save",
  async (data: SaveClientBehaviorsProperties, thunkAPI) => {
    const response =
      await API.ClientAPI.BehaviorReduction.clients.saveClientBehaviors(data);
    if (!!response.error) {
      return thunkAPI.rejectWithValue(response.error);
    }
    return response as BehaviorReductionProperties;
  }
);

export const updateBehavior = createAsyncThunk(
  "/behaviors/update",
  async (data: UpdateClientBehaviorsProperties, thunkAPI) => {
    const response =
      await API.ClientAPI.BehaviorReduction.clients.updateClientBehaviors(data);
    if (!!response.error) {
      return thunkAPI.rejectWithValue(response.error);
    }
    return response as BehaviorReductionProperties;
  }
);

export const deleteBehavior = createAsyncThunk(
  "/behaviors/delete",
  async (data: DeleteClientBehaviorsProperties, thunkAPI) => {
    const response =
      await API.ClientAPI.BehaviorReduction.clients.deleteClientBehaviors(data);
    if (!!response.error) {
      return thunkAPI.rejectWithValue(response.error);
    }
    return response as { isSuccessful: boolean };
  }
);

export const archiveBehavior = createAsyncThunk(
  "/behaviors/archive",
  async (data: ArchiveClientBehaviorsProperties, thunkAPI) => {
    const response =
      await API.ClientAPI.BehaviorReduction.clients.archiveClientBehaviors(
        data
      );
    if (!!response.error) {
      return thunkAPI.rejectWithValue(response.error);
    }
    return response as BehaviorReductionProperties;
  }
);

export const unArchiveBehavior = createAsyncThunk(
  "/behaviors/unarchive",
  async (data: ArchiveClientBehaviorsProperties, thunkAPI) => {
    const response =
      await API.ClientAPI.BehaviorReduction.clients.unArchiveClientBehaviors(
        data
      );
    if (!!response.error) {
      return thunkAPI.rejectWithValue(response.error);
    }
    return response as BehaviorReductionProperties;
  }
);

const BehaviorReductionSlice = createSlice({
  name: "behaviorReduction",
  initialState: initialBehaviorReductionState,
  reducers: {},
  extraReducers: (builder) => {
    //saveBehavior
    builder.addCase(saveBehavior.pending, (state) => {
      state.loading = true;
      state.error = initialBehaviorReductionState.error;
    });
    builder.addCase(saveBehavior.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(saveBehavior.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload as ErrorProperties;
    });
    //updateBehavior
    builder.addCase(updateBehavior.pending, (state) => {
      state.loading = true;
      state.error = initialBehaviorReductionState.error;
    });
    builder.addCase(updateBehavior.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(updateBehavior.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload as ErrorProperties;
    });
    //getBehaviorCategories
    builder.addCase(getBehaviorCategories.pending, (state) => {
      state.loading = true;
      state.error = initialBehaviorReductionState.error;
    });
    builder.addCase(getBehaviorCategories.fulfilled, (state, action) => {
      state.loading = false;
      state.behaviorCategories = action.payload;
      state.error = initialBehaviorReductionState.error;
    });
    builder.addCase(getBehaviorCategories.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload as ErrorProperties;
    });
    //getBehaviorTypes
    builder.addCase(getBehaviorTypes.pending, (state) => {
      state.loading = true;
      state.error = initialBehaviorReductionState.error;
    });
    builder.addCase(getBehaviorTypes.fulfilled, (state, action) => {
      state.loading = false;
      state.behaviorTypes = action.payload;
      state.error = initialBehaviorReductionState.error;
    });
    builder.addCase(getBehaviorTypes.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload as ErrorProperties;
    });
    //getBehaviors
    builder.addCase(getBehaviors.pending, (state) => {
      state.loading = true;
      state.error = initialBehaviorReductionState.error;
    });
    builder.addCase(getBehaviors.fulfilled, (state, action) => {
      state.loading = false;
      state.behaviors = action.payload;
      state.error = initialBehaviorReductionState.error;
    });
    builder.addCase(getBehaviors.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload as ErrorProperties;
    });
  },
});

export default BehaviorReductionSlice.reducer;
