import { useState, FC } from "react";
import { styled } from "@mui/material/styles";
import {
  CloseOutlined as Close,
  CheckOutlined as Check,
  DeleteOutline as Trash,
} from "@mui/icons-material";
import { Tooltip } from "@mui/material";

interface DeleteProperties {
  deleteHandler: () => void;
}

const Wrapper = styled("div")<{ removeConfirm: boolean }>((props) => ({
  width: props.removeConfirm ? "40px" : "20px",
  display: "flex",
  justifyContent: "space-between",
  cursor: "pointer",
}));

const Delete: FC<DeleteProperties> = ({ deleteHandler }) => {
  const [removeConfirm, setRemoveConfirm] = useState<boolean>(false);

  const onDelete = () => {
    deleteHandler();
    setRemoveConfirm(false);
  };

  return (
    <Wrapper removeConfirm={removeConfirm}>
      <Tooltip title="Delete">
        {removeConfirm ? (
          <>
            <div onClick={() => setRemoveConfirm(false)}>
              <Close fontSize="small" />
            </div>
            <div onClick={onDelete}>
              <Check fontSize="small" />
            </div>
          </>
        ) : (
          <div onClick={() => setRemoveConfirm(true)}>
            <Trash fontSize="small" />
          </div>
        )}
      </Tooltip>
    </Wrapper>
  );
};

export default Delete;
