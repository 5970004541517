import { FC } from "react";
import { useDispatch } from "react-redux";
import { Link, useLocation } from "react-router-dom";

import {
  PersonOutlined as Person,
  EmailOutlined as Email,
  PhoneIphoneOutlined as Phone,
  PsychologyOutlined as Therapist,
} from "@mui/icons-material";

import { PatientsDataProperties } from "./tableInfo";
import { Loader, Row, Wrapper } from "../StyledComponents";
import {
  MobilePagination,
  Text,
  PatientStatusBadge,
} from "../../../shared/uiComponents";

import { DispatchProperties, useSelector } from "../../../redux/store";
import MobileSort from "../../../components/MobileSort";
import { SelectDataProperties } from "../../../shared/uiComponents/Dropdown";
import { clearSkillAreas } from "../../../redux/State/clientSlice/skillAreaSlice";
import Action from "./actions";

export const patientSortData: Array<SelectDataProperties> = [
  { id: "", name: "none" },
  { id: "fullName", name: "Full Name" },
  { id: "numberOfTherapist", name: "Providers" },
  { id: "parentPhoneNumber", name: "Parent Phone Number" },
  { id: "status", name: "Status" },
];

export const patientStatusData: Array<SelectDataProperties> = [
  { id: "0", name: "Active" },
  { id: "1", name: "Paused" },
  { id: "2", name: "Discharged" },
];

const MobileView: FC<PatientsDataProperties> = (data) => {
  const dispatch = useDispatch<DispatchProperties>();
  const { pathname } = useLocation();

  const { query, totalNumberOfItems } = data;
  const loading = useSelector((state) => state.client.loading);

  const navigatePatientHandler = () => {
    dispatch(clearSkillAreas());
  };

  return (
    <div className={"mobileView"}>
      <MobileSort
        data={patientSortData}
        hasStatus={true}
        statusData={patientStatusData}
      />
      {loading && <Loader />}
      {!loading && !totalNumberOfItems && (
        <div className={"mobileView"}>
          <Text
            title={"No results"}
            size={"smallBold"}
            className={"marginAuto"}
          />
        </div>
      )}
      {!loading &&
        !!query &&
        query.map((item, index) => (
          <Wrapper key={index}>
            <Row style={{ justifyContent: "space-between" }}>
              <div style={{ marginLeft: "16px" }}>
                <PatientStatusBadge statusCode={item.status.id} />
              </div>
              <Action item={item} />
            </Row>
            <Link to={`${pathname}/patient/${item.id}`}>
              <Row>
                <Person />
                <Text
                  title={item.fullName}
                  className={"marginLeft8"}
                  onClick={navigatePatientHandler}
                />
              </Row>
            </Link>
            <Row>
              <Email /> <Text title={item.email} className={"marginLeft8"} />
            </Row>
            <Row>
              <Therapist />
              <Text
                title={`${item.numberOfTherapist}`}
                className={"marginLeft8"}
              />
            </Row>
            <Row>
              <Phone />
              <Text
                title={!!item.parentPhoneNumber ? item.parentPhoneNumber : "-"}
                className={"marginLeft8"}
              />
            </Row>
          </Wrapper>
        ))}
      <MobilePagination data={data} />
    </div>
  );
};

export default MobileView;
