import { IdentityAPI } from "../IdentityAPI";
import { ClientAPI } from "../ClientAPI";
import { UserAPI } from "../UserAPI";
import { ExportAPI } from "../ExportAPI";
import { setToken } from "./fetchAPI";

const API = {
    setToken,
    IdentityAPI,
    ClientAPI,
    UserAPI,
    ExportAPI,
};

export default API;