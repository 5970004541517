import { SOAPNoteWrapper } from "../helpers";
import CreateSoapNote from "./createAssessment";
import UpdateSoapNote from "./updateAssessment";

const Assessment = () => (
  <SOAPNoteWrapper
    CreateSoapNote={CreateSoapNote}
    UpdateSoapNote={UpdateSoapNote}
  />
);

export default Assessment;
