import { IdentityAPI } from "../IdentityAPI";
import { FetchAPI, setToken } from "./fetchAPI";

export interface UrlProperties {
    urlStr: string;
    data?: object | string;
}

export interface ResponseProperties {
    response: any;
    urlStr: string;
    data?: any;
    method: string;
}

export const API_URL =
    process.env.NODE_ENV === "development"
        ? "https://lighthouse-app-dev.eastus.cloudapp.azure.com/api"
        : `https://${window.location.host}/api`;


let loading = false;

const setLoading = (flag: boolean) => (loading = flag);

export const getToken = () => window.localStorage.getItem("token");
export const getRefreshToken = () =>
    window.localStorage.getItem("refresh-token");

export const refresh = async ({
    urlStr,
    data,
    method,
}: {
    urlStr: string;
    data: any;
    method: string;
}) => {
    const token = getRefreshToken();

    if (!token) {
        console.error("Refresh token is missing");
        return;
    }

    try {
        const newToken = await IdentityAPI.Account.refresh(token);

        setToken(newToken.token.access_token);
        window.localStorage.setItem("token", JSON.stringify(newToken.token));
        window.localStorage.setItem("refresh-token", newToken.token.refresh_token);
        setLoading(false);
        // @ts-ignore
        const callback = FetchAPI[method];

        return callback({ urlStr, data });
    } catch (error) {
        console.error(error);
        window.localStorage.removeItem("token");
        window.location.reload();
    }
};

export const recursion = async ({
    urlStr,
    data,
    method,
}: {
    urlStr: string;
    data: any;
    method: string;
}) => {
    const token = window.localStorage.getItem("refresh-token");

    if (!token) {
        console.error("Refresh token is missing");
        return;
    }
    // @ts-ignore
    const callback = FetchAPI[method];

    return callback({ urlStr, data });
};

export const handleResponse = async ({
    response,
    urlStr,
    data,
    method,
}: ResponseProperties) => {
    let responseObj = {};

    try {
        const contentType = (
            response.headers.get("content-type") || ""
        ).toLowerCase();

        if (contentType.indexOf("application/json") !== -1) {
            responseObj = await response.json();
        } else if (contentType.indexOf("application/pdf") !== -1) {
            responseObj = await response.blob();
        } else if (contentType.indexOf("application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") !== -1) {
            const file: Blob = await response.blob();
            const stringArray: Array<string> = response.headers.get("content-disposition").split(';')
            const fileName = stringArray.find(n => n.includes('filename='))?.slice(11, -1) ?? "";
            responseObj = {
                file,
                fileName,
            }
        } else if (contentType.indexOf("application/zip") !== -1) {
            responseObj = await response.blob();
            // const stringArray: Array<string> = response.headers.get("content-disposition").split(';')
            // const fileName = stringArray.find(n => n.includes('filename='))?.slice(11, -1) ?? "";
            // responseObj = {
            //     file,
            //     fileName,
            // }
        } else {
            responseObj = await response.json();
        }
    } catch (error: any) {
        responseObj = { title: error.message };
    }

    if (response.status === 401) {
        if (!loading) {
            setLoading(true);
            return await refresh({ urlStr, data, method });
        } else {
            return await recursion({ urlStr, data, method });
        }
    }

    if (response.ok) {
        return responseObj;
    }

    return {
        error: responseObj,
    };
};