import { ChangeEvent, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";

import {
  CustomAlert,
  CustomButton,
  CustomInput,
} from "../../../../../../shared/uiComponents";
import { errorNormalizer } from "../../../../../../shared/Helpers/functions";

import { DispatchProperties, useSelector } from "../../../../../../redux/store";
import { AddClientProgramProperties } from "../../../../../../redux/API/ClientAPIHelpers/clientProgramProperties";
import {
  addProgram,
  getPrograms,
} from "../../../../../../redux/State/clientSlice/clientProgramSlice";

const AddProgram = () => {
  const [addProgramOpen, setAddProgramOpen] = useState<boolean>(false);
  const [params] = useSearchParams();
  const skillAreaId = params.get("skillAreaId");
  const loading = useSelector((state) => state.clientProgram.loading);

  const onCloseAddProgram = () => setAddProgramOpen(false);

  return (
    <>
      <CustomButton
        title="Add program"
        onClick={() => setAddProgramOpen(true)}
        className={"marginBottom16 marginTop16"}
        disabled={!skillAreaId}
        loading={loading}
      />
      <CustomAlert
        open={addProgramOpen}
        onClose={onCloseAddProgram}
        title={"Add program"}
        Content={({ onClose }) => <AddProgramContent onClose={onClose} />}
      />
    </>
  );
};

const AddProgramContent = ({ onClose }: { onClose: () => void }) => {
  const dispatch = useDispatch<DispatchProperties>();
  const [params] = useSearchParams();
  const skillAreaId = params.get("skillAreaId");

  const [name, setName] = useState<string>("");
  const [goalName, setGoalName] = useState<string>("");

  const onProgramNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setName(value);
  };

  const onGoalNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setGoalName(value);
  };

  const saveHandler = async () => {
    if (!name || !goalName || !skillAreaId) return;

    const data: AddClientProgramProperties = { goalName, name };

    dispatch(addProgram({ data, skillAreaId }))
      .then(unwrapResult)
      .then(() => {
        dispatch(getPrograms({ skillAreaId }));
        onClose();
      })
      .catch(errorNormalizer);
  };

  return (
    <>
      <CustomInput
        label={"Program name"}
        value={name}
        setValue={onProgramNameChange}
        className={"marginBottom8"}
      />
      <CustomInput
        label={"Goal name"}
        value={goalName}
        setValue={onGoalNameChange}
        className={"marginBottom24"}
      />
      <CustomButton
        title={"Save"}
        onClick={saveHandler}
        disabled={!name || !goalName || !skillAreaId}
      />
    </>
  );
};

export default AddProgram;
