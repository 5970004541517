import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import "./targets.scss";
import {
  Text,
  CustomTable,
  CustomAccordion,
} from "../../../shared/uiComponents";
import { TargetBody, TargetHeader } from "./tableInfo";
import Create from "./Actions/CreateTarget/";
import Actions from "./Actions";
import { AdminTypes } from "../../../components/Action";
import TargetInfo from "./TargetInfo";
import RBTRow from "../../RBTpanel/ProgramTargets";

import { DispatchProperties, StateProperties } from "../../../redux/store";
import { getTargets } from "../../../redux/State/clientSlice/programTargetSlice";
import TargetDetails from "./Actions/detailsTarget";
import { TargetProperties } from "../../../redux/API/ClientAPIHelpers/programTargetsProperties";

const ProgramTargets = () => {
  const dispatch = useDispatch<DispatchProperties>();
  const [isRBT, setIsRBT] = useState<boolean>(false);

  const { programName, programId } = useParams();

  const programTargets = useSelector(
    (state: StateProperties) => state.programTarget.targets
  );
  const role = useSelector((state: StateProperties) => state.account.role);
  const loading = useSelector(
    (state: StateProperties) => state.programTarget.loadingGetTargets
  );

  useEffect(() => {
    if (!role) return;
    setIsRBT(role.section.id === AdminTypes.rbt);
  }, [role]);

  useEffect(() => {
    if (!programId) return;

    dispatch(getTargets(programId));
  }, [programId, dispatch]);

  return (
    <div className="container" style={{ flexDirection: "column" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Text
          title={programName as string}
          size="mediumBold"
          className="marginBottom16"
        />
        {!isRBT && <Create />}
      </div>
      <div className="targetsDesktop">
        <CustomTable
          data={{ query: programTargets }}
          headers={TargetHeader}
          TableBody={TargetBody}
          loading={loading}
          hasPagination={false}
        />
      </div>
      <div className="targetsMobile">
        {!!programTargets &&
          programTargets.map((target) => (
            <div className="targets" key={target.id}>
              <CustomAccordion
                HeaderContent={() => <TargetDetails target={target} />}
                Content={({ open }) => (
                  <TargetContent target={target} open={open} />
                )}
              />
            </div>
          ))}
      </div>
    </div>
  );
};

const TargetContent = ({
  target,
  open,
}: {
  target: TargetProperties;
  open: boolean;
}) => {
  const [isRBT, setIsRBT] = useState<boolean>(false);
  const { id, programId } = target;
  const role = useSelector((state: StateProperties) => state.account.role);

  useEffect(() => {
    if (!role) return;
    setIsRBT(role.section.id === AdminTypes.rbt);
  }, [role]);

  return !isRBT ? (
    <>
      <Actions item={target} />
      <TargetInfo item={target} id={id} programId={programId} open={open} />
    </>
  ) : (
    <RBTRow item={target} />
  );
};

export default ProgramTargets;
