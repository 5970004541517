import { styled } from "@mui/material";
import { white100 } from "../../shared/Helpers/colors";

export const WhiteContainer = styled("div")(() => ({
  width: "464px",
  maxWidth: "464px",
  padding: "48px",
  backgroundColor: white100,
  boxShadow: "0px 4px 10px rgba(224, 238, 242, 0.8)",
  borderRadius: "12px",
  "@media (max-width: 600px)": {
    padding: "24px",
    backgroundColor: "transparent",
    boxShadow: "none",
  },
}));

export const LoginFunctionalities = styled("div")(() => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  marginBottom: "16px",
}));

export const ImageWrapper = styled("div")(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-start",
  width: '100%',
  height: '120px',
  overflow: 'hidden',
  userSelect: 'none',
}));
