import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import API from "../../API/Main/API";
import {
  GetDurationProperties,
  GetFrequencyProperties,
  GetSessionTrialsInfoProperties,
  PromptCodeProperties,
  SaveDurationProperties,
  SaveFrequencyProperties,
  SessionTrialProperties,
  TargetDTTSessionProperties,
  TargetTASessionProperties,
  UpdateFrequencyProperties,
  UpdatePromptsProperties
} from "../../API/ClientAPIHelpers/dataCollectionProperties";
import { TargetTypes } from "../../API/ClientAPIHelpers/programTargetsProperties";
import { BehaviorInfoProperties } from "../../API/ClientAPIHelpers/behaviorReductionProperties";
import {
  DTTPromptsResponseProperties,
  TAPromptsResponseProperties,
} from "../../API/ClientAPIHelpers/calendarProperties";
import { ErrorProperties } from "../../API/identityAPIProperties";
import { DataProperties } from "../../../shared/uiComponents/Radio";

interface DataCollectionStateProperties {
  loading: boolean;
  promptsDTT: Array<DTTPromptsResponseProperties>;
  promptsTA: Array<TAPromptsResponseProperties>;
  promptCodes: Array<DataProperties>,
  sessionBehaviors: Array<BehaviorInfoProperties>;
  error: ErrorProperties;
}

const initialDataCollectionState: DataCollectionStateProperties = {
  loading: false,
  promptsDTT: [],
  promptsTA: [],
  sessionBehaviors: [],
  promptCodes: [],
  error: {
    status: 0,
    title: ''
  },
}

export const getPromptCodes = createAsyncThunk(
  "/prompts/codes",
  async (_, thunkAPI) => {
    const response = await API.ClientAPI.PromptCode.getPrompCodes();
    if (!!response.error) {
      return thunkAPI.rejectWithValue(response.error);
    }
    const normalizedResponse: Array<DataProperties> =
      response.map((x: PromptCodeProperties) => ({ id: x.id, label: x.abbreviation }))
    return normalizedResponse;
  }
);

export const getDTTPrompts = createAsyncThunk(
  '/prompts/dtt',
  async ({ targetId, sessionId }: { targetId: string, sessionId?: string }, thunkAPI) => {
    const response = await API.ClientAPI.DataCollection.targets.getDTTPrompts(targetId, sessionId);
    if (!!response.error) {
      return thunkAPI.rejectWithValue(response.error);
    }
    return response as Array<DTTPromptsResponseProperties>;
  }
);

export const getTAPrompts = createAsyncThunk(
  '/prompts/ta',
  async ({ targetId, sessionId }: { targetId: string, sessionId?: string }, thunkAPI) => {
    const response = await API.ClientAPI.DataCollection.targets.getTAPrompts(targetId, sessionId);
    if (!!response.error) {
      return thunkAPI.rejectWithValue(response.error);
    }
    return response as Array<TAPromptsResponseProperties>;
  }
);

export const updateDTTPrompts = createAsyncThunk(
  '/prompts/dtt/update',
  async ({ targetId, data }: UpdatePromptsProperties, thunkAPI) => {
    const response = await API.ClientAPI.DataCollection.targets.updateDTTPrompts(targetId, data);
    if (!!response.error) {
      return thunkAPI.rejectWithValue(response.error);
    }
    return response;
  }
);

export const updateTAPrompts = createAsyncThunk(
  '/prompts/ta/update',
  async ({ targetId, data }: UpdatePromptsProperties, thunkAPI) => {
    const response = await API.ClientAPI.DataCollection.targets.updateTAPrompts(targetId, data);
    if (!!response.error) {
      return thunkAPI.rejectWithValue(response.error);
    }
    return response;
  }
);

export const getSessionTrialInfo = createAsyncThunk(
  '/sessions/getInfo',
  async (data: GetSessionTrialsInfoProperties, thunkAPI) => {
    const { type } = data;
    if (type === TargetTypes.DTT) {
      const response = await API.ClientAPI.DataCollection.sessions.targets.getDDTSessionTrialInfo(data);
      if (!!response.error) {
        return thunkAPI.rejectWithValue(response.error);
      }
      return response as SessionTrialProperties;
    }
    if (type === TargetTypes.TA) {
      const response = await API.ClientAPI.DataCollection.sessions.targets.getTASessionTrialInfo(data);
      if (!!response.error) {
        return thunkAPI.rejectWithValue(response.error);
      }
      return response as SessionTrialProperties;
    }
    //default value to eliminate undefined 
    return { correctPercentage: 0, independent: 0, prompt: 0, trials: 0 } as SessionTrialProperties;
  }
);

export const saveDTTSessionInfo = createAsyncThunk(
  '/sessions/dtt',
  async (data: TargetDTTSessionProperties, thunkAPI) => {
    const response = await API.ClientAPI.DataCollection.sessions.targets.saveDTTSessionInfo(data);
    if (!!response.error) {
      return thunkAPI.rejectWithValue(response.error);
    }
    return response;
  }
);

export const saveTASessionInfo = createAsyncThunk(
  '/sessions/ta',
  async (data: TargetTASessionProperties, thunkAPI) => {
    const response = await API.ClientAPI.DataCollection.sessions.targets.saveTASessionInfo(data);
    if (!!response.error) {
      return thunkAPI.rejectWithValue(response.error);
    }
    return response;
  }
);

export const deletePrompts = createAsyncThunk(
  '/delete/prompts',
  async (data: Array<string>, thunkAPI) => {
    const response = await API.ClientAPI.DataCollection.responses.deletePrompt(data);
    if (!!response.error) {
      return thunkAPI.rejectWithValue(response.error);
    }
    return response;
  }
);

export const getSessionBehaviors = createAsyncThunk(
  'get/session/behaviors',
  async (sessionId: string, thunkAPI) => {
    const response = await API.ClientAPI.DataCollection.sessions.behaviors.getBehaviors(sessionId);
    if (!!response.error) {
      return thunkAPI.rejectWithValue(response.error);
    }
    return response as Array<BehaviorInfoProperties>;
  }
)

export const getFrequency = createAsyncThunk(
  '/frequency/get',
  async (data: GetFrequencyProperties, thunkAPI) => {
    const response = await API.ClientAPI.DataCollection.sessions.behaviors.getFrequency(data);
    if (!!response.error) {
      return thunkAPI.rejectWithValue(response.error);
    }
    return response as { behaviorId: string; count: number };
  }
)

export const saveFrequency = createAsyncThunk(
  '/frequency/save',
  async (data: SaveFrequencyProperties, thunkAPI) => {
    const response = await API.ClientAPI.DataCollection.sessions.behaviors.saveFrequency(data);
    if (!!response.error) {
      return thunkAPI.rejectWithValue(response.error);
    }
    return response;
  }
)

export const updateFrequency = createAsyncThunk(
  '/frequency/update',
  async (data: UpdateFrequencyProperties, thunkAPI) => {
    const response = await API.ClientAPI.DataCollection.sessions.behaviors.updateFrequency(data);
    if (!!response.error) {
      return thunkAPI.rejectWithValue(response.error);
    }
    return response;
  }
)

export const getDuration = createAsyncThunk(
  '/duration/get',
  async (data: GetDurationProperties, thunkAPI) => {
    const response = await API.ClientAPI.DataCollection.sessions.behaviors.getDuration(data);
    if (!!response.error) {
      return thunkAPI.rejectWithValue(response.error);
    }
    return response as { behaviorId: string; duration: string };
  }
)

export const saveDuration = createAsyncThunk(
  '/duration/save',
  async (data: SaveDurationProperties, thunkAPI) => {
    const response = await API.ClientAPI.DataCollection.sessions.behaviors.saveDuration(data);
    if (!!response.error) {
      return thunkAPI.rejectWithValue(response.error);
    }
    return response;
  }
)

const DataCollectionSlice = createSlice({
  name: "dataCollection",
  initialState: initialDataCollectionState,
  reducers: {},
  extraReducers: (builder) => {

    //getSessionBehaviors
    builder.addCase(getSessionBehaviors.pending, (state) => {
      state.loading = true;
      state.error = initialDataCollectionState.error;
    });
    builder.addCase(getSessionBehaviors.fulfilled, (state, action) => {
      state.loading = false;
      state.sessionBehaviors = action.payload;
    });
    builder.addCase(getSessionBehaviors.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload as ErrorProperties;
    });
    //getPromptCodes
    builder.addCase(getPromptCodes.pending, (state) => {
      state.loading = true;
      state.error = initialDataCollectionState.error;
    });
    builder.addCase(getPromptCodes.fulfilled, (state, action) => {
      state.loading = false;
      state.promptCodes = action.payload;
    });
    builder.addCase(getPromptCodes.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload as ErrorProperties;
    });
    //getDTTPrompts
    builder.addCase(getDTTPrompts.pending, (state) => {
      state.loading = true;
      state.error = initialDataCollectionState.error;
    })
    builder.addCase(getDTTPrompts.fulfilled, (state, action) => {
      state.loading = false;
      state.promptsDTT = action.payload;
    })
    builder.addCase(getDTTPrompts.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload as ErrorProperties;
    })

    //getTAPrompts
    builder.addCase(getTAPrompts.pending, (state) => {
      state.loading = true;
      state.error = initialDataCollectionState.error;
    });
    builder.addCase(getTAPrompts.fulfilled, (state, action) => {
      state.loading = false;
      state.promptsTA = action.payload;
    });
    builder.addCase(getTAPrompts.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload as ErrorProperties;
    });

    //getDTTSessionTrialInfo
    builder.addCase(getSessionTrialInfo.pending, (state) => {
      state.loading = true;
      state.error = initialDataCollectionState.error;
    });
    builder.addCase(getSessionTrialInfo.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(getSessionTrialInfo.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload as ErrorProperties;
    });

    //deletePrompts
    builder.addCase(deletePrompts.pending, (state) => {
      state.loading = true;
      state.error = initialDataCollectionState.error;
    });
    builder.addCase(deletePrompts.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(deletePrompts.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload as ErrorProperties;
    });
  },
});

export default DataCollectionSlice.reducer;
