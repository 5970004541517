import { FC, MouseEventHandler } from "react";
import Button, { ButtonProps } from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import { CircularProgress } from "@mui/material";

import { blue100, blue400, gray100, white100 } from "../../Helpers/colors";

type ButtonTypes = "button" | "submit";

interface ButtonProperties {
  title: string;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  type?: ButtonTypes;
  disabled?: boolean;
  secondaryButton?: boolean;
  className?: string;
  loading?: boolean;
}

const CustomizedButton = styled(Button)<ButtonProps>(() => ({
  border: "1px solid",
  borderColor: blue400,
  borderRadius: "10px",
  color: white100,
  background: blue400,
  width: "100%",
  lineHeight: "24px",
  whiteSpace: "nowrap",
  "&:disabled": {
    backgroundColor: blue100,
    color: white100,
    borderColor: blue100,
  },
  "&:hover": {
    background: blue100,
    borderColor: blue100,
  },
}));

const CustomizedButtonWhite = styled(Button)<ButtonProps>(() => ({
  border: "1px solid",
  borderColor: blue400,
  borderRadius: "10px",
  color: blue400,
  background: white100,
  width: "100%",
  lineHeight: "24px",
  whiteSpace: "nowrap",
  "&:disabled": {
    backgroundColor: white100,
    color: blue400,
    borderColor: blue400,
  },
  "&:hover": {
    background: gray100,
    borderColor: blue400,
  },
}));

const CustomButton: FC<ButtonProperties> = ({
  title,
  onClick,
  type = "button",
  disabled = false,
  secondaryButton = false,
  className,
  loading = false,
}) => {
  const ButtonVariant = secondaryButton
    ? CustomizedButtonWhite
    : CustomizedButton;

  return (
    <div className={className}>
      <ButtonVariant
        type={type}
        variant="contained"
        disabled={disabled || loading}
        onClick={onClick}
      >
        {!loading ? title : <CircularProgress size={24} color={"inherit"} />}
      </ButtonVariant>
    </div>
  );
};

export default CustomButton;
