import { PERMISSIONS } from "../../../App/constants";
import { useSelector } from "../../../redux/store"

export const useCheckPermission = (permission: string | null) => {
    const role = useSelector(state => state.account.role);
    if (!role) return { permissionGranted: false };
    if (!permission) return { permissionGranted: true };
    const claims: Array<string> = role.claims.map((x) => x.claimValue);
    const permissionGranted = claims.includes(PERMISSIONS.SUPERADMIN) || claims.includes(permission) || claims.some(x => x.includes(permission))
    return { permissionGranted };
}