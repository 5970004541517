import { ChangeEvent, FormEvent, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { unwrapResult } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "react-redux";
import {
  VisibilityOutlined as ShowPassword,
  VisibilityOffOutlined as HidePassword,
} from "@mui/icons-material";

import {
  CustomInput,
  CustomButton,
  CustomCheckBox,
  Text,
} from "../../shared/uiComponents/";
import { blue400 } from "../../shared/Helpers/colors";
import Wrapper from "../../components/WrapperWithoutMenu";
import Logo from "../../shared/Assets/logos/IlluminateLogoMain.png";
import { errorNormalizer, validateEmail } from "../../shared/Helpers/functions";
import {
  ImageWrapper,
  LoginFunctionalities,
  WhiteContainer,
} from "./styledComponents";
import { AdminTypes } from "../../components/Action";
import { IMG } from "../../components/Menu/styledComponents";

import { DispatchProperties, StateProperties } from "../../redux/store";
import { login } from "../../redux/State/identitySlice/accountSlice";

const LoginContent = () => {
  const loading = useSelector(
    (state: StateProperties) => state.account.loading
  );
  const [email, setEmail] = useState<string>("");
  const [emailError, setEmailError] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [passwordError, setPasswordError] = useState<string>("");

  const navigate = useNavigate();
  const dispatch = useDispatch<DispatchProperties>();

  const [rememberMe, setRememberMe] = useState<boolean>(true);
  const [showPassword, setShowPassword] = useState<boolean>(false);

  const onForgetClick = () => {
    navigate(`/recover-password?email=${encodeURIComponent(email)}`);
  };

  useEffect(() => {
    window.localStorage.setItem("remember", JSON.stringify(rememberMe));
  }, [rememberMe]);

  const handleEmailInputBlur = () => {
    if (!!email && !validateEmail(email)) {
      setEmailError("Please enter a valid email");
    } else {
      setEmailError("");
    }
  }

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!email || !password){
      if (!email) {
        setEmailError("Please enter your email");
        return;
      }

      if (!password) {
        setPasswordError("Please enter your password");
      }

      return;
    }

    dispatch(login({ email, password }))
      .then(unwrapResult)
      .then(({ role }) => {
        const section = role.section.id;

        switch (section) {
          case AdminTypes.administrator:
            return navigate("/admin/dashboard");
          case AdminTypes.bcba:
            return navigate("/bcba/dashboard");
          case AdminTypes.rbt:
            return navigate("/rbt/dashboard");
          default:
            return navigate("/bcba/dashboard");
        }
      })
      .catch(errorNormalizer);
  };

  const onPasswordChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setPassword(value);
    if (!!value) {
      setPasswordError("");
    }
  };

  const onEmailChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value.trim();
    setEmail(value);
    if (!!value) {
      setEmailError("");
    }
  };

  const onRememberMeChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { checked } = event.target;
    setRememberMe(checked);
  };

  return (
    <WhiteContainer>
      <Text title={"Login"} size={"largeBold"} className={"marginBottom24"} />

      <form onSubmit={handleSubmit}>
        <CustomInput
          value={email}
          setValue={onEmailChange}
          label={"Email"}
          className={"marginBottom16"}
          type={"email"}
          error={!!emailError}
          errorMessage={emailError}
          onBlur={handleEmailInputBlur}
        />
        <CustomInput
          value={password}
          setValue={onPasswordChange}
          label={"Password"}
          className={"marginBottom16"}
          type={showPassword ? "text" : "password"}
          EndAdornment={{
            Icon: showPassword ? ShowPassword : HidePassword,
            onClickEndAdornment: () => setShowPassword(!showPassword),
          }}
          error={!!passwordError}
          errorMessage={passwordError}
        />

        <LoginFunctionalities>
          <CustomCheckBox
            item={{ checked: rememberMe, id: "1", label: "Remember me" }}
            onChange={onRememberMeChange}
          />
          <Text
            title={"Forgot password?"}
            className={"link"}
            textColor={blue400}
            onClick={onForgetClick}
          />
        </LoginFunctionalities>

        <CustomButton
          title={"Log in"}
          type={"submit"}
          loading={loading}
          disabled={!!emailError || !!passwordError}
        />
      </form>

      <ImageWrapper>
        <IMG src={Logo} alt={"Logo"} />
      </ImageWrapper>
    </WhiteContainer>
  );
};

const Login = () => {
  return <Wrapper Component={LoginContent} />;
};

export default Login;
