import { FC } from "react";
import { useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { TableBody as Body, TableCell, TableRow } from "@mui/material";

import { Text, TableNoData } from "../../../shared/uiComponents";
import {
  HeaderProperties,
  TableDataProperties,
} from "../../../shared/uiComponents/Table/tableProperties";
import Action from "../../../components/Action";

import { UserProperties } from "../../../redux/API/userAPIProperties";

import { DispatchProperties } from "../../../redux/store";
import { getBCBAs } from "../../../redux/State/userSlice";
import { UserStatusBadge } from "../../../shared/uiComponents/StatusBadge";

export interface AdminDataProperties extends TableDataProperties {
  query: Array<UserProperties> | null;
}

interface RowRendererProperties {
  data: UserProperties[];
}

export const Headers: HeaderProperties[] = [
  { id: "0", name: "Full Name", orderBy: "fullName" },
  {
    id: "1",
    name: "Number of Clients",
    orderBy: "numberOfClients",
    width: "200px",
  },
  { id: "2", name: "Status", orderBy: "emailConfirmed" },
  { id: "3", name: "Licensed or Limited", orderBy: "authorizationType" },
  { id: "4", name: "Role" },
  { id: "5", name: "Actions", width: "350px" },
];

export const TableBody: FC<RowRendererProperties> = ({ data }) => {
  const dispatch = useDispatch<DispatchProperties>();
  const [params] = useSearchParams();

  const fetchUsers = () => {
    const page = params.get("page") || "1";
    const pageSize = params.get("pageSize") || "8";
    const orderBy = params.get("orderBy") || "";
    const direction = params.get("direction") || "";
    const searchString = params.get("search") || "";

    dispatch(getBCBAs({ page, pageSize, orderBy, direction, searchString }));
  };

  if (!data || !data.length) {
    return <TableNoData spanScope={Headers.length} />;
  }

  return (
    <Body>
      {data.map((row, index) => (
        <TableRow key={index}>
          <TableCell>
            <Text title={row.fullName} size={"tiny"} />
          </TableCell>
          <TableCell>
            <Text title={row.numberOfClients.toString()} size={"tiny"} />
          </TableCell>
          <TableCell>
            <UserStatusBadge emailConfirmed={row.emailConfirmed} />
          </TableCell>
          <TableCell>
            <Text
              title={!!row.authorizationType ? row.authorizationType.name : "-"}
              size={"tiny"}
            />
          </TableCell>
          <TableCell>
            <Text title={row.role.name} size={"tiny"} />
          </TableCell>
          <TableCell>
            <Action item={row} fetchUsers={fetchUsers} />
          </TableCell>
        </TableRow>
      ))}
    </Body>
  );
};
