import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { ChangeEvent, useEffect, useState } from "react";
import { SelectChangeEvent, styled } from "@mui/material";

import {
  TableBody,
  TableBodyAll,
  TableBodyBCBA,
  headers,
  headersAll,
} from "./tableInfo";
import AllocateHours from "./AllocateHours";
import {
  CustomButton,
  CustomTable,
  Text,
} from "../../../../../shared/uiComponents";
import AddInsuranceAuthorization from "./AddInsuranceAuthorization";
import CustomSelect, {
  SelectDataProperties,
} from "../../../../../shared/uiComponents/Dropdown";
import { AdminTypes } from "../../../../../components/Action";
import CustomRadio from "../../../../../shared/uiComponents/Radio";
import { blue400 } from "../../../../../shared/Helpers/colors";

import { DispatchProperties, useSelector } from "../../../../../redux/store";
import { getTherapists } from "../../../../../redux/State/clientSlice/userClientSlice";
import {
  getActiveInsuranceAuthorizations,
  getInsuranceAuthorizations,
} from "../../../../../redux/State/clientSlice/insuranceAuthorizationSlice";
import { ActiveAuthorizationProperties } from "../../../../../redux/API/ClientAPIHelpers/insuranceAuthorizationProperties";

const Wrapper = styled("div")(() => ({
  display: "flex",
  flexDirection: "column",
  flexWrap: "wrap",
  width: "300px",
}));

export const InsuranceAuthorization = () => {
  const dispatch = useDispatch<DispatchProperties>();
  const [params, setParams] = useSearchParams();

  const navigate = useNavigate();
  const { clientId } = useParams();

  const [therapistId, setTherapistId] = useState<string>("");
  const [option, setOption] = useState<string>("1");

  const therapists = useSelector((state) => state.userClient.therapists);
  const [therapistsDataBCBA, setTherapistsDataBCBA] = useState<
    Array<SelectDataProperties>
  >([]);
  const authorizationList = useSelector(
    (state) => state.authorization.authorizationDetails
  );
  const [therapistsDataRBT, setTherapistsDataRBT] = useState<
    Array<SelectDataProperties>
  >([]);
  const [authorizations, setAuthorizations] = useState<
    Array<ActiveAuthorizationProperties>
  >([]);

  useEffect(() => {
    if (!authorizationList) return;
    if (option === "2") {
      const rbtAuthorizations = authorizationList
        .filter((x) =>
          x.authorizationDetails.find((y) => y.sessionType.isForRbts)
        )
        .map((z) => ({
          ...z,
          authorizationDetails: z.authorizationDetails.filter((t) =>
            t.sessionType.name.includes("Behavior Treatment")
          ),
        }));
      setAuthorizations(rbtAuthorizations);
      return;
    }
    const bcbaAuthorizations = authorizationList
      .filter((x) =>
        x.authorizationDetails.find((y) => !y.sessionType.isForRbts)
      )
      .map((z) => ({
        ...z,
        authorizationDetails: z.authorizationDetails.filter(
          (t) => !t.sessionType.name.includes("Behavior Treatment")
        ),
      }));
    setAuthorizations(bcbaAuthorizations);
  }, [option, authorizationList]);

  useEffect(() => {
    if (!therapists) return;
    const dataBcba: Array<SelectDataProperties> = therapists
      .filter((x) => x.role.section.id !== AdminTypes.rbt)
      .map((x) => ({
        id: x.id,
        name: `${x.fullName} (${x.role.name})`,
      }));
    const dataRbt: Array<SelectDataProperties> = therapists
      .filter((x) => x.role.section.id === AdminTypes.rbt)
      .map((x) => ({
        id: x.id,
        name: `${x.fullName}`,
      }));
    setTherapistsDataBCBA(dataBcba);
    setTherapistsDataRBT(dataRbt);
  }, [therapists, dispatch]);

  useEffect(() => {
    if (!clientId) return;
    dispatch(getTherapists(clientId));
  }, [clientId, dispatch]);

  useEffect(() => {
    if (!clientId || !!authorizationList) return;
    dispatch(getActiveInsuranceAuthorizations({ clientId }));
  }, [clientId, authorizationList, dispatch]);

  const onChangeTherapist = (event: SelectChangeEvent<string>) => {
    const { value } = event.target;
    setTherapistId(value);
  };

  const navigateAddAuthorizationHandler = () => {
    params.set("tab", "Insurance Authorization");
    navigate(`/admin/patients/patient/${clientId}?${params.toString()}`);
  };

  const therapistRadioHandler = (
    _: ChangeEvent<HTMLInputElement>,
    value: string
  ) => {
    setOption(value);
    params.delete("authId");
    setParams(params);
    setTherapistId("");
  };

  return !!authorizationList && !!authorizationList.length ? (
    <>
      <Wrapper className="marginBottom16 marginTop16">
        <CustomRadio
          id={"1"}
          label={"For BCBA"}
          value={option}
          onChange={therapistRadioHandler}
        />
        {option === "1" && (
          <CustomSelect
            data={therapistsDataBCBA}
            value={therapistId}
            setValue={onChangeTherapist}
            className="marginBottom16 marginTop16"
          />
        )}
        <CustomRadio
          id={"2"}
          label={"For RBTs"}
          value={option}
          onChange={therapistRadioHandler}
        />
        {option === "2" && (
          <CustomSelect
            data={therapistsDataRBT}
            value={therapistId}
            setValue={onChangeTherapist}
            className="marginBottom16 marginTop16"
          />
        )}
        <AllocateHours therapistId={therapistId} />
      </Wrapper>
      <CustomTable
        TableBody={TableBody}
        headers={headers}
        data={{ query: authorizations }}
        loading={false}
        hasPagination={false}
      />
    </>
  ) : (
    <Wrapper>
      <Text
        title={"Client does not have active insurances"}
        size="smallBold"
        className="marginBottom16"
      />
      <CustomButton
        title="Please, Add authorization"
        onClick={navigateAddAuthorizationHandler}
      />
    </Wrapper>
  );
};

export const InsuranceAuthorizationTab = () => {
  const role = useSelector((state) => state.account.role);

  const [panelId, setPanelId] = useState<string>("1");

  if (!role) return null;

  return (
    <div
      style={{
        border: `2px solid ${blue400}`,
        borderRadius: "12px",
        padding: "10px",
        marginTop: "10px",
      }}
    >
      {role.section.id === AdminTypes.administrator ? (
        <>
          <div
            style={{
              display: "flex",
              gap: "16px",
              border: `1px solid ${blue400}`,
              borderRadius: "12px",
              padding: "8px",
              width: "max-content",
            }}
          >
            <Text
              title={"Client authorizations"}
              onClick={() => setPanelId("1")}
              size="smallBold"
              textColor={panelId === "1" ? blue400 : ""}
            />
            <Text
              title={"My authorizations"}
              onClick={() => setPanelId("2")}
              size="smallBold"
              textColor={panelId === "2" ? blue400 : ""}
            />
          </div>
          <div>
            {panelId === "1" && <AdminPanel />}
            {panelId === "2" && <BCBAPanel />}
          </div>
        </>
      ) : (
        <BCBAPanel />
      )}
    </div>
  );
};

const AdminPanel = () => {
  const dispatch = useDispatch<DispatchProperties>();
  const { clientId } = useParams();
  const [params] = useSearchParams();

  const userId = useSelector((state) => state.account.user.id);
  const loading = useSelector((state) => state.authorization.loading);
  const authorization = useSelector(
    (state) => state.authorization.allInsuranceAuthorizations
  );

  useEffect(() => {
    if (!clientId || !userId) return;
    const page = params.get("page") || "1";
    const pageSize = params.get("pageSize") || "8";

    dispatch(getInsuranceAuthorizations({ clientId, page, pageSize }));
  }, [clientId, dispatch, userId, params]);

  return (
    <>
      <AddInsuranceAuthorization />
      <CustomTable
        TableBody={TableBodyAll}
        headers={headersAll}
        data={authorization}
        loading={loading}
      />
    </>
  );
};

const BCBAPanel = () => {
  const dispatch = useDispatch<DispatchProperties>();
  const { clientId } = useParams();

  const userId = useSelector((state) => state.account.user.id);
  const loading = useSelector((state) => state.authorization.loading);
  const authorization = useSelector(
    (state) => state.authorization.authorizationDetails
  );

  useEffect(() => {
    if (!clientId || !userId) return;
    dispatch(getActiveInsuranceAuthorizations({ clientId, userId }));
  }, [clientId, dispatch, userId]);

  return (
    <CustomTable
      TableBody={TableBodyBCBA}
      headers={headersAll}
      data={{ query: authorization }}
      loading={loading}
      hasPagination={false}
    />
  );
};
